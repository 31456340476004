import React, { Suspense, lazy } from 'react';
import Loading from 'components/Loading/Loading';

const IrrigationSystemForm = lazy(() =>
  import(
    /* webpackChunkName: "IrrigationSystemForm" */ './IrrigationSystemForm'
  )
);

const LoadableIrrigationSystemForm: React.FC = props => (
  <Suspense fallback={<Loading />}>
    <IrrigationSystemForm {...props} />
  </Suspense>
);

export default LoadableIrrigationSystemForm;
