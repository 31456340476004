import * as GoogleAnalytics from 'react-ga';
import amplitude from 'amplitude-js';

// Only initialize once ever
let initialized = false;
let analyticsEnabled = false;
const project = amplitude.getInstance();
const initializeIfNeeded = (_analyticsEnabled: boolean) => {
  if (initialized) return;
  analyticsEnabled = _analyticsEnabled;
  if (!analyticsEnabled) return;

  GoogleAnalytics.initialize('UA-69562088-4');
  project.init('28327cd4ba2612f0e65e94e36903ab3f');

  initialized = true;
};

const set = (fields: GoogleAnalytics.FieldsObject) => {
  if (!analyticsEnabled) return;
  GoogleAnalytics.set(fields);
};

const setUserId = (id: string | null) => {
  if (!analyticsEnabled) return;
  project.setUserId(id);
};

const pageview = (page: string) => {
  if (!analyticsEnabled) return;
  GoogleAnalytics.pageview(page);
};

type LocalstorageEvents = string;
type FieldsPageEvents = 'search';

interface Events {
  Localstorage: LocalstorageEvents;
  'Fields Page': FieldsPageEvents;
}
type EventCategory = keyof Events;

function event<Category extends EventCategory>(
  // Typically the object that was interacted with (e.g. 'Video')
  category: Category,
  // The type of interaction (e.g. 'play')
  action: Events[Category],
  // Useful for categorizing events (e.g. 'Fall Campaign')
  label: string,
  // 	A numeric value associated with the event (e.g. 42)
  value?: number
) {
  if (!analyticsEnabled) return;
  const amplitudeEvent = `${category} - ${action}`;
  project.logEvent(amplitudeEvent, { label, value });
  GoogleAnalytics.event({ category, action, label, value });
}

export default {
  event,
  initializeIfNeeded,
  set,
  setUserId,
  pageview,
};
