import React, { Suspense, lazy } from 'react';
import Loading from 'components/Loading/Loading';

const IrrigationSystemList = lazy(() =>
  import(
    /* webpackChunkName: "IrrigationSystemList" */ './IrrigationSystemList'
  )
);

const LoadableIrrigationSystemList: React.FC = props => (
  <Suspense fallback={<Loading />}>
    <IrrigationSystemList {...props} />
  </Suspense>
);

export default LoadableIrrigationSystemList;
