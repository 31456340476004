import React, { Suspense, lazy } from 'react';
import Loading from 'components/Loading/Loading';

const PressureSensorEdit = lazy(() =>
  import(/* webpackChunkName: "PressureSensorEdit" */ './PressureSensorEdit')
);

const LoadablePressureSensorEdit: React.FC = props => (
  <Suspense fallback={<Loading />}>
    <PressureSensorEdit {...props} />
  </Suspense>
);

export default LoadablePressureSensorEdit;
