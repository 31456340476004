import React, { useContext } from 'react';

const ResponsiveContext = React.createContext({
  isMobile: false,
});

export const {
  Consumer: ResponsiveConsumer,
  Provider: ResponsiveProvider,
} = ResponsiveContext;

export const MobileOnly: (props: React.Props<{}>) => JSX.Element = ({
  children,
}) => (
  <ResponsiveConsumer>
    {({ isMobile }) => isMobile && children}
  </ResponsiveConsumer>
);

export const DesktopOnly: (props: React.Props<{}>) => JSX.Element = ({
  children,
}) => (
  <ResponsiveConsumer>
    {({ isMobile }) => !isMobile && children}
  </ResponsiveConsumer>
);

export const IsMobileConsumer = ResponsiveConsumer;

export const useIsMobile = () => useContext(ResponsiveContext).isMobile;
