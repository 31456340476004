import React, { Suspense, lazy } from 'react';
import Loading from 'components/Loading/Loading';

const MissingInfo = lazy(() =>
  import(/* webpackChunkName: "MissingInfo" */ './MissingInfo')
);

const LoadableMissingInfo: React.FC = props => (
  <Suspense fallback={<Loading />}>
    <MissingInfo {...props} />
  </Suspense>
);

export default LoadableMissingInfo;
