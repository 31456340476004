import * as React from 'react';

import debounce from 'lodash/debounce';

import { ResponsiveProvider } from 'providers/Responsive';

const MOBILE_LIMIT = 600;

interface WithResponsiveState {
  width: number;
  height: number;
  isMobile: boolean;
}

const withResponsive = (WrappedComponent: React.ComponentType) => {
  class WithResponsive extends React.Component<{}, WithResponsiveState> {
    handleResize: () => void;
    isMobile: (windowWidth: number, windowHeight: number) => boolean;
    constructor(props: {}) {
      super(props);
      this.isMobile = (windowWidth: number, windowHeight: number) => {
        if (windowWidth < MOBILE_LIMIT) return true;
        if (windowHeight < MOBILE_LIMIT) return true;
        return false;
      };

      const initialWidth = window.innerWidth;
      const initialHeight = window.innerHeight;
      this.state = {
        width: initialWidth,
        height: initialHeight,
        isMobile: this.isMobile(initialWidth, initialHeight),
      };

      this.handleResize = debounce(() => {
        const width = window.innerWidth;
        const height = window.innerHeight;
        this.setState({
          width,
          height,
          isMobile: this.isMobile(width, height),
        });
      }, 500);
    }

    componentDidMount() {
      window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.handleResize);
    }

    shouldComponentUpdate(_nextProps: {}, nextState: WithResponsiveState) {
      return this.state.isMobile !== nextState.isMobile;
    }

    render() {
      return (
        <ResponsiveProvider value={{ isMobile: this.state.isMobile }}>
          <WrappedComponent {...this.props} />
        </ResponsiveProvider>
      );
    }
  }

  return WithResponsive;
};

export default withResponsive;
