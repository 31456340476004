import React, { Suspense, lazy } from 'react';
import Loading from '../../components/Loading/Loading';

const Field = lazy(() => import(/* webpackChunkName: "Field" */ './Field'));

const LoadableField: React.FC = props => (
  <Suspense fallback={<Loading />}>
    <Field {...props} />
  </Suspense>
);

export default LoadableField;
