import React, { Suspense, lazy } from 'react';
import Loading from 'components/Loading/Loading';

const Gateway = lazy(() =>
  import(/* webpackChunkName: "Gateway" */ './Gateway')
);

const LoadableGateway: React.FC<import('./Gateway').GatewayProps> = props => (
  <Suspense fallback={<Loading />}>
    <Gateway {...props} />
  </Suspense>
);

export default LoadableGateway;
