import * as React from 'react';

import App from './App';
import withTheme from './wrappers/theme';
import withTracker from './wrappers/analytics';
import withApollo from './wrappers/apollo';
import withErrorHandler from './wrappers/errorHandler';
import withResponsive from './wrappers/responsive';

// These are applied in descending order
const wrappers = [
  withErrorHandler,
  withResponsive,
  withApollo,
  withTracker,
  withTheme,
];

type HOC = (component: React.ComponentType<{}>) => React.ComponentType<{}>;

function applyWrappers(root: React.ComponentType<{}>, arr: HOC[]) {
  return arr
    .reverse()
    .reduce((e: React.ComponentType<{}>, f: HOC) => f(e), root);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Wrapped = applyWrappers(App as any, wrappers as any);

export default Wrapped;
