import * as React from 'react';
import { Link } from '@reach/router';
import IconButton from '@material-ui/core/IconButton';
import NotificationsIcon from '@material-ui/icons/Notifications';

import { colors } from 'services/theme';

interface NotificationsButtonProps {
  unread: number;
  active: boolean;
}

interface NotificationsButtonState {}

class NotificationsButton extends React.Component<
  NotificationsButtonProps,
  NotificationsButtonState
> {
  render() {
    const amountUnread = this.props.unread > 99 ? '99+' : this.props.unread;
    return (
      <Link to="/notifications" style={{ position: 'relative' }}>
        {this.props.unread > 0 && (
          <div
            style={{
              position: 'absolute',
              borderRadius: '50%',
              background: colors.error,
              width: 22,
              height: 22,
              border: `2px solid ${colors.error}`,
              color: 'white',
              display: 'flex',
              font: '10px Arial, sans-serif',
              alignItems: 'center',
              justifyContent: 'center',
              top: 5,
              right: 5,
              zIndex: 1,
              pointerEvents: 'none',
            }}
          >
            {amountUnread}
          </div>
        )}
        <IconButton
          style={{
            background: this.props.active ? 'rgb(191, 200, 196)' : '#E4EEEA',
          }}
          centerRipple={false}
        >
          <NotificationsIcon style={{ fontSize: 36 }} />
        </IconButton>
      </Link>
    );
  }
}

export default NotificationsButton;
