import React, { Suspense, lazy } from 'react';
import Loading from 'components/Loading/Loading';

const Archive = lazy(() =>
  import(/* webpackChunkName: "Archive" */ './Archive')
);

const LoadableArchive: React.FC = (props) => (
  <Suspense fallback={<Loading />}>
    <Archive {...props} />
  </Suspense>
);

export default LoadableArchive;
