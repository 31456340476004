import React from 'react';

import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';

import CloseIcon from '@material-ui/icons/Close';

import { colors, weights, FontClasses } from 'services/theme';
import { useIsMobile } from 'providers/Responsive';

interface BasicModalProps {
  title?: string;
  subtitle?: string;
  children?: JSX.Element | Array<JSX.Element>;
  open: boolean;
  onClose: () => void;
  maxWidth?: number | string;
  noPadding?: boolean;
  testid?: string;
  overflow?: 'auto' | 'visible';
}

const BasicModal: React.FC<BasicModalProps> = (props) => {
  const {
    title,
    subtitle,
    open,
    onClose,
    maxWidth,
    noPadding,
    testid,
    children,
    overflow,
  } = props;

  const isMobile = useIsMobile();

  const padding = isMobile ? 10 : 20;

  const bothStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    background: 'white',
    // visible pulls date picker to front of page
    overflowY: overflow || 'visible',
    // Gets rid of blue outline for focus
    outline: 'none',
  };

  const desktopStyle: React.CSSProperties = {
    ...bothStyle,
    boxShadow: '1px 1px 16px -1px black',
    maxHeight: '90%',
    marginLeft: 48,
    marginRight: 48,
    maxWidth,
    flex: 1,
  };

  const mobileStyle: React.CSSProperties = {
    ...bothStyle,
    margin: 'auto',
    height: '100%',
    width: '100%',
  };

  const modalStyle = isMobile ? mobileStyle : desktopStyle;

  const modalHeader: React.CSSProperties = {
    display: 'flex',
    background: colors.lightGrey,
    margin: 0,
    padding,
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: '0 0 auto',
  };
  const modalHeaderContent: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
  };
  const modalHeaderTitle = {
    color: colors.text,
    fontWeight: weights.bold,
    margin: 0,
  };
  const modalHeaderSubtitle: React.CSSProperties = {
    color: colors.textLight,
    margin: 0,
  };
  const contentStyle: React.CSSProperties = {
    padding: noPadding ? 0 : padding,
    flex: '1 1 auto',
    flexDirection: 'column',
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div style={modalStyle} data-testid={testid}>
        {(title || subtitle) && (
          <div className={FontClasses.title} style={modalHeader}>
            <div style={modalHeaderContent}>
              <span className={FontClasses.title} style={modalHeaderTitle}>
                {title}
              </span>
              <span
                className={FontClasses.subtitle}
                style={modalHeaderSubtitle}
              >
                {subtitle}
              </span>
            </div>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
        )}
        <div style={contentStyle}>{children}</div>
      </div>
    </Modal>
  );
};

export default BasicModal;
