import React, { Suspense, lazy } from 'react';
import Loading from 'components/Loading/Loading';

const IrrigationSystem = lazy(() =>
  import(/* webpackChunkName: "IrrigationSystem" */ './IrrigationSystem')
);

const LoadableIrrigationSystem: React.FC = props => (
  <Suspense fallback={<Loading />}>
    <IrrigationSystem {...props} />
  </Suspense>
);

export default LoadableIrrigationSystem;
