import React, { useEffect } from 'react';
import { Location, WindowLocation } from '@reach/router';
import { withAuth, InjectedAuthProps } from 'providers/Auth';

import { firebaseAuth } from 'services/auth';
import { isProduction } from 'services/env';
import analytics from 'services/analytics';

type LocationProps = { location: WindowLocation };
type AnalyticsProps = LocationProps & InjectedAuthProps;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const withRouter = (WrappedComponent: React.ComponentType<any>) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const Routed = (props: any) => (
    <Location>
      {({ location }) => <WrappedComponent location={location} {...props} />}
    </Location>
  );
  return Routed;
};

const withTracker = (WrappedComponent: React.ComponentType) => {
  const Analytics: React.FC<AnalyticsProps> = props => {
    const analyticsEnabled = isProduction && !props.peeking;
    analytics.initializeIfNeeded(analyticsEnabled);

    // Track page views
    const page = props.location.pathname;
    useEffect(() => {
      analytics.set({ page });
      analytics.pageview(page);
    }, [page]);

    // Set user id
    useEffect(() => {
      const unsub = firebaseAuth().onAuthStateChanged(user => {
        const userId = user === null ? null : user.uid;
        analytics.set({ userId });
        analytics.setUserId(userId);
      });
      return unsub;
    }, []);

    return <WrappedComponent {...props} />;
  };

  return withRouter(withAuth(Analytics));
};

export default withTracker;
