/* THIS IS A GENERATED FILE, DO NOT EDIT DIRECTLY */
/* eslint-disable */
import gql from 'graphql-tag';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export enum AboveOrBelow {
  above = 'above',
  below = 'below',
}

export type ActivateCustomerInput = {
  /** The id of the customer to activate */
  customerId: Scalars['String'];
};

export type ActivateCustomerResponse = {
  __typename: 'ActivateCustomerResponse';
  userId: Maybe<Scalars['String']>;
};

export type ActivationAddCardToUserInput = {
  last4: Scalars['String'];
  brand: Scalars['String'];
  /** Stripe card token */
  stripeToken: Scalars['String'];
  userId: Scalars['String'];
  /** The users activation token */
  token: Scalars['String'];
};

export type ActivationChangeDataAccessDataInput = {
  id: Scalars['String'];
  plan?: Maybe<SensorDataPlan>;
  gateway?: Maybe<Scalars['Boolean']>;
};

export type ActivationChangeDataAccessInput = {
  dataAccessData?: Maybe<Array<Maybe<ActivationChangeDataAccessDataInput>>>;
  token: Scalars['String'];
  userId: Scalars['String'];
};

export type ActivationChangeMutationResponse = {
  __typename: 'ActivationChangeMutationResponse';
  /** The id of the user modified */
  userId: Maybe<Scalars['String']>;
};

export type ActivationChangeNameInput = {
  name: NameInput;
  token: Scalars['String'];
  userId: Scalars['String'];
};

export type ActivationChangePasswordInput = {
  password: Scalars['String'];
  token: Scalars['String'];
  userId: Scalars['String'];
};

export type ActivationFinishInput = {
  token: Scalars['String'];
  userId: Scalars['String'];
  notifications?: Maybe<ActivationFinishNotificationsInput>;
  sharing?: Maybe<Array<Maybe<ActivationFinishSharingInput>>>;
};

export type ActivationFinishNotificationsInput = {
  triggerAlertThresholdHours: Scalars['Float'];
  textEnabled: Scalars['Boolean'];
  phone: Scalars['String'];
  emailEnabled: Scalars['Boolean'];
  email: Scalars['String'];
};

export type ActivationFinishPrepaidNotificationsInput = {
  triggerAlertThresholdHours: Scalars['Float'];
  textEnabled: Scalars['Boolean'];
  phone: Scalars['String'];
  emailEnabled: Scalars['Boolean'];
  email: Scalars['String'];
};

export type ActivationFinishSharingInput = {
  email: Scalars['String'];
  name: Scalars['String'];
  andEdit: Scalars['Boolean'];
};

export type ActivationPrepaidFinishInput = {
  token: Scalars['String'];
  userId: Scalars['String'];
  notifications?: Maybe<ActivationFinishPrepaidNotificationsInput>;
  sharing?: Maybe<Array<Maybe<ActivationFinishSharingInput>>>;
};

export type ActivationProgress = {
  __typename: 'ActivationProgress';
  billing: Scalars['Boolean'];
  dataAccess: Scalars['Boolean'];
  name: Scalars['Boolean'];
  password: Scalars['Boolean'];
};

export type ActivationProgressInput = {
  userId: Scalars['String'];
  token: Scalars['String'];
};

export type ActivationProgressResponse = {
  __typename: 'ActivationProgressResponse';
  /** Activation URL for customer */
  activationUrl: Maybe<Scalars['String']>;
  /** The id of the user */
  id: Scalars['String'];
  /** The users name, if they have set it yet */
  name: Maybe<Name>;
  email: Maybe<Scalars['String']>;
  /** The users selected data plans */
  dataAccess: Maybe<Array<ActivationProgressResponseDataAccess>>;
  cardBrand: Maybe<Scalars['String']>;
  cardLast4: Maybe<Scalars['String']>;
  /** The email of the distributor who activated the customer */
  distributorEmail: Maybe<Scalars['String']>;
  /** The name of the distributor who activated the customer */
  distributorName: Maybe<Scalars['String']>;
  /** The email of the rep who activated the customer */
  repEmail: Maybe<Scalars['String']>;
  /** The name of the rep who activated the customer */
  repName: Maybe<Scalars['String']>;
  /** A list of the users gateways */
  gateways: Array<Gateway>;
  /** The type of activation.  Different questions will be asked based on how the user got an account. */
  type: Maybe<Scalars['String']>;
  /** How far the user has made it through the activation form */
  progress: ActivationProgress;
  /** A list of the users sensors */
  sensors: Array<Sensor>;
};

export type ActivationProgressResponseDataAccess = {
  __typename: 'ActivationProgressResponseDataAccess';
  id: Scalars['String'];
  plan: Maybe<SensorDataPlan>;
};

export type ActivationSharedFinishInput = {
  token: Scalars['String'];
  userId: Scalars['String'];
};

export type AddCustomerAddressInput = {
  /** The id of the customer to add the address to */
  customerId: Scalars['String'];
  address: AddressInput;
};

export type AddCustomerAddressResponse = {
  __typename: 'AddCustomerAddressResponse';
  id: Maybe<Scalars['String']>;
};

export type AddCustomerInput = {
  /** Used when distributor is creating customer for rep */
  repId?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
  email: Scalars['String'];
  name: NameInput;
  phone?: Maybe<Scalars['String']>;
};

export type AddCustomerResponse = {
  __typename: 'AddCustomerResponse';
  /** The id of the new customer */
  id: Maybe<Scalars['String']>;
};

export type AddDistributorInput = {
  email: Scalars['String'];
  name: NameInput;
  phone: Scalars['String'];
  /** ID of pricing sheet to use */
  pricing: Scalars['String'];
  salesRep: Scalars['String'];
};

export type AddDistributorInputBranding = {
  name?: Maybe<Scalars['String']>;
};

export type AddDistributorResponse = {
  __typename: 'AddDistributorResponse';
  /** The id of the new distributor */
  id: Maybe<Scalars['String']>;
};

export type AddFieldInput = {
  boundary?: Maybe<Array<NNLocationInput>>;
  boundarySoilStats?: Maybe<Array<FieldBoundaryInfoSoilStatInput>>;
  boundarySensorInfo?: Maybe<Array<FieldBoundaryInfoResultSensorInput>>;
  name: Scalars['String'];
  gateways?: Maybe<Array<Scalars['String']>>;
  /** Information about a fields irrigation */
  irrigationMetadata?: Maybe<FieldInputIrrigationMetadata>;
  sensors?: Maybe<Array<Scalars['String']>>;
  /** If a rep is adding a field to a customer, this must be supplied */
  customerId?: Maybe<Scalars['String']>;
  sensorWeights?: Maybe<Array<SensorWeightInput>>;
  /** Acreage estimate entered during new field creation */
  estimatedAcreage?: Maybe<Scalars['Float']>;
  /** Acreage calculated from field boundary input */
  calculatedAcreage?: Maybe<Scalars['Float']>;
  /** Crop ID corresponding to this field's crop_type */
  crop_type?: Maybe<Scalars['String']>;
  /** String designating specific crop variety */
  crop_variety?: Maybe<Scalars['String']>;
  /** Yield of this field */
  yield?: Maybe<YieldInput>;
};

export type AddOrderInput = {
  address: AddressInput;
  hardware: Array<AddOrderInputHardware>;
  repId: Scalars['String'];
  customerId?: Maybe<Scalars['String']>;
  prepaid?: Maybe<AddOrderInputPrepaid>;
  notes?: Maybe<Scalars['String']>;
  /**
   * If emails are provided, they will have the hardware shared with them upon
   * the order being bound
   * If set, sharingLevel and sharingName must also be set
   */
  sharingEmails?: Maybe<Array<Scalars['String']>>;
  sharingNames?: Maybe<Array<Scalars['String']>>;
  /** The level the sharingEmails should be shared at */
  sharingLevel?: Maybe<PermissionLevelInput>;
  /** If the order is managed service, this will be set */
  managedServiceOptions?: Maybe<AddOrderManagedServiceOptionsInput>;
};

export type AddOrderInputHardware = {
  model: ModelInput;
  quantity: Scalars['Int'];
};

export type AddOrderInputPrepaid = {
  plan: SensorDataPlan;
  months: Scalars['Int'];
  /**
   * Start date
   * If not set, will start when bound
   */
  start?: Maybe<Scalars['Float']>;
};

export type AddOrderManagedServiceOptionsInput = {
  /** The monthly cost of order */
  price: Scalars['Float'];
  /** Number of months it is for */
  months: Scalars['Int'];
  /** Whether or not it is prepaid managed service or monthly */
  prepaid: Scalars['Boolean'];
};

export type AddOrderResponse = {
  __typename: 'AddOrderResponse';
  /** The id of the new order */
  id: Maybe<Scalars['Int']>;
};

export type AddOrderResponseHardware = {
  __typename: 'AddOrderResponseHardware';
  model: Maybe<Model>;
  tempId: Maybe<Scalars['String']>;
};

export type AddPricingInput = {
  name: Scalars['String'];
  hardware: Array<AddPricingInputHardware>;
};

export type AddPricingInputHardware = {
  modelId: Scalars['String'];
  prices: Array<AddPricingInputHardwarePrice>;
};

export type AddPricingInputHardwarePrice = {
  quantity: Scalars['Int'];
  price: Scalars['Float'];
};

export type AddPricingResponse = {
  __typename: 'AddPricingResponse';
  /** The id of the new pricing object */
  id: Maybe<Scalars['String']>;
};

export type AddRepAddressInput = {
  repId: Scalars['String'];
  address: AddressInput;
};

export type AddRepAddressResponse = {
  __typename: 'AddRepAddressResponse';
  /** The id of the new address */
  id: Maybe<Scalars['String']>;
};

export type AddRepInput = {
  email: Scalars['String'];
  name: NameInput;
  phone: Scalars['String'];
  /** Used for creating rep as admin */
  distributorId?: Maybe<Scalars['String']>;
};

export type AddRepResponse = {
  __typename: 'AddRepResponse';
  /** The id of the new rep */
  id: Maybe<Scalars['String']>;
};

export type Address = {
  __typename: 'Address';
  city: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Maybe<Name>;
  state: Maybe<Scalars['String']>;
  street: Maybe<Scalars['String']>;
  zip: Maybe<Scalars['String']>;
};

export type AddressInput = {
  city: Scalars['String'];
  name: NameInput;
  state: Scalars['String'];
  street: Scalars['String'];
  zip: Scalars['String'];
};

export type AddSoilInput = {
  name: Scalars['String'];
  t_wet?: Maybe<Scalars['Float']>;
  t_dry?: Maybe<Scalars['Float']>;
  c_wet?: Maybe<Scalars['Float']>;
  c_dry?: Maybe<Scalars['Float']>;
  clay_percent?: Maybe<Scalars['Float']>;
};

export type AddSoilResponse = {
  __typename: 'AddSoilResponse';
  success: Scalars['Boolean'];
  soil: Soil;
};

export type Alert = {
  __typename: 'Alert';
  last_monthly_report: Maybe<Scalars['Float']>;
  last_weekly_report: Maybe<Scalars['Float']>;
  last_daily_report: Maybe<Scalars['Float']>;
  last_login_nag: Maybe<Scalars['Float']>;
  last_soil_alert: Maybe<Scalars['Float']>;
  did_you_knows: Array<UsedDidYouKnows>;
  last_registration_reminder: Maybe<Scalars['Float']>;
  total_registration_reminders: Maybe<Scalars['Float']>;
  irrigationNeeded: Maybe<Scalars['Float']>;
};

export type ApplyPrepaidToUserInput = {
  email: Scalars['String'];
  /** Timestamp in ms */
  start: Scalars['Float'];
  end: Scalars['Float'];
  sensorIds: Array<Scalars['String']>;
  gatewayIds: Array<Scalars['String']>;
};

export type ApproveOrderInput = {
  /** The id of the order to approve */
  orderId: Scalars['Int'];
  /** A boolean designating if the order is to be approved or rejected */
  approved: Scalars['Boolean'];
};

export type ApproveOrderResponse = {
  __typename: 'ApproveOrderResponse';
  /** The id of the approved order */
  id: Maybe<Scalars['Int']>;
  /** Temporary ids given to hardware */
  hardware: Maybe<Array<Maybe<AddOrderResponseHardware>>>;
};

export type ArchivedField = {
  __typename: 'ArchivedField';
  /** YYYY-MM-DD */
  archiveDate: Scalars['String'];
  gateways: Array<ArchivedGateway>;
  id: Scalars['String'];
  irrigationMetadata: IrrigationMetadata;
  irrigationEvents: Array<IrrigationEvent>;
  moistureData: FieldMoistureDataResult;
  cropType: Maybe<Crop>;
  name: Scalars['String'];
  permissionLevel: Maybe<PermissionLevel>;
  /** If field contains multiple hardware rainfall data generators, they will be returned in this array */
  rainfallSources: Maybe<Array<Maybe<ArchivedHardware>>>;
  /** Season total rainfall entered by user during archive */
  seasonTotalRainfall: Maybe<Scalars['Float']>;
  /** Season total irrigation entered by user during archive, may not match cumulative total from Irrigation Events */
  seasonTotalIrrigation: Maybe<Scalars['Float']>;
  sensors: Array<ArchivedSensor>;
  sensorWeights: Array<SensorWeight>;
  owner: Maybe<HardwareUser>;
  users: Array<HardwareUser>;
  yield: YieldType;
  yieldData: YieldData;
};

export type ArchivedGateway = ArchivedHardware & {
  __typename: 'ArchivedGateway';
  /** YYYY-MM-DD */
  archiveDate: Scalars['String'];
  archiveId: Scalars['String'];
  dataView: Maybe<SensorDataView>;
  fields: Array<Maybe<Field>>;
  hasDataAccess: Scalars['Boolean'];
  userHasSensorAccess: Scalars['Boolean'];
  /** Returns true if this model of gateway has a rain gauge */
  hasRainGauge: Scalars['Boolean'];
  id: Scalars['String'];
  originalId: Scalars['String'];
  location: Maybe<Location>;
  model: Maybe<Model>;
  name: Scalars['String'];
  owner: Maybe<HardwareUser>;
  permissionLevel: Maybe<PermissionLevel>;
  /** Always null */
  plan: Maybe<SensorDataPlan>;
  sharingMaxUsers: Scalars['Int'];
  users: Array<HardwareUser>;
};

/** Archived Sensor or Gateway */
export type ArchivedHardware = {
  id: Scalars['String'];
  dataView: Maybe<SensorDataView>;
  hasDataAccess: Scalars['Boolean'];
  userHasSensorAccess: Scalars['Boolean'];
  hasRainGauge: Scalars['Boolean'];
  name: Scalars['String'];
  sharingMaxUsers: Scalars['Int'];
  owner: Maybe<HardwareUser>;
  plan: Maybe<SensorDataPlan>;
  permissionLevel: Maybe<PermissionLevel>;
  users: Array<HardwareUser>;
};

export type ArchivedIrrigationSystem = {
  __typename: 'ArchivedIrrigationSystem';
  /** YYYY-MM-DD */
  archiveDate: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  sensors: Array<ArchivedTrellisPressureSensor>;
  users: Array<HardwareUser>;
};

/** A sensor that has been archived */
export type ArchivedSensor = ArchivedHardware &
  SensorBase & {
    __typename: 'ArchivedSensor';
    /** YYYY-MM-DD */
    archiveDate: Scalars['String'];
    canDownloadData: Scalars['Boolean'];
    crop_type: Maybe<Crop>;
    crop_variety: Maybe<Scalars['String']>;
    dataView: Maybe<SensorDataView>;
    field: Maybe<Field>;
    /** YYYY-MM-DD */
    harvest_date: Maybe<Scalars['String']>;
    hasDataAccess: Scalars['Boolean'];
    hasRainfallData: Scalars['Boolean'];
    userHasSensorAccess: Scalars['Boolean'];
    id: Scalars['String'];
    originalId: Scalars['String'];
    location: Maybe<Location>;
    managementPlan: Maybe<SensorManagementPlan>;
    model: Maybe<Model>;
    moistureMethod: Maybe<MoistureMethod>;
    name: Scalars['String'];
    owner: Maybe<HardwareUser>;
    users: Array<HardwareUser>;
    isOwner: Scalars['Boolean'];
    permissionLevel: Maybe<PermissionLevel>;
    /** Always null */
    plan: Maybe<SensorDataPlan>;
    plant_date: Maybe<Scalars['String']>;
    sharingMaxUsers: Scalars['Int'];
    soil_type: Maybe<Soil>;
    /** Returns true if this model of sensor has a rain gauge */
    hasRainGauge: Scalars['Boolean'];
    weather: Weather;
  };

export type ArchivedTrellisPressureSensor = ArchivedTrellisSensor & {
  __typename: 'ArchivedTrellisPressureSensor';
  data: PressureSensorData;
  plan: Maybe<PressureSensorPlan>;
  irrigationSystem: Maybe<IrrigationSystem>;
  id: Scalars['String'];
  name: Scalars['String'];
  location: Maybe<Location>;
  isOwner: Scalars['Boolean'];
  owner: Maybe<HardwareUser>;
  permissionLevel: Maybe<PermissionLevel>;
  users: Array<HardwareUser>;
};

export type ArchivedTrellisSensor = {
  id: Scalars['String'];
  name: Scalars['String'];
  location: Maybe<Location>;
  isOwner: Scalars['Boolean'];
  owner: Maybe<HardwareUser>;
  permissionLevel: Maybe<PermissionLevel>;
  users: Array<HardwareUser>;
};

export type ArchiveFieldInput = {
  fieldId: Scalars['String'];
  seasonStartDate: Scalars['Float'];
  seasonEndDate: Scalars['Float'];
  yieldData?: Maybe<ArchiveFieldYieldDataInput>;
  /** Season total rainfall entered by user during archive */
  seasonTotalRainfall?: Maybe<Scalars['Float']>;
  /** Season total irrigation entered by user during archive, may not match cumulative total from Irrigation Events */
  seasonTotalIrrigation?: Maybe<Scalars['Float']>;
};

export type ArchiveFieldResponse = {
  __typename: 'ArchiveFieldResponse';
  success: Scalars['Boolean'];
};

export type ArchiveFieldYieldDataInput = {
  actualYield?: Maybe<YieldInput>;
  targetYield?: Maybe<YieldInput>;
  performance?: Maybe<YieldDataPerformance>;
};

export type ArchiveGatewayInput = {
  gatewayId: Scalars['String'];
  seasonStartDate: Scalars['Float'];
  seasonEndDate: Scalars['Float'];
};

export type ArchiveGatewayResponse = {
  __typename: 'ArchiveGatewayResponse';
  success: Scalars['Boolean'];
  archivedId: Scalars['String'];
};

export type ArchiveIrrigationSystemInput = {
  irrigationSystemId: Scalars['String'];
  seasonStartDate: Scalars['Float'];
  seasonEndDate: Scalars['Float'];
};

export type ArchiveIrrigationSystemResponse = {
  __typename: 'ArchiveIrrigationSystemResponse';
  success: Scalars['Boolean'];
};

export type ArchiveSensorInput = {
  sensorId: Scalars['String'];
  seasonStartDate: Scalars['Float'];
  seasonEndDate: Scalars['Float'];
};

export type ArchiveSensorResponse = {
  __typename: 'ArchiveSensorResponse';
  success: Scalars['Boolean'];
  archivedId: Scalars['String'];
};

export type BindOrderHardwareIds = {
  /** The temporary id given to the hardware */
  tempId: Scalars['String'];
  /** The id of the piece of hardware being bound */
  newId: Scalars['String'];
  model: ModelInput;
};

export type BindOrderInput = {
  /** The id of the order to update */
  orderId: Scalars['Int'];
  hardwareIds?: Maybe<Array<BindOrderHardwareIds>>;
};

export type BindOrderResponse = {
  __typename: 'BindOrderResponse';
  /** The id of the order that was bound */
  id: Maybe<Scalars['Int']>;
};

export enum CacheControlScope {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
}

export type CancelPrepaidToUserInput = {
  email: Scalars['String'];
  sensorIds: Array<Scalars['String']>;
  gatewayIds: Array<Scalars['String']>;
};

export type ChangeCardInput = {
  stripeToken: Scalars['String'];
};

export type ChangeCardResponse = {
  __typename: 'ChangeCardResponse';
  /** The id of the user updated */
  id: Maybe<Scalars['String']>;
};

export enum ClaimHardwareIdType {
  SENSOR = 'SENSOR',
  GATEWAY = 'GATEWAY',
}

export type CommissionStructure = {
  __typename: 'CommissionStructure';
  value: Maybe<Scalars['Float']>;
  rate: Maybe<Scalars['Float']>;
};

export enum ConnectionStatus {
  online = 'online',
  warning = 'warning',
  offline = 'offline',
}

export type Crop = {
  __typename: 'Crop';
  id: Scalars['String'];
  name: Scalars['String'];
  didYouKnows: Array<CropDidYouKnow>;
  custom: Scalars['Boolean'];
};

export type CropAddInput = {
  name: Scalars['String'];
};

export type CropAddResponse = {
  __typename: 'CropAddResponse';
  crop: Crop;
};

export type CropDeleteInput = {
  id: Scalars['String'];
};

export type CropDeleteResponse = {
  __typename: 'CropDeleteResponse';
  success: Scalars['Boolean'];
};

export type CropDidYouKnow = {
  __typename: 'CropDidYouKnow';
  id: Scalars['String'];
  content: Scalars['String'];
};

export type CropUpdateInput = {
  id: Scalars['String'];
  name: Scalars['String'];
};

export type CropUpdateResponse = {
  __typename: 'CropUpdateResponse';
  crop: Crop;
};

export type CurrentPressure = {
  __typename: 'CurrentPressure';
  /** Latest data point */
  latest: SensorPressureData;
  /** Whether or not the pressure sensor is currently on */
  isOn: Scalars['Boolean'];
  scheduleResult: PressureScheduleResult;
};

export type CurrentPressureResult = CurrentPressure | SensorOffline;

export type CurrentWeatherResult = WeatherStationData | SensorOffline;

/** A static copy of a User that was saved when the distibutor added the customer. Can be edited independently. */
export type Customer = {
  __typename: 'Customer';
  addresses: Array<Address>;
  email: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Maybe<Name>;
  phone: Maybe<Scalars['String']>;
  repId: Maybe<Scalars['String']>;
  /** The user object of this customer.  Can be used to get sensors, data plans, etc */
  user: User;
};

export type DeleteDealerInput = {
  /** The id of the delear to be deleted */
  dealerId: Scalars['String'];
};

export type DeleteDealerResponse = {
  __typename: 'DeleteDealerResponse';
  /** The id of the deleted dealer */
  id: Scalars['String'];
};

export type DeleteFieldInput = {
  fieldId: Scalars['String'];
  /** If a rep is deleting a customers field, this must be supplied */
  customerId?: Maybe<Scalars['String']>;
};

export type DeleteSoilInput = {
  id: Scalars['String'];
};

export type DeleteSoilResponse = {
  __typename: 'DeleteSoilResponse';
  /** The id of the soil deleted */
  id: Scalars['String'];
};

export type Distributor = {
  __typename: 'Distributor';
  activationCompleted: Scalars['Boolean'];
  alert_history: Maybe<Alert>;
  /** For a rep, this is all of their direct customers.  For a distributor, this is'direct customers + their rep's customers */
  customers: Array<Customer>;
  email: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** Used on the distributor portal to determine if the admin stuff should show up */
  isAdmin: Maybe<Scalars['Boolean']>;
  isRep: Maybe<Scalars['Boolean']>;
  isDistributor: Maybe<Scalars['Boolean']>;
  name: Maybe<Name>;
  /** If this is a rep account, it will be the distributor that created the rep */
  parent: Maybe<Distributor>;
  phone: Maybe<Scalars['String']>;
  pricing: Pricing;
  reps: Array<DistributorRepCopy>;
  orders: Array<Order>;
  salesRep: Maybe<SalesRep>;
  stripeInfo: Maybe<DistributorStripeInfo>;
  /** Hardware that the distributor has purchased but not assigned to a customer */
  unassigned: DistributorUnassigned;
};

export type DistributorBranding = {
  __typename: 'DistributorBranding';
  name: Maybe<Scalars['String']>;
};

export type DistributorChangePasswordInput = {
  password: Scalars['String'];
  token: Scalars['String'];
  userId: Scalars['String'];
};

export type DistributorChangePasswordResponse = {
  __typename: 'DistributorChangePasswordResponse';
  /** The id of the new distributor changed */
  id: Maybe<Scalars['String']>;
};

/** A static copy of a Rep that was saved when the distributor added the rep. Can be edited independently. */
export type DistributorRepCopy = {
  __typename: 'DistributorRepCopy';
  addresses: Array<Address>;
  email: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Maybe<Name>;
  phone: Maybe<Scalars['String']>;
};

export type DistributorSignTC = {
  __typename: 'DistributorSignTC';
  id: Maybe<Scalars['String']>;
};

export type DistributorSignTCInput = {
  token: Scalars['String'];
  userId: Scalars['String'];
  tcText: Scalars['String'];
};

export type DistributorStripeInfo = {
  __typename: 'DistributorStripeInfo';
  /** Per Stripe: 'The URL expires a brief period after you retrieve it from the login_link API, so you should use it promptly.' */
  stripeURL: Scalars['String'];
};

export type DistributorStripeRegisterInput = {
  authCode: Scalars['String'];
};

export type DistributorStripeRegisterResponse = {
  __typename: 'DistributorStripeRegisterResponse';
  success: Scalars['Boolean'];
};

export type DistributorUnassigned = {
  __typename: 'DistributorUnassigned';
  sensors: Array<Sensor>;
  gateways: Array<Gateway>;
};

export type DistributorUnassignedUnbound = {
  __typename: 'DistributorUnassignedUnbound';
  id: Scalars['String'];
  model: Maybe<Model>;
  order: Maybe<Order>;
  orderId: Maybe<Scalars['Int']>;
};

export type DripStats = {
  /** Emitter flow rate (gallons/min) */
  emitterFlow?: Maybe<Scalars['Float']>;
  /** Emitter spacing along the line (cm) */
  emitterSpacing?: Maybe<Scalars['Float']>;
  /** Distance between drip lines (in) */
  dripDistance?: Maybe<Scalars['Float']>;
};

export type DripStatsType = {
  __typename: 'DripStatsType';
  emitterFlow: Maybe<Scalars['Float']>;
  emitterSpacing: Maybe<Scalars['Float']>;
  dripDistance: Maybe<Scalars['Float']>;
};

export type EditCustomerHardwareAddInputType = {
  sensorIds?: Maybe<Array<Scalars['String']>>;
  gatewayIds?: Maybe<Array<Scalars['String']>>;
};

export type EditCustomerHardwareInputType = {
  /** The id of the customer to edit */
  customerId: Scalars['String'];
  add?: Maybe<EditCustomerHardwareAddInputType>;
  remove?: Maybe<EditCustomerHardwareRemoveInputType>;
};

export type EditCustomerHardwareRemoveInputType = {
  sensorIds?: Maybe<Array<Scalars['String']>>;
  gatewayIds?: Maybe<Array<Scalars['String']>>;
};

export type EditCustomerHardwareResponse = {
  __typename: 'EditCustomerHardwareResponse';
  /** The id of the customer that was changed */
  id: Maybe<Scalars['String']>;
};

export type FakeHistoricalWeatherDataInput = {
  /** The id of the sensor to populate */
  sensorId: Scalars['String'];
  /** Timestamp ms to start */
  start: Scalars['Float'];
  /** Timestamp ms to end */
  end: Scalars['Float'];
};

export type FakeLocationDataInput = {
  /** The id of the sensor to populate */
  sensorId: Scalars['String'];
  /** The gw of the sensor to fake. Default is null */
  gatewayId?: Maybe<Scalars['String']>;
};

export type FakeLocationDataResponse = {
  __typename: 'FakeLocationDataResponse';
  loc: Maybe<Array<Maybe<Scalars['Float']>>>;
};

export type FakeSensorDataInput = {
  /** The id of the sensor to populate */
  sensorId: Scalars['String'];
  /** The gw of the sensor. Default is null */
  gatewayId?: Maybe<Scalars['String']>;
  /** Timestamp ms to start */
  start: Scalars['Float'];
  /** Timestamp ms to end */
  end: Scalars['Float'];
  /** The kind of data to populate it with */
  type: FakeSensorDataType;
};

export type FakeSensorDataResponse = {
  __typename: 'FakeSensorDataResponse';
  sensorId: Maybe<Scalars['String']>;
};

export enum FakeSensorDataType {
  /** No sensor data */
  none = 'none',
  /** 2 Moisture, 2 Temp */
  WWTT = 'WWTT',
  /** 3 Moisture, 1 Temp */
  WWWT = 'WWWT',
  /** Rain Gauge */
  RG = 'RG',
}

export type Field = {
  __typename: 'Field';
  boundary: Maybe<Array<Location>>;
  boundarySoilStats: Maybe<Array<FieldBoundaryInfoSoilStat>>;
  boundarySensorInfo: Maybe<Array<FieldBoundaryInfoResultSensor>>;
  crop_type: Maybe<Crop>;
  crop_variety: Maybe<Scalars['String']>;
  current_state: Maybe<Scalars['Int']>;
  current_tension: Maybe<Scalars['Int']>;
  gateways: Array<Gateway>;
  hoursToDry: HoursToDry;
  id: Scalars['String'];
  active: Maybe<Scalars['Boolean']>;
  irrigationMetadata: IrrigationMetadata;
  irrigationEvents: Array<IrrigationEvent>;
  moistureData: FieldMoistureDataResult;
  moistureProjection: FieldMoistureProjection;
  name: Scalars['String'];
  permissionLevel: Maybe<PermissionLevel>;
  /** If field contains multiple hardware rainfall data generators, they will be returned in this array */
  rainfallSources: Maybe<Array<Maybe<Hardware>>>;
  sensors: Array<Sensor>;
  sensorWeights: Array<SensorWeight>;
  owner: Maybe<HardwareUser>;
  users: Array<HardwareUser>;
  weatherStation: Maybe<TrellisWeatherSensor>;
  yield: YieldType;
  /** new acreage calculations */
  estimatedAcreage: Maybe<Scalars['Float']>;
  calculatedAcreage: Maybe<Scalars['Float']>;
};

export type FieldBoundaryInfoInput = {
  bounds: Array<NNLocationInput>;
  sensors: Array<FieldBoundaryInfoInputSensor>;
};

export type FieldBoundaryInfoInputSensor = {
  id: Scalars['String'];
  location: NNLocationInput;
};

export type FieldBoundaryInfoResult = {
  __typename: 'FieldBoundaryInfoResult';
  sensorsInBounds: Array<FieldBoundaryInfoResultSensor>;
  soilStats: Array<FieldBoundaryInfoSoilStat>;
  calculatedAcreage: Maybe<Scalars['Float']>;
};

export type FieldBoundaryInfoResultSensor = {
  __typename: 'FieldBoundaryInfoResultSensor';
  id: Scalars['String'];
  soil: Maybe<Soil>;
  /** % between 0 - 100 */
  soilPercent: Maybe<Scalars['Int']>;
};

export type FieldBoundaryInfoResultSensorInput = {
  id: Scalars['String'];
  soilId: Scalars['String'];
  soilPercent: Scalars['Int'];
};

export type FieldBoundaryInfoSoilStat = {
  __typename: 'FieldBoundaryInfoSoilStat';
  soil: Soil;
  soilPercent: Scalars['Int'];
};

export type FieldBoundaryInfoSoilStatInput = {
  soilId: Scalars['String'];
  soilPercent: Scalars['Int'];
};

export type FieldInputIrrigationMetadata = {
  irrigationType?: Maybe<Scalars['String']>;
  /** Source of irrigation water */
  waterSource?: Maybe<Scalars['String']>;
  /** Source of irrigation power */
  powerSource?: Maybe<Scalars['String']>;
  /** Vendor of the irrigation equipment */
  vendor?: Maybe<Scalars['String']>;
  /** The size of the field */
  acreage?: Maybe<Scalars['Float']>;
  /** Drip irrigation metadata */
  dripStats?: Maybe<DripStats>;
};

export type FieldMoistureData = {
  __typename: 'FieldMoistureData';
  moistureAvg: Array<FieldMoistureDataPoint>;
  /** Ordered list of soil threshold averages */
  thresholdsAvg: Array<MoistureThresholdPoint>;
  /** Moisture method of the data */
  moistureMethod: MoistureMethod;
  /** Errors if some sensors could not be used in the calculation */
  errors: Array<Scalars['String']>;
};

export type FieldMoistureDataError = {
  __typename: 'FieldMoistureDataError';
  messages: Array<Scalars['String']>;
};

export type FieldMoistureDataPoint = {
  __typename: 'FieldMoistureDataPoint';
  /** Time in unix ms */
  t: Scalars['Float'];
  /** Average soil moisture tension */
  sm: Scalars['Float'];
};

export type FieldMoistureDataResult =
  | FieldMoistureData
  | FieldMoistureDataError;

export type FieldMoistureProjection = {
  __typename: 'FieldMoistureProjection';
  data: Maybe<Array<FieldMoistureProjectionPoint>>;
  /** Any errors about projecting any of the sensors */
  errors: Array<Scalars['String']>;
};

export type FieldMoistureProjectionPoint = {
  __typename: 'FieldMoistureProjectionPoint';
  t: Scalars['Float'];
  sm: Scalars['Float'];
};

export type FieldMutationResponse = {
  __typename: 'FieldMutationResponse';
  success: Scalars['Boolean'];
  field: Maybe<Field>;
};

export type FieldSharingUpdateInput = {
  fieldId: Scalars['String'];
  /** The email of the user to share it with */
  email: Scalars['String'];
  /** The permission level to give the user, or null to remove the permission */
  level?: Maybe<PermissionLevelInput>;
};

export type FieldSharingUpdateResponse = {
  __typename: 'FieldSharingUpdateResponse';
  success: Scalars['Boolean'];
  sharedUid: Scalars['String'];
  field: Field;
};

export type FlashLibraryEntry = {
  __typename: 'FlashLibraryEntry';
  name: Scalars['String'];
  memoryLocation: Scalars['Float'];
  defaultValue: Scalars['String'];
  min: Maybe<Scalars['String']>;
  max: Maybe<Scalars['String']>;
};

export type Gateway = Hardware & {
  __typename: 'Gateway';
  /**
   * Whether or not the sensor is active.  Controls alerts, visibility on
   * dashboard, etc... Set true when the sensor's data is paid for, and false when
   * the sensor is archived.
   */
  active: Scalars['Boolean'];
  cell_signal: Maybe<Scalars['Float']>;
  /** The latest values reported by the gateway */
  configuration: Maybe<GatewayConfiguration>;
  configurationUpdated: Maybe<Scalars['Float']>;
  gpsUpdated: Maybe<Scalars['Float']>;
  gpsLocked: Maybe<Scalars['Boolean']>;
  /** Is the gateway online or offline? */
  connectionStatus: ConnectionStatus;
  currentPressure: Maybe<SensorPressureData>;
  currentWeather: Maybe<WeatherStationData>;
  data: Maybe<Array<GatewayData>>;
  dataView: Maybe<SensorDataView>;
  fields: Array<Maybe<Field>>;
  gateway_battery: Maybe<Scalars['Float']>;
  gateway_time: Maybe<Scalars['Float']>;
  /** Current gateway reporting interval in ms */
  gateway_time_interval: Maybe<Scalars['Int']>;
  hasDataAccess: Scalars['Boolean'];
  userHasSensorAccess: Scalars['Boolean'];
  /** Returns true if this model of gateway has a rain gauge */
  hasRainGauge: Scalars['Boolean'];
  id: Scalars['String'];
  /** Whether or not the sensor should appear on the distributor portal status page */
  inactive: Scalars['Boolean'];
  location: Maybe<Location>;
  mailbox: Array<MailboxSlot>;
  model: Maybe<Model>;
  name: Scalars['String'];
  network: Maybe<GatewayNetwork>;
  order: Maybe<Order>;
  owner: Maybe<HardwareUser>;
  isOwner: Scalars['Boolean'];
  permissionLevel: Maybe<PermissionLevel>;
  /**
   * If stripeSubActiveUntil is in the future, this will be it
   * This will be set regardless of if the sensor is cancelled or not
   */
  planActiveUntil: Maybe<Scalars['Float']>;
  /**
   * If stripeSubCancelledAt is in the future, this will be it
   * This should be set only if the sensor is scheduled to be cancelled at the end of the cycle
   */
  planCancelDate: Maybe<Scalars['Float']>;
  plan: Maybe<SensorDataPlan>;
  /** The currently selected plan for the gateway OR null if the gateway does not have an active subscription */
  activePlan: Maybe<SensorDataPlan>;
  /** Stripe plan ID. Use "plan" for frontend stuff. */
  planId: Maybe<StripeDataPlan>;
  /** Whether or not this piece of hardware needs a plan to access data */
  planRequired: Scalars['Boolean'];
  sensorData: Maybe<Array<SensorData>>;
  sensorIds: Array<Scalars['String']>;
  sensors: Array<Sensor>;
  serviceLogs: Array<ServiceLog>;
  sharingMaxUsers: Scalars['Int'];
  /** List of warnings for gateway */
  statusWarnings: Array<HardwareWarningEnum>;
  users: Array<HardwareUser>;
};

export type GatewaydataArgs = {
  start: Maybe<Scalars['Float']>;
  limit: Maybe<Scalars['Int']>;
};

export type GatewayConfiguration = {
  __typename: 'GatewayConfiguration';
  boot: Maybe<Scalars['Boolean']>;
  crit_th: Maybe<Scalars['Float']>;
  freq: Maybe<Scalars['Float']>;
  hw_ver: Maybe<Scalars['String']>;
  led_th: Maybe<Scalars['Float']>;
  sw_ver: Maybe<Scalars['String']>;
  voltage_th: Maybe<Scalars['Float']>;
  ws_fw_ver: Maybe<Scalars['String']>;
};

export type GatewayData = {
  __typename: 'GatewayData';
  cell_signal: Maybe<Scalars['Float']>;
  netOpenRetry: Maybe<Scalars['Float']>;
  tcpOpenRetry: Maybe<Scalars['Float']>;
  tcpSendRetry: Maybe<Scalars['Float']>;
  failCounter: Maybe<Scalars['Float']>;
  resetCounter: Maybe<Scalars['Float']>;
  rebootCounter: Maybe<Scalars['Float']>;
  rxBadCounter: Maybe<Scalars['Float']>;
  localCounter: Maybe<Scalars['Float']>;
  rebootLimitCounter: Maybe<Scalars['Float']>;
  isCharging: Maybe<Scalars['Float']>;
  pwrOnRstCounter: Maybe<Scalars['Float']>;
  brnOutRstCounter: Maybe<Scalars['Float']>;
  extRstCounter: Maybe<Scalars['Float']>;
  wdtRstCounter: Maybe<Scalars['Float']>;
  tcpFailID: Maybe<Scalars['Float']>;
  msg: Maybe<Scalars['Float']>;
  time: Maybe<Scalars['Float']>;
  vbat: Maybe<Scalars['Float']>;
  vsol: Maybe<Scalars['Float']>;
  rbat: Maybe<Scalars['Float']>;
  lock: Maybe<Scalars['Boolean']>;
};

export type GatewayFlashInput = {
  gatewayId: Scalars['String'];
  location: Scalars['String'];
  value: Scalars['String'];
  name: Scalars['String'];
};

export type GatewayFlashResponse = {
  __typename: 'GatewayFlashResponse';
  success: Scalars['Boolean'];
};

export type GatewayNetwork = {
  __typename: 'GatewayNetwork';
  availableServices: Maybe<Array<Maybe<Scalars['String']>>>;
  ccid: Maybe<Scalars['String']>;
  currentService: Maybe<Scalars['String']>;
  firmwareVersion: Maybe<Scalars['String']>;
};

export type GatewayReprogramInput = {
  gatewayId: Scalars['String'];
  softwareVersion: Scalars['String'];
  hardwareVersion: Scalars['String'];
  variant: Scalars['String'];
  name: Scalars['String'];
};

export type GatewayReprogramResponse = {
  __typename: 'GatewayReprogramResponse';
  success: Scalars['Boolean'];
};

export type GatewayResetInput = {
  gatewayId: Scalars['String'];
  name: Scalars['String'];
};

export type GatewayResetResponse = {
  __typename: 'GatewayResetResponse';
  success: Scalars['Boolean'];
};

export type GatewaySimcomInput = {
  gatewayId: Scalars['String'];
  payload: Scalars['String'];
  name: Scalars['String'];
};

export type GatewaySimcomResponse = {
  __typename: 'GatewaySimcomResponse';
  success: Scalars['Boolean'];
};

export type GatewayStatusMutationResponse = {
  __typename: 'GatewayStatusMutationResponse';
  /** The id of the gateway modified */
  id: Maybe<Scalars['String']>;
};

/** Sensor or Gateway */
export type Hardware = {
  id: Scalars['String'];
  dataView: Maybe<SensorDataView>;
  hasDataAccess: Scalars['Boolean'];
  userHasSensorAccess: Scalars['Boolean'];
  hasRainGauge: Scalars['Boolean'];
  location: Maybe<Location>;
  name: Scalars['String'];
  planRequired: Scalars['Boolean'];
  sharingMaxUsers: Scalars['Int'];
  owner: Maybe<HardwareUser>;
  plan: Maybe<SensorDataPlan>;
  activePlan: Maybe<SensorDataPlan>;
  permissionLevel: Maybe<PermissionLevel>;
  users: Array<HardwareUser>;
};

export enum HardwareTypeEnum {
  sensor = 'sensor',
  gateway = 'gateway',
  replacementSensorProbe = 'replacementSensorProbe',
  replacementEnclosure = 'replacementEnclosure',
  replacementAntenna = 'replacementAntenna',
  replacementGatewayEnclosure = 'replacementGatewayEnclosure',
  replacementSensorEnclosure = 'replacementSensorEnclosure',
  replacementRainGauge = 'replacementRainGauge',
  replacementWeatherStation = 'replacementWeatherStation',
  watermarkSensor = 'watermarkSensor',
  auger = 'auger',
  replacementCircuitBoard = 'replacementCircuitBoard',
  replacementSensorBoard = 'replacementSensorBoard',
  replacementGatewayBoard = 'replacementGatewayBoard',
  replacementMercuryModule = 'replacementMercuryModule',
}

/**
 * A limited version of a user, used for sharing
 * Admins can get more information via the user field
 */
export type HardwareUser = {
  __typename: 'HardwareUser';
  id: Scalars['String'];
  name: Maybe<Name>;
  accountEmail: Maybe<Scalars['String']>;
  permissionLevel: PermissionLevel;
  /** Admin only */
  user: User;
};

export type HardwareVersions = {
  __typename: 'HardwareVersions';
  name: Scalars['String'];
  variants: Array<Scalars['String']>;
};

export enum HardwareWarningEnum {
  BATTERY_LOW = 'BATTERY_LOW',
  SIGNAL_LOW = 'SIGNAL_LOW',
  NOT_REPORTED_RECENTLY = 'NOT_REPORTED_RECENTLY',
  ZERO_ADC = 'ZERO_ADC',
  INACTIVE_SUB = 'INACTIVE_SUB',
}

export type HoursToDry = {
  __typename: 'HoursToDry';
  prediction: Maybe<Scalars['Int']>;
  errors: Array<Scalars['String']>;
};

export type InstallModeInput = {
  hwid: Scalars['String'];
  options: Scalars['String'];
};

export type InstallModeMutationResponse = {
  __typename: 'InstallModeMutationResponse';
  success: Scalars['Boolean'];
};

export type Invoice = {
  __typename: 'Invoice';
  date: Scalars['Float'];
  amount: Scalars['Float'];
};

export type Irrigation = {
  __typename: 'Irrigation';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type IrrigationEvent = {
  __typename: 'IrrigationEvent';
  id: Scalars['String'];
  /** Amount of irrigation in inches */
  amount: Scalars['Float'];
  /** Date of irrigation event (YYYY-MM-DD) */
  date: Scalars['String'];
  /** note about irrigation event */
  note: Maybe<Scalars['String']>;
  /** the source of the irrigation event (rainfall or irrigation) */
  source: IrrigationEventSource;
  /** If this event was automatically created, this is the origin */
  automatedOrigin: Maybe<IrrigationEventAutomatedOriginEnum>;
};

export type IrrigationEventAddInput = {
  /** id of field event took place in */
  fieldId: Scalars['String'];
  /** Amount of irrigation in inches */
  amount: Scalars['Float'];
  /** Date of irrigation event (YYYY-MM-DD) */
  date: Scalars['String'];
  /** note about irrigation event */
  note?: Maybe<Scalars['String']>;
  /**
   * the source of the irrigation event (rainfall or irrigation)
   * Default is irrigation
   */
  source?: Maybe<IrrigationEventSource>;
  /** If this is created automatically, include this */
  automatedOrigin?: Maybe<IrrigationEventAutomatedOriginEnum>;
};

export type IrrigationEventAddResponse = {
  __typename: 'IrrigationEventAddResponse';
  success: Scalars['Boolean'];
  /** The created irrigation event */
  irrigationEvent: IrrigationEvent;
};

export enum IrrigationEventAutomatedOriginEnum {
  DETECTED = 'DETECTED',
  WISECONN = 'WISECONN',
}

/** Deleted irrigation event */
export type IrrigationEventDeleteInput = {
  /** id of irrigation event to delete */
  id: Scalars['String'];
  /** id of field event took place in */
  fieldId: Scalars['String'];
};

export type IrrigationEventDeleteResponse = {
  __typename: 'IrrigationEventDeleteResponse';
  success: Scalars['Boolean'];
};

export enum IrrigationEventSource {
  IRRIGATION = 'IRRIGATION',
  RAINFALL = 'RAINFALL',
}

/** Updated irrigation event */
export type IrrigationEventUpdateInput = {
  /** id of irrigation event to update */
  id: Scalars['String'];
  /** id of field event took place in */
  fieldId: Scalars['String'];
  /** Updated amount of irrigation in inches */
  amount?: Maybe<Scalars['Float']>;
  /** Updated date of irrigation event (YYYY-MM-DD) */
  date?: Maybe<Scalars['String']>;
  /** Updates note */
  note?: Maybe<Scalars['String']>;
  /**
   * the source of the irrigation event (rainfall or irrigation)
   * Default is irrigation
   */
  source?: Maybe<IrrigationEventSource>;
};

export type IrrigationEventUpdateResponse = {
  __typename: 'IrrigationEventUpdateResponse';
  success: Scalars['Boolean'];
  /** The created irrigation event */
  irrigationEvent: IrrigationEvent;
};

export type IrrigationMetadata = {
  __typename: 'IrrigationMetadata';
  irrigationType: Maybe<Scalars['String']>;
  /** Source of irrigation water */
  waterSource: Maybe<Scalars['String']>;
  /** Source of irrigation power */
  powerSource: Maybe<Scalars['String']>;
  /** Vendor of the irrigation equipment */
  vendor: Maybe<Scalars['String']>;
  /** The size of the field */
  acreage: Maybe<Scalars['Float']>;
  /** Drip irrigation metadata */
  dripStats: DripStatsType;
};

export type IrrigationSystem = {
  __typename: 'IrrigationSystem';
  id: Scalars['String'];
  name: Scalars['String'];
  sensors: Array<TrellisPressureSensor>;
  users: Array<HardwareUser>;
};

export type IrrigationSystemAddInput = {
  name: Scalars['String'];
  sensorIds: Array<Scalars['String']>;
  sensorPlans: Array<PressureSensorPlanInput>;
};

export type IrrigationSystemDeleteResponse = {
  __typename: 'IrrigationSystemDeleteResponse';
  success: Scalars['Boolean'];
};

export type IrrigationSystemUpdateInput = {
  name: Scalars['String'];
  sensorIds: Array<Scalars['String']>;
  sensorPlans: Array<PressureSensorPlanInput>;
};

export type Location = {
  __typename: 'Location';
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type LocationInput = {
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

export type MailboxSlot = {
  __typename: 'MailboxSlot';
  toDevice: Maybe<Scalars['String']>;
  toServer: Maybe<Scalars['String']>;
};

export type ManagedServiceOptions = {
  __typename: 'ManagedServiceOptions';
  /** The monthly cost of order */
  price: Scalars['Float'];
  /** Number of months it is for */
  months: Scalars['Int'];
  /** Whether or not it is prepaid managed service or monthly */
  prepaid: Scalars['Boolean'];
  /** When the service started */
  startTime: Maybe<Scalars['Float']>;
};

export type ManagedServiceStatus = {
  __typename: 'ManagedServiceStatus';
  orderId: Scalars['Int'];
  invoiceUrl: Maybe<Scalars['String']>;
  /** If the first payment for the order has been made */
  initialPaymentMade: Scalars['Boolean'];
  prepaid: Scalars['Boolean'];
  /** Whether or not the subscription has been started */
  subscriptionStarted: Scalars['Boolean'];
  /** Unix ms start timestamp */
  subscriptionStartTime: Maybe<Scalars['Float']>;
  /** The last month that has been paid (1 is first) */
  lastPaidMonth: Maybe<Scalars['Int']>;
  /** True if all invoices for order have been paid */
  paidInFull: Scalars['Boolean'];
};

export type Model = {
  __typename: 'Model';
  antennaLength: Maybe<Scalars['Float']>;
  moistureDepths: Maybe<Array<Scalars['Float']>>;
  temperatureDepths: Maybe<Array<Scalars['Float']>>;
  ecDepths: Maybe<Array<Scalars['Float']>>;
  hasWeatherStation: Scalars['Boolean'];
  hasRainGauge: Scalars['Boolean'];
  hasPressureSensor: Scalars['Boolean'];
  moistureMethod: Maybe<MoistureMethod>;
  probeless: Scalars['Boolean'];
  custom: Scalars['Boolean'];
  type: HardwareTypeEnum;
  /**
   * For a standalone watermark senosr, the lead length in feet
   * For a watermark auger, the length of the auger in feet
   * For a sentek auger, the length of the auger in cm
   */
  length: Maybe<Scalars['Float']>;
  hasSpigot: Scalars['Boolean'];
  softwareVersion: Maybe<Scalars['String']>;
};

export type ModelInput = {
  antennaLength?: Maybe<Scalars['Float']>;
  moistureDepths?: Maybe<Array<Scalars['Float']>>;
  temperatureDepths?: Maybe<Array<Scalars['Float']>>;
  ecDepths?: Maybe<Array<Scalars['Float']>>;
  hasWeatherStation?: Maybe<Scalars['Boolean']>;
  hasRainGauge?: Maybe<Scalars['Boolean']>;
  hasPressureSensor?: Maybe<Scalars['Boolean']>;
  moistureMethod?: Maybe<MoistureMethod>;
  type: HardwareTypeEnum;
  probeless?: Maybe<Scalars['Boolean']>;
  custom?: Maybe<Scalars['Boolean']>;
  /**
   * For a standalone watermark senosr, the lead length in feet
   * For a watermark auger, the length of the auger in feet
   * For a sentek auger, the length of the auger in cm
   */
  length?: Maybe<Scalars['Float']>;
  hasSpigot?: Maybe<Scalars['Boolean']>;
  softwareVersion?: Maybe<Scalars['String']>;
};

export enum MoistureMethod {
  capacitance = 'capacitance',
  tension = 'tension',
}

export type MoistureThresholdPoint = {
  __typename: 'MoistureThresholdPoint';
  wet: Scalars['Float'];
  dry: Scalars['Float'];
  time: Scalars['Float'];
};

export type MonthlyInvoiceManagedServiceInput = {
  orderId: Scalars['Int'];
};

export type MonthlyInvoiceManagedServiceResponse = {
  __typename: 'MonthlyInvoiceManagedServiceResponse';
  success: Scalars['Boolean'];
};

/** Trellis mutations */
export type Mutation = {
  __typename: 'Mutation';
  /** Initiate install mode for a Gateway or Sensor */
  installMode: Maybe<InstallModeMutationResponse>;
  /** Register a new user */
  registerUser: Maybe<RegistrationMutationResponse>;
  /** Send user verification email */
  sendVerificationEmail: Maybe<RegistrationMutationResponse>;
  /** Send push notification */
  sendPushNotification: Maybe<PushNotificationMutationResponse>;
  /** Add a field */
  addField: Maybe<FieldMutationResponse>;
  /** Delete a field */
  deleteField: Maybe<FieldMutationResponse>;
  /** Update a field */
  updateField: Maybe<UpdateFieldMutationResponse>;
  /** Update sensor status */
  updateSensorStatus: Maybe<SensorMutationResponse>;
  /** Update a sensor */
  updateSensor: Maybe<SensorMutationResponse>;
  /** Update sensor alert */
  updateSensorAlert: Maybe<SensorMutationResponse>;
  /** Update sensor state */
  updateSensorState: Maybe<SensorMutationResponse>;
  /** Add a card to a user */
  activationAddCardToUser: Maybe<ActivationChangeMutationResponse>;
  /** Change a users name */
  activationChangeName: Maybe<ActivationChangeMutationResponse>;
  /** Change a users data access */
  activationChangeDataAccess: Maybe<ActivationChangeMutationResponse>;
  /** Change a users password */
  activationChangePassword: Maybe<ActivationChangeMutationResponse>;
  /** Finish user activation process */
  activationFinish: Maybe<ActivationChangeMutationResponse>;
  /** Finish shared user activation process */
  activationSharedFinish: Maybe<ActivationChangeMutationResponse>;
  /** Finish prepaid user activation process */
  activationPrepaidFinish: Maybe<ActivationChangeMutationResponse>;
  /** Resend activation email */
  activationResendEmail: Scalars['Boolean'];
  /** Redirect to stripe billing portal */
  stripeBillingPortal: StripeBillingPortalResponse;
  /** Update a user's data plan */
  updateDataPlan: Maybe<UpdateDataPlanResponse>;
  /** Change a user's card */
  changeCard: Maybe<ChangeCardResponse>;
  /** Change a user's preferences */
  updateUserPreferences: Maybe<UpdateUserPreferencesResponse>;
  /** Apply prepaid to a user */
  applyPrepaidToUser: Maybe<Scalars['Boolean']>;
  /** Cancel prepaid to a user */
  cancelPrepaidToUser: Maybe<Scalars['Boolean']>;
  /** Change a user's profile */
  updateUserProfile: Maybe<UpdateUserProfileResponse>;
  /** Add a login for a user */
  updateLoginTracking: Maybe<UpdateLoginResponse>;
  /** Change a user's alert history */
  updateAlertHistory: Maybe<UpdateAlertHistoryResponse>;
  /** Set a user's push token */
  userSetPushToken: Maybe<UserSetPushTokenResponse>;
  /** Update gateway hardware_status */
  updateGatewayStatus: Maybe<GatewayStatusMutationResponse>;
  /** Update gateway */
  updateGateway: Maybe<UpdateGatewayMutationResponse>;
  /** Add a pricing object */
  addPricing: Maybe<AddPricingResponse>;
  /** Add a distributor */
  addDistributor: Maybe<AddDistributorResponse>;
  /** Add a Rep */
  addRep: Maybe<AddRepResponse>;
  /** Delete a dealer */
  deleteDealer: DeleteDealerResponse;
  /** Add a shipping address for a Rep */
  addRepAddress: Maybe<AddRepAddressResponse>;
  /** Change a distributors password */
  changeDistributorPassword: Maybe<DistributorChangePasswordResponse>;
  /** Activate a customer's account */
  activateCustomer: Maybe<ActivateCustomerResponse>;
  /** Add a customer for the logged in user */
  addCustomer: Maybe<AddCustomerResponse>;
  /** Add an address to a customer for the logged in user */
  addCustomerAddress: Maybe<AddCustomerAddressResponse>;
  /** Add/remove from customers hardware */
  editCustomerHardware: Maybe<EditCustomerHardwareResponse>;
  /** Remove a customer */
  removeCustomer: Maybe<RemoveCustomerResponse>;
  /** Add an order */
  addOrder: Maybe<AddOrderResponse>;
  /** Approve an order */
  approveOrder: Maybe<ApproveOrderResponse>;
  /** Bind an order (Set the ids) */
  bindOrder: Maybe<BindOrderResponse>;
  /** Mark an order as shipped */
  shipOrder: Maybe<ShipOrderResponse>;
  /** Populate a sensor with fake data */
  fakeSensorData: Maybe<FakeSensorDataResponse>;
  /** Populate a sensor with fake location data */
  fakeLocationData: Maybe<FakeLocationDataResponse>;
  /** Populate a sensor with fake historical weather data */
  fakeHistoricalWeatherData: Maybe<FakeSensorDataResponse>;
  /** Add a soil for current user */
  addSoil: AddSoilResponse;
  /** Delete a soil for current user */
  deleteSoil: DeleteSoilResponse;
  /** Update a soil for current user */
  updateSoil: UpdateSoilResponse;
  /** Add a crop type for current user */
  cropAdd: CropAddResponse;
  /** Delete a crop type for current user */
  cropDelete: CropDeleteResponse;
  /** Update a crop type for current user */
  cropUpdate: CropUpdateResponse;
  /** Share or unshare a sensor */
  updateSensorSharing: Maybe<UpdateSensorSharingResponse>;
  /** Share or unshare a gateway */
  updateGatewaySharing: Maybe<UpdateGatewaySharingResponse>;
  /** Share a field and the hardware in it */
  fieldSharingUpdate: Maybe<FieldSharingUpdateResponse>;
  /** Add an irrigation event */
  irrigationEventAdd: Maybe<IrrigationEventAddResponse>;
  /** Update an irrigation event */
  irrigationEventUpdate: Maybe<IrrigationEventUpdateResponse>;
  /** Delete an irrigation event */
  irrigationEventDelete: Maybe<IrrigationEventDeleteResponse>;
  /** Create a service log */
  serviceLogCreate: Maybe<ServiceLogCreateReponse>;
  /** Update a service log */
  serviceLogUpdate: Maybe<ServiceLogUpdateReponse>;
  /** Delete a service log */
  serviceLogDelete: Maybe<ServiceLogDeleteReponse>;
  /** Add announcement about something new! */
  notificationAnnouncementAdd: Maybe<NotificationAnnouncementAdd>;
  /** Add announcement about new release! */
  notificationReleaseAdd: Maybe<NotificationReleaseAdd>;
  /** Add notification about a dry sensor */
  notificationDrySensorAdd: Maybe<NotificationDrySensorAdd>;
  /** Add notification about a sensor soil type being populated automatically */
  notificationSoilTypePopulateAdd: Maybe<NotificationSoilTypePopulateAdd>;
  /** Mark notifications as read */
  notificationMarkAsRead: Maybe<NotificationMarkAsRead>;
  /** Archive a sensor */
  archiveSensor: Maybe<ArchiveSensorResponse>;
  /** Archive a gateway */
  archiveGateway: Maybe<ArchiveGatewayResponse>;
  /** Archive a field */
  archiveField: Maybe<ArchiveFieldResponse>;
  /** Archive an irrigation system */
  archiveIrrigationSystem: ArchiveIrrigationSystemResponse;
  /** Sign distributor terms and conditions */
  distributorSignTC: Maybe<DistributorSignTC>;
  /** Claim hardware id (returns the id) */
  claimHardwareId: Scalars['String'];
  sensorManagementPlanAdd: SensorManagementPlanAddResponse;
  sensorManagementPlanUpdate: SensorManagementPlanResponse;
  sensorManagementPlanDelete: SensorManagementPlanResponse;
  weatherStationManagementPlanAdd: WeatherStationManagementPlanResponse;
  weatherStationManagementPlanUpdate: WeatherStationManagementPlanResponse;
  weatherStationManagementPlanDelete: WeatherStationManagementPlanResponse;
  postPerformance: PostPerformanceResponse;
  commandGatewayResetSend: GatewayResetResponse;
  commandGatewayFlashSend: GatewayFlashResponse;
  commandGatewaySimcomSend: GatewaySimcomResponse;
  commandGatewayReprogramSend: GatewayReprogramResponse;
  commandSensorResetSend: SensorResetResponse;
  commandSensorRetrySend: SensorRetryResponse;
  commandSensorEditSend: SensorEditResponse;
  commandSensorReprogramSend: SensorReprogramResponse;
  desktopDebuggerSendLogs: Scalars['Boolean'];
  weatherStationUpdate: WeatherStationUpdateResponse;
  pressureSensorUpdate: TrellisPressureSensor;
  irrigationSystemAdd: IrrigationSystem;
  irrigationSystemUpdate: IrrigationSystem;
  irrigationSystemDelete: IrrigationSystemDeleteResponse;
  distributorStripeRegister: DistributorStripeRegisterResponse;
  /**
   * Managed service orders do not begin when they are paid
   * Instead, users or dealers trigger the start
   */
  startManagedService: StartManagedServiceResponse;
  /**
   * Renew managed service.  Should be called by backend a week before the next
   * year starts
   */
  renewManagedService: RenewManagedServiceResponse;
  transferFundsManagedService: TransferFundsManagedServiceReponse;
  monthlyInvoiceManagedService: MonthlyInvoiceManagedServiceResponse;
  sendSupportMessage: SendSupportMessageResponse;
};

/** Trellis mutations */
export type MutationinstallModeArgs = {
  input: InstallModeInput;
};

/** Trellis mutations */
export type MutationregisterUserArgs = {
  input: RegisterUserInput;
};

/** Trellis mutations */
export type MutationsendVerificationEmailArgs = {
  input: SendVerificationEmailInput;
};

/** Trellis mutations */
export type MutationsendPushNotificationArgs = {
  input: SendPushNotificationInput;
};

/** Trellis mutations */
export type MutationaddFieldArgs = {
  input: AddFieldInput;
};

/** Trellis mutations */
export type MutationdeleteFieldArgs = {
  input: Maybe<DeleteFieldInput>;
};

/** Trellis mutations */
export type MutationupdateFieldArgs = {
  fieldId: Scalars['String'];
  input: UpdateFieldInput;
};

/** Trellis mutations */
export type MutationupdateSensorStatusArgs = {
  sensorId: Scalars['String'];
  input: UpdateSensorStatusInput;
};

/** Trellis mutations */
export type MutationupdateSensorArgs = {
  sensorId: Scalars['String'];
  input: UpdateSensorInput;
  admin: Maybe<Scalars['Boolean']>;
};

/** Trellis mutations */
export type MutationupdateSensorAlertArgs = {
  id: Scalars['String'];
  type: Scalars['String'];
};

/** Trellis mutations */
export type MutationupdateSensorStateArgs = {
  input: UpdateSensorStateInput;
};

/** Trellis mutations */
export type MutationactivationAddCardToUserArgs = {
  input: ActivationAddCardToUserInput;
};

/** Trellis mutations */
export type MutationactivationChangeNameArgs = {
  input: ActivationChangeNameInput;
};

/** Trellis mutations */
export type MutationactivationChangeDataAccessArgs = {
  input: ActivationChangeDataAccessInput;
};

/** Trellis mutations */
export type MutationactivationChangePasswordArgs = {
  input: ActivationChangePasswordInput;
};

/** Trellis mutations */
export type MutationactivationFinishArgs = {
  input: ActivationFinishInput;
};

/** Trellis mutations */
export type MutationactivationSharedFinishArgs = {
  input: ActivationSharedFinishInput;
};

/** Trellis mutations */
export type MutationactivationPrepaidFinishArgs = {
  input: ActivationPrepaidFinishInput;
};

/** Trellis mutations */
export type MutationactivationResendEmailArgs = {
  customerId: Scalars['String'];
};

/** Trellis mutations */
export type MutationstripeBillingPortalArgs = {
  userId: Scalars['String'];
};

/** Trellis mutations */
export type MutationupdateDataPlanArgs = {
  input: UpdateDataPlanInput;
};

/** Trellis mutations */
export type MutationchangeCardArgs = {
  input: ChangeCardInput;
};

/** Trellis mutations */
export type MutationupdateUserPreferencesArgs = {
  input: UpdateUserPreferencesInput;
};

/** Trellis mutations */
export type MutationapplyPrepaidToUserArgs = {
  input: ApplyPrepaidToUserInput;
};

/** Trellis mutations */
export type MutationcancelPrepaidToUserArgs = {
  input: CancelPrepaidToUserInput;
};

/** Trellis mutations */
export type MutationupdateUserProfileArgs = {
  input: UpdateUserProfileInput;
};

/** Trellis mutations */
export type MutationupdateLoginTrackingArgs = {
  input: UpdateLoginInput;
};

/** Trellis mutations */
export type MutationupdateAlertHistoryArgs = {
  input: UpdateAlertHistoryInput;
};

/** Trellis mutations */
export type MutationuserSetPushTokenArgs = {
  input: UserSetPushTokenInput;
};

/** Trellis mutations */
export type MutationupdateGatewayStatusArgs = {
  input: UpdateGatewayStatusInput;
};

/** Trellis mutations */
export type MutationupdateGatewayArgs = {
  input: UpdateGatewayInput;
  admin: Maybe<Scalars['Boolean']>;
};

/** Trellis mutations */
export type MutationaddPricingArgs = {
  input: AddPricingInput;
};

/** Trellis mutations */
export type MutationaddDistributorArgs = {
  input: AddDistributorInput;
};

/** Trellis mutations */
export type MutationaddRepArgs = {
  input: AddRepInput;
};

/** Trellis mutations */
export type MutationdeleteDealerArgs = {
  input: DeleteDealerInput;
};

/** Trellis mutations */
export type MutationaddRepAddressArgs = {
  input: AddRepAddressInput;
};

/** Trellis mutations */
export type MutationchangeDistributorPasswordArgs = {
  input: DistributorChangePasswordInput;
};

/** Trellis mutations */
export type MutationactivateCustomerArgs = {
  input: ActivateCustomerInput;
};

/** Trellis mutations */
export type MutationaddCustomerArgs = {
  input: AddCustomerInput;
};

/** Trellis mutations */
export type MutationaddCustomerAddressArgs = {
  input: AddCustomerAddressInput;
};

/** Trellis mutations */
export type MutationeditCustomerHardwareArgs = {
  input: EditCustomerHardwareInputType;
};

/** Trellis mutations */
export type MutationremoveCustomerArgs = {
  input: RemoveCustomerInput;
};

/** Trellis mutations */
export type MutationaddOrderArgs = {
  input: AddOrderInput;
};

/** Trellis mutations */
export type MutationapproveOrderArgs = {
  input: ApproveOrderInput;
};

/** Trellis mutations */
export type MutationbindOrderArgs = {
  input: BindOrderInput;
};

/** Trellis mutations */
export type MutationshipOrderArgs = {
  input: ShipOrderInput;
};

/** Trellis mutations */
export type MutationfakeSensorDataArgs = {
  input: FakeSensorDataInput;
};

/** Trellis mutations */
export type MutationfakeLocationDataArgs = {
  input: FakeLocationDataInput;
};

/** Trellis mutations */
export type MutationfakeHistoricalWeatherDataArgs = {
  input: FakeHistoricalWeatherDataInput;
};

/** Trellis mutations */
export type MutationaddSoilArgs = {
  input: AddSoilInput;
};

/** Trellis mutations */
export type MutationdeleteSoilArgs = {
  input: DeleteSoilInput;
};

/** Trellis mutations */
export type MutationupdateSoilArgs = {
  input: UpdateSoilInput;
};

/** Trellis mutations */
export type MutationcropAddArgs = {
  input: CropAddInput;
};

/** Trellis mutations */
export type MutationcropDeleteArgs = {
  input: CropDeleteInput;
};

/** Trellis mutations */
export type MutationcropUpdateArgs = {
  input: CropUpdateInput;
};

/** Trellis mutations */
export type MutationupdateSensorSharingArgs = {
  input: UpdateSensorSharingInput;
};

/** Trellis mutations */
export type MutationupdateGatewaySharingArgs = {
  input: UpdateGatewaySharingInput;
};

/** Trellis mutations */
export type MutationfieldSharingUpdateArgs = {
  input: FieldSharingUpdateInput;
};

/** Trellis mutations */
export type MutationirrigationEventAddArgs = {
  input: IrrigationEventAddInput;
  admin: Maybe<Scalars['Boolean']>;
};

/** Trellis mutations */
export type MutationirrigationEventUpdateArgs = {
  input: IrrigationEventUpdateInput;
};

/** Trellis mutations */
export type MutationirrigationEventDeleteArgs = {
  input: IrrigationEventDeleteInput;
};

/** Trellis mutations */
export type MutationserviceLogCreateArgs = {
  input: ServiceLogCreateInput;
};

/** Trellis mutations */
export type MutationserviceLogUpdateArgs = {
  input: ServiceLogUpdateInput;
};

/** Trellis mutations */
export type MutationserviceLogDeleteArgs = {
  input: ServiceLogDeleteInput;
};

/** Trellis mutations */
export type MutationnotificationAnnouncementAddArgs = {
  input: Maybe<NotificationAnnouncementInput>;
};

/** Trellis mutations */
export type MutationnotificationReleaseAddArgs = {
  input: Maybe<NotificationReleaseInput>;
};

/** Trellis mutations */
export type MutationnotificationDrySensorAddArgs = {
  input: Maybe<NotificationDrySensorInput>;
};

/** Trellis mutations */
export type MutationnotificationSoilTypePopulateAddArgs = {
  input: Maybe<NotificationSoilTypePopulateInput>;
};

/** Trellis mutations */
export type MutationnotificationMarkAsReadArgs = {
  input: Maybe<NotificationMarkAsReadInput>;
};

/** Trellis mutations */
export type MutationarchiveSensorArgs = {
  input: ArchiveSensorInput;
};

/** Trellis mutations */
export type MutationarchiveGatewayArgs = {
  input: ArchiveGatewayInput;
};

/** Trellis mutations */
export type MutationarchiveFieldArgs = {
  input: ArchiveFieldInput;
};

/** Trellis mutations */
export type MutationarchiveIrrigationSystemArgs = {
  input: ArchiveIrrigationSystemInput;
};

/** Trellis mutations */
export type MutationdistributorSignTCArgs = {
  input: DistributorSignTCInput;
};

/** Trellis mutations */
export type MutationclaimHardwareIdArgs = {
  type: ClaimHardwareIdType;
};

/** Trellis mutations */
export type MutationsensorManagementPlanAddArgs = {
  sensorId: Scalars['String'];
  input: SensorManagementPlanInput;
};

/** Trellis mutations */
export type MutationsensorManagementPlanUpdateArgs = {
  planId: Scalars['String'];
  input: SensorManagementPlanInput;
};

/** Trellis mutations */
export type MutationsensorManagementPlanDeleteArgs = {
  planId: Scalars['String'];
};

/** Trellis mutations */
export type MutationweatherStationManagementPlanAddArgs = {
  input: WeatherStationManagementPlanInput;
};

/** Trellis mutations */
export type MutationweatherStationManagementPlanUpdateArgs = {
  planId: Scalars['String'];
  input: WeatherStationManagementPlanInput;
};

/** Trellis mutations */
export type MutationweatherStationManagementPlanDeleteArgs = {
  planId: Scalars['String'];
};

/** Trellis mutations */
export type MutationpostPerformanceArgs = {
  input: PerformanceStatisticsInput;
};

/** Trellis mutations */
export type MutationcommandGatewayResetSendArgs = {
  input: GatewayResetInput;
};

/** Trellis mutations */
export type MutationcommandGatewayFlashSendArgs = {
  input: GatewayFlashInput;
};

/** Trellis mutations */
export type MutationcommandGatewaySimcomSendArgs = {
  input: GatewaySimcomInput;
};

/** Trellis mutations */
export type MutationcommandGatewayReprogramSendArgs = {
  input: GatewayReprogramInput;
};

/** Trellis mutations */
export type MutationcommandSensorResetSendArgs = {
  input: SensorResetInput;
};

/** Trellis mutations */
export type MutationcommandSensorRetrySendArgs = {
  input: SensorRetryInput;
};

/** Trellis mutations */
export type MutationcommandSensorEditSendArgs = {
  input: SensorEditInput;
};

/** Trellis mutations */
export type MutationcommandSensorReprogramSendArgs = {
  input: SensorReprogramInput;
};

/** Trellis mutations */
export type MutationdesktopDebuggerSendLogsArgs = {
  data: Scalars['String'];
};

/** Trellis mutations */
export type MutationweatherStationUpdateArgs = {
  id: Scalars['String'];
  input: WeatherStationUpdateInput;
};

/** Trellis mutations */
export type MutationpressureSensorUpdateArgs = {
  id: Scalars['String'];
  input: PressureSensorUpdateInput;
};

/** Trellis mutations */
export type MutationirrigationSystemAddArgs = {
  input: IrrigationSystemAddInput;
};

/** Trellis mutations */
export type MutationirrigationSystemUpdateArgs = {
  id: Scalars['String'];
  input: IrrigationSystemUpdateInput;
};

/** Trellis mutations */
export type MutationirrigationSystemDeleteArgs = {
  id: Scalars['String'];
};

/** Trellis mutations */
export type MutationdistributorStripeRegisterArgs = {
  input: DistributorStripeRegisterInput;
};

/** Trellis mutations */
export type MutationstartManagedServiceArgs = {
  input: StartManagedServiceInput;
};

/** Trellis mutations */
export type MutationrenewManagedServiceArgs = {
  input: RenewManagedServiceInput;
};

/** Trellis mutations */
export type MutationtransferFundsManagedServiceArgs = {
  input: TransferFundsManagedServiceInput;
};

/** Trellis mutations */
export type MutationmonthlyInvoiceManagedServiceArgs = {
  input: MonthlyInvoiceManagedServiceInput;
};

/** Trellis mutations */
export type MutationsendSupportMessageArgs = {
  body: Scalars['String'];
};

export type Name = {
  __typename: 'Name';
  first: Maybe<Scalars['String']>;
  last: Maybe<Scalars['String']>;
  /** Combined first and last name */
  full: Maybe<Scalars['String']>;
};

export type NameInput = {
  first: Scalars['String'];
  last: Scalars['String'];
};

export type NNLocationInput = {
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

/** Notifications that show up on dashboard */
export type Notification = {
  id: Scalars['String'];
  read: Scalars['Boolean'];
  timestamp: Maybe<Scalars['Float']>;
};

/** Dashboard Announcement */
export type NotificationAnnouncement = Notification & {
  __typename: 'NotificationAnnouncement';
  id: Scalars['String'];
  read: Scalars['Boolean'];
  timestamp: Maybe<Scalars['Float']>;
  primary: Scalars['String'];
  secondary: Scalars['String'];
  href: Maybe<Scalars['String']>;
  /** 7/9 graphqlgen crashes if it's named popup or popupId :) */
  popupToShow: Maybe<PopupId>;
};

export type NotificationAnnouncementAdd = {
  __typename: 'NotificationAnnouncementAdd';
  success: Scalars['Boolean'];
};

export type NotificationAnnouncementInput = {
  userId: Scalars['String'];
  primary: Scalars['String'];
  secondary: Scalars['String'];
  href?: Maybe<Scalars['String']>;
  /** 7/9 graphqlgen crashes if it's named popup or popupId :) */
  popupToShow?: Maybe<PopupId>;
};

/** Dry sensor notification */
export type NotificationDrySensor = Notification & {
  __typename: 'NotificationDrySensor';
  id: Scalars['String'];
  read: Scalars['Boolean'];
  timestamp: Maybe<Scalars['Float']>;
  sensorId: Scalars['String'];
};

export type NotificationDrySensorAdd = {
  __typename: 'NotificationDrySensorAdd';
  success: Scalars['Boolean'];
};

export type NotificationDrySensorInput = {
  userId: Scalars['String'];
  timestamp: Scalars['Float'];
  sensorId: Scalars['String'];
};

export type NotificationMarkAsRead = {
  __typename: 'NotificationMarkAsRead';
  success: Scalars['Boolean'];
  notifications: Array<Notification>;
};

export type NotificationMarkAsReadInput = {
  notificationIds: Array<Scalars['String']>;
};

/** Dashboard Release */
export type NotificationRelease = Notification & {
  __typename: 'NotificationRelease';
  id: Scalars['String'];
  read: Scalars['Boolean'];
  timestamp: Maybe<Scalars['Float']>;
  primary: Scalars['String'];
  secondary: Scalars['String'];
  /** Blog post link */
  href: Scalars['String'];
  header: Scalars['String'];
  body: Scalars['String'];
  img: Scalars['String'];
  minVersion: Scalars['String'];
};

export type NotificationReleaseAdd = {
  __typename: 'NotificationReleaseAdd';
  success: Scalars['Boolean'];
};

export type NotificationReleaseInput = {
  userId: Scalars['String'];
  primary: Scalars['String'];
  secondary: Scalars['String'];
  /** Blog post link */
  href: Scalars['String'];
  header: Scalars['String'];
  body: Scalars['String'];
  img: Scalars['String'];
  minVersion: Scalars['String'];
};

/** Soil Type Populated Notification */
export type NotificationSoilTypePopulate = Notification & {
  __typename: 'NotificationSoilTypePopulate';
  id: Scalars['String'];
  read: Scalars['Boolean'];
  timestamp: Maybe<Scalars['Float']>;
  sensorId: Scalars['String'];
  sensorName: Scalars['String'];
  /** The name of the auto populated soil type */
  soilType: Scalars['String'];
};

export type NotificationSoilTypePopulateAdd = {
  __typename: 'NotificationSoilTypePopulateAdd';
  success: Scalars['Boolean'];
};

export type NotificationSoilTypePopulateInput = {
  userId: Scalars['String'];
  timestamp: Scalars['Float'];
  sensorId: Scalars['String'];
  soilType: Scalars['String'];
  sensorName: Scalars['String'];
};

/** An order request */
export type Order = {
  __typename: 'Order';
  approved: Scalars['Boolean'];
  details: OrderDetails;
  id: Scalars['Int'];
  notes: Maybe<Scalars['String']>;
  placedTimestamp: Scalars['Float'];
  prepaid: Maybe<OrderPrepaid>;
  boundIds: Array<Scalars['String']>;
  sharingEmails: Array<Scalars['String']>;
  status: OrderStatus;
  totalPrice: Scalars['Float'];
  unboundHardware: Array<OrderUnboundHardware>;
  zohoSalesOrder: Maybe<ZohoSalesOrder>;
  managedServiceOptions: Maybe<ManagedServiceOptions>;
  shipments: Array<OrderShipment>;
  unshippedHardware: Array<OrderDetailsHardware>;
};

export type OrderDetails = {
  __typename: 'OrderDetails';
  address: Maybe<Address>;
  hardware: Array<OrderDetailsHardware>;
  customer: Maybe<OrderDetailsCustomerCopy>;
  rep: Maybe<OrderDetailsRepCopy>;
};

export type OrderDetailsCustomerCopy = {
  __typename: 'OrderDetailsCustomerCopy';
  id: Scalars['String'];
  email: Maybe<Scalars['String']>;
  name: Maybe<Name>;
  phone: Maybe<Scalars['String']>;
};

export type OrderDetailsHardware = {
  __typename: 'OrderDetailsHardware';
  id: Maybe<Scalars['String']>;
  model: Model;
  pricePerUnit: Scalars['Float'];
  type: HardwareTypeEnum;
  quantity: Scalars['Int'];
  boundIds: Array<Scalars['String']>;
};

export type OrderDetailsRepCopy = {
  __typename: 'OrderDetailsRepCopy';
  id: Scalars['String'];
  email: Maybe<Scalars['String']>;
  name: Maybe<Name>;
  phone: Maybe<Scalars['String']>;
};

export type OrderPrepaid = {
  __typename: 'OrderPrepaid';
  plan: SensorDataPlan;
  months: Scalars['Int'];
  start: Scalars['Float'];
};

export type OrderShipment = {
  __typename: 'OrderShipment';
  id: Scalars['Int'];
  amount: Scalars['Float'];
  carrier: Scalars['String'];
  trackingNumber: Maybe<Scalars['String']>;
  deliveredDate: Maybe<Scalars['String']>;
  hardware: Array<OrderDetailsHardware>;
  boundIds: Array<Scalars['String']>;
};

export enum OrderStatus {
  pending = 'pending',
  processing = 'processing',
  shipped = 'shipped',
  fulfilled = 'fulfilled',
  rejected = 'rejected',
}

export type OrderUnboundHardware = {
  __typename: 'OrderUnboundHardware';
  id: Scalars['String'];
  model: Model;
};

export type PerformanceStatistics = {
  __typename: 'PerformanceStatistics';
  percentOnline: Scalars['Float'];
  percentWarning: Scalars['Float'];
  percentOffline: Scalars['Float'];
  time: Scalars['Float'];
};

export type PerformanceStatisticsInput = {
  percentOnline: Scalars['Float'];
  percentWarning: Scalars['Float'];
  percentOffline: Scalars['Float'];
  time: Scalars['Float'];
};

export enum PermissionLevel {
  owner = 'owner',
  admin = 'admin',
  viewer = 'viewer',
}

export enum PermissionLevelInput {
  admin = 'admin',
  viewer = 'viewer',
}

export type PlanInformation = {
  __typename: 'PlanInformation';
  active: Maybe<Scalars['Boolean']>;
  email_update: Maybe<Scalars['Boolean']>;
  export_data: Maybe<Scalars['Boolean']>;
  text_update: Maybe<Scalars['Boolean']>;
};

/** Corresponds to popup modals on the client */
export enum PopupId {
  SensorManagementPlanRelease = 'SensorManagementPlanRelease',
  IrrigationRecommendationRelease = 'IrrigationRecommendationRelease',
  ChatSupportRelease = 'ChatSupportRelease',
}

export type PostPerformanceResponse = {
  __typename: 'PostPerformanceResponse';
  success: Scalars['Boolean'];
};

export type PressureNoSchedule = {
  __typename: 'PressureNoSchedule';
  /** This type doesn't need any extra information */
  void: Scalars['Boolean'];
};

export type PressureOffSchedule = {
  __typename: 'PressureOffSchedule';
  /** Whether or not the sensor is on schedule right now */
  currentlyOnSchedule: Scalars['Boolean'];
  /** List of times in the past 24 hours where the sensor was off schedule */
  offScheduleTimes: Array<PressureOffScheduleTime>;
};

export type PressureOffScheduleTime = {
  __typename: 'PressureOffScheduleTime';
  /** Time of issue in hh:mm a */
  time: Scalars['String'];
  /** Whether or not we expected the sensor to be on or off */
  shouldBeOn: Scalars['Boolean'];
  /** Explanation about off schedule */
  type: PressureOffScheduleType;
};

export enum PressureOffScheduleType {
  unexpected = 'unexpected',
  missed = 'missed',
  lowPressure = 'lowPressure',
  highPressure = 'highPressure',
}

export type PressureOnSchedule = {
  __typename: 'PressureOnSchedule';
  /** This type doesn't need any extra information */
  void: Scalars['Boolean'];
};

export type PressureScheduleResult =
  | PressureOnSchedule
  | PressureOffSchedule
  | PressureNoSchedule;

export type PressureSensorData = {
  __typename: 'PressureSensorData';
  currentPressure: CurrentPressureResult;
  onOffTimes: Array<SensorPressureOnOffData>;
  pressure: Array<SensorPressureData>;
};

export type PressureSensorDatapressureArgs = {
  start: Maybe<Scalars['Float']>;
  end: Maybe<Scalars['Float']>;
};

export type PressureSensorDataResult = PressureSensorData | SensorNoPlan;

export type PressureSensorPlan = {
  __typename: 'PressureSensorPlan';
  timeZone: Scalars['String'];
  expectedPressure: Maybe<Scalars['Float']>;
  stages: Array<PressureSensorPlanStage>;
};

export type PressureSensorPlanInput = {
  sensorId: Scalars['String'];
  /** Time zone string e.g. America/New_York */
  timeZone: Scalars['String'];
  expectedPressure?: Maybe<Scalars['Float']>;
  stages: Array<PressureSensorPlanStageInput>;
};

export type PressureSensorPlanStage = {
  __typename: 'PressureSensorPlanStage';
  /** YYYY-MM-DD */
  startDate: Scalars['String'];
  onTimes: Array<PressureSensorPlanStageTime>;
};

export type PressureSensorPlanStageInput = {
  /** YYYY-MM-DD */
  startDate: Scalars['String'];
  onTimes: Array<PressureSensorPlanStageTimeInput>;
};

export type PressureSensorPlanStageTime = {
  __typename: 'PressureSensorPlanStageTime';
  /** Start time in minutes (0 is midnight) */
  start: Scalars['Float'];
  /** End time in minutes (0 is midnight) */
  end: Scalars['Float'];
};

export type PressureSensorPlanStageTimeInput = {
  /** Start time in minutes (0 is midnight) */
  start: Scalars['Float'];
  /** End time in minutes (0 is midnight) */
  end: Scalars['Float'];
};

export type PressureSensorUpdateInput = {
  name?: Maybe<Scalars['String']>;
  location?: Maybe<LocationInput>;
};

export type Pricing = {
  __typename: 'Pricing';
  id: Scalars['String'];
  name: Maybe<Scalars['String']>;
  hardware: Array<PricingHardware>;
};

export type PricingHardware = {
  __typename: 'PricingHardware';
  modelId: Scalars['String'];
  prices: Array<PricingHardwarePrices>;
};

export type PricingHardwarePrices = {
  __typename: 'PricingHardwarePrices';
  /** If someone buys >= this amount, they get the discounted price */
  quantity: Scalars['Int'];
  price: Scalars['Float'];
};

export type PushNotificationMutationResponse = {
  __typename: 'PushNotificationMutationResponse';
  success: Scalars['Boolean'];
};

/** Trellis queries */
export type Query = {
  __typename: 'Query';
  /** Get info of a field */
  field: Maybe<Field>;
  /** Check a users activation progress */
  activationProgress: ActivationProgressResponse;
  /** Get info of a sensor */
  sensor: Maybe<Sensor>;
  weatherStation: TrellisWeatherSensor;
  pressureSensor: TrellisPressureSensor;
  irrigationSystem: IrrigationSystem;
  /** Get all sensors */
  allSensors: Array<Sensor>;
  /** Get all weather stations */
  allWeatherStations: Array<TrellisWeatherSensor>;
  /** Get all irrigation systems */
  irrigationSystems: Array<IrrigationSystem>;
  /** Get info of a gateway */
  gateway: Maybe<Gateway>;
  /** Get all gateways */
  allGateways: Array<Gateway>;
  /** Get the info of the logged in user. */
  me: User;
  /** Get a user by id */
  user: Maybe<User>;
  /** Get a distributor by id */
  distributor: Maybe<Distributor>;
  /** Get all dashboard users. */
  allUsers: Array<User>;
  /** Get all fields */
  allFields: Array<Field>;
  /** Get all crop types */
  crops: Array<Crop>;
  /** Get all soil types (Trellis defaults + User defined) */
  soils: Array<Soil>;
  /** Get an single soil type */
  soil: Maybe<Soil>;
  /** Get all irrigation types */
  irrigationTypes: Array<Irrigation>;
  /** Get distributor portal for the logged in user. */
  currentDistributor: Distributor;
  /** Get all distributors */
  allDistributors: Array<Distributor>;
  /** Get a rep of currentDistributor */
  rep: Maybe<Distributor>;
  /** Get an order */
  order: Maybe<Order>;
  /** Get all Open Orders */
  openOrders: Array<Order>;
  /** Get all Orders */
  allOrders: Array<Order>;
  /** Get a customer */
  customer: Maybe<Customer>;
  /** Get the list of pricings */
  pricings: Array<Pricing>;
  /** Get the registration url for a user/distributor */
  registrationUrl: Maybe<Scalars['String']>;
  /** Get an archived sensor */
  archivedSensor: Maybe<ArchivedSensor>;
  /** Get an archived gateway */
  archivedGateway: Maybe<ArchivedGateway>;
  /** Get info of an archived field */
  archivedField: Maybe<ArchivedField>;
  /** Get info of an archived irrigation system */
  archivedIrrigationSystem: ArchivedIrrigationSystem;
  archivedPressureSensor: ArchivedTrellisPressureSensor;
  /** Get all archived sensors */
  archivedSensors: Array<ArchivedSensor>;
  /** Sales Rep information */
  salesRep: Maybe<SalesRep>;
  /** All Sales Reps */
  salesReps: Array<SalesRep>;
  /** Get soil type from lat/lng (using Web Soil Survey) */
  soilTypeFromLocation: Maybe<Scalars['String']>;
  /** Daily performance statistics for all of the hardware */
  performanceStatistics: Array<PerformanceStatistics>;
  sensorManagementPlan: Maybe<SensorManagementPlan>;
  gatewaySoftwareVersions: Array<SoftwareVersion>;
  gatewayFlashLibrary: Array<FlashLibraryEntry>;
  gatewaySimcomLibrary: Array<Scalars['String']>;
  sensorSoftwareVersions: Array<SoftwareVersion>;
  sensorFlashLibrary: Array<FlashLibraryEntry>;
  averageUsageStats: Maybe<UsageStats>;
  /** Get soil type from lat/lng (using Web Soil Survey) */
  fieldBoundaryInfo: Maybe<FieldBoundaryInfoResult>;
  salesRepForCustomer: Maybe<SalesRep>;
  supportChatLog: SupportChatLog;
};

/** Trellis queries */
export type QueryfieldArgs = {
  id: Scalars['String'];
  admin: Maybe<Scalars['Boolean']>;
};

/** Trellis queries */
export type QueryactivationProgressArgs = {
  input: ActivationProgressInput;
};

/** Trellis queries */
export type QuerysensorArgs = {
  id: Scalars['String'];
  admin: Maybe<Scalars['Boolean']>;
};

/** Trellis queries */
export type QueryweatherStationArgs = {
  id: Scalars['String'];
  admin: Maybe<Scalars['Boolean']>;
};

/** Trellis queries */
export type QuerypressureSensorArgs = {
  id: Scalars['String'];
  admin: Maybe<Scalars['Boolean']>;
};

/** Trellis queries */
export type QueryirrigationSystemArgs = {
  id: Scalars['String'];
};

/** Trellis queries */
export type QueryallSensorsArgs = {
  onlyRG: Maybe<Scalars['Boolean']>;
};

/** Trellis queries */
export type QuerygatewayArgs = {
  id: Maybe<Scalars['String']>;
  admin: Maybe<Scalars['Boolean']>;
};

/** Trellis queries */
export type QueryuserArgs = {
  id: Scalars['String'];
};

/** Trellis queries */
export type QuerydistributorArgs = {
  id: Scalars['String'];
};

/** Trellis queries */
export type QuerysoilArgs = {
  id: Scalars['String'];
};

/** Trellis queries */
export type QueryrepArgs = {
  repId: Scalars['String'];
};

/** Trellis queries */
export type QueryorderArgs = {
  orderId: Scalars['Int'];
};

/** Trellis queries */
export type QuerycustomerArgs = {
  customerId: Maybe<Scalars['String']>;
  includeZoho: Maybe<Scalars['Boolean']>;
};

/** Trellis queries */
export type QueryregistrationUrlArgs = {
  distributor: Maybe<Scalars['Boolean']>;
  email: Scalars['String'];
};

/** Trellis queries */
export type QueryarchivedSensorArgs = {
  id: Scalars['String'];
  admin: Maybe<Scalars['Boolean']>;
};

/** Trellis queries */
export type QueryarchivedGatewayArgs = {
  id: Scalars['String'];
  admin: Maybe<Scalars['Boolean']>;
};

/** Trellis queries */
export type QueryarchivedFieldArgs = {
  id: Scalars['String'];
};

/** Trellis queries */
export type QueryarchivedIrrigationSystemArgs = {
  id: Scalars['String'];
  admin: Maybe<Scalars['Boolean']>;
};

/** Trellis queries */
export type QueryarchivedPressureSensorArgs = {
  id: Scalars['String'];
  admin: Maybe<Scalars['Boolean']>;
};

/** Trellis queries */
export type QueryarchivedSensorsArgs = {
  onlyRG: Maybe<Scalars['Boolean']>;
};

/** Trellis queries */
export type QuerysalesRepArgs = {
  id: Scalars['String'];
};

/** Trellis queries */
export type QuerysoilTypeFromLocationArgs = {
  location: NNLocationInput;
};

/** Trellis queries */
export type QuerysensorManagementPlanArgs = {
  id: Scalars['String'];
};

/** Trellis queries */
export type QueryfieldBoundaryInfoArgs = {
  input: FieldBoundaryInfoInput;
};

/** Trellis queries */
export type QuerysalesRepForCustomerArgs = {
  email: Scalars['String'];
};

export type RegisterUserInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
  userId: Scalars['String'];
  token: Scalars['String'];
  password: Scalars['String'];
  textEnabled: Scalars['Boolean'];
  emailEnabled: Scalars['Boolean'];
  termsCheckbox: Scalars['Boolean'];
};

export type RegistrationMutationResponse = {
  __typename: 'RegistrationMutationResponse';
  success: Scalars['Boolean'];
  userId: Scalars['String'];
};

export type RemoveCustomerInput = {
  /** ID of customer to remove */
  customerId: Scalars['String'];
};

export type RemoveCustomerResponse = {
  __typename: 'RemoveCustomerResponse';
  /** The id of the removed customer */
  id: Maybe<Scalars['String']>;
};

export type RenewManagedServiceInput = {
  orderId: Scalars['Int'];
};

export type RenewManagedServiceResponse = {
  __typename: 'RenewManagedServiceResponse';
  success: Scalars['Boolean'];
};

export type SalesRep = {
  __typename: 'SalesRep';
  id: Scalars['String'];
  email: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  commissionStructure: Array<CommissionStructure>;
  orders: Array<SalesRepOrder>;
  /** Calculate commission for a sales rep over a period */
  periodCommission: Maybe<Scalars['Float']>;
};

export type SalesRepperiodCommissionArgs = {
  periodStart: Maybe<Scalars['Float']>;
  periodEnd: Maybe<Scalars['Float']>;
};

export type SalesRepOrder = {
  __typename: 'SalesRepOrder';
  amount: Scalars['Float'];
  cost: Scalars['Float'];
  time: Scalars['Float'];
  source: Scalars['String'];
  id: Scalars['String'];
};

export type SendPushNotificationInput = {
  customerId: Scalars['String'];
  title: Scalars['String'];
  body: Scalars['String'];
  data?: Maybe<Scalars['String']>;
};

export type SendSupportMessageResponse = {
  __typename: 'SendSupportMessageResponse';
  success: Scalars['Boolean'];
  supportChatLog: SupportChatLog;
};

export type SendVerificationEmailInput = {
  email: Scalars['String'];
  isDistPortal?: Maybe<Scalars['Boolean']>;
};

export type Sensor = Hardware &
  SensorBase & {
    __typename: 'Sensor';
    /**
     * Whether or not the sensor is active.  Controls alerts, visibility on
     * dashboard, etc... Set true when the sensor's data is paid for, and false when
     * the sensor is archived.
     */
    active: Scalars['Boolean'];
    alerts: SensorAlerts;
    battery: Maybe<Scalars['Float']>;
    canDownloadData: Scalars['Boolean'];
    configuration: Maybe<SensorConfiguration>;
    configurationUpdated: Maybe<Scalars['Float']>;
    crop_type: Maybe<Crop>;
    crop_variety: Maybe<Scalars['String']>;
    current_state: Maybe<Scalars['Int']>;
    data: Maybe<Array<SensorData>>;
    dataView: Maybe<SensorDataView>;
    current_tension: Maybe<Scalars['Int']>;
    errors: Array<SensorData>;
    field: Maybe<Field>;
    gateway: Maybe<Gateway>;
    gatewayId: Maybe<Scalars['String']>;
    /** Format: YYYY-MM-DD */
    harvest_date: Maybe<Scalars['String']>;
    hasDataAccess: Scalars['Boolean'];
    userHasSensorAccess: Scalars['Boolean'];
    id: Scalars['String'];
    /** Used to prevent hw from showing up on the dist portal */
    inactive: Scalars['Boolean'];
    location: Maybe<Location>;
    gpsUpdated: Maybe<Scalars['Float']>;
    gpsLocked: Maybe<Scalars['Boolean']>;
    managementPlan: Maybe<SensorManagementPlan>;
    /**
     * A list of the users sensor management plans that have
     * compatible models
     */
    compatibleManagementPlans: Array<SensorManagementPlan>;
    /** Is the sensor online or offline? */
    connectionStatus: ConnectionStatus;
    /**
     * If the sensor has a management plan, this will be
     * the current stage
     */
    currentStage: Maybe<SensorManagementPlanStage>;
    model: Maybe<Model>;
    moistureMethod: Maybe<MoistureMethod>;
    name: Scalars['String'];
    order: Maybe<Order>;
    owner: Maybe<HardwareUser>;
    isOwner: Scalars['Boolean'];
    permissionLevel: Maybe<PermissionLevel>;
    persistence: Maybe<Scalars['Float']>;
    /**
     * If the stripeSubActiveUntil is in the future, this will be it
     * This will be set regardless of if the sensor is cancelled or not
     */
    planActiveUntil: Maybe<Scalars['Float']>;
    /**
     * If stripeSubCancelledAt is in the future, this will be it
     * This should be set only if the sensor is scheduled to be cancelled at the end of the cycle
     */
    planCancelDate: Maybe<Scalars['Float']>;
    /** The currently selected plan for the sensor OR null if the sensor does not have an active subscription */
    activePlan: Maybe<SensorDataPlan>;
    /** The currently selected plan for the sensor */
    plan: Maybe<SensorDataPlan>;
    /** Stripe plan ID. Use "plan" for frontend stuff. */
    planId: Maybe<StripeDataPlan>;
    /** Whether or not this piece of hardware needs a plan to access data */
    planRequired: Scalars['Boolean'];
    plant_date: Maybe<Scalars['String']>;
    rssi: Maybe<Scalars['Float']>;
    sensor_time: Maybe<Scalars['Float']>;
    serviceLogs: Array<ServiceLog>;
    sharingMaxUsers: Scalars['Int'];
    soil_type: Maybe<Soil>;
    /** List of warnings for sensor */
    statusWarnings: Array<HardwareWarningEnum>;
    /** Returns true if the sensors has had at least 1 rain gauge message */
    hasRainfallData: Scalars['Boolean'];
    /** Returns true if this model of sensor has a rain gauge */
    hasRainGauge: Scalars['Boolean'];
    /** Returns true if this sensor is a child of a gateway with a pressure sensor */
    hasPressureSensor: Scalars['Boolean'];
    usage_stats: Maybe<UsageStats>;
    users: Array<HardwareUser>;
    weather: Weather;
  };

export type SensordataArgs = {
  limit: Maybe<Scalars['Int']>;
  start: Maybe<Scalars['Float']>;
  end: Maybe<Scalars['Float']>;
};

export type SensordataViewArgs = {
  start: Maybe<Scalars['Float']>;
  end: Maybe<Scalars['Float']>;
};

export type SensorAlerts = {
  __typename: 'SensorAlerts';
  dry: Maybe<Scalars['Float']>;
};

/** Class that contains fields for both Sensor and ArchivedSensor */
export type SensorBase = {
  canDownloadData: Scalars['Boolean'];
  crop_type: Maybe<Crop>;
  crop_variety: Maybe<Scalars['String']>;
  dataView: Maybe<SensorDataView>;
  field: Maybe<Field>;
  harvest_date: Maybe<Scalars['String']>;
  hasDataAccess: Scalars['Boolean'];
  hasRainfallData: Scalars['Boolean'];
  userHasSensorAccess: Scalars['Boolean'];
  id: Scalars['String'];
  location: Maybe<Location>;
  managementPlan: Maybe<SensorManagementPlan>;
  model: Maybe<Model>;
  moistureMethod: Maybe<MoistureMethod>;
  name: Scalars['String'];
  owner: Maybe<HardwareUser>;
  users: Array<HardwareUser>;
  isOwner: Scalars['Boolean'];
  permissionLevel: Maybe<PermissionLevel>;
  plan: Maybe<SensorDataPlan>;
  plant_date: Maybe<Scalars['String']>;
  sharingMaxUsers: Scalars['Int'];
  soil_type: Maybe<Soil>;
  hasRainGauge: Scalars['Boolean'];
  weather: Weather;
};

export type SensorConfiguration = {
  __typename: 'SensorConfiguration';
  ack_timeout: Maybe<Scalars['Float']>;
  boot: Maybe<Scalars['Boolean']>;
  freq: Maybe<Scalars['Float']>;
  gps_enable: Maybe<Scalars['Boolean']>;
  hibernate_enable: Maybe<Scalars['Boolean']>;
  historical_enable: Maybe<Scalars['Boolean']>;
  hw_ver: Maybe<Scalars['String']>;
  missed_message_sleep: Maybe<Scalars['Float']>;
  rpt_int: Maybe<Scalars['Float']>;
  sensor_var: Maybe<Scalars['String']>;
  sw_ver: Maybe<Scalars['String']>;
  t_sleep: Maybe<Scalars['Float']>;
  tx_high_threshold: Maybe<Scalars['Float']>;
  tx_low_threshold: Maybe<Scalars['Float']>;
  tx_power: Maybe<Scalars['Float']>;
};

export type SensorData = {
  __typename: 'SensorData';
  adc: Maybe<Array<Maybe<Scalars['Float']>>>;
  counters: Maybe<Array<Maybe<Scalars['Float']>>>;
  cumulativeCount: Maybe<Scalars['Float']>;
  vic: Maybe<Array<Maybe<Scalars['Float']>>>;
  gateway: Maybe<Scalars['String']>;
  meas_no: Maybe<Scalars['Float']>;
  moistures: Maybe<Array<Maybe<Scalars['Float']>>>;
  node: Maybe<Scalars['String']>;
  old_server_time: Maybe<Scalars['Float']>;
  /** If there is an error message sent for this serial number, query for it. */
  error: Maybe<Scalars['String']>;
  /** The raw data object (stringified).  Useful for prototyping stuff, don't use in prod. */
  raw: Scalars['String'];
  rainfall: Maybe<Array<SensorDataRainfall>>;
  rssi: Maybe<Scalars['Float']>;
  rtc_time: Maybe<Scalars['String']>;
  tcount: Maybe<Array<Maybe<Scalars['Float']>>>;
  bootCounter: Maybe<Scalars['Float']>;
  bootLimitCounter: Maybe<Scalars['Float']>;
  sessionRetry: Maybe<Scalars['Float']>;
  sessionFail: Maybe<Scalars['Float']>;
  historicalMessages: Maybe<Scalars['Float']>;
  pwrOnRstCounter: Maybe<Scalars['Float']>;
  brnOutRstCounter: Maybe<Scalars['Float']>;
  extRstCounter: Maybe<Scalars['Float']>;
  wdtRstCounter: Maybe<Scalars['Float']>;
  temp: Maybe<Array<Scalars['Float']>>;
  tensions: Maybe<Array<Maybe<Scalars['Float']>>>;
  tensionsWithTemp: Maybe<Array<Maybe<Scalars['Float']>>>;
  pressures: Maybe<Array<Scalars['Float']>>;
  voltages: Maybe<Array<Scalars['Float']>>;
  /** Pressure in psi */
  averagePressure: Maybe<Scalars['Float']>;
  /** Voltage in mV */
  averageVoltage: Maybe<Scalars['Float']>;
  /** Runtime in seconds */
  runtime: Maybe<Scalars['Float']>;
  time: Scalars['Float'];
  vin: Maybe<Scalars['Float']>;
  /** Power pack voltage [V] */
  vpp: Maybe<Scalars['Float']>;
  /** Telemetry data point - contains no measurement data */
  nonsens: Maybe<Scalars['Int']>;
};

export enum SensorDataPlan {
  active = 'active',
}

export type SensorDataRainfall = {
  __typename: 'SensorDataRainfall';
  time: Scalars['Float'];
  /** Hourly rainfall in inches */
  amount: Scalars['Float'];
};

export type SensorDataView = {
  __typename: 'SensorDataView';
  soil: Array<SensorSoilData>;
  rainfall: Array<SensorRainfallData>;
  weather: Array<WeatherStationData>;
  pressure: Array<SensorPressureData>;
};

export type SensorDataViewrainfallArgs = {
  tzoffset?: Maybe<Scalars['Int']>;
  limit: Maybe<Scalars['Int']>;
};

export type SensorEditInput = {
  sensorId: Scalars['String'];
  location: Scalars['String'];
  value: Scalars['String'];
  name: Scalars['String'];
};

export type SensorEditResponse = {
  __typename: 'SensorEditResponse';
  success: Scalars['Boolean'];
};

/**
 * A plan for how to interpret data from a sensor
 * for the duration of a season
 */
export type SensorManagementPlan = {
  __typename: 'SensorManagementPlan';
  id: Scalars['String'];
  name: Scalars['String'];
  model: Maybe<Model>;
  stages: Array<SensorManagementPlanStage>;
};

export type SensorManagementPlanAddResponse = {
  __typename: 'SensorManagementPlanAddResponse';
  success: Scalars['Boolean'];
  sensorManagementPlan: SensorManagementPlan;
  sensor: Sensor;
};

export type SensorManagementPlanInput = {
  name: Scalars['String'];
  stages: Array<SensorManagementPlanStageInput>;
};

export type SensorManagementPlanResponse = {
  __typename: 'SensorManagementPlanResponse';
  success: Scalars['Boolean'];
  sensorManagementPlan: Maybe<SensorManagementPlan>;
};

/**
 * A representation of how a sensor's data should
 * be interpreted for a window of time
 */
export type SensorManagementPlanStage = {
  __typename: 'SensorManagementPlanStage';
  name: Scalars['String'];
  wetTH: Scalars['Float'];
  dryTH: Scalars['Float'];
  weights: Array<Scalars['Float']>;
  DAP: Scalars['Int'];
};

export type SensorManagementPlanStageInput = {
  name: Scalars['String'];
  wetTH: Scalars['Float'];
  dryTH: Scalars['Float'];
  weights: Array<Scalars['Float']>;
  DAP: Scalars['Int'];
};

export type SensorMutationResponse = {
  __typename: 'SensorMutationResponse';
  /** The id of the sensor modified */
  sensorId: Maybe<Scalars['String']>;
  sensor: Maybe<Sensor>;
  success: Scalars['Boolean'];
};

export type SensorNoPlan = {
  __typename: 'SensorNoPlan';
  message: Scalars['String'];
};

export type SensorOffline = {
  __typename: 'SensorOffline';
  message: Scalars['String'];
};

export type SensorPlanDetails = {
  __typename: 'SensorPlanDetails';
  /** If the sensor is prepaid, it's subscription start date might be in the future */
  activeAfter: Maybe<Scalars['Float']>;
  /**
   * If the stripeSubActiveUntil is in the future, this will be it
   * This will be set regardless of if the sensor is cancelled or not
   */
  activeUntil: Maybe<Scalars['Float']>;
  /**
   * If stripeSubCancelledAt is in the future, this will be it
   * This should be set only if the sensor is scheduled to be cancelled at the end of the cycle
   */
  cancelDate: Maybe<Scalars['Float']>;
  /** True if the sensor has an active data plan */
  active: Scalars['Boolean'];
};

export type SensorPressureData = {
  __typename: 'SensorPressureData';
  /** Time in unix ms */
  t: Scalars['Float'];
  /** Average pressure in psi */
  avgP: Scalars['Float'];
  /** Runtime in seconds (0-120) */
  rt: Scalars['Float'];
};

export type SensorPressureOnOffData = {
  __typename: 'SensorPressureOnOffData';
  /** Time in unix ms (start of change) */
  t: Scalars['Float'];
  /** Whether this is turning on, off, offline */
  state: SensorPressureOnOffDataState;
  /** Average pressure in psi during on/off */
  avgP: Scalars['Float'];
};

export enum SensorPressureOnOffDataState {
  on = 'on',
  off = 'off',
  offline = 'offline',
}

export type SensorRainfallData = {
  __typename: 'SensorRainfallData';
  /** Start of rainfall bucket.  Format: YYYY-MM-DD */
  t: Scalars['String'];
  /** Amount of rainfall in inches */
  a: Scalars['Float'];
};

export type SensorReprogramInput = {
  sensorId: Scalars['String'];
  softwareVersion: Scalars['String'];
  hardwareVersion: Scalars['String'];
  variant: Scalars['String'];
  name: Scalars['String'];
};

export type SensorReprogramResponse = {
  __typename: 'SensorReprogramResponse';
  success: Scalars['Boolean'];
};

export type SensorResetInput = {
  sensorId: Scalars['String'];
  name: Scalars['String'];
};

export type SensorResetResponse = {
  __typename: 'SensorResetResponse';
  success: Scalars['Boolean'];
};

export type SensorRetryInput = {
  sensorId: Scalars['String'];
  measurement: Scalars['String'];
  name: Scalars['String'];
};

export type SensorRetryResponse = {
  __typename: 'SensorRetryResponse';
  success: Scalars['Boolean'];
};

export type SensorSoilData = {
  __typename: 'SensorSoilData';
  /** Time in unix ms */
  t: Scalars['Float'];
  /** Soil moisture tensions */
  sm: Array<Maybe<Scalars['Float']>>;
  /** Soil moisture tensions with temp */
  smt: Array<Maybe<Scalars['Float']>>;
  /** Soil temperature */
  st: Array<Maybe<Scalars['Float']>>;
  /** Volumetric Ion Content of soil */
  vic: Array<Maybe<Scalars['Float']>>;
  /** VIC converted to EC in dS/m */
  ec: Array<Maybe<Scalars['Float']>>;
};

export type SensorWeight = {
  __typename: 'SensorWeight';
  id: Scalars['String'];
  weight: Scalars['Int'];
};

export type SensorWeightInput = {
  id: Scalars['String'];
  weight: Scalars['Int'];
};

export type ServiceLog = {
  __typename: 'ServiceLog';
  /** Key of the log in firebase */
  id: Scalars['String'];
  /** Author of service log */
  name: Scalars['String'];
  /** Date the service happened */
  date: Scalars['Float'];
  /** Location of the service */
  location: Scalars['String'];
  odometerTo: Scalars['Float'];
  odometerFrom: Scalars['Float'];
  /** Time spent servicing this hardware */
  timeSpent: Scalars['String'];
  /** Reason for the service */
  reason: Scalars['String'];
  /** Result of the service */
  result: Scalars['String'];
  /** Follow up tasks for after the service */
  followUpTasks: Scalars['String'];
};

export type ServiceLogCreateInput = {
  /** Id of the hardware */
  hw: Scalars['String'];
  /** Type of hardware, either gateway or sensor */
  type: Scalars['String'];
  /** Author of service log */
  name: Scalars['String'];
  /** Date the service happened */
  date: Scalars['Float'];
  /** Location of the service */
  location: Scalars['String'];
  odometerTo: Scalars['Float'];
  odometerFrom: Scalars['Float'];
  /** Time spent servicing this hardware */
  timeSpent: Scalars['String'];
  /** Reason for the service */
  reason: Scalars['String'];
  /** Result of the service */
  result: Scalars['String'];
  /** Follow up tasks for after the service */
  followUpTasks: Scalars['String'];
};

export type ServiceLogCreateReponse = {
  __typename: 'ServiceLogCreateReponse';
  /** If the creation succeeded */
  success: Scalars['Boolean'];
  /** If creation succeeded, the log */
  log: ServiceLog;
};

export type ServiceLogDeleteInput = {
  /** Key of the service log */
  id: Scalars['String'];
  /** Id of the hardware */
  hw: Scalars['String'];
  /** Type of hardware, either gateway or sensor */
  type: Scalars['String'];
};

export type ServiceLogDeleteReponse = {
  __typename: 'ServiceLogDeleteReponse';
  /** If the delete succeeded */
  success: Scalars['Boolean'];
};

export type ServiceLogUpdateInput = {
  /** Key of the service log */
  id: Scalars['String'];
  /** Id of the hardware */
  hw: Scalars['String'];
  /** Type of hardware, either gateway or sensor */
  type: Scalars['String'];
  /** Author of service log */
  name: Scalars['String'];
  /** Date the service happened */
  date: Scalars['Float'];
  /** Location of the service */
  location: Scalars['String'];
  odometerTo: Scalars['Float'];
  odometerFrom: Scalars['Float'];
  /** Time spent servicing this hardware */
  timeSpent: Scalars['String'];
  /** Reason for the service */
  reason: Scalars['String'];
  /** Result of the service */
  result: Scalars['String'];
  /** Follow up tasks for after the service */
  followUpTasks: Scalars['String'];
};

export type ServiceLogUpdateReponse = {
  __typename: 'ServiceLogUpdateReponse';
  /** If the update succeeded */
  success: Scalars['Boolean'];
  /** If update succeeded, the log */
  log: ServiceLog;
};

export type ShipOrderInput = {
  /** The id of the order to mark as shpped */
  orderId: Scalars['Int'];
  /** Tracking number of the shipment (required) */
  trackingNumber: Scalars['String'];
  /** Carrier being used (USPS) */
  carrier: Scalars['String'];
  /** Shipping cost in USD */
  amount: Scalars['Float'];
  /** Whether or not to send shipping email */
  sendShippingEmail: Scalars['Boolean'];
};

export type ShipOrderResponse = {
  __typename: 'ShipOrderResponse';
  /** The id of the order that was marked as shipped */
  id: Maybe<Scalars['Int']>;
};

export type SoftwareVersion = {
  __typename: 'SoftwareVersion';
  name: Scalars['String'];
  hardwareVersions: Array<HardwareVersions>;
};

export type Soil = {
  __typename: 'Soil';
  id: Scalars['String'];
  name: Scalars['String'];
  /** Tension threshold beneath which soil is considered dry */
  t_dry: Scalars['Float'];
  /** Tension threshold above which soil is considered wet */
  t_wet: Scalars['Float'];
  /** Capacitance threshold beneath which soil is considered dry */
  c_dry: Scalars['Float'];
  /** Capacitance threshold above which soil is considered wet */
  c_wet: Scalars['Float'];
  /** True if a user created the soil type */
  custom: Scalars['Boolean'];
  clay_percent: Scalars['Float'];
};

export type StartManagedServiceInput = {
  orderId: Scalars['Int'];
};

export type StartManagedServiceResponse = {
  __typename: 'StartManagedServiceResponse';
  success: Scalars['Boolean'];
};

export type StripeBillingPortalResponse = {
  __typename: 'StripeBillingPortalResponse';
  /** Redirect url for stripe billing page */
  sessionUrl: Scalars['String'];
};

export type StripeCard = {
  __typename: 'StripeCard';
  id: Scalars['String'];
  brand: Maybe<Scalars['String']>;
  last4: Maybe<Scalars['String']>;
};

export enum StripeDataPlan {
  TrellisDashboardPrepaid = 'TrellisDashboardPrepaid',
  TrellisDashboard = 'TrellisDashboard',
  TrellisDashboard15 = 'TrellisDashboard15',
  TrellisDashboard25 = 'TrellisDashboard25',
  TrellisDashboard30 = 'TrellisDashboard30',
  TrellisDashboard25Volume = 'TrellisDashboard25Volume',
  TrellisMonthly35 = 'TrellisMonthly35',
}

export type StripeSubscription = {
  __typename: 'StripeSubscription';
  id: Scalars['String'];
  canceled: Scalars['Boolean'];
  periodEnd: Scalars['Float'];
};

export type Subscription = {
  __typename: 'Subscription';
  /**
   * In the future, we may return a SupportChatLog here so the client doesn't need to refresh.
   * Boolean acts as a placeholder.
   */
  updateSupportChat: Scalars['Boolean'];
};

export type SupportChatLog = {
  __typename: 'SupportChatLog';
  messages: Array<SupportMessage>;
  closed: Scalars['Boolean'];
};

export type SupportMessage = {
  __typename: 'SupportMessage';
  from: Scalars['String'];
  body: Scalars['String'];
  id: Scalars['String'];
  time: Scalars['String'];
};

export type TransferFundsManagedServiceInput = {
  /** ID of distributor to transfer funds to */
  distributorId: Scalars['String'];
  /** Amount in cents */
  amount: Scalars['Int'];
};

export type TransferFundsManagedServiceReponse = {
  __typename: 'TransferFundsManagedServiceReponse';
  success: Scalars['Boolean'];
};

export type TrellisPressureSensor = TrellisSensor & {
  __typename: 'TrellisPressureSensor';
  data: PressureSensorDataResult;
  plan: Maybe<PressureSensorPlan>;
  irrigationSystem: Maybe<IrrigationSystem>;
  hasDataAccess: Scalars['Boolean'];
  id: Scalars['String'];
  name: Scalars['String'];
  /**
   * Whether or not the sensor is active.  Controls alerts, visibility on
   * dashboard, etc... Set true when the sensor's data is paid for, and false when
   * the sensor is archived.
   */
  active: Scalars['Boolean'];
  /** Is the sensor online or offline? */
  connectionStatus: ConnectionStatus;
  dataPlan: SensorPlanDetails;
  lastReportedTime: Maybe<Scalars['Float']>;
  location: Maybe<Location>;
  isOwner: Scalars['Boolean'];
  owner: Maybe<HardwareUser>;
  permissionLevel: Maybe<PermissionLevel>;
  users: Array<HardwareUser>;
};

export type TrellisSensor = {
  id: Scalars['String'];
  name: Scalars['String'];
  /**
   * Whether or not the sensor is active.  Controls alerts, visibility on
   * dashboard, etc... Set true when the sensor's data is paid for, and false when
   * the sensor is archived.
   */
  active: Scalars['Boolean'];
  /** Is the sensor online or offline? */
  connectionStatus: ConnectionStatus;
  dataPlan: SensorPlanDetails;
  lastReportedTime: Maybe<Scalars['Float']>;
  location: Maybe<Location>;
  isOwner: Scalars['Boolean'];
  owner: Maybe<HardwareUser>;
  permissionLevel: Maybe<PermissionLevel>;
  users: Array<HardwareUser>;
};

export type TrellisWeatherSensor = TrellisSensor & {
  __typename: 'TrellisWeatherSensor';
  data: WeatherSensorDataResult;
  managementPlan: Maybe<WeatherStationManagementPlan>;
  fields: Array<Field>;
  id: Scalars['String'];
  name: Scalars['String'];
  /**
   * Whether or not the sensor is active.  Controls alerts, visibility on
   * dashboard, etc... Set true when the sensor's data is paid for, and false when
   * the sensor is archived.
   */
  active: Scalars['Boolean'];
  /** Is the sensor online or offline? */
  connectionStatus: ConnectionStatus;
  dataPlan: SensorPlanDetails;
  lastReportedTime: Maybe<Scalars['Float']>;
  location: Maybe<Location>;
  isOwner: Scalars['Boolean'];
  owner: Maybe<HardwareUser>;
  permissionLevel: Maybe<PermissionLevel>;
  users: Array<HardwareUser>;
};

export type UpdateAlertHistoryInput = {
  /** ID of user to update */
  uid: Scalars['String'];
  /** Update the last login reminder time */
  loginReminderSent?: Maybe<Scalars['Boolean']>;
  /** Update the last daily alert time */
  dailySent?: Maybe<Scalars['Boolean']>;
  /** Update the last weekly alert time */
  weeklySent?: Maybe<Scalars['Boolean']>;
  /** Update the last monthly alert time */
  monthlySent?: Maybe<Scalars['Boolean']>;
  /** Update the dyk used */
  dykId?: Maybe<Scalars['String']>;
  /** Update the last registration reminder time */
  registrationReminderSent?: Maybe<Scalars['Boolean']>;
  /** Update the last irrigation needed alert time */
  irrigationNeeded?: Maybe<Scalars['Boolean']>;
};

export type UpdateAlertHistoryResponse = {
  __typename: 'UpdateAlertHistoryResponse';
  /** The id of the user updated */
  id: Maybe<Scalars['String']>;
};

/** A list of all gateways owned by the user with a plan. */
export type UpdateDataPlanGatewayInput = {
  /** ID of the gateway */
  id: Scalars['String'];
  /** Data plan, or null for none */
  plan?: Maybe<SensorDataPlan>;
};

export type UpdateDataPlanInput = {
  sensors?: Maybe<Array<UpdateDataPlanSensorInput>>;
  gateways?: Maybe<Array<UpdateDataPlanGatewayInput>>;
};

export type UpdateDataPlanResponse = {
  __typename: 'UpdateDataPlanResponse';
  /** The id of the user updated */
  id: Maybe<Scalars['String']>;
};

/** A list of all sensors owned by the user with a plan. */
export type UpdateDataPlanSensorInput = {
  /** ID of the sensor */
  id: Scalars['String'];
  /** Data plan, or null for none */
  plan?: Maybe<SensorDataPlan>;
};

export type UpdateFieldInput = {
  boundary?: Maybe<Array<NNLocationInput>>;
  boundarySoilStats?: Maybe<Array<FieldBoundaryInfoSoilStatInput>>;
  boundarySensorInfo?: Maybe<Array<FieldBoundaryInfoResultSensorInput>>;
  field_name?: Maybe<Scalars['String']>;
  /** New list of sensor ids */
  field_sensors?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** New list of gateway ids */
  field_gateways?: Maybe<Array<Maybe<Scalars['String']>>>;
  field_notes?: Maybe<Scalars['String']>;
  irrigationMetadata?: Maybe<FieldInputIrrigationMetadata>;
  sensorWeights?: Maybe<Array<SensorWeightInput>>;
  /** Crop ID corresponding to this field's crop_type */
  crop_type?: Maybe<Scalars['String']>;
  /** String designating specific crop variety */
  crop_variety?: Maybe<Scalars['String']>;
  /** Yield of this field */
  yield?: Maybe<YieldInput>;
};

export type UpdateFieldMutationResponse = {
  __typename: 'UpdateFieldMutationResponse';
  success: Scalars['Boolean'];
  field: Maybe<Field>;
};

export type UpdateGatewayInput = {
  id: Scalars['String'];
  active?: Maybe<Scalars['Boolean']>;
  location?: Maybe<LocationInput>;
};

export type UpdateGatewayMutationResponse = {
  __typename: 'UpdateGatewayMutationResponse';
  success: Scalars['Boolean'];
  gateway: Maybe<Gateway>;
};

export type UpdateGatewaySharingInput = {
  gatewayId: Scalars['String'];
  /** The email of the user to share it with */
  email: Scalars['String'];
  /** The permission level to give the user, or null to remove the permission */
  level?: Maybe<PermissionLevelInput>;
};

export type UpdateGatewaySharingResponse = {
  __typename: 'UpdateGatewaySharingResponse';
  /** The id of the gateway modified */
  success: Scalars['Boolean'];
  /** ID of user that was shared with */
  sharedUid: Scalars['String'];
  gateway: Gateway;
};

export type UpdateGatewayStatusInput = {
  id: Scalars['String'];
  inactive: Scalars['Boolean'];
};

export type UpdateLoginInput = {
  id: Scalars['String'];
  timestamp: Scalars['Float'];
};

export type UpdateLoginResponse = {
  __typename: 'UpdateLoginResponse';
  /** The id of the login added */
  id: Maybe<Scalars['String']>;
};

export type UpdateSensorInput = {
  /** Format: YYYY-MM-DD */
  harvest_date?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  crop_type?: Maybe<Scalars['String']>;
  crop_variety?: Maybe<Scalars['String']>;
  /** If calling as a rep, this must be set */
  customerId?: Maybe<Scalars['String']>;
  soil_type?: Maybe<Scalars['String']>;
  plant_date?: Maybe<Scalars['String']>;
  location?: Maybe<LocationInput>;
  active?: Maybe<Scalars['Boolean']>;
  managementPlan?: Maybe<Scalars['String']>;
};

export type UpdateSensorSharingInput = {
  sensorId: Scalars['String'];
  /** The email of the user to share it with */
  email: Scalars['String'];
  /** The permission level to give the user, or null to remove the permission */
  level?: Maybe<PermissionLevelInput>;
};

export type UpdateSensorSharingResponse = {
  __typename: 'UpdateSensorSharingResponse';
  /** The id of the sensor modified */
  success: Scalars['Boolean'];
  /** ID of user that was shared with */
  sharedUid: Scalars['String'];
  sensor: Sensor;
};

export type UpdateSensorStateInput = {
  sensorId: Scalars['String'];
  state: Scalars['Int'];
  tension: Scalars['Float'];
};

export type UpdateSensorStatusInput = {
  inactive?: Maybe<Scalars['Boolean']>;
  battery?: Maybe<Scalars['Float']>;
  rssi?: Maybe<Scalars['Float']>;
  sensor_time?: Maybe<Scalars['Float']>;
  usage_stats?: Maybe<UsageStatsInput>;
};

export type UpdateSoilInput = {
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  t_wet?: Maybe<Scalars['Float']>;
  t_dry?: Maybe<Scalars['Float']>;
  c_wet?: Maybe<Scalars['Float']>;
  c_dry?: Maybe<Scalars['Float']>;
};

export type UpdateSoilResponse = {
  __typename: 'UpdateSoilResponse';
  success: Scalars['Boolean'];
  soil: Soil;
};

export type UpdateUserPreferencesInput = {
  textEnabled?: Maybe<Scalars['Boolean']>;
  emailEnabled?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  triggerAlertThresholdHours?: Maybe<Scalars['Float']>;
};

export type UpdateUserPreferencesResponse = {
  __typename: 'UpdateUserPreferencesResponse';
  success: Scalars['Boolean'];
  /** The user that was updated */
  me: User;
};

export type UpdateUserProfileInput = {
  name?: Maybe<NameInput>;
};

export type UpdateUserProfileResponse = {
  __typename: 'UpdateUserProfileResponse';
  /** The id of the user updated */
  id: Maybe<Scalars['String']>;
};

export type UsageStats = {
  __typename: 'UsageStats';
  dry: Scalars['Float'];
  ok: Scalars['Float'];
  wet: Scalars['Float'];
  online: Scalars['Float'];
  offline: Scalars['Float'];
  logins: Scalars['Float'];
};

export type UsageStatsInput = {
  dry: Scalars['Float'];
  ok: Scalars['Float'];
  wet: Scalars['Float'];
  online: Scalars['Float'];
  offline: Scalars['Float'];
  logins: Scalars['Float'];
};

export type UsedDidYouKnows = {
  __typename: 'UsedDidYouKnows';
  id: Scalars['String'];
  timestamp: Scalars['Float'];
};

export type User = {
  __typename: 'User';
  /** Firebase email associated with uid */
  accountEmail: Maybe<Scalars['String']>;
  /** True if the user has been activated by their distributor */
  activated: Maybe<Scalars['Boolean']>;
  /** True if the user has finished activating their account */
  activationCompleted: Maybe<Scalars['Boolean']>;
  alert_history: Maybe<Alert>;
  archivedSensors: Array<ArchivedSensor>;
  archivedGateways: Array<ArchivedGateway>;
  archivedFields: Array<ArchivedField>;
  archivedIrrigationSystems: Array<ArchivedIrrigationSystem>;
  cards: Maybe<Array<Maybe<StripeCard>>>;
  /** Email used for email alerts */
  email: Maybe<Scalars['String']>;
  distributor: Maybe<Distributor>;
  fields: Array<Field>;
  gateways: Array<Gateway>;
  gatewayIds: Array<Scalars['String']>;
  hasAccountIssues: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  /** Past invoices */
  invoices: Maybe<Array<Invoice>>;
  logins: Array<Maybe<Scalars['String']>>;
  managedServiceStatuses: Array<ManagedServiceStatus>;
  name: Name;
  notifications: Maybe<Array<Notification>>;
  /** Phone number used for text alerts */
  phone: Maybe<Scalars['String']>;
  planID: Maybe<StripeDataPlan>;
  preferences: UserPreferences;
  irrigationSystems: Array<IrrigationSystem>;
  pressureSensors: Array<TrellisPressureSensor>;
  prepaid: Maybe<Scalars['Boolean']>;
  prepaidStart: Maybe<Scalars['Float']>;
  prepaidUntil: Maybe<Scalars['Float']>;
  pushToken: Maybe<Scalars['String']>;
  sensors: Array<Sensor>;
  sensorIds: Array<Scalars['String']>;
  stripeSubID: Maybe<Scalars['String']>;
  stripeSubscription: Maybe<StripeSubscription>;
  stripeID: Maybe<Scalars['String']>;
  /** Sensors which have not been assigned to a field for this user */
  unassignedSensors: Array<Sensor>;
  upcomingInvoice: Maybe<Invoice>;
  /** All of the user's management plans */
  weatherManagementPlans: Array<WeatherStationManagementPlan>;
  weatherStations: Array<TrellisWeatherSensor>;
};

export type UserfieldsArgs = {
  onlyOwned: Maybe<Scalars['Boolean']>;
  onlyAdmin: Maybe<Scalars['Boolean']>;
};

export type UsergatewaysArgs = {
  onlyOwned: Maybe<Scalars['Boolean']>;
  onlyAdmin: Maybe<Scalars['Boolean']>;
  onlyRG: Maybe<Scalars['Boolean']>;
  onlyAvailable: Maybe<Scalars['Boolean']>;
};

export type UsersensorsArgs = {
  onlyOwned: Maybe<Scalars['Boolean']>;
  onlyAdmin: Maybe<Scalars['Boolean']>;
  onlyEditable: Maybe<Scalars['Boolean']>;
  onlyAvailable: Maybe<Scalars['Boolean']>;
};

export type UserPreferences = {
  __typename: 'UserPreferences';
  /** How many hours before the dry threshold a user wants to be notified */
  triggerAlertThresholdHours: Scalars['Float'];
  emailAlerts: Maybe<Scalars['Boolean']>;
  emailDailyReport: Maybe<Scalars['Boolean']>;
  emailWeeklyReport: Maybe<Scalars['Boolean']>;
  emailMonthlyReport: Maybe<Scalars['Boolean']>;
  textAlerts: Maybe<Scalars['Boolean']>;
};

export type UserSetPushTokenInput = {
  token: Scalars['String'];
};

export type UserSetPushTokenResponse = {
  __typename: 'UserSetPushTokenResponse';
  success: Scalars['Boolean'];
};

export type Weather = {
  __typename: 'Weather';
  weeklyForecast: Array<WeatherWeeklyForecast>;
  historical: Array<WeatherHistorical>;
};

export type WeatherHistorical = {
  __typename: 'WeatherHistorical';
  /** 1 Hour rainfall amount (in) */
  precipAmount: Scalars['Float'];
  /** Time in unix ms */
  time: Scalars['Float'];
  /** Humidity (%) (0 - 100) */
  hum: Maybe<Scalars['Float']>;
  /** UV Index */
  uvIndex: Maybe<Scalars['Float']>;
  /** High Temperature (F) */
  tempHigh: Maybe<Scalars['Float']>;
  /** Low Temperature (F) */
  tempLow: Maybe<Scalars['Float']>;
  /** Wind speed (mph) */
  windSpd: Maybe<Scalars['Float']>;
  /** Precipitation intensity */
  precipIntensity: Maybe<Scalars['Float']>;
  /** Cloud cover (%) (0 - 100) */
  cloudCover: Maybe<Scalars['Float']>;
  /** pressure */
  pressure: Maybe<Scalars['Float']>;
};

export type WeatherSensorData = {
  __typename: 'WeatherSensorData';
  currentWeather: CurrentWeatherResult;
  dailyRainfall: Array<SensorRainfallData>;
  weather: Array<WeatherStationData>;
};

export type WeatherSensorDatadailyRainfallArgs = {
  tzoffset?: Maybe<Scalars['Int']>;
  start: Maybe<Scalars['Float']>;
};

export type WeatherSensorDataweatherArgs = {
  start: Maybe<Scalars['Float']>;
};

export type WeatherSensorDataResult = WeatherSensorData | SensorNoPlan;

export type WeatherStationAlert = {
  __typename: 'WeatherStationAlert';
  type: WeatherStationAlertType;
  relationToThreshold: AboveOrBelow;
  threshold: Scalars['Float'];
};

export type WeatherStationAlertInput = {
  type: WeatherStationAlertType;
  relationToThreshold: AboveOrBelow;
  threshold: Scalars['Float'];
};

export enum WeatherStationAlertType {
  humidity = 'humidity',
  rainrate = 'rainrate',
  solar = 'solar',
  temperature = 'temperature',
  wetBulb = 'wetBulb',
  wind = 'wind',
}

export type WeatherStationData = {
  __typename: 'WeatherStationData';
  /** Time in unix ms */
  time: Scalars['Float'];
  /** Humidity (%) (0 - 100) */
  hum: Scalars['Float'];
  /** Max Wind Direction (N/NE/etc) */
  mWindDir: Scalars['String'];
  /** Max wind speed (mph) */
  mWindSpd: Scalars['Float'];
  /** Rain Count */
  rnCnt: Scalars['Float'];
  /** Rain rate (in/hr) */
  rnRate: Scalars['Float'];
  /** Rain total (in/hr) */
  rnTotal: Scalars['Float'];
  /** Solar radiation (W/m²) */
  solarRad: Scalars['Float'];
  /** Temperature (F) */
  temp: Scalars['Float'];
  /** Wet bulb temperature (F) */
  wbTemp: Maybe<Scalars['Float']>;
  /** Wind direction (N/NE/etc) */
  windDir: Scalars['String'];
  /** Wind speed (mph) */
  windSpd: Scalars['Float'];
  /** Wind error */
  windErr: Maybe<Scalars['Boolean']>;
  /** Rain rate error */
  rnRateErr: Maybe<Scalars['Boolean']>;
  /** Solar radiation error */
  solarRadErr: Maybe<Scalars['Boolean']>;
  /** Temperature error */
  tempErr: Maybe<Scalars['Boolean']>;
  /** Max wind error */
  mWindErr: Maybe<Scalars['Boolean']>;
  /** Humidity error */
  humErr: Maybe<Scalars['Boolean']>;
  /** Rain count error */
  rnCntErr: Maybe<Scalars['Boolean']>;
};

export type WeatherStationManagementPlan = {
  __typename: 'WeatherStationManagementPlan';
  id: Scalars['String'];
  name: Scalars['String'];
  alerts: Array<WeatherStationAlert>;
};

export type WeatherStationManagementPlanInput = {
  name: Scalars['String'];
  alerts: Array<WeatherStationAlertInput>;
};

export type WeatherStationManagementPlanResponse = {
  __typename: 'WeatherStationManagementPlanResponse';
  success: Scalars['Boolean'];
  weatherStationManagementPlan: Maybe<WeatherStationManagementPlan>;
};

/** Null values will be ignored */
export type WeatherStationUpdateInput = {
  name?: Maybe<Scalars['String']>;
  managementPlan?: Maybe<Scalars['String']>;
  location?: Maybe<LocationInput>;
};

export type WeatherStationUpdateResponse = {
  __typename: 'WeatherStationUpdateResponse';
  success: Scalars['Boolean'];
  weatherStation: TrellisWeatherSensor;
};

export type WeatherWeeklyForecast = {
  __typename: 'WeatherWeeklyForecast';
  /** 24 Hour rainfall amount (in) */
  precipAmount: Scalars['Float'];
  /** Probability of precipitation (100-0) */
  precipProbability: Scalars['Float'];
  icon: Maybe<Scalars['String']>;
  tempHigh: Scalars['Float'];
  tempLow: Scalars['Float'];
  /** Time in unix ms */
  time: Scalars['Float'];
};

/** Yield data entered during archiving process */
export type YieldData = {
  __typename: 'YieldData';
  actualYield: Maybe<YieldType>;
  targetYield: Maybe<YieldType>;
  performance: Maybe<YieldDataPerformance>;
};

export enum YieldDataPerformance {
  over = 'over',
  equal = 'equal',
  under = 'under',
}

export type YieldInput = {
  amount?: Maybe<Scalars['Float']>;
  units?: Maybe<YieldUnits>;
};

export type YieldType = {
  __typename: 'YieldType';
  amount: Maybe<Scalars['Float']>;
  units: Maybe<YieldUnits>;
};

export enum YieldUnits {
  lbs = 'lbs',
  bu = 'bu',
  crt = 'crt',
}

export type ZohoInvoice = {
  __typename: 'ZohoInvoice';
  due: Scalars['Float'];
  paymentUrl: Scalars['String'];
  status: ZohoInvoiceStatus;
};

export enum ZohoInvoiceStatus {
  paid = 'paid',
  sent = 'sent',
  draft = 'draft',
  overdue = 'overdue',
}

export type ZohoSalesOrder = {
  __typename: 'ZohoSalesOrder';
  invoice: Maybe<ZohoInvoice>;
};

export type AnalyticsInfoQueryVariables = {};

export type AnalyticsInfoQuery = {
  __typename: 'Query';
  me: {
    __typename: 'User';
    id: string;
    accountEmail: Maybe<string>;
    name: { __typename: 'Name'; first: Maybe<string>; last: Maybe<string> };
  };
};

export type ArchiveSensorStepSensorFragment = {
  __typename: 'Sensor';
  id: string;
  name: string;
  crop_variety: Maybe<string>;
  plant_date: Maybe<string>;
  harvest_date: Maybe<string>;
  crop_type: Maybe<{ __typename: 'Crop'; id: string; name: string }>;
  soil_type: Maybe<{ __typename: 'Soil'; id: string; name: string }>;
};

export type ArchiveUpdateSensorMutationVariables = {
  id: Scalars['String'];
  input: UpdateSensorInput;
};

export type ArchiveUpdateSensorMutation = {
  __typename: 'Mutation';
  updateSensor: Maybe<{
    __typename: 'SensorMutationResponse';
    sensor: Maybe<
      { __typename: 'Sensor'; id: string } & ArchiveSensorStepSensorFragment
    >;
  }>;
};

export type CurrentWeatherSensorFragment = {
  __typename: 'TrellisWeatherSensor';
  id: string;
  managementPlan: Maybe<{
    __typename: 'WeatherStationManagementPlan';
    id: string;
    alerts: Array<{
      __typename: 'WeatherStationAlert';
      type: WeatherStationAlertType;
      relationToThreshold: AboveOrBelow;
      threshold: number;
    }>;
  }>;
  data:
    | {
        __typename: 'WeatherSensorData';
        currentWeather:
          | {
              __typename: 'WeatherStationData';
              time: number;
              hum: number;
              rnRate: number;
              solarRad: number;
              temp: number;
              windDir: string;
              windSpd: number;
              wbTemp: Maybe<number>;
              windErr: Maybe<boolean>;
              rnRateErr: Maybe<boolean>;
              solarRadErr: Maybe<boolean>;
              tempErr: Maybe<boolean>;
              mWindErr: Maybe<boolean>;
              humErr: Maybe<boolean>;
              rnCntErr: Maybe<boolean>;
            }
          | { __typename: 'SensorOffline'; message: string };
        dailyRainfall: Array<{
          __typename: 'SensorRainfallData';
          t: string;
          a: number;
        }>;
      }
    | { __typename: 'SensorNoPlan'; message: string };
};

export type DevicesMapSensorFragment = {
  __typename: 'Sensor';
  id: string;
  name: string;
  current_state: Maybe<number>;
  hasRainGauge: boolean;
  location: Maybe<{ __typename: 'Location'; lat: number; lng: number }>;
};

export type DevicesMapArchivedSensorFragment = {
  __typename: 'ArchivedSensor';
  id: string;
  name: string;
  hasRainGauge: boolean;
  location: Maybe<{ __typename: 'Location'; lat: number; lng: number }>;
};

export type DevicesMapGatewayFragment = {
  __typename: 'Gateway';
  id: string;
  name: string;
  hasRainGauge: boolean;
  location: Maybe<{ __typename: 'Location'; lat: number; lng: number }>;
};

export type DevicesMapArchivedGatewayFragment = {
  __typename: 'ArchivedGateway';
  id: string;
  name: string;
  hasRainGauge: boolean;
  location: Maybe<{ __typename: 'Location'; lat: number; lng: number }>;
};

export type DevicesMapWeatherStationFragment = {
  __typename: 'TrellisWeatherSensor';
  id: string;
  name: string;
  location: Maybe<{ __typename: 'Location'; lat: number; lng: number }>;
};

export type DevicesMapPressureSensorFragment = {
  __typename: 'TrellisPressureSensor';
  id: string;
  name: string;
  location: Maybe<{ __typename: 'Location'; lat: number; lng: number }>;
  data:
    | {
        __typename: 'PressureSensorData';
        currentPressure:
          | { __typename: 'CurrentPressure'; isOn: boolean }
          | { __typename: 'SensorOffline'; message: string };
      }
    | { __typename: 'SensorNoPlan'; message: string };
};

export type DevicesMapArchivedPressureSensorFragment = {
  __typename: 'ArchivedTrellisPressureSensor';
  id: string;
  name: string;
  location: Maybe<{ __typename: 'Location'; lat: number; lng: number }>;
};

export type DevicesTableSensorFragment = {
  __typename: 'Sensor';
  id: string;
  hasDataAccess: boolean;
  hasRainGauge: boolean;
  userHasSensorAccess: boolean;
  name: string;
  battery: Maybe<number>;
  rssi: Maybe<number>;
  connectionStatus: ConnectionStatus;
  current_state: Maybe<number>;
  current_tension: Maybe<number>;
  moistureMethod: Maybe<MoistureMethod>;
  permissionLevel: Maybe<PermissionLevel>;
  planRequired: boolean;
  field: Maybe<{ __typename: 'Field'; id: string; name: string }>;
  owner: Maybe<{
    __typename: 'HardwareUser';
    id: string;
    accountEmail: Maybe<string>;
    name: Maybe<{
      __typename: 'Name';
      first: Maybe<string>;
      last: Maybe<string>;
    }>;
  }>;
} & SensorOnlineSensorFragment;

export type DevicesTableGatewayFragment = {
  __typename: 'Gateway';
  id: string;
  name: string;
  hasDataAccess: boolean;
  hasRainGauge: boolean;
  userHasSensorAccess: boolean;
  cell_signal: Maybe<number>;
  gateway_battery: Maybe<number>;
  connectionStatus: ConnectionStatus;
  permissionLevel: Maybe<PermissionLevel>;
  planRequired: boolean;
  model: Maybe<{
    __typename: 'Model';
    hasWeatherStation: boolean;
    hasPressureSensor: boolean;
  }>;
  owner: Maybe<{
    __typename: 'HardwareUser';
    id: string;
    accountEmail: Maybe<string>;
    name: Maybe<{
      __typename: 'Name';
      first: Maybe<string>;
      last: Maybe<string>;
    }>;
  }>;
} & GatewayOnlineGatewayFragment;

type ArchiveChartFieldMoistureDataResult_FieldMoistureData_Fragment = {
  __typename: 'FieldMoistureData';
  moistureMethod: MoistureMethod;
  moistureAvg: Array<{
    __typename: 'FieldMoistureDataPoint';
    t: number;
    sm: number;
  }>;
  thresholdsAvg: Array<{
    __typename: 'MoistureThresholdPoint';
    wet: number;
    dry: number;
    time: number;
  }>;
};

type ArchiveChartFieldMoistureDataResult_FieldMoistureDataError_Fragment = {
  __typename: 'FieldMoistureDataError';
  messages: Array<string>;
};

export type ArchiveChartFieldMoistureDataResultFragment =
  | ArchiveChartFieldMoistureDataResult_FieldMoistureData_Fragment
  | ArchiveChartFieldMoistureDataResult_FieldMoistureDataError_Fragment;

export type ArchiveHistoricalRainfallGraphFieldFragment = {
  __typename: 'Field';
  id: string;
  irrigationEvents: Array<{
    __typename: 'IrrigationEvent';
    id: string;
    amount: number;
    date: string;
    source: IrrigationEventSource;
    note: Maybe<string>;
  }>;
  sensors: Array<{
    __typename: 'Sensor';
    id: string;
    plant_date: Maybe<string>;
    hasDataAccess: boolean;
    weather: {
      __typename: 'Weather';
      historical: Array<{
        __typename: 'WeatherHistorical';
        precipAmount: number;
        time: number;
      }>;
    };
  }>;
  rainfallSources: Maybe<
    Array<
      Maybe<
        | {
            __typename: 'Gateway';
            id: string;
            name: string;
            hasDataAccess: boolean;
            dataView: Maybe<{
              __typename: 'SensorDataView';
              rainfall: Array<{
                __typename: 'SensorRainfallData';
                t: string;
                a: number;
              }>;
            }>;
          }
        | {
            __typename: 'Sensor';
            id: string;
            name: string;
            hasDataAccess: boolean;
            dataView: Maybe<{
              __typename: 'SensorDataView';
              rainfall: Array<{
                __typename: 'SensorRainfallData';
                t: string;
                a: number;
              }>;
            }>;
          }
      >
    >
  >;
  moistureData:
    | ({
        __typename: 'FieldMoistureData';
      } & ArchiveChartFieldMoistureDataResult_FieldMoistureData_Fragment)
    | ({
        __typename: 'FieldMoistureDataError';
      } & ArchiveChartFieldMoistureDataResult_FieldMoistureDataError_Fragment);
  moistureProjection: {
    __typename: 'FieldMoistureProjection';
    errors: Array<string>;
    data: Maybe<
      Array<{
        __typename: 'FieldMoistureProjectionPoint';
        t: number;
        sm: number;
      }>
    >;
  };
};

export type ArchiveHistoricalRainfallGraphArchivedFieldFragment = {
  __typename: 'ArchivedField';
  id: string;
  irrigationEvents: Array<{
    __typename: 'IrrigationEvent';
    id: string;
    amount: number;
    date: string;
    source: IrrigationEventSource;
    note: Maybe<string>;
  }>;
  sensors: Array<{
    __typename: 'ArchivedSensor';
    id: string;
    plant_date: Maybe<string>;
    hasDataAccess: boolean;
    weather: {
      __typename: 'Weather';
      historical: Array<{
        __typename: 'WeatherHistorical';
        precipAmount: number;
        time: number;
      }>;
    };
  }>;
  rainfallSources: Maybe<
    Array<
      Maybe<
        | {
            __typename: 'ArchivedSensor';
            id: string;
            name: string;
            hasDataAccess: boolean;
            dataView: Maybe<{
              __typename: 'SensorDataView';
              rainfall: Array<{
                __typename: 'SensorRainfallData';
                t: string;
                a: number;
              }>;
            }>;
          }
        | {
            __typename: 'ArchivedGateway';
            id: string;
            name: string;
            hasDataAccess: boolean;
            dataView: Maybe<{
              __typename: 'SensorDataView';
              rainfall: Array<{
                __typename: 'SensorRainfallData';
                t: string;
                a: number;
              }>;
            }>;
          }
      >
    >
  >;
  moistureData:
    | ({
        __typename: 'FieldMoistureData';
      } & ArchiveChartFieldMoistureDataResult_FieldMoistureData_Fragment)
    | ({
        __typename: 'FieldMoistureDataError';
      } & ArchiveChartFieldMoistureDataResult_FieldMoistureDataError_Fragment);
};

export type cropsQueryVariables = {};

export type cropsQuery = {
  __typename: 'Query';
  crops: Array<{ __typename: 'Crop'; id: string; name: string }>;
};

export type FieldFormSensorFragment = {
  __typename: 'Sensor';
  id: string;
  name: string;
  current_state: Maybe<number>;
  hasRainfallData: boolean;
  location: Maybe<{ __typename: 'Location'; lat: number; lng: number }>;
  model: Maybe<{
    __typename: 'Model';
    moistureDepths: Maybe<Array<number>>;
    antennaLength: Maybe<number>;
  }>;
};

export type FieldFormGatewayFragment = {
  __typename: 'Gateway';
  id: string;
  hasRainGauge: boolean;
  location: Maybe<{ __typename: 'Location'; lat: number; lng: number }>;
};

export type FieldBoundaryInfoQueryVariables = {
  input: FieldBoundaryInfoInput;
};

export type FieldBoundaryInfoQuery = {
  __typename: 'Query';
  fieldBoundaryInfo: Maybe<{
    __typename: 'FieldBoundaryInfoResult';
    calculatedAcreage: Maybe<number>;
    sensorsInBounds: Array<{
      __typename: 'FieldBoundaryInfoResultSensor';
      id: string;
      soilPercent: Maybe<number>;
      soil: Maybe<{ __typename: 'Soil'; id: string; name: string }>;
    }>;
    soilStats: Array<{
      __typename: 'FieldBoundaryInfoSoilStat';
      soilPercent: number;
      soil: { __typename: 'Soil'; id: string; name: string };
    }>;
  }>;
};

export type SensorGatewayQueryVariables = {
  id: Scalars['String'];
};

export type SensorGatewayQuery = {
  __typename: 'Query';
  sensor: Maybe<{ __typename: 'Sensor'; id: string; gatewayId: Maybe<string> }>;
};

export type AddCropMutationVariables = {
  input: CropAddInput;
};

export type AddCropMutation = {
  __typename: 'Mutation';
  cropAdd: {
    __typename: 'CropAddResponse';
    crop: { __typename: 'Crop'; id: string; name: string; custom: boolean };
  };
};

export type SensorOnlineSensorFragment = {
  __typename: 'Sensor';
  id: string;
  battery: Maybe<number>;
  rssi: Maybe<number>;
  connectionStatus: ConnectionStatus;
};

export type GatewayOnlineGatewayFragment = {
  __typename: 'Gateway';
  id: string;
  gateway_battery: Maybe<number>;
  cell_signal: Maybe<number>;
  connectionStatus: ConnectionStatus;
};

type ChartFieldMoistureDataResult_FieldMoistureData_Fragment = {
  __typename: 'FieldMoistureData';
  moistureMethod: MoistureMethod;
  moistureAvg: Array<{
    __typename: 'FieldMoistureDataPoint';
    t: number;
    sm: number;
  }>;
  thresholdsAvg: Array<{
    __typename: 'MoistureThresholdPoint';
    wet: number;
    dry: number;
    time: number;
  }>;
};

type ChartFieldMoistureDataResult_FieldMoistureDataError_Fragment = {
  __typename: 'FieldMoistureDataError';
  messages: Array<string>;
};

export type ChartFieldMoistureDataResultFragment =
  | ChartFieldMoistureDataResult_FieldMoistureData_Fragment
  | ChartFieldMoistureDataResult_FieldMoistureDataError_Fragment;

export type HistoricalRainfallGraphFieldFragment = {
  __typename: 'Field';
  id: string;
  irrigationEvents: Array<{
    __typename: 'IrrigationEvent';
    id: string;
    amount: number;
    date: string;
    source: IrrigationEventSource;
    note: Maybe<string>;
  }>;
  sensors: Array<{
    __typename: 'Sensor';
    id: string;
    plant_date: Maybe<string>;
    hasDataAccess: boolean;
    hasRainfallData: boolean;
    weather: {
      __typename: 'Weather';
      historical: Array<{
        __typename: 'WeatherHistorical';
        precipAmount: number;
        time: number;
      }>;
    };
  }>;
  rainfallSources: Maybe<
    Array<
      Maybe<
        | {
            __typename: 'Gateway';
            id: string;
            name: string;
            hasDataAccess: boolean;
            hasRainGauge: boolean;
            dataView: Maybe<{
              __typename: 'SensorDataView';
              rainfall: Array<{
                __typename: 'SensorRainfallData';
                t: string;
                a: number;
              }>;
            }>;
          }
        | {
            __typename: 'Sensor';
            id: string;
            name: string;
            hasDataAccess: boolean;
            hasRainGauge: boolean;
            dataView: Maybe<{
              __typename: 'SensorDataView';
              rainfall: Array<{
                __typename: 'SensorRainfallData';
                t: string;
                a: number;
              }>;
            }>;
          }
      >
    >
  >;
  moistureData:
    | ({
        __typename: 'FieldMoistureData';
      } & ChartFieldMoistureDataResult_FieldMoistureData_Fragment)
    | ({
        __typename: 'FieldMoistureDataError';
      } & ChartFieldMoistureDataResult_FieldMoistureDataError_Fragment);
  moistureProjection: {
    __typename: 'FieldMoistureProjection';
    errors: Array<string>;
    data: Maybe<
      Array<{
        __typename: 'FieldMoistureProjectionPoint';
        t: number;
        sm: number;
      }>
    >;
  };
};

export type HistoricalRainfallGraphArchivedFieldFragment = {
  __typename: 'ArchivedField';
  id: string;
  irrigationEvents: Array<{
    __typename: 'IrrigationEvent';
    id: string;
    amount: number;
    date: string;
    source: IrrigationEventSource;
    note: Maybe<string>;
  }>;
  sensors: Array<{
    __typename: 'ArchivedSensor';
    id: string;
    plant_date: Maybe<string>;
    hasDataAccess: boolean;
    hasRainfallData: boolean;
    hasRainGauge: boolean;
    weather: {
      __typename: 'Weather';
      historical: Array<{
        __typename: 'WeatherHistorical';
        precipAmount: number;
        time: number;
      }>;
    };
  }>;
  rainfallSources: Maybe<
    Array<
      Maybe<
        | {
            __typename: 'ArchivedSensor';
            id: string;
            name: string;
            hasDataAccess: boolean;
            hasRainGauge: boolean;
            dataView: Maybe<{
              __typename: 'SensorDataView';
              rainfall: Array<{
                __typename: 'SensorRainfallData';
                t: string;
                a: number;
              }>;
            }>;
          }
        | {
            __typename: 'ArchivedGateway';
            id: string;
            name: string;
            hasDataAccess: boolean;
            hasRainGauge: boolean;
            dataView: Maybe<{
              __typename: 'SensorDataView';
              rainfall: Array<{
                __typename: 'SensorRainfallData';
                t: string;
                a: number;
              }>;
            }>;
          }
      >
    >
  >;
  moistureData:
    | ({
        __typename: 'FieldMoistureData';
      } & ChartFieldMoistureDataResult_FieldMoistureData_Fragment)
    | ({
        __typename: 'FieldMoistureDataError';
      } & ChartFieldMoistureDataResult_FieldMoistureDataError_Fragment);
};

export type IrrigationSystemChartIrrigationSystemFragment = {
  __typename: 'IrrigationSystem';
  id: string;
  sensors: Array<{
    __typename: 'TrellisPressureSensor';
    id: string;
    name: string;
    data:
      | {
          __typename: 'PressureSensorData';
          onOffTimes: Array<{
            __typename: 'SensorPressureOnOffData';
            t: number;
            state: SensorPressureOnOffDataState;
            avgP: number;
          }>;
        }
      | { __typename: 'SensorNoPlan' };
    plan: Maybe<{
      __typename: 'PressureSensorPlan';
      timeZone: string;
      stages: Array<{
        __typename: 'PressureSensorPlanStage';
        startDate: string;
        onTimes: Array<{
          __typename: 'PressureSensorPlanStageTime';
          start: number;
          end: number;
        }>;
      }>;
    }>;
  }>;
};

export type IrrigationSystemChartArchivedIrrigationSystemFragment = {
  __typename: 'ArchivedIrrigationSystem';
  id: string;
  sensors: Array<{
    __typename: 'ArchivedTrellisPressureSensor';
    id: string;
    name: string;
    data: {
      __typename: 'PressureSensorData';
      onOffTimes: Array<{
        __typename: 'SensorPressureOnOffData';
        t: number;
        state: SensorPressureOnOffDataState;
        avgP: number;
      }>;
    };
    plan: Maybe<{
      __typename: 'PressureSensorPlan';
      timeZone: string;
      stages: Array<{
        __typename: 'PressureSensorPlanStage';
        startDate: string;
        onTimes: Array<{
          __typename: 'PressureSensorPlanStageTime';
          start: number;
          end: number;
        }>;
      }>;
    }>;
  }>;
};

type NotificationPopupNotification_NotificationAnnouncement_Fragment = {
  __typename: 'NotificationAnnouncement';
  popupToShow: Maybe<PopupId>;
  id: string;
  read: boolean;
};

type NotificationPopupNotification_NotificationDrySensor_Fragment = {
  __typename: 'NotificationDrySensor';
  id: string;
  read: boolean;
};

type NotificationPopupNotification_NotificationRelease_Fragment = {
  __typename: 'NotificationRelease';
  primary: string;
  secondary: string;
  header: string;
  body: string;
  img: string;
  id: string;
  read: boolean;
  blogPostURL: string;
};

type NotificationPopupNotification_NotificationSoilTypePopulate_Fragment = {
  __typename: 'NotificationSoilTypePopulate';
  id: string;
  read: boolean;
};

export type NotificationPopupNotificationFragment =
  | NotificationPopupNotification_NotificationAnnouncement_Fragment
  | NotificationPopupNotification_NotificationDrySensor_Fragment
  | NotificationPopupNotification_NotificationRelease_Fragment
  | NotificationPopupNotification_NotificationSoilTypePopulate_Fragment;

export type PressureSensorChartFragment = {
  __typename: 'TrellisPressureSensor';
  id: string;
  name: string;
  data:
    | {
        __typename: 'PressureSensorData';
        pressure: Array<{
          __typename: 'SensorPressureData';
          t: number;
          avgP: number;
        }>;
      }
    | { __typename: 'SensorNoPlan'; message: string };
  plan: Maybe<{
    __typename: 'PressureSensorPlan';
    expectedPressure: Maybe<number>;
    timeZone: string;
    stages: Array<{
      __typename: 'PressureSensorPlanStage';
      startDate: string;
      onTimes: Array<{
        __typename: 'PressureSensorPlanStageTime';
        start: number;
        end: number;
      }>;
    }>;
  }>;
};

export type ArchivePressureSensorChartFragment = {
  __typename: 'ArchivedTrellisPressureSensor';
  id: string;
  name: string;
  data: {
    __typename: 'PressureSensorData';
    pressure: Array<{
      __typename: 'SensorPressureData';
      t: number;
      avgP: number;
    }>;
  };
  plan: Maybe<{
    __typename: 'PressureSensorPlan';
    expectedPressure: Maybe<number>;
    timeZone: string;
    stages: Array<{
      __typename: 'PressureSensorPlanStage';
      startDate: string;
      onTimes: Array<{
        __typename: 'PressureSensorPlanStageTime';
        start: number;
        end: number;
      }>;
    }>;
  }>;
};

type SensorGraphs_ArchivedSensor_Fragment = {
  __typename: 'ArchivedSensor';
  id: string;
} & SensorMoistureGraph_ArchivedSensor_Fragment &
  SensorTemperatureGraph_ArchivedSensor_Fragment &
  SensorECGraph_ArchivedSensor_Fragment;

type SensorGraphs_Sensor_Fragment = {
  __typename: 'Sensor';
  id: string;
} & SensorMoistureGraph_Sensor_Fragment &
  SensorTemperatureGraph_Sensor_Fragment &
  SensorECGraph_Sensor_Fragment;

export type SensorGraphsFragment =
  | SensorGraphs_ArchivedSensor_Fragment
  | SensorGraphs_Sensor_Fragment;

type SensorECGraph_ArchivedSensor_Fragment = {
  __typename: 'ArchivedSensor';
  id: string;
  name: string;
  hasDataAccess: boolean;
  dataView: Maybe<{
    __typename: 'SensorDataView';
    soil: Array<{
      __typename: 'SensorSoilData';
      t: number;
      ec: Array<Maybe<number>>;
    }>;
  }>;
  model: Maybe<{ __typename: 'Model'; ecDepths: Maybe<Array<number>> }>;
};

type SensorECGraph_Sensor_Fragment = {
  __typename: 'Sensor';
  id: string;
  name: string;
  hasDataAccess: boolean;
  dataView: Maybe<{
    __typename: 'SensorDataView';
    soil: Array<{
      __typename: 'SensorSoilData';
      t: number;
      ec: Array<Maybe<number>>;
    }>;
  }>;
  model: Maybe<{ __typename: 'Model'; ecDepths: Maybe<Array<number>> }>;
};

export type SensorECGraphFragment =
  | SensorECGraph_ArchivedSensor_Fragment
  | SensorECGraph_Sensor_Fragment;

type SensorMoistureGraph_ArchivedSensor_Fragment = {
  __typename: 'ArchivedSensor';
  id: string;
  name: string;
  hasDataAccess: boolean;
  hasRainfallData: boolean;
  moistureMethod: Maybe<MoistureMethod>;
  plant_date: Maybe<string>;
  dataView: Maybe<{
    __typename: 'SensorDataView';
    soil: Array<{
      __typename: 'SensorSoilData';
      t: number;
      sm: Array<Maybe<number>>;
      ec: Array<Maybe<number>>;
    }>;
    rainfall: Array<{ __typename: 'SensorRainfallData'; t: string; a: number }>;
  }>;
  model: Maybe<{ __typename: 'Model'; moistureDepths: Maybe<Array<number>> }>;
  soil_type: Maybe<{
    __typename: 'Soil';
    id: string;
    t_dry: number;
    t_wet: number;
    c_dry: number;
    c_wet: number;
  }>;
  managementPlan: Maybe<{
    __typename: 'SensorManagementPlan';
    id: string;
    stages: Array<{
      __typename: 'SensorManagementPlanStage';
      name: string;
      wetTH: number;
      dryTH: number;
      weights: Array<number>;
      DAP: number;
    }>;
  }>;
  weather: {
    __typename: 'Weather';
    historical: Array<{
      __typename: 'WeatherHistorical';
      precipAmount: number;
      time: number;
    }>;
  };
  field: Maybe<{
    __typename: 'Field';
    id: string;
    name: string;
    rainfallSources: Maybe<
      Array<
        Maybe<
          | {
              __typename: 'Gateway';
              id: string;
              name: string;
              hasRainGauge: boolean;
              dataView: Maybe<{
                __typename: 'SensorDataView';
                rainfall: Array<{
                  __typename: 'SensorRainfallData';
                  t: string;
                  a: number;
                }>;
              }>;
            }
          | {
              __typename: 'Sensor';
              id: string;
              name: string;
              hasRainGauge: boolean;
              dataView: Maybe<{
                __typename: 'SensorDataView';
                rainfall: Array<{
                  __typename: 'SensorRainfallData';
                  t: string;
                  a: number;
                }>;
              }>;
            }
        >
      >
    >;
    irrigationEvents: Array<{
      __typename: 'IrrigationEvent';
      id: string;
      amount: number;
      date: string;
      note: Maybe<string>;
      source: IrrigationEventSource;
    }>;
  }>;
};

type SensorMoistureGraph_Sensor_Fragment = {
  __typename: 'Sensor';
  id: string;
  name: string;
  hasDataAccess: boolean;
  hasRainfallData: boolean;
  moistureMethod: Maybe<MoistureMethod>;
  plant_date: Maybe<string>;
  dataView: Maybe<{
    __typename: 'SensorDataView';
    soil: Array<{
      __typename: 'SensorSoilData';
      t: number;
      sm: Array<Maybe<number>>;
      ec: Array<Maybe<number>>;
    }>;
    rainfall: Array<{ __typename: 'SensorRainfallData'; t: string; a: number }>;
  }>;
  model: Maybe<{ __typename: 'Model'; moistureDepths: Maybe<Array<number>> }>;
  soil_type: Maybe<{
    __typename: 'Soil';
    id: string;
    t_dry: number;
    t_wet: number;
    c_dry: number;
    c_wet: number;
  }>;
  managementPlan: Maybe<{
    __typename: 'SensorManagementPlan';
    id: string;
    stages: Array<{
      __typename: 'SensorManagementPlanStage';
      name: string;
      wetTH: number;
      dryTH: number;
      weights: Array<number>;
      DAP: number;
    }>;
  }>;
  weather: {
    __typename: 'Weather';
    historical: Array<{
      __typename: 'WeatherHistorical';
      precipAmount: number;
      time: number;
    }>;
  };
  field: Maybe<{
    __typename: 'Field';
    id: string;
    name: string;
    rainfallSources: Maybe<
      Array<
        Maybe<
          | {
              __typename: 'Gateway';
              id: string;
              name: string;
              hasRainGauge: boolean;
              dataView: Maybe<{
                __typename: 'SensorDataView';
                rainfall: Array<{
                  __typename: 'SensorRainfallData';
                  t: string;
                  a: number;
                }>;
              }>;
            }
          | {
              __typename: 'Sensor';
              id: string;
              name: string;
              hasRainGauge: boolean;
              dataView: Maybe<{
                __typename: 'SensorDataView';
                rainfall: Array<{
                  __typename: 'SensorRainfallData';
                  t: string;
                  a: number;
                }>;
              }>;
            }
        >
      >
    >;
    irrigationEvents: Array<{
      __typename: 'IrrigationEvent';
      id: string;
      amount: number;
      date: string;
      note: Maybe<string>;
      source: IrrigationEventSource;
    }>;
  }>;
};

export type SensorMoistureGraphFragment =
  | SensorMoistureGraph_ArchivedSensor_Fragment
  | SensorMoistureGraph_Sensor_Fragment;

type SensorTemperatureGraph_ArchivedSensor_Fragment = {
  __typename: 'ArchivedSensor';
  id: string;
  name: string;
  hasDataAccess: boolean;
  dataView: Maybe<{
    __typename: 'SensorDataView';
    soil: Array<{
      __typename: 'SensorSoilData';
      t: number;
      st: Array<Maybe<number>>;
    }>;
  }>;
  model: Maybe<{
    __typename: 'Model';
    temperatureDepths: Maybe<Array<number>>;
  }>;
};

type SensorTemperatureGraph_Sensor_Fragment = {
  __typename: 'Sensor';
  id: string;
  name: string;
  hasDataAccess: boolean;
  dataView: Maybe<{
    __typename: 'SensorDataView';
    soil: Array<{
      __typename: 'SensorSoilData';
      t: number;
      st: Array<Maybe<number>>;
    }>;
  }>;
  model: Maybe<{
    __typename: 'Model';
    temperatureDepths: Maybe<Array<number>>;
  }>;
};

export type SensorTemperatureGraphFragment =
  | SensorTemperatureGraph_ArchivedSensor_Fragment
  | SensorTemperatureGraph_Sensor_Fragment;

type SensorHeaderData_ArchivedSensor_Fragment = {
  __typename: 'ArchivedSensor';
  originalId: string;
  id: string;
  name: string;
  hasRainGauge: boolean;
  crop_variety: Maybe<string>;
  plant_date: Maybe<string>;
  harvest_date: Maybe<string>;
  location: Maybe<{ __typename: 'Location'; lat: number; lng: number }>;
  soil_type: Maybe<{ __typename: 'Soil'; id: string; name: string }>;
  managementPlan: Maybe<{
    __typename: 'SensorManagementPlan';
    id: string;
    name: string;
  }>;
  crop_type: Maybe<{ __typename: 'Crop'; id: string; name: string }>;
  field: Maybe<{
    __typename: 'Field';
    id: string;
    name: string;
    crop_variety: Maybe<string>;
    crop_type: Maybe<{ __typename: 'Crop'; id: string; name: string }>;
  }>;
} & DevicesMapArchivedSensorFragment;

type SensorHeaderData_Sensor_Fragment = {
  __typename: 'Sensor';
  permissionLevel: Maybe<PermissionLevel>;
  moistureMethod: Maybe<MoistureMethod>;
  current_state: Maybe<number>;
  current_tension: Maybe<number>;
  id: string;
  name: string;
  hasRainGauge: boolean;
  crop_variety: Maybe<string>;
  plant_date: Maybe<string>;
  harvest_date: Maybe<string>;
  location: Maybe<{ __typename: 'Location'; lat: number; lng: number }>;
  soil_type: Maybe<{ __typename: 'Soil'; id: string; name: string }>;
  managementPlan: Maybe<{
    __typename: 'SensorManagementPlan';
    id: string;
    name: string;
  }>;
  crop_type: Maybe<{ __typename: 'Crop'; id: string; name: string }>;
  field: Maybe<{
    __typename: 'Field';
    id: string;
    name: string;
    crop_variety: Maybe<string>;
    crop_type: Maybe<{ __typename: 'Crop'; id: string; name: string }>;
  }>;
} & SensorOnlineSensorFragment &
  DevicesMapSensorFragment;

export type SensorHeaderDataFragment =
  | SensorHeaderData_ArchivedSensor_Fragment
  | SensorHeaderData_Sensor_Fragment;

export type WeatherStationChartFragment = {
  __typename: 'TrellisWeatherSensor';
  id: string;
  name: string;
  managementPlan: Maybe<{
    __typename: 'WeatherStationManagementPlan';
    id: string;
    alerts: Array<{
      __typename: 'WeatherStationAlert';
      type: WeatherStationAlertType;
      relationToThreshold: AboveOrBelow;
      threshold: number;
    }>;
  }>;
  data:
    | {
        __typename: 'WeatherSensorData';
        dailyRainfall: Array<{
          __typename: 'SensorRainfallData';
          t: string;
          a: number;
        }>;
        weather: Array<{
          __typename: 'WeatherStationData';
          time: number;
          hum: number;
          rnRate: number;
          solarRad: number;
          temp: number;
          windDir: string;
          windSpd: number;
          wbTemp: Maybe<number>;
          windErr: Maybe<boolean>;
          rnRateErr: Maybe<boolean>;
          solarRadErr: Maybe<boolean>;
          tempErr: Maybe<boolean>;
          mWindErr: Maybe<boolean>;
          humErr: Maybe<boolean>;
          rnCntErr: Maybe<boolean>;
        }>;
      }
    | { __typename: 'SensorNoPlan'; message: string };
};

export type MockOneQueryVariables = {};

export type MockOneQuery = {
  __typename: 'Query';
  me: { __typename: 'User'; email: Maybe<string> };
};

export type MockTwoQueryVariables = {};

export type MockTwoQuery = {
  __typename: 'Query';
  me: { __typename: 'User'; email: Maybe<string> };
};

export type MockThreeQueryVariables = {};

export type MockThreeQuery = {
  __typename: 'Query';
  me: { __typename: 'User'; id: string; email: Maybe<string> };
};

export type MockFourQueryVariables = {};

export type MockFourQuery = {
  __typename: 'Query';
  me: {
    __typename: 'User';
    name: { __typename: 'Name'; first: Maybe<string>; last: Maybe<string> };
  };
};

export type AddFieldDataQueryVariables = {};

export type AddFieldDataQuery = {
  __typename: 'Query';
  me: {
    __typename: 'User';
    id: string;
    sensors: Array<
      { __typename: 'Sensor'; id: string } & FieldFormSensorFragment
    >;
    gateways: Array<
      { __typename: 'Gateway'; id: string } & FieldFormGatewayFragment
    >;
  };
  irrigationTypes: Array<{
    __typename: 'Irrigation';
    id: string;
    name: string;
  }>;
};

export type AddFieldMutationVariables = {
  input: AddFieldInput;
};

export type AddFieldMutation = {
  __typename: 'Mutation';
  addField: Maybe<{ __typename: 'FieldMutationResponse'; success: boolean }>;
};

export type ArchivesQueryVariables = {};

export type ArchivesQuery = {
  __typename: 'Query';
  me: {
    __typename: 'User';
    id: string;
    fields: Array<{ __typename: 'Field'; id: string; name: string }>;
    archivedFields: Array<{
      __typename: 'ArchivedField';
      id: string;
      name: string;
      archiveDate: string;
      sensors: Array<{
        __typename: 'ArchivedSensor';
        id: string;
        harvest_date: Maybe<string>;
      }>;
    }>;
    archivedSensors: Array<{
      __typename: 'ArchivedSensor';
      id: string;
      originalId: string;
      name: string;
      archiveDate: string;
      harvest_date: Maybe<string>;
    }>;
    archivedGateways: Array<{
      __typename: 'ArchivedGateway';
      id: string;
      originalId: string;
      name: string;
      archiveDate: string;
    }>;
    archivedIrrigationSystems: Array<{
      __typename: 'ArchivedIrrigationSystem';
      id: string;
      name: string;
      archiveDate: string;
    }>;
  };
};

export type FieldForArchiveNewQueryVariables = {
  fieldId: Scalars['String'];
  tzoffset: Scalars['Int'];
};

export type FieldForArchiveNewQuery = {
  __typename: 'Query';
  field: Maybe<{
    __typename: 'Field';
    id: string;
    name: string;
    calculatedAcreage: Maybe<number>;
    estimatedAcreage: Maybe<number>;
    irrigationEvents: Array<{
      __typename: 'IrrigationEvent';
      id: string;
      amount: number;
      source: IrrigationEventSource;
      date: string;
      note: Maybe<string>;
    }>;
    irrigationMetadata: {
      __typename: 'IrrigationMetadata';
      irrigationType: Maybe<string>;
      waterSource: Maybe<string>;
      powerSource: Maybe<string>;
      vendor: Maybe<string>;
      acreage: Maybe<number>;
      dripStats: {
        __typename: 'DripStatsType';
        emitterFlow: Maybe<number>;
        emitterSpacing: Maybe<number>;
        dripDistance: Maybe<number>;
      };
    };
    yield: {
      __typename: 'YieldType';
      amount: Maybe<number>;
      units: Maybe<YieldUnits>;
    };
    crop_type: Maybe<{
      __typename: 'Crop';
      id: string;
      name: string;
      custom: boolean;
    }>;
    sensors: Array<{
      __typename: 'Sensor';
      id: string;
      name: string;
      hasRainGauge: boolean;
      current_state: Maybe<number>;
      crop_variety: Maybe<string>;
      plant_date: Maybe<string>;
      harvest_date: Maybe<string>;
      crop_type: Maybe<{ __typename: 'Crop'; id: string; name: string }>;
      soil_type: Maybe<{ __typename: 'Soil'; id: string; name: string }>;
      location: Maybe<{ __typename: 'Location'; lat: number; lng: number }>;
      weather: {
        __typename: 'Weather';
        historical: Array<{
          __typename: 'WeatherHistorical';
          precipAmount: number;
          time: number;
        }>;
      };
    }>;
    gateways: Array<{
      __typename: 'Gateway';
      id: string;
      name: string;
      hasRainGauge: boolean;
      location: Maybe<{ __typename: 'Location'; lat: number; lng: number }>;
    }>;
    moistureData:
      | {
          __typename: 'FieldMoistureData';
          moistureMethod: MoistureMethod;
          moistureAvg: Array<{
            __typename: 'FieldMoistureDataPoint';
            sm: number;
            t: number;
          }>;
        }
      | { __typename: 'FieldMoistureDataError'; messages: Array<string> };
    rainfallSources: Maybe<
      Array<
        Maybe<
          | {
              __typename: 'Gateway';
              id: string;
              name: string;
              dataView: Maybe<{
                __typename: 'SensorDataView';
                rainfall: Array<{
                  __typename: 'SensorRainfallData';
                  t: string;
                  a: number;
                }>;
              }>;
            }
          | {
              __typename: 'Sensor';
              id: string;
              name: string;
              dataView: Maybe<{
                __typename: 'SensorDataView';
                rainfall: Array<{
                  __typename: 'SensorRainfallData';
                  t: string;
                  a: number;
                }>;
              }>;
            }
        >
      >
    >;
  }>;
  crops: Array<{ __typename: 'Crop'; name: string; id: string }>;
  soils: Array<{ __typename: 'Soil'; name: string; id: string }>;
};

export type ArchiveFieldNewMutationVariables = {
  input: ArchiveFieldInput;
};

export type ArchiveFieldNewMutation = {
  __typename: 'Mutation';
  archiveField: Maybe<{ __typename: 'ArchiveFieldResponse'; success: boolean }>;
};

export type ArchiveFieldUpdateInfoNewMutationVariables = {
  id: Scalars['String'];
  input: UpdateFieldInput;
};

export type ArchiveFieldUpdateInfoNewMutation = {
  __typename: 'Mutation';
  updateField: Maybe<{
    __typename: 'UpdateFieldMutationResponse';
    field: Maybe<{
      __typename: 'Field';
      id: string;
      irrigationMetadata: {
        __typename: 'IrrigationMetadata';
        acreage: Maybe<number>;
        irrigationType: Maybe<string>;
        vendor: Maybe<string>;
        powerSource: Maybe<string>;
        waterSource: Maybe<string>;
        dripStats: {
          __typename: 'DripStatsType';
          emitterFlow: Maybe<number>;
          emitterSpacing: Maybe<number>;
          dripDistance: Maybe<number>;
        };
      };
    }>;
  }>;
};

export type ArchivedFieldQueryVariables = {
  id: Scalars['String'];
};

export type ArchivedFieldQuery = {
  __typename: 'Query';
  archivedField: Maybe<
    {
      __typename: 'ArchivedField';
      id: string;
      sensors: Array<
        {
          __typename: 'ArchivedSensor';
          id: string;
          name: string;
          hasRainGauge: boolean;
          crop_variety: Maybe<string>;
          plant_date: Maybe<string>;
          harvest_date: Maybe<string>;
          crop_type: Maybe<{ __typename: 'Crop'; id: string; name: string }>;
          soil_type: Maybe<{ __typename: 'Soil'; id: string; name: string }>;
        } & DevicesMapArchivedSensorFragment
      >;
      gateways: Array<
        {
          __typename: 'ArchivedGateway';
          id: string;
          name: string;
        } & DevicesMapArchivedGatewayFragment
      >;
    } & FieldInfoArchivedFieldFragment
  >;
};

export type ArchivedHistoricalRainfallQueryVariables = {
  fieldId: Scalars['String'];
  tzoffset?: Maybe<Scalars['Int']>;
};

export type ArchivedHistoricalRainfallQuery = {
  __typename: 'Query';
  archivedField: Maybe<
    {
      __typename: 'ArchivedField';
      id: string;
    } & HistoricalRainfallGraphArchivedFieldFragment
  >;
};

export type ArchivedIrrigationSystemQueryVariables = {
  id: Scalars['String'];
};

export type ArchivedIrrigationSystemQuery = {
  __typename: 'Query';
  archivedIrrigationSystem: {
    __typename: 'ArchivedIrrigationSystem';
    id: string;
    name: string;
    sensors: Array<
      {
        __typename: 'ArchivedTrellisPressureSensor';
        id: string;
        name: string;
      } & DevicesMapArchivedPressureSensorFragment
    >;
  } & IrrigationSystemChartArchivedIrrigationSystemFragment;
};

export type ArchivedPressureSensorQueryVariables = {
  id: Scalars['String'];
};

export type ArchivedPressureSensorQuery = {
  __typename: 'Query';
  archivedPressureSensor: {
    __typename: 'ArchivedTrellisPressureSensor';
    id: string;
    name: string;
  } & DevicesMapArchivedPressureSensorFragment &
    ArchivePressureSensorChartFragment;
};

export type ArchivedSensorQueryVariables = {
  sensorId: Scalars['String'];
  tzoffset?: Maybe<Scalars['Int']>;
};

export type ArchivedSensorQuery = {
  __typename: 'Query';
  archivedSensor: Maybe<
    {
      __typename: 'ArchivedSensor';
      id: string;
    } & SensorGraphs_ArchivedSensor_Fragment &
      SensorHeaderData_ArchivedSensor_Fragment
  >;
};

export type EditFieldFieldFragment = {
  __typename: 'Field';
  id: string;
  name: string;
  crop_variety: Maybe<string>;
  sensorWeights: Array<{
    __typename: 'SensorWeight';
    id: string;
    weight: number;
  }>;
  irrigationMetadata: {
    __typename: 'IrrigationMetadata';
    irrigationType: Maybe<string>;
    waterSource: Maybe<string>;
    powerSource: Maybe<string>;
    vendor: Maybe<string>;
    acreage: Maybe<number>;
  };
  crop_type: Maybe<{ __typename: 'Crop'; id: string; name: string }>;
  sensors: Array<
    { __typename: 'Sensor'; id: string } & FieldFormSensorFragment
  >;
  gateways: Array<
    { __typename: 'Gateway'; id: string } & FieldFormGatewayFragment
  >;
  boundary: Maybe<Array<{ __typename: 'Location'; lat: number; lng: number }>>;
  boundarySoilStats: Maybe<
    Array<{
      __typename: 'FieldBoundaryInfoSoilStat';
      soilPercent: number;
      soil: { __typename: 'Soil'; id: string; name: string };
    }>
  >;
  boundarySensorInfo: Maybe<
    Array<{
      __typename: 'FieldBoundaryInfoResultSensor';
      id: string;
      soilPercent: Maybe<number>;
      soil: Maybe<{ __typename: 'Soil'; id: string; name: string }>;
    }>
  >;
  yield: {
    __typename: 'YieldType';
    amount: Maybe<number>;
    units: Maybe<YieldUnits>;
  };
};

export type EditFieldDataQueryVariables = {
  id: Scalars['String'];
};

export type EditFieldDataQuery = {
  __typename: 'Query';
  me: {
    __typename: 'User';
    id: string;
    sensors: Array<
      { __typename: 'Sensor'; id: string } & FieldFormSensorFragment
    >;
    gateways: Array<
      { __typename: 'Gateway'; id: string } & FieldFormGatewayFragment
    >;
  };
  field: Maybe<{ __typename: 'Field'; id: string } & EditFieldFieldFragment>;
  irrigationTypes: Array<{
    __typename: 'Irrigation';
    id: string;
    name: string;
  }>;
};

export type EditFieldMutationVariables = {
  fieldId: Scalars['String'];
  input: UpdateFieldInput;
};

export type EditFieldMutation = {
  __typename: 'Mutation';
  updateField: Maybe<{
    __typename: 'UpdateFieldMutationResponse';
    field: Maybe<{ __typename: 'Field'; id: string } & EditFieldFieldFragment>;
  }>;
};

export type ActivationProgressQueryVariables = {
  input: ActivationProgressInput;
};

export type ActivationProgressQuery = {
  __typename: 'Query';
  activationProgress: {
    __typename: 'ActivationProgressResponse';
    id: string;
    email: Maybe<string>;
    cardBrand: Maybe<string>;
    cardLast4: Maybe<string>;
    distributorName: Maybe<string>;
    distributorEmail: Maybe<string>;
    repName: Maybe<string>;
    repEmail: Maybe<string>;
    type: Maybe<string>;
    name: Maybe<{
      __typename: 'Name';
      first: Maybe<string>;
      last: Maybe<string>;
    }>;
    dataAccess: Maybe<
      Array<{
        __typename: 'ActivationProgressResponseDataAccess';
        id: string;
        plan: Maybe<SensorDataPlan>;
      }>
    >;
    progress: {
      __typename: 'ActivationProgress';
      billing: boolean;
      name: boolean;
      password: boolean;
      dataAccess: boolean;
    };
    sensors: Array<{
      __typename: 'Sensor';
      id: string;
      name: string;
      model: Maybe<{
        __typename: 'Model';
        antennaLength: Maybe<number>;
        moistureDepths: Maybe<Array<number>>;
      }>;
    }>;
    gateways: Array<{
      __typename: 'Gateway';
      id: string;
      model: Maybe<{
        __typename: 'Model';
        antennaLength: Maybe<number>;
        hasRainGauge: boolean;
        hasWeatherStation: boolean;
      }>;
    }>;
  };
};

export type AddCardToUserMutationVariables = {
  input: ActivationAddCardToUserInput;
};

export type AddCardToUserMutation = {
  __typename: 'Mutation';
  activationAddCardToUser: Maybe<{
    __typename: 'ActivationChangeMutationResponse';
    userId: Maybe<string>;
  }>;
};

export type SaveDataAccessDataMutationVariables = {
  input: ActivationChangeDataAccessInput;
};

export type SaveDataAccessDataMutation = {
  __typename: 'Mutation';
  activationChangeDataAccess: Maybe<{
    __typename: 'ActivationChangeMutationResponse';
    userId: Maybe<string>;
  }>;
};

export type ChangePasswordMutationVariables = {
  input: ActivationChangePasswordInput;
};

export type ChangePasswordMutation = {
  __typename: 'Mutation';
  activationChangePassword: Maybe<{
    __typename: 'ActivationChangeMutationResponse';
    userId: Maybe<string>;
  }>;
};

export type ActivationFinishMutationVariables = {
  input: ActivationFinishInput;
};

export type ActivationFinishMutation = {
  __typename: 'Mutation';
  activationFinish: Maybe<{
    __typename: 'ActivationChangeMutationResponse';
    userId: Maybe<string>;
  }>;
};

export type ActivationSharedFinishMutationVariables = {
  input: ActivationSharedFinishInput;
};

export type ActivationSharedFinishMutation = {
  __typename: 'Mutation';
  activationSharedFinish: Maybe<{
    __typename: 'ActivationChangeMutationResponse';
    userId: Maybe<string>;
  }>;
};

export type ActivationPrepaidFinishMutationVariables = {
  input: ActivationPrepaidFinishInput;
};

export type ActivationPrepaidFinishMutation = {
  __typename: 'Mutation';
  activationPrepaidFinish: Maybe<{
    __typename: 'ActivationChangeMutationResponse';
    userId: Maybe<string>;
  }>;
};

export type ChangeNameMutationVariables = {
  input: ActivationChangeNameInput;
};

export type ChangeNameMutation = {
  __typename: 'Mutation';
  activationChangeName: Maybe<{
    __typename: 'ActivationChangeMutationResponse';
    userId: Maybe<string>;
  }>;
};

export type SendVerificationEmailMutationVariables = {
  input: SendVerificationEmailInput;
};

export type SendVerificationEmailMutation = {
  __typename: 'Mutation';
  sendVerificationEmail: Maybe<{
    __typename: 'RegistrationMutationResponse';
    success: boolean;
  }>;
};

export type RegisterUserMutationVariables = {
  input: RegisterUserInput;
};

export type RegisterUserMutation = {
  __typename: 'Mutation';
  registerUser: Maybe<{
    __typename: 'RegistrationMutationResponse';
    success: boolean;
  }>;
};

export type FieldQueryVariables = {
  fieldId: Scalars['String'];
  tzoffset?: Maybe<Scalars['Int']>;
};

export type FieldQuery = {
  __typename: 'Query';
  field: Maybe<{ __typename: 'Field'; id: string } & FieldViewFieldFragment>;
  me: {
    __typename: 'User';
    id: string;
    sensors: Array<{ __typename: 'Sensor'; id: string; name: string }>;
    gateways: Array<{ __typename: 'Gateway'; id: string }>;
  };
};

export type FieldViewFieldFragment = {
  __typename: 'Field';
  id: string;
  sensors: Array<
    { __typename: 'Sensor'; id: string } & DevicesTableSensorFragment &
      ForecastSensorFragment
  >;
  gateways: Array<
    { __typename: 'Gateway'; id: string } & DevicesTableGatewayFragment
  >;
} & FieldInfoFieldFragment &
  FieldChartFieldFragment &
  FieldChartMenuFieldFragment;

export type FieldForArchiveQueryVariables = {
  fieldId: Scalars['String'];
  tzoffset: Scalars['Int'];
};

export type FieldForArchiveQuery = {
  __typename: 'Query';
  field: Maybe<{
    __typename: 'Field';
    id: string;
    name: string;
    irrigationEvents: Array<{
      __typename: 'IrrigationEvent';
      id: string;
      amount: number;
      source: IrrigationEventSource;
      date: string;
      note: Maybe<string>;
    }>;
    irrigationMetadata: {
      __typename: 'IrrigationMetadata';
      irrigationType: Maybe<string>;
      waterSource: Maybe<string>;
      powerSource: Maybe<string>;
      vendor: Maybe<string>;
      acreage: Maybe<number>;
      dripStats: {
        __typename: 'DripStatsType';
        emitterFlow: Maybe<number>;
        emitterSpacing: Maybe<number>;
        dripDistance: Maybe<number>;
      };
    };
    yield: {
      __typename: 'YieldType';
      amount: Maybe<number>;
      units: Maybe<YieldUnits>;
    };
    sensors: Array<{
      __typename: 'Sensor';
      id: string;
      name: string;
      crop_variety: Maybe<string>;
      plant_date: Maybe<string>;
      harvest_date: Maybe<string>;
      crop_type: Maybe<{ __typename: 'Crop'; id: string; name: string }>;
      soil_type: Maybe<{ __typename: 'Soil'; id: string; name: string }>;
      weather: {
        __typename: 'Weather';
        historical: Array<{
          __typename: 'WeatherHistorical';
          precipAmount: number;
          time: number;
        }>;
      };
    }>;
    rainfallSources: Maybe<
      Array<
        Maybe<
          | {
              __typename: 'Gateway';
              id: string;
              name: string;
              hasRainGauge: boolean;
              dataView: Maybe<{
                __typename: 'SensorDataView';
                rainfall: Array<{
                  __typename: 'SensorRainfallData';
                  t: string;
                  a: number;
                }>;
              }>;
            }
          | {
              __typename: 'Sensor';
              id: string;
              name: string;
              hasRainGauge: boolean;
              dataView: Maybe<{
                __typename: 'SensorDataView';
                rainfall: Array<{
                  __typename: 'SensorRainfallData';
                  t: string;
                  a: number;
                }>;
              }>;
            }
        >
      >
    >;
  }>;
  crops: Array<{ __typename: 'Crop'; name: string; id: string }>;
  soils: Array<{ __typename: 'Soil'; name: string; id: string }>;
};

export type ArchiveFieldMutationVariables = {
  input: ArchiveFieldInput;
};

export type ArchiveFieldMutation = {
  __typename: 'Mutation';
  archiveField: Maybe<{ __typename: 'ArchiveFieldResponse'; success: boolean }>;
};

export type ArchiveFieldUpdateInfoMutationVariables = {
  id: Scalars['String'];
  input: UpdateFieldInput;
};

export type ArchiveFieldUpdateInfoMutation = {
  __typename: 'Mutation';
  updateField: Maybe<{
    __typename: 'UpdateFieldMutationResponse';
    field: Maybe<{
      __typename: 'Field';
      id: string;
      irrigationMetadata: {
        __typename: 'IrrigationMetadata';
        acreage: Maybe<number>;
        irrigationType: Maybe<string>;
        vendor: Maybe<string>;
        powerSource: Maybe<string>;
        waterSource: Maybe<string>;
        dripStats: {
          __typename: 'DripStatsType';
          emitterFlow: Maybe<number>;
          emitterSpacing: Maybe<number>;
          dripDistance: Maybe<number>;
        };
      };
    }>;
  }>;
};

export type FieldChartFieldFragment = {
  __typename: 'Field';
  id: string;
  weatherStation: Maybe<
    {
      __typename: 'TrellisWeatherSensor';
      id: string;
    } & WeatherStationChartFragment
  >;
} & HistoricalRainfallGraphFieldFragment;

export type CurrentPressureFieldFragment = {
  __typename: 'Field';
  id: string;
  gateways: Array<{
    __typename: 'Gateway';
    id: string;
    model: Maybe<{ __typename: 'Model'; hasPressureSensor: boolean }>;
    currentPressure: Maybe<{
      __typename: 'SensorPressureData';
      t: number;
      avgP: number;
    }>;
  }>;
};

export type FieldChartMenuFieldFragment = {
  __typename: 'Field';
  id: string;
  weatherStation: Maybe<
    {
      __typename: 'TrellisWeatherSensor';
      id: string;
    } & CurrentWeatherSensorFragment
  >;
} & FieldTotalsFieldFragment &
  CurrentPressureFieldFragment;

export type FieldTotalsFieldFragment = {
  __typename: 'Field';
  id: string;
  name: string;
  irrigationEvents: Array<{
    __typename: 'IrrigationEvent';
    id: string;
    amount: number;
    date: string;
    note: Maybe<string>;
    source: IrrigationEventSource;
  }>;
  sensors: Array<{
    __typename: 'Sensor';
    id: string;
    plant_date: Maybe<string>;
    hasDataAccess: boolean;
    weather: {
      __typename: 'Weather';
      historical: Array<{
        __typename: 'WeatherHistorical';
        precipAmount: number;
        time: number;
      }>;
    };
  }>;
  rainfallSources: Maybe<
    Array<
      Maybe<
        | {
            __typename: 'Gateway';
            id: string;
            name: string;
            hasRainGauge: boolean;
            dataView: Maybe<{
              __typename: 'SensorDataView';
              rainfall: Array<{
                __typename: 'SensorRainfallData';
                t: string;
                a: number;
              }>;
            }>;
          }
        | {
            __typename: 'Sensor';
            id: string;
            name: string;
            hasRainGauge: boolean;
            dataView: Maybe<{
              __typename: 'SensorDataView';
              rainfall: Array<{
                __typename: 'SensorRainfallData';
                t: string;
                a: number;
              }>;
            }>;
          }
      >
    >
  >;
};

export type DeleteFieldMutationVariables = {
  input: DeleteFieldInput;
};

export type DeleteFieldMutation = {
  __typename: 'Mutation';
  deleteField: Maybe<{ __typename: 'FieldMutationResponse'; success: boolean }>;
};

export type FieldInfoFieldFragment = {
  __typename: 'Field';
  id: string;
  name: string;
  active: Maybe<boolean>;
  permissionLevel: Maybe<PermissionLevel>;
  current_state: Maybe<number>;
  current_tension: Maybe<number>;
  crop_variety: Maybe<string>;
  hoursToDry: {
    __typename: 'HoursToDry';
    prediction: Maybe<number>;
    errors: Array<string>;
  };
  crop_type: Maybe<{ __typename: 'Crop'; id: string; name: string }>;
  yield: {
    __typename: 'YieldType';
    amount: Maybe<number>;
    units: Maybe<YieldUnits>;
  };
  sensors: Array<
    {
      __typename: 'Sensor';
      id: string;
      crop_variety: Maybe<string>;
      plant_date: Maybe<string>;
      harvest_date: Maybe<string>;
      crop_type: Maybe<{ __typename: 'Crop'; id: string; name: string }>;
      soil_type: Maybe<{ __typename: 'Soil'; id: string; name: string }>;
    } & DevicesMapSensorFragment
  >;
  gateways: Array<
    { __typename: 'Gateway'; id: string } & DevicesMapGatewayFragment
  >;
};

export type FieldInfoArchivedFieldFragment = {
  __typename: 'ArchivedField';
  id: string;
  name: string;
  permissionLevel: Maybe<PermissionLevel>;
  yield: {
    __typename: 'YieldType';
    amount: Maybe<number>;
    units: Maybe<YieldUnits>;
  };
  cropType: Maybe<{ __typename: 'Crop'; id: string; name: string }>;
  sensors: Array<
    {
      __typename: 'ArchivedSensor';
      id: string;
      crop_variety: Maybe<string>;
      plant_date: Maybe<string>;
      harvest_date: Maybe<string>;
      crop_type: Maybe<{ __typename: 'Crop'; id: string; name: string }>;
      soil_type: Maybe<{ __typename: 'Soil'; id: string; name: string }>;
    } & DevicesMapArchivedSensorFragment
  >;
  gateways: Array<
    {
      __typename: 'ArchivedGateway';
      id: string;
    } & DevicesMapArchivedGatewayFragment
  >;
};

export type AddIrrigationMutationVariables = {
  input: IrrigationEventAddInput;
};

export type AddIrrigationMutation = {
  __typename: 'Mutation';
  irrigationEventAdd: Maybe<{
    __typename: 'IrrigationEventAddResponse';
    irrigationEvent: {
      __typename: 'IrrigationEvent';
      id: string;
      amount: number;
      date: string;
      note: Maybe<string>;
      source: IrrigationEventSource;
    };
  }>;
};

export type IrrigationQueryVariables = {
  fieldId: Scalars['String'];
  tzoffset?: Maybe<Scalars['Int']>;
};

export type IrrigationQuery = {
  __typename: 'Query';
  field: Maybe<
    {
      __typename: 'Field';
      id: string;
      irrigationMetadata: {
        __typename: 'IrrigationMetadata';
        irrigationType: Maybe<string>;
        waterSource: Maybe<string>;
        powerSource: Maybe<string>;
        acreage: Maybe<number>;
        vendor: Maybe<string>;
        dripStats: {
          __typename: 'DripStatsType';
          emitterFlow: Maybe<number>;
          emitterSpacing: Maybe<number>;
          dripDistance: Maybe<number>;
        };
      };
    } & HistoricalRainfallGraphFieldFragment
  >;
};

export type UpdateIrrigationMetadataMutationVariables = {
  fieldId: Scalars['String'];
  input: UpdateFieldInput;
};

export type UpdateIrrigationMetadataMutation = {
  __typename: 'Mutation';
  updateField: Maybe<{
    __typename: 'UpdateFieldMutationResponse';
    success: boolean;
    field: Maybe<{
      __typename: 'Field';
      id: string;
      irrigationMetadata: {
        __typename: 'IrrigationMetadata';
        vendor: Maybe<string>;
        irrigationType: Maybe<string>;
        waterSource: Maybe<string>;
        powerSource: Maybe<string>;
        acreage: Maybe<number>;
        dripStats: {
          __typename: 'DripStatsType';
          emitterFlow: Maybe<number>;
          emitterSpacing: Maybe<number>;
          dripDistance: Maybe<number>;
        };
      };
    }>;
  }>;
};

export type DeleteIrrigationMutationVariables = {
  input: IrrigationEventDeleteInput;
};

export type DeleteIrrigationMutation = {
  __typename: 'Mutation';
  irrigationEventDelete: Maybe<{
    __typename: 'IrrigationEventDeleteResponse';
    success: boolean;
  }>;
};

export type UpdateIrrigationMutationVariables = {
  input: IrrigationEventUpdateInput;
};

export type UpdateIrrigationMutation = {
  __typename: 'Mutation';
  irrigationEventUpdate: Maybe<{
    __typename: 'IrrigationEventUpdateResponse';
    irrigationEvent: {
      __typename: 'IrrigationEvent';
      id: string;
      amount: number;
      date: string;
      note: Maybe<string>;
      source: IrrigationEventSource;
    };
  }>;
};

export type FieldsQueryVariables = {};

export type FieldsQuery = {
  __typename: 'Query';
  me: {
    __typename: 'User';
    id: string;
    fields: Array<{ __typename: 'Field'; id: string } & FieldCardFieldFragment>;
  };
};

export type FieldCardFieldFragment = {
  __typename: 'Field';
  id: string;
  name: string;
  current_state: Maybe<number>;
  current_tension: Maybe<number>;
  hoursToDry: {
    __typename: 'HoursToDry';
    prediction: Maybe<number>;
    errors: Array<string>;
  };
  sensors: Array<
    {
      __typename: 'Sensor';
      id: string;
      hasDataAccess: boolean;
      userHasSensorAccess: boolean;
      moistureMethod: Maybe<MoistureMethod>;
      crop_type: Maybe<{ __typename: 'Crop'; id: string; name: string }>;
    } & DevicesMapSensorFragment
  >;
  gateways: Array<
    {
      __typename: 'Gateway';
      id: string;
      userHasSensorAccess: boolean;
      hasDataAccess: boolean;
    } & DevicesMapGatewayFragment
  >;
};

export type GatewayQueryVariables = {
  id: Scalars['String'];
};

export type GatewayQuery = {
  __typename: 'Query';
  gateway: Maybe<{
    __typename: 'Gateway';
    id: string;
    name: string;
    active: boolean;
  }>;
};

export type ActivateGatewayMutationVariables = {
  id: Scalars['String'];
};

export type ActivateGatewayMutation = {
  __typename: 'Mutation';
  updateGateway: Maybe<{
    __typename: 'UpdateGatewayMutationResponse';
    gateway: Maybe<{ __typename: 'Gateway'; id: string; active: boolean }>;
  }>;
};

export type DevicesQueryVariables = {};

export type DevicesQuery = {
  __typename: 'Query';
  me: {
    __typename: 'User';
    id: string;
    sensors: Array<
      {
        __typename: 'Sensor';
        id: string;
        active: boolean;
      } & DevicesTableSensorFragment &
        DevicesMapSensorFragment
    >;
    gateways: Array<
      {
        __typename: 'Gateway';
        id: string;
        active: boolean;
      } & DevicesTableGatewayFragment &
        DevicesMapGatewayFragment
    >;
  };
};

export type IrrigationSystemQueryVariables = {
  id: Scalars['String'];
};

export type IrrigationSystemQuery = {
  __typename: 'Query';
  irrigationSystem: {
    __typename: 'IrrigationSystem';
    id: string;
    name: string;
    sensors: Array<
      {
        __typename: 'TrellisPressureSensor';
        id: string;
        data:
          | {
              __typename: 'PressureSensorData';
              currentPressure:
                | {
                    __typename: 'CurrentPressure';
                    scheduleResult:
                      | { __typename: 'PressureOnSchedule' }
                      | {
                          __typename: 'PressureOffSchedule';
                          offScheduleTimes: Array<{
                            __typename: 'PressureOffScheduleTime';
                            time: string;
                            shouldBeOn: boolean;
                            type: PressureOffScheduleType;
                          }>;
                        }
                      | { __typename: 'PressureNoSchedule' };
                  }
                | { __typename: 'SensorOffline'; message: string };
            }
          | { __typename: 'SensorNoPlan' };
      } & DevicesMapPressureSensorFragment &
        IrrigationSystemRowFragment
    >;
  } & IrrigationSystemChartIrrigationSystemFragment;
};

export type DeleteIrrigationSystemMutationVariables = {
  id: Scalars['String'];
};

export type DeleteIrrigationSystemMutation = {
  __typename: 'Mutation';
  irrigationSystemDelete: {
    __typename: 'IrrigationSystemDeleteResponse';
    success: boolean;
  };
};

export type IrrigationSystemRowFragment = {
  __typename: 'TrellisPressureSensor';
  id: string;
  name: string;
  data:
    | {
        __typename: 'PressureSensorData';
        currentPressure:
          | {
              __typename: 'CurrentPressure';
              isOn: boolean;
              scheduleResult:
                | { __typename: 'PressureOnSchedule' }
                | {
                    __typename: 'PressureOffSchedule';
                    currentlyOnSchedule: boolean;
                  }
                | { __typename: 'PressureNoSchedule' };
            }
          | { __typename: 'SensorOffline'; message: string };
      }
    | { __typename: 'SensorNoPlan' };
};

export type ArchiveIrrigationSystemMutationVariables = {
  input: ArchiveIrrigationSystemInput;
};

export type ArchiveIrrigationSystemMutation = {
  __typename: 'Mutation';
  archiveIrrigationSystem: {
    __typename: 'ArchiveIrrigationSystemResponse';
    success: boolean;
  };
};

export type IrrigationSystemFormAddQueryVariables = {};

export type IrrigationSystemFormAddQuery = {
  __typename: 'Query';
  me: { __typename: 'User'; id: string } & AvailableSensorsFragment;
};

export type IrrigationSystemFormUpdateQueryVariables = {
  id: Scalars['String'];
};

export type IrrigationSystemFormUpdateQuery = {
  __typename: 'Query';
  irrigationSystem: {
    __typename: 'IrrigationSystem';
    id: string;
    name: string;
    sensors: Array<
      {
        __typename: 'TrellisPressureSensor';
        id: string;
      } & IrrigationSystemEditPressureSensorFragment
    >;
  };
  me: { __typename: 'User'; id: string } & AvailableSensorsFragment;
};

export type AvailableSensorsFragment = {
  __typename: 'User';
  id: string;
  pressureSensors: Array<
    {
      __typename: 'TrellisPressureSensor';
      id: string;
    } & IrrigationSystemEditPressureSensorFragment
  >;
};

export type IrrigationSystemEditPressureSensorFragment = {
  __typename: 'TrellisPressureSensor';
  id: string;
  name: string;
  irrigationSystem: Maybe<{ __typename: 'IrrigationSystem'; id: string }>;
  plan: Maybe<{
    __typename: 'PressureSensorPlan';
    timeZone: string;
    expectedPressure: Maybe<number>;
    stages: Array<{
      __typename: 'PressureSensorPlanStage';
      startDate: string;
      onTimes: Array<{
        __typename: 'PressureSensorPlanStageTime';
        start: number;
        end: number;
      }>;
    }>;
  }>;
} & DevicesMapPressureSensorFragment;

export type SystemBoundaryInfoQueryVariables = {
  input: FieldBoundaryInfoInput;
};

export type SystemBoundaryInfoQuery = {
  __typename: 'Query';
  fieldBoundaryInfo: Maybe<{
    __typename: 'FieldBoundaryInfoResult';
    sensorsInBounds: Array<{
      __typename: 'FieldBoundaryInfoResultSensor';
      id: string;
    }>;
  }>;
};

export type CreateIrrigationSystemMutationVariables = {
  input: IrrigationSystemAddInput;
};

export type CreateIrrigationSystemMutation = {
  __typename: 'Mutation';
  irrigationSystemAdd: { __typename: 'IrrigationSystem'; id: string };
};

export type UpdateIrrigationSystemMutationVariables = {
  id: Scalars['String'];
  input: IrrigationSystemUpdateInput;
};

export type UpdateIrrigationSystemMutation = {
  __typename: 'Mutation';
  irrigationSystemUpdate: {
    __typename: 'IrrigationSystem';
    id: string;
    name: string;
    sensors: Array<{
      __typename: 'TrellisPressureSensor';
      id: string;
      name: string;
      plan: Maybe<{
        __typename: 'PressureSensorPlan';
        expectedPressure: Maybe<number>;
        stages: Array<{
          __typename: 'PressureSensorPlanStage';
          startDate: string;
          onTimes: Array<{
            __typename: 'PressureSensorPlanStageTime';
            start: number;
            end: number;
          }>;
        }>;
      }>;
    }>;
  };
};

export type UpdatePressureSensorNameMutationVariables = {
  id: Scalars['String'];
  name: Scalars['String'];
};

export type UpdatePressureSensorNameMutation = {
  __typename: 'Mutation';
  pressureSensorUpdate: {
    __typename: 'TrellisPressureSensor';
    id: string;
    name: string;
  };
};

export type IrrigationSystemCardFragment = {
  __typename: 'IrrigationSystem';
  id: string;
  name: string;
  sensors: Array<
    {
      __typename: 'TrellisPressureSensor';
      id: string;
      plan: Maybe<{ __typename: 'PressureSensorPlan' }>;
      data:
        | {
            __typename: 'PressureSensorData';
            currentPressure:
              | {
                  __typename: 'CurrentPressure';
                  scheduleResult:
                    | { __typename: 'PressureOnSchedule' }
                    | { __typename: 'PressureOffSchedule' }
                    | { __typename: 'PressureNoSchedule' };
                }
              | { __typename: 'SensorOffline'; message: string };
          }
        | { __typename: 'SensorNoPlan'; message: string };
    } & DevicesMapPressureSensorFragment
  >;
};

export type IrrigationSystemsQueryVariables = {};

export type IrrigationSystemsQuery = {
  __typename: 'Query';
  me: {
    __typename: 'User';
    id: string;
    irrigationSystems: Array<
      {
        __typename: 'IrrigationSystem';
        id: string;
      } & IrrigationSystemCardFragment
    >;
  };
};

export type GetIssuesQueryVariables = {};

export type GetIssuesQuery = {
  __typename: 'Query';
  me: {
    __typename: 'User';
    id: string;
    sensors: Array<{
      __typename: 'Sensor';
      id: string;
      name: string;
      plant_date: Maybe<string>;
      soil_type: Maybe<{ __typename: 'Soil'; id: string }>;
      crop_type: Maybe<{ __typename: 'Crop'; id: string }>;
      field: Maybe<{ __typename: 'Field'; id: string }>;
    }>;
  };
};

export type NotificationsQueryVariables = {};

export type NotificationsQuery = {
  __typename: 'Query';
  me: {
    __typename: 'User';
    id: string;
    hasAccountIssues: Maybe<boolean>;
    notifications: Maybe<
      Array<
        | ({
            __typename: 'NotificationAnnouncement';
            id: string;
          } & NotificationListNotification_NotificationAnnouncement_Fragment)
        | ({
            __typename: 'NotificationDrySensor';
            id: string;
          } & NotificationListNotification_NotificationDrySensor_Fragment)
        | ({
            __typename: 'NotificationRelease';
            id: string;
          } & NotificationListNotification_NotificationRelease_Fragment)
        | ({
            __typename: 'NotificationSoilTypePopulate';
            id: string;
          } & NotificationListNotification_NotificationSoilTypePopulate_Fragment)
      >
    >;
  };
};

type NotificationListNotification_NotificationAnnouncement_Fragment = {
  __typename: 'NotificationAnnouncement';
  primary: string;
  secondary: string;
  href: Maybe<string>;
  popupToShow: Maybe<PopupId>;
  id: string;
  timestamp: Maybe<number>;
  read: boolean;
} & NotificationPopupNotification_NotificationAnnouncement_Fragment;

type NotificationListNotification_NotificationDrySensor_Fragment = {
  __typename: 'NotificationDrySensor';
  sensorId: string;
  id: string;
  timestamp: Maybe<number>;
  read: boolean;
} & NotificationPopupNotification_NotificationDrySensor_Fragment;

type NotificationListNotification_NotificationRelease_Fragment = {
  __typename: 'NotificationRelease';
  id: string;
  timestamp: Maybe<number>;
  read: boolean;
} & NotificationPopupNotification_NotificationRelease_Fragment;

type NotificationListNotification_NotificationSoilTypePopulate_Fragment = {
  __typename: 'NotificationSoilTypePopulate';
  sensorId: string;
  soilType: string;
  sensorName: string;
  id: string;
  timestamp: Maybe<number>;
  read: boolean;
} & NotificationPopupNotification_NotificationSoilTypePopulate_Fragment;

export type NotificationListNotificationFragment =
  | NotificationListNotification_NotificationAnnouncement_Fragment
  | NotificationListNotification_NotificationDrySensor_Fragment
  | NotificationListNotification_NotificationRelease_Fragment
  | NotificationListNotification_NotificationSoilTypePopulate_Fragment;

export type MarkAsReadMutationVariables = {
  input?: Maybe<NotificationMarkAsReadInput>;
};

export type MarkAsReadMutation = {
  __typename: 'Mutation';
  notificationMarkAsRead: Maybe<{
    __typename: 'NotificationMarkAsRead';
    success: boolean;
    notifications: Array<
      | { __typename: 'NotificationAnnouncement'; id: string; read: boolean }
      | { __typename: 'NotificationDrySensor'; id: string; read: boolean }
      | { __typename: 'NotificationRelease'; id: string; read: boolean }
      | {
          __typename: 'NotificationSoilTypePopulate';
          id: string;
          read: boolean;
        }
    >;
  }>;
};

export type OverlayQueryVariables = {
  sensorId: Scalars['String'];
  fieldId: Scalars['String'];
  archivedSensorId: Scalars['String'];
  fetchSensor: Scalars['Boolean'];
  fetchArchivedSensor: Scalars['Boolean'];
  fetchField: Scalars['Boolean'];
};

export type OverlayQuery = {
  __typename: 'Query';
  me: {
    __typename: 'User';
    id: string;
    hasAccountIssues: Maybe<boolean>;
    name: { __typename: 'Name'; first: Maybe<string>; last: Maybe<string> };
    notifications: Maybe<
      Array<
        | ({
            __typename: 'NotificationAnnouncement';
            id: string;
            read: boolean;
          } & NotificationPopupNotification_NotificationAnnouncement_Fragment)
        | ({
            __typename: 'NotificationDrySensor';
            id: string;
            read: boolean;
          } & NotificationPopupNotification_NotificationDrySensor_Fragment)
        | ({
            __typename: 'NotificationRelease';
            id: string;
            read: boolean;
          } & NotificationPopupNotification_NotificationRelease_Fragment)
        | ({
            __typename: 'NotificationSoilTypePopulate';
            id: string;
            read: boolean;
          } & NotificationPopupNotification_NotificationSoilTypePopulate_Fragment)
      >
    >;
  } & SidebarUserFragment;
  sensor: Maybe<{ __typename: 'Sensor'; id: string; name: string }>;
  archivedSensor: Maybe<{
    __typename: 'ArchivedSensor';
    id: string;
    name: string;
  }>;
  field: Maybe<{ __typename: 'Field'; id: string; name: string }>;
};

export type SidebarUserFragment = {
  __typename: 'User';
  id: string;
  weatherStations: Array<{ __typename: 'TrellisWeatherSensor'; id: string }>;
  pressureSensors: Array<{ __typename: 'TrellisPressureSensor'; id: string }>;
};

export type PressureSensorQueryVariables = {
  id: Scalars['String'];
};

export type PressureSensorQuery = {
  __typename: 'Query';
  pressureSensor: {
    __typename: 'TrellisPressureSensor';
    id: string;
    name: string;
    permissionLevel: Maybe<PermissionLevel>;
  } & DevicesMapPressureSensorFragment &
    PressureSensorChartFragment;
};

export type PressureSensorFormQueryVariables = {
  id: Scalars['String'];
};

export type PressureSensorFormQuery = {
  __typename: 'Query';
  pressureSensor: {
    __typename: 'TrellisPressureSensor';
    id: string;
  } & PressureSensorFormFieldsFragment;
};

export type UpdatePressureSensorMutationVariables = {
  id: Scalars['String'];
  input: PressureSensorUpdateInput;
};

export type UpdatePressureSensorMutation = {
  __typename: 'Mutation';
  pressureSensorUpdate: {
    __typename: 'TrellisPressureSensor';
    id: string;
  } & PressureSensorFormFieldsFragment;
};

export type PressureSensorFormFieldsFragment = {
  __typename: 'TrellisPressureSensor';
  id: string;
  name: string;
  location: Maybe<{ __typename: 'Location'; lat: number; lng: number }>;
};

export type SensorQueryVariables = {
  sensorId: Scalars['String'];
  tzoffset?: Maybe<Scalars['Int']>;
};

export type SensorQuery = {
  __typename: 'Query';
  sensor: Maybe<
    {
      __typename: 'Sensor';
      id: string;
      active: boolean;
      name: string;
      permissionLevel: Maybe<PermissionLevel>;
      crop_type: Maybe<{ __typename: 'Crop'; id: string; name: string }>;
    } & SensorGraphs_Sensor_Fragment &
      SensorHeaderData_Sensor_Fragment
  >;
};

export type ArchiveSensorMutationVariables = {
  input: ArchiveSensorInput;
};

export type ArchiveSensorMutation = {
  __typename: 'Mutation';
  archiveSensor: Maybe<{
    __typename: 'ArchiveSensorResponse';
    success: boolean;
  }>;
};

export type ArchiveSensorInfoQueryVariables = {
  id: Scalars['String'];
};

export type ArchiveSensorInfoQuery = {
  __typename: 'Query';
  sensor: Maybe<
    { __typename: 'Sensor'; id: string } & ArchiveSensorStepSensorFragment
  >;
  crops: Array<{ __typename: 'Crop'; id: string; name: string }>;
  soils: Array<{ __typename: 'Soil'; id: string; name: string }>;
};

export type ActivateSensorMutationVariables = {
  id: Scalars['String'];
};

export type ActivateSensorMutation = {
  __typename: 'Mutation';
  updateSensor: Maybe<{
    __typename: 'SensorMutationResponse';
    sensor: Maybe<{ __typename: 'Sensor'; id: string; active: boolean }>;
  }>;
};

export type SensorEditQueryVariables = {
  id: Scalars['String'];
};

export type SensorEditQuery = {
  __typename: 'Query';
  sensor: Maybe<
    { __typename: 'Sensor'; id: string } & SensorEditFormSensorFragment
  >;
  crops: Array<{ __typename: 'Crop'; id: string } & SensorEditFormCropFragment>;
  soils: Array<{ __typename: 'Soil'; id: string } & SensorEditFormSoilFragment>;
};

export type UpdateSensorMutationVariables = {
  sensorId: Scalars['String'];
  input: UpdateSensorInput;
};

export type UpdateSensorMutation = {
  __typename: 'Mutation';
  updateSensor: Maybe<{
    __typename: 'SensorMutationResponse';
    sensor: Maybe<
      { __typename: 'Sensor'; id: string } & SensorEditFormSensorFragment
    >;
  }>;
};

export type DeleteManagementPlanMutationVariables = {
  planId: Scalars['String'];
};

export type DeleteManagementPlanMutation = {
  __typename: 'Mutation';
  sensorManagementPlanDelete: {
    __typename: 'SensorManagementPlanResponse';
    success: boolean;
  };
};

export type SensorEditFormSensorFragment = {
  __typename: 'Sensor';
  id: string;
  name: string;
  crop_variety: Maybe<string>;
  plant_date: Maybe<string>;
  harvest_date: Maybe<string>;
  moistureMethod: Maybe<MoistureMethod>;
  location: Maybe<{ __typename: 'Location'; lat: number; lng: number }>;
  soil_type: Maybe<{
    __typename: 'Soil';
    id: string;
    name: string;
    t_dry: number;
    t_wet: number;
    c_dry: number;
    c_wet: number;
    custom: boolean;
    clay_percent: number;
  }>;
  crop_type: Maybe<{ __typename: 'Crop'; id: string; name: string }>;
  managementPlan: Maybe<
    { __typename: 'SensorManagementPlan'; id: string } & FullPlanFragment
  >;
  compatibleManagementPlans: Array<
    { __typename: 'SensorManagementPlan'; id: string } & FullPlanFragment
  >;
  model: Maybe<{ __typename: 'Model'; moistureDepths: Maybe<Array<number>> }>;
};

export type SensorEditFormCropFragment = {
  __typename: 'Crop';
  id: string;
  name: string;
  custom: boolean;
};

export type SensorEditFormSoilFragment = {
  __typename: 'Soil';
  id: string;
  name: string;
  t_dry: number;
  t_wet: number;
  c_dry: number;
  c_wet: number;
  custom: boolean;
  clay_percent: number;
};

export type CropDeleteMutationVariables = {
  input: CropDeleteInput;
};

export type CropDeleteMutation = {
  __typename: 'Mutation';
  cropDelete: { __typename: 'CropDeleteResponse'; success: boolean };
};

export type CropAddMutationVariables = {
  input: CropAddInput;
};

export type CropAddMutation = {
  __typename: 'Mutation';
  cropAdd: {
    __typename: 'CropAddResponse';
    crop: { __typename: 'Crop'; id: string; name: string; custom: boolean };
  };
};

export type FullPlanFragment = {
  __typename: 'SensorManagementPlan';
  id: string;
  name: string;
  model: Maybe<{ __typename: 'Model'; moistureDepths: Maybe<Array<number>> }>;
  stages: Array<{
    __typename: 'SensorManagementPlanStage';
    name: string;
    wetTH: number;
    dryTH: number;
    weights: Array<number>;
    DAP: number;
  }>;
};

export type UpdateManagementPlanMutationVariables = {
  planId: Scalars['String'];
  input: SensorManagementPlanInput;
};

export type UpdateManagementPlanMutation = {
  __typename: 'Mutation';
  sensorManagementPlanUpdate: {
    __typename: 'SensorManagementPlanResponse';
    sensorManagementPlan: Maybe<
      { __typename: 'SensorManagementPlan'; id: string } & FullPlanFragment
    >;
  };
};

export type AddManagementPlanMutationVariables = {
  sensorId: Scalars['String'];
  input: SensorManagementPlanInput;
};

export type AddManagementPlanMutation = {
  __typename: 'Mutation';
  sensorManagementPlanAdd: {
    __typename: 'SensorManagementPlanAddResponse';
    sensorManagementPlan: {
      __typename: 'SensorManagementPlan';
      id: string;
    } & FullPlanFragment;
    sensor: {
      __typename: 'Sensor';
      id: string;
      managementPlan: Maybe<{ __typename: 'SensorManagementPlan'; id: string }>;
      compatibleManagementPlans: Array<{
        __typename: 'SensorManagementPlan';
        id: string;
      }>;
    };
  };
};

export type AddSoilMutationVariables = {
  input: AddSoilInput;
};

export type AddSoilMutation = {
  __typename: 'Mutation';
  addSoil: {
    __typename: 'AddSoilResponse';
    soil: {
      __typename: 'Soil';
      id: string;
      name: string;
      t_dry: number;
      t_wet: number;
      c_dry: number;
      c_wet: number;
      custom: boolean;
      clay_percent: number;
    };
  };
};

export type SoilsForAddQueryVariables = {};

export type SoilsForAddQuery = {
  __typename: 'Query';
  soils: Array<{
    __typename: 'Soil';
    id: string;
    name: string;
    t_dry: number;
    t_wet: number;
    c_dry: number;
    c_wet: number;
    custom: boolean;
    clay_percent: number;
  }>;
};

export type DeleteSoilMutationVariables = {
  input: DeleteSoilInput;
};

export type DeleteSoilMutation = {
  __typename: 'Mutation';
  deleteSoil: { __typename: 'DeleteSoilResponse'; id: string };
};

export type UpdateSoilMutationVariables = {
  input: UpdateSoilInput;
};

export type UpdateSoilMutation = {
  __typename: 'Mutation';
  updateSoil: {
    __typename: 'UpdateSoilResponse';
    soil: {
      __typename: 'Soil';
      id: string;
      name: string;
      t_dry: number;
      t_wet: number;
      c_dry: number;
      c_wet: number;
      custom: boolean;
      clay_percent: number;
    };
  };
};

export type SoilsForDeleteQueryVariables = {};

export type SoilsForDeleteQuery = {
  __typename: 'Query';
  soils: Array<{ __typename: 'Soil'; id: string }>;
};

export type SoilsForUpdateQueryVariables = {};

export type SoilsForUpdateQuery = {
  __typename: 'Query';
  soils: Array<{
    __typename: 'Soil';
    id: string;
    name: string;
    t_dry: number;
    t_wet: number;
    c_dry: number;
    c_wet: number;
    custom: boolean;
    clay_percent: number;
  }>;
};

export type SensorInfoFragment = {
  __typename: 'Sensor';
  id: string;
  name: string;
  hasDataAccess: boolean;
  field: Maybe<{ __typename: 'Field'; id: string }>;
  model: Maybe<{
    __typename: 'Model';
    moistureDepths: Maybe<Array<number>>;
    temperatureDepths: Maybe<Array<number>>;
    ecDepths: Maybe<Array<number>>;
    hasRainGauge: boolean;
    hasWeatherStation: boolean;
  }>;
};

export type GatewayInfoFragment = {
  __typename: 'Gateway';
  id: string;
  name: string;
  hasDataAccess: boolean;
  fields: Array<Maybe<{ __typename: 'Field'; id: string }>>;
  model: Maybe<{
    __typename: 'Model';
    hasRainGauge: boolean;
    hasWeatherStation: boolean;
    hasPressureSensor: boolean;
  }>;
};

export type ArchivedSensorInfoFragment = {
  __typename: 'ArchivedSensor';
  id: string;
  name: string;
  hasDataAccess: boolean;
  field: Maybe<{ __typename: 'Field'; id: string }>;
  model: Maybe<{
    __typename: 'Model';
    moistureDepths: Maybe<Array<number>>;
    temperatureDepths: Maybe<Array<number>>;
    ecDepths: Maybe<Array<number>>;
    hasRainGauge: boolean;
    hasWeatherStation: boolean;
  }>;
};

export type ArchivedGatewayInfoFragment = {
  __typename: 'ArchivedGateway';
  id: string;
  name: string;
  hasDataAccess: boolean;
  fields: Array<Maybe<{ __typename: 'Field'; id: string }>>;
  model: Maybe<{
    __typename: 'Model';
    hasRainGauge: boolean;
    hasWeatherStation: boolean;
    hasPressureSensor: boolean;
  }>;
};

export type ExportInfoQueryVariables = {};

export type ExportInfoQuery = {
  __typename: 'Query';
  me: {
    __typename: 'User';
    id: string;
    fields: Array<{ __typename: 'Field'; id: string; name: string }>;
    sensors: Array<{ __typename: 'Sensor'; id: string } & SensorInfoFragment>;
    gateways: Array<
      { __typename: 'Gateway'; id: string } & GatewayInfoFragment
    >;
    archivedFields: Array<{
      __typename: 'ArchivedField';
      id: string;
      name: string;
      sensors: Array<
        {
          __typename: 'ArchivedSensor';
          id: string;
        } & ArchivedSensorInfoFragment
      >;
      gateways: Array<
        {
          __typename: 'ArchivedGateway';
          id: string;
        } & ArchivedGatewayInfoFragment
      >;
    }>;
    archivedSensors: Array<
      { __typename: 'ArchivedSensor'; id: string } & ArchivedSensorInfoFragment
    >;
    archivedGateways: Array<
      {
        __typename: 'ArchivedGateway';
        id: string;
      } & ArchivedGatewayInfoFragment
    >;
  };
};

export type ExportSensorSoilDataQueryVariables = {
  id: Scalars['String'];
};

export type ExportSensorSoilDataQuery = {
  __typename: 'Query';
  sensor: Maybe<{
    __typename: 'Sensor';
    id: string;
    model: Maybe<{
      __typename: 'Model';
      moistureMethod: Maybe<MoistureMethod>;
      moistureDepths: Maybe<Array<number>>;
      temperatureDepths: Maybe<Array<number>>;
      ecDepths: Maybe<Array<number>>;
    }>;
    dataView: Maybe<{
      __typename: 'SensorDataView';
      soil: Array<{
        __typename: 'SensorSoilData';
        t: number;
        sm: Array<Maybe<number>>;
        st: Array<Maybe<number>>;
        ec: Array<Maybe<number>>;
      }>;
    }>;
    soil_type: Maybe<{
      __typename: 'Soil';
      id: string;
      t_dry: number;
      t_wet: number;
      c_dry: number;
      c_wet: number;
    }>;
  }>;
};

export type ExportArchivedSensorSoilDataQueryVariables = {
  id: Scalars['String'];
};

export type ExportArchivedSensorSoilDataQuery = {
  __typename: 'Query';
  sensor: Maybe<{
    __typename: 'ArchivedSensor';
    id: string;
    model: Maybe<{
      __typename: 'Model';
      moistureMethod: Maybe<MoistureMethod>;
      moistureDepths: Maybe<Array<number>>;
      temperatureDepths: Maybe<Array<number>>;
      ecDepths: Maybe<Array<number>>;
    }>;
    dataView: Maybe<{
      __typename: 'SensorDataView';
      soil: Array<{
        __typename: 'SensorSoilData';
        t: number;
        sm: Array<Maybe<number>>;
        st: Array<Maybe<number>>;
        ec: Array<Maybe<number>>;
      }>;
    }>;
    soil_type: Maybe<{
      __typename: 'Soil';
      id: string;
      t_dry: number;
      t_wet: number;
      c_dry: number;
      c_wet: number;
    }>;
  }>;
};

export type ExportGatewayWeatherDataQueryVariables = {
  id: Scalars['String'];
};

export type ExportGatewayWeatherDataQuery = {
  __typename: 'Query';
  gateway: Maybe<{
    __typename: 'Gateway';
    id: string;
    dataView: Maybe<{
      __typename: 'SensorDataView';
      weather: Array<{
        __typename: 'WeatherStationData';
        time: number;
        hum: number;
        mWindDir: string;
        mWindSpd: number;
        rnCnt: number;
        rnRate: number;
        rnTotal: number;
        solarRad: number;
        temp: number;
        windDir: string;
        windSpd: number;
      }>;
    }>;
  }>;
};

export type ExportArchivedGatewayWeatherDataQueryVariables = {
  id: Scalars['String'];
};

export type ExportArchivedGatewayWeatherDataQuery = {
  __typename: 'Query';
  gateway: Maybe<{
    __typename: 'ArchivedGateway';
    id: string;
    dataView: Maybe<{
      __typename: 'SensorDataView';
      weather: Array<{
        __typename: 'WeatherStationData';
        time: number;
        hum: number;
        mWindDir: string;
        mWindSpd: number;
        rnCnt: number;
        rnRate: number;
        rnTotal: number;
        solarRad: number;
        temp: number;
        windDir: string;
        windSpd: number;
      }>;
    }>;
  }>;
};

export type ExportSensorRainfallDataQueryVariables = {
  id: Scalars['String'];
};

export type ExportSensorRainfallDataQuery = {
  __typename: 'Query';
  sensor: Maybe<{
    __typename: 'Sensor';
    id: string;
    dataView: Maybe<{
      __typename: 'SensorDataView';
      rainfall: Array<{
        __typename: 'SensorRainfallData';
        t: string;
        a: number;
      }>;
    }>;
  }>;
};

export type ExportArchivedSensorRainfallDataQueryVariables = {
  id: Scalars['String'];
};

export type ExportArchivedSensorRainfallDataQuery = {
  __typename: 'Query';
  sensor: Maybe<{
    __typename: 'ArchivedSensor';
    id: string;
    dataView: Maybe<{
      __typename: 'SensorDataView';
      rainfall: Array<{
        __typename: 'SensorRainfallData';
        t: string;
        a: number;
      }>;
    }>;
  }>;
};

export type ExportGatewayRainfallDataQueryVariables = {
  id: Scalars['String'];
};

export type ExportGatewayRainfallDataQuery = {
  __typename: 'Query';
  gateway: Maybe<{
    __typename: 'Gateway';
    id: string;
    dataView: Maybe<{
      __typename: 'SensorDataView';
      rainfall: Array<{
        __typename: 'SensorRainfallData';
        t: string;
        a: number;
      }>;
    }>;
  }>;
};

export type ExportArchivedGatewayRainfallDataQueryVariables = {
  id: Scalars['String'];
};

export type ExportArchivedGatewayRainfallDataQuery = {
  __typename: 'Query';
  gateway: Maybe<{
    __typename: 'ArchivedGateway';
    id: string;
    dataView: Maybe<{
      __typename: 'SensorDataView';
      rainfall: Array<{
        __typename: 'SensorRainfallData';
        t: string;
        a: number;
      }>;
    }>;
  }>;
};

export type ExportFieldIrrigationDataQueryVariables = {
  id: Scalars['String'];
};

export type ExportFieldIrrigationDataQuery = {
  __typename: 'Query';
  field: Maybe<{
    __typename: 'Field';
    id: string;
    irrigationEvents: Array<{
      __typename: 'IrrigationEvent';
      id: string;
      amount: number;
      date: string;
      note: Maybe<string>;
    }>;
  }>;
};

export type ExportArchivedFieldIrrigationDataQueryVariables = {
  id: Scalars['String'];
};

export type ExportArchivedFieldIrrigationDataQuery = {
  __typename: 'Query';
  field: Maybe<{
    __typename: 'ArchivedField';
    id: string;
    irrigationEvents: Array<{
      __typename: 'IrrigationEvent';
      id: string;
      amount: number;
      date: string;
      note: Maybe<string>;
    }>;
  }>;
};

export type ExportSensorNOAADataQueryVariables = {
  id: Scalars['String'];
};

export type ExportSensorNOAADataQuery = {
  __typename: 'Query';
  sensor: Maybe<{
    __typename: 'Sensor';
    id: string;
    weather: {
      __typename: 'Weather';
      historical: Array<{
        __typename: 'WeatherHistorical';
        time: number;
        precipAmount: number;
      }>;
    };
  }>;
};

export type ExportArchivedSensorNOAADataQueryVariables = {
  id: Scalars['String'];
};

export type ExportArchivedSensorNOAADataQuery = {
  __typename: 'Query';
  sensor: Maybe<{
    __typename: 'ArchivedSensor';
    id: string;
    weather: {
      __typename: 'Weather';
      historical: Array<{
        __typename: 'WeatherHistorical';
        time: number;
        precipAmount: number;
      }>;
    };
  }>;
};

export type ExportGatewayPressureDataQueryVariables = {
  id: Scalars['String'];
};

export type ExportGatewayPressureDataQuery = {
  __typename: 'Query';
  pressureSensor: {
    __typename: 'TrellisPressureSensor';
    id: string;
    data:
      | {
          __typename: 'PressureSensorData';
          pressure: Array<{
            __typename: 'SensorPressureData';
            t: number;
            avgP: number;
            rt: number;
          }>;
        }
      | { __typename: 'SensorNoPlan' };
  };
};

export type InvoicesQueryVariables = {};

export type InvoicesQuery = {
  __typename: 'Query';
  me: {
    __typename: 'User';
    id: string;
    invoices: Maybe<
      Array<{ __typename: 'Invoice'; date: number; amount: number }>
    >;
    upcomingInvoice: Maybe<{
      __typename: 'Invoice';
      date: number;
      amount: number;
    }>;
  };
};

export type ManageUsersUserFragment = {
  __typename: 'HardwareUser';
  id: string;
  accountEmail: Maybe<string>;
  permissionLevel: PermissionLevel;
  name: Maybe<{
    __typename: 'Name';
    first: Maybe<string>;
    last: Maybe<string>;
  }>;
};

type ManageUsersHardware_Gateway_Fragment = {
  __typename: 'Gateway';
  id: string;
  users: Array<
    { __typename: 'HardwareUser'; id: string } & ManageUsersUserFragment
  >;
};

type ManageUsersHardware_Sensor_Fragment = {
  __typename: 'Sensor';
  id: string;
  users: Array<
    { __typename: 'HardwareUser'; id: string } & ManageUsersUserFragment
  >;
};

export type ManageUsersHardwareFragment =
  | ManageUsersHardware_Gateway_Fragment
  | ManageUsersHardware_Sensor_Fragment;

export type ManageUsersFieldFragment = {
  __typename: 'Field';
  id: string;
  users: Array<
    { __typename: 'HardwareUser'; id: string } & ManageUsersUserFragment
  >;
};

export type ManageUsersQueryVariables = {};

export type ManageUsersQuery = {
  __typename: 'Query';
  me: {
    __typename: 'User';
    id: string;
    sensors: Array<
      { __typename: 'Sensor'; id: string } & ManageUsersHardware_Sensor_Fragment
    >;
    gateways: Array<
      {
        __typename: 'Gateway';
        id: string;
      } & ManageUsersHardware_Gateway_Fragment
    >;
    fields: Array<
      { __typename: 'Field'; id: string } & ManageUsersFieldFragment
    >;
  };
};

type ManageUserHardware_Gateway_Fragment = {
  __typename: 'Gateway';
  id: string;
  name: string;
  hasRainGauge: boolean;
  planName: Maybe<SensorDataPlan>;
  users: Array<{
    __typename: 'HardwareUser';
    id: string;
    accountEmail: Maybe<string>;
    permissionLevel: PermissionLevel;
  }>;
  owner: Maybe<{
    __typename: 'HardwareUser';
    id: string;
    accountEmail: Maybe<string>;
  }>;
};

type ManageUserHardware_Sensor_Fragment = {
  __typename: 'Sensor';
  id: string;
  name: string;
  hasRainGauge: boolean;
  planName: Maybe<SensorDataPlan>;
  users: Array<{
    __typename: 'HardwareUser';
    id: string;
    accountEmail: Maybe<string>;
    permissionLevel: PermissionLevel;
  }>;
  owner: Maybe<{
    __typename: 'HardwareUser';
    id: string;
    accountEmail: Maybe<string>;
  }>;
};

export type ManageUserHardwareFragment =
  | ManageUserHardware_Gateway_Fragment
  | ManageUserHardware_Sensor_Fragment;

export type ManageUserFieldFragment = {
  __typename: 'Field';
  id: string;
  name: string;
  users: Array<{
    __typename: 'HardwareUser';
    id: string;
    accountEmail: Maybe<string>;
    permissionLevel: PermissionLevel;
  }>;
  owner: Maybe<{
    __typename: 'HardwareUser';
    id: string;
    accountEmail: Maybe<string>;
  }>;
  sensors: Array<
    { __typename: 'Sensor'; id: string } & ManageUserHardware_Sensor_Fragment
  >;
  gateways: Array<
    { __typename: 'Gateway'; id: string } & ManageUserHardware_Gateway_Fragment
  >;
};

export type UpdateSensorSharingMutationVariables = {
  input: UpdateSensorSharingInput;
};

export type UpdateSensorSharingMutation = {
  __typename: 'Mutation';
  updateSensorSharing: Maybe<{
    __typename: 'UpdateSensorSharingResponse';
    sensor: {
      __typename: 'Sensor';
      id: string;
    } & ManageUserHardware_Sensor_Fragment &
      ManageUsersHardware_Sensor_Fragment;
  }>;
};

export type UpdateGatewaySharingMutationVariables = {
  input: UpdateGatewaySharingInput;
};

export type UpdateGatewaySharingMutation = {
  __typename: 'Mutation';
  updateGatewaySharing: Maybe<{
    __typename: 'UpdateGatewaySharingResponse';
    gateway: {
      __typename: 'Gateway';
      id: string;
    } & ManageUserHardware_Gateway_Fragment &
      ManageUsersHardware_Gateway_Fragment;
  }>;
};

export type FieldSharingUpdateMutationVariables = {
  input: FieldSharingUpdateInput;
};

export type FieldSharingUpdateMutation = {
  __typename: 'Mutation';
  fieldSharingUpdate: Maybe<{
    __typename: 'FieldSharingUpdateResponse';
    success: boolean;
    field: { __typename: 'Field'; id: string } & ManageUserFieldFragment &
      ManageUsersFieldFragment;
  }>;
};

export type HardwareSharingQueryVariables = {};

export type HardwareSharingQuery = {
  __typename: 'Query';
  me: {
    __typename: 'User';
    id: string;
    sensors: Array<
      { __typename: 'Sensor'; id: string } & ManageUserHardware_Sensor_Fragment
    >;
    gateways: Array<
      {
        __typename: 'Gateway';
        id: string;
      } & ManageUserHardware_Gateway_Fragment
    >;
    fields: Array<
      { __typename: 'Field'; id: string } & ManageUserFieldFragment
    >;
  };
};

export type NotificationsSettingsUserFragment = {
  __typename: 'User';
  id: string;
  email: Maybe<string>;
  phone: Maybe<string>;
  preferences: {
    __typename: 'UserPreferences';
    emailAlerts: Maybe<boolean>;
    textAlerts: Maybe<boolean>;
    triggerAlertThresholdHours: number;
  };
};

export type NotificationSettingsQueryVariables = {};

export type NotificationSettingsQuery = {
  __typename: 'Query';
  me: { __typename: 'User'; id: string } & NotificationsSettingsUserFragment;
};

export type UpdateUserPreferencesMutationVariables = {
  input: UpdateUserPreferencesInput;
};

export type UpdateUserPreferencesMutation = {
  __typename: 'Mutation';
  updateUserPreferences: Maybe<{
    __typename: 'UpdateUserPreferencesResponse';
    me: { __typename: 'User'; id: string } & NotificationsSettingsUserFragment;
  }>;
};

export type ProfileQueryVariables = {};

export type ProfileQuery = {
  __typename: 'Query';
  me: {
    __typename: 'User';
    id: string;
    accountEmail: Maybe<string>;
    prepaid: Maybe<boolean>;
    prepaidStart: Maybe<number>;
    prepaidUntil: Maybe<number>;
    name: { __typename: 'Name'; first: Maybe<string>; last: Maybe<string> };
    upcomingInvoice: Maybe<{
      __typename: 'Invoice';
      date: number;
      amount: number;
    }>;
    stripeSubscription: Maybe<{
      __typename: 'StripeSubscription';
      id: string;
      canceled: boolean;
      periodEnd: number;
    }>;
    cards: Maybe<
      Array<
        Maybe<{
          __typename: 'StripeCard';
          id: string;
          brand: Maybe<string>;
          last4: Maybe<string>;
        }>
      >
    >;
    managedServiceStatuses: Array<{
      __typename: 'ManagedServiceStatus';
      orderId: number;
      invoiceUrl: Maybe<string>;
      initialPaymentMade: boolean;
      subscriptionStarted: boolean;
      prepaid: boolean;
    }>;
    sensors: Array<{
      __typename: 'Sensor';
      id: string;
      activePlan: Maybe<SensorDataPlan>;
      permissionLevel: Maybe<PermissionLevel>;
    }>;
    gateways: Array<{
      __typename: 'Gateway';
      id: string;
      activePlan: Maybe<SensorDataPlan>;
      permissionLevel: Maybe<PermissionLevel>;
      model: Maybe<{
        __typename: 'Model';
        hasRainGauge: boolean;
        hasWeatherStation: boolean;
      }>;
    }>;
  };
};

export type UpdateProfileMutationVariables = {
  input: UpdateUserProfileInput;
};

export type UpdateProfileMutation = {
  __typename: 'Mutation';
  updateUserProfile: Maybe<{
    __typename: 'UpdateUserProfileResponse';
    id: Maybe<string>;
  }>;
};

export type SubscriptionsQueryVariables = {};

export type SubscriptionsQuery = {
  __typename: 'Query';
  me: {
    __typename: 'User';
    id: string;
    accountEmail: Maybe<string>;
    prepaid: Maybe<boolean>;
    prepaidStart: Maybe<number>;
    prepaidUntil: Maybe<number>;
    name: { __typename: 'Name'; first: Maybe<string>; last: Maybe<string> };
    upcomingInvoice: Maybe<{
      __typename: 'Invoice';
      date: number;
      amount: number;
    }>;
    stripeSubscription: Maybe<{
      __typename: 'StripeSubscription';
      id: string;
      canceled: boolean;
      periodEnd: number;
    }>;
    cards: Maybe<
      Array<
        Maybe<{
          __typename: 'StripeCard';
          id: string;
          brand: Maybe<string>;
          last4: Maybe<string>;
        }>
      >
    >;
    managedServiceStatuses: Array<{
      __typename: 'ManagedServiceStatus';
      orderId: number;
      invoiceUrl: Maybe<string>;
      initialPaymentMade: boolean;
      subscriptionStarted: boolean;
      prepaid: boolean;
    }>;
    sensors: Array<{
      __typename: 'Sensor';
      id: string;
      activePlan: Maybe<SensorDataPlan>;
      permissionLevel: Maybe<PermissionLevel>;
    }>;
    gateways: Array<{
      __typename: 'Gateway';
      id: string;
      activePlan: Maybe<SensorDataPlan>;
      permissionLevel: Maybe<PermissionLevel>;
      model: Maybe<{
        __typename: 'Model';
        hasRainGauge: boolean;
        hasWeatherStation: boolean;
      }>;
    }>;
  };
};

export type StartManagedServiceSubscriptionMutationVariables = {
  input: StartManagedServiceInput;
};

export type StartManagedServiceSubscriptionMutation = {
  __typename: 'Mutation';
  startManagedService: {
    __typename: 'StartManagedServiceResponse';
    success: boolean;
  };
};

export type StripeBillingPortalMutationVariables = {
  input: Scalars['String'];
};

export type StripeBillingPortalMutation = {
  __typename: 'Mutation';
  stripeBillingPortal: {
    __typename: 'StripeBillingPortalResponse';
    sessionUrl: string;
  };
};

export type ChangeCardMutationVariables = {
  input: ChangeCardInput;
};

export type ChangeCardMutation = {
  __typename: 'Mutation';
  changeCard: Maybe<{ __typename: 'ChangeCardResponse'; id: Maybe<string> }>;
};

export type DataPlanSensorsQueryVariables = {};

export type DataPlanSensorsQuery = {
  __typename: 'Query';
  me: {
    __typename: 'User';
    id: string;
    sensors: Array<{
      __typename: 'Sensor';
      id: string;
      name: string;
      planActiveUntil: Maybe<number>;
      planCancelDate: Maybe<number>;
      activePlan: Maybe<SensorDataPlan>;
    }>;
    gateways: Array<{
      __typename: 'Gateway';
      id: string;
      name: string;
      planActiveUntil: Maybe<number>;
      planCancelDate: Maybe<number>;
      activePlan: Maybe<SensorDataPlan>;
      planRequired: boolean;
    }>;
  };
};

export type UpdateDataPlanMutationVariables = {
  input: UpdateDataPlanInput;
};

export type UpdateDataPlanMutation = {
  __typename: 'Mutation';
  updateDataPlan: Maybe<{
    __typename: 'UpdateDataPlanResponse';
    id: Maybe<string>;
  }>;
};

export type WeatherStationQueryVariables = {
  id: Scalars['String'];
  tzoffset: Scalars['Int'];
};

export type WeatherStationQuery = {
  __typename: 'Query';
  weatherStation: {
    __typename: 'TrellisWeatherSensor';
    id: string;
    name: string;
    permissionLevel: Maybe<PermissionLevel>;
    location: Maybe<{ __typename: 'Location'; lat: number; lng: number }>;
    fields: Array<{ __typename: 'Field'; id: string; name: string }>;
  } & CurrentWeatherSensorFragment &
    WeatherStationChartFragment;
};

export type WeatherStationDataFieldsFragment = {
  __typename: 'WeatherStationData';
  time: number;
  hum: number;
  rnRate: number;
  solarRad: number;
  temp: number;
  wbTemp: Maybe<number>;
  windDir: string;
  windSpd: number;
  windErr: Maybe<boolean>;
  rnRateErr: Maybe<boolean>;
  solarRadErr: Maybe<boolean>;
  tempErr: Maybe<boolean>;
  mWindErr: Maybe<boolean>;
  humErr: Maybe<boolean>;
  rnCntErr: Maybe<boolean>;
};

export type AddWeatherStationManagementPlanMutationVariables = {
  input: WeatherStationManagementPlanInput;
};

export type AddWeatherStationManagementPlanMutation = {
  __typename: 'Mutation';
  weatherStationManagementPlanAdd: {
    __typename: 'WeatherStationManagementPlanResponse';
    weatherStationManagementPlan: Maybe<{
      __typename: 'WeatherStationManagementPlan';
      id: string;
    }>;
  };
};

export type UpdateWeatherStationManagementPlanMutationVariables = {
  input: WeatherStationManagementPlanInput;
  planId: Scalars['String'];
};

export type UpdateWeatherStationManagementPlanMutation = {
  __typename: 'Mutation';
  weatherStationManagementPlanUpdate: {
    __typename: 'WeatherStationManagementPlanResponse';
    success: boolean;
  };
};

export type WeatherStationEditQueryVariables = {
  id: Scalars['String'];
};

export type WeatherStationEditQuery = {
  __typename: 'Query';
  weatherStation: {
    __typename: 'TrellisWeatherSensor';
    id: string;
    name: string;
    location: Maybe<{ __typename: 'Location'; lat: number; lng: number }>;
    managementPlan: Maybe<
      {
        __typename: 'WeatherStationManagementPlan';
        id: string;
      } & WeatherManagementPlanFieldsFragment
    >;
  };
  me: {
    __typename: 'User';
    id: string;
    weatherManagementPlans: Array<
      {
        __typename: 'WeatherStationManagementPlan';
        id: string;
      } & WeatherManagementPlanFieldsFragment
    >;
  };
};

export type WeatherManagementPlanFieldsFragment = {
  __typename: 'WeatherStationManagementPlan';
  id: string;
  name: string;
  alerts: Array<{
    __typename: 'WeatherStationAlert';
    type: WeatherStationAlertType;
    relationToThreshold: AboveOrBelow;
    threshold: number;
  }>;
};

export type DeleteWeatherStationManagementPlanMutationVariables = {
  planId: Scalars['String'];
};

export type DeleteWeatherStationManagementPlanMutation = {
  __typename: 'Mutation';
  weatherStationManagementPlanDelete: {
    __typename: 'WeatherStationManagementPlanResponse';
    success: boolean;
  };
};

export type UpdateWeatherStationMutationVariables = {
  id: Scalars['String'];
  input: WeatherStationUpdateInput;
};

export type UpdateWeatherStationMutation = {
  __typename: 'Mutation';
  weatherStationUpdate: {
    __typename: 'WeatherStationUpdateResponse';
    weatherStation: {
      __typename: 'TrellisWeatherSensor';
      id: string;
      name: string;
      location: Maybe<{ __typename: 'Location'; lat: number; lng: number }>;
      managementPlan: Maybe<{
        __typename: 'WeatherStationManagementPlan';
        id: string;
      }>;
    };
  };
};

export type WeatherStationListQueryVariables = {};

export type WeatherStationListQuery = {
  __typename: 'Query';
  me: {
    __typename: 'User';
    id: string;
    weatherStations: Array<{
      __typename: 'TrellisWeatherSensor';
      id: string;
      name: string;
      location: Maybe<{ __typename: 'Location'; lat: number; lng: number }>;
      managementPlan: Maybe<{
        __typename: 'WeatherStationManagementPlan';
        id: string;
        alerts: Array<{
          __typename: 'WeatherStationAlert';
          type: WeatherStationAlertType;
          relationToThreshold: AboveOrBelow;
          threshold: number;
        }>;
      }>;
      data:
        | {
            __typename: 'WeatherSensorData';
            currentWeather:
              | {
                  __typename: 'WeatherStationData';
                  time: number;
                  hum: number;
                  rnRate: number;
                  solarRad: number;
                  temp: number;
                  wbTemp: Maybe<number>;
                  windDir: string;
                  windSpd: number;
                  windErr: Maybe<boolean>;
                  rnRateErr: Maybe<boolean>;
                  solarRadErr: Maybe<boolean>;
                  tempErr: Maybe<boolean>;
                  mWindErr: Maybe<boolean>;
                  humErr: Maybe<boolean>;
                  rnCntErr: Maybe<boolean>;
                }
              | { __typename: 'SensorOffline'; message: string };
          }
        | { __typename: 'SensorNoPlan'; message: string };
    }>;
  };
};

export type ForecastSensorFragment = {
  __typename: 'Sensor';
  id: string;
  weather: {
    __typename: 'Weather';
    weeklyForecast: Array<{
      __typename: 'WeatherWeeklyForecast';
      icon: Maybe<string>;
      tempHigh: number;
      tempLow: number;
      precipAmount: number;
      precipProbability: number;
      time: number;
    }>;
  };
};

export const ArchiveSensorStepSensorFragmentDoc = gql`
  fragment ArchiveSensorStepSensor on Sensor {
    id
    name
    crop_variety
    plant_date
    harvest_date
    crop_type {
      id
      name
    }
    soil_type {
      id
      name
    }
  }
`;
export const DevicesMapWeatherStationFragmentDoc = gql`
  fragment DevicesMapWeatherStation on TrellisWeatherSensor {
    id
    name
    location {
      lat
      lng
    }
  }
`;
export const DevicesMapArchivedPressureSensorFragmentDoc = gql`
  fragment DevicesMapArchivedPressureSensor on ArchivedTrellisPressureSensor {
    id
    name
    location {
      lat
      lng
    }
  }
`;
export const ArchiveChartFieldMoistureDataResultFragmentDoc = gql`
  fragment ArchiveChartFieldMoistureDataResult on FieldMoistureDataResult {
    __typename
    ... on FieldMoistureData {
      moistureMethod
      moistureAvg {
        t
        sm
      }
      thresholdsAvg {
        wet
        dry
        time
      }
    }
    ... on FieldMoistureDataError {
      messages
    }
  }
`;
export const ArchiveHistoricalRainfallGraphFieldFragmentDoc = gql`
  fragment ArchiveHistoricalRainfallGraphField on Field {
    id
    irrigationEvents {
      id
      amount
      date
      source
      note
    }
    sensors {
      id
      plant_date
      hasDataAccess
      weather {
        historical {
          precipAmount
          time
        }
      }
    }
    rainfallSources {
      id
      name
      hasDataAccess
      dataView {
        rainfall(tzoffset: $tzoffset) {
          t
          a
        }
      }
    }
    moistureData {
      ...ArchiveChartFieldMoistureDataResult
    }
    moistureProjection {
      data {
        t
        sm
      }
      errors
    }
  }
  ${ArchiveChartFieldMoistureDataResultFragmentDoc}
`;
export const ArchiveHistoricalRainfallGraphArchivedFieldFragmentDoc = gql`
  fragment ArchiveHistoricalRainfallGraphArchivedField on ArchivedField {
    id
    irrigationEvents {
      id
      amount
      date
      source
      note
    }
    sensors {
      id
      plant_date
      hasDataAccess
      weather {
        historical {
          precipAmount
          time
        }
      }
    }
    rainfallSources {
      id
      name
      hasDataAccess
      dataView {
        rainfall(tzoffset: $tzoffset) {
          t
          a
        }
      }
    }
    moistureData {
      ...ArchiveChartFieldMoistureDataResult
    }
  }
  ${ArchiveChartFieldMoistureDataResultFragmentDoc}
`;
export const ChartFieldMoistureDataResultFragmentDoc = gql`
  fragment ChartFieldMoistureDataResult on FieldMoistureDataResult {
    __typename
    ... on FieldMoistureData {
      moistureMethod
      moistureAvg {
        t
        sm
      }
      thresholdsAvg {
        wet
        dry
        time
      }
    }
    ... on FieldMoistureDataError {
      messages
    }
  }
`;
export const HistoricalRainfallGraphArchivedFieldFragmentDoc = gql`
  fragment HistoricalRainfallGraphArchivedField on ArchivedField {
    id
    irrigationEvents {
      id
      amount
      date
      source
      note
    }
    sensors {
      id
      plant_date
      hasDataAccess
      hasRainfallData
      hasRainGauge
      weather {
        historical {
          precipAmount
          time
        }
      }
    }
    rainfallSources {
      id
      name
      hasDataAccess
      hasRainGauge
      dataView {
        rainfall(tzoffset: $tzoffset) {
          t
          a
        }
      }
    }
    moistureData {
      ...ChartFieldMoistureDataResult
    }
  }
  ${ChartFieldMoistureDataResultFragmentDoc}
`;
export const IrrigationSystemChartIrrigationSystemFragmentDoc = gql`
  fragment IrrigationSystemChartIrrigationSystem on IrrigationSystem {
    id
    sensors {
      id
      name
      data {
        __typename
        ... on PressureSensorData {
          onOffTimes {
            t
            state
            avgP
          }
        }
      }
      plan {
        timeZone
        stages {
          startDate
          onTimes {
            start
            end
          }
        }
      }
    }
  }
`;
export const IrrigationSystemChartArchivedIrrigationSystemFragmentDoc = gql`
  fragment IrrigationSystemChartArchivedIrrigationSystem on ArchivedIrrigationSystem {
    id
    sensors {
      id
      name
      data {
        __typename
        ... on PressureSensorData {
          onOffTimes {
            t
            state
            avgP
          }
        }
      }
      plan {
        timeZone
        stages {
          startDate
          onTimes {
            start
            end
          }
        }
      }
    }
  }
`;
export const PressureSensorChartFragmentDoc = gql`
  fragment PressureSensorChart on TrellisPressureSensor {
    id
    name
    data {
      __typename
      ... on PressureSensorData {
        pressure {
          t
          avgP
        }
      }
      ... on SensorNoPlan {
        message
      }
    }
    plan {
      expectedPressure
      timeZone
      stages {
        startDate
        onTimes {
          start
          end
        }
      }
    }
  }
`;
export const ArchivePressureSensorChartFragmentDoc = gql`
  fragment ArchivePressureSensorChart on ArchivedTrellisPressureSensor {
    id
    name
    data {
      __typename
      ... on PressureSensorData {
        pressure {
          t
          avgP
        }
      }
    }
    plan {
      expectedPressure
      timeZone
      stages {
        startDate
        onTimes {
          start
          end
        }
      }
    }
  }
`;
export const SensorMoistureGraphFragmentDoc = gql`
  fragment SensorMoistureGraph on SensorBase {
    id
    name
    hasDataAccess
    hasRainfallData
    moistureMethod
    plant_date
    dataView {
      soil {
        t
        sm
        ec
      }
      rainfall(tzoffset: $tzoffset) {
        t
        a
      }
    }
    model {
      moistureDepths
    }
    soil_type {
      id
      t_dry
      t_wet
      c_dry
      c_wet
    }
    managementPlan {
      id
      stages {
        name
        wetTH
        dryTH
        weights
        DAP
      }
    }
    weather {
      historical {
        precipAmount
        time
      }
    }
    field {
      id
      name
      rainfallSources {
        id
        name
        hasRainGauge
        dataView {
          rainfall(tzoffset: $tzoffset) {
            t
            a
          }
        }
      }
      irrigationEvents {
        id
        amount
        date
        note
        source
      }
    }
  }
`;
export const SensorTemperatureGraphFragmentDoc = gql`
  fragment SensorTemperatureGraph on SensorBase {
    id
    name
    hasDataAccess
    dataView {
      soil {
        t
        st
      }
    }
    model {
      temperatureDepths
    }
  }
`;
export const SensorECGraphFragmentDoc = gql`
  fragment SensorECGraph on SensorBase {
    id
    name
    hasDataAccess
    dataView {
      soil {
        t
        ec
      }
    }
    model {
      ecDepths
    }
  }
`;
export const SensorGraphsFragmentDoc = gql`
  fragment SensorGraphs on SensorBase {
    id
    ...SensorMoistureGraph
    ...SensorTemperatureGraph
    ...SensorECGraph
  }
  ${SensorMoistureGraphFragmentDoc}
  ${SensorTemperatureGraphFragmentDoc}
  ${SensorECGraphFragmentDoc}
`;
export const SensorOnlineSensorFragmentDoc = gql`
  fragment SensorOnlineSensor on Sensor {
    id
    battery
    rssi
    connectionStatus
  }
`;
export const DevicesMapSensorFragmentDoc = gql`
  fragment DevicesMapSensor on Sensor {
    id
    name
    current_state
    hasRainGauge
    location {
      lat
      lng
    }
  }
`;
export const DevicesMapArchivedSensorFragmentDoc = gql`
  fragment DevicesMapArchivedSensor on ArchivedSensor {
    id
    name
    hasRainGauge
    location {
      lat
      lng
    }
  }
`;
export const SensorHeaderDataFragmentDoc = gql`
  fragment SensorHeaderData on SensorBase {
    id
    name
    hasRainGauge
    location {
      lat
      lng
    }
    soil_type {
      id
      name
    }
    managementPlan {
      id
      name
    }
    crop_type {
      id
      name
    }
    crop_variety
    plant_date
    harvest_date
    field {
      id
      name
      crop_type {
        id
        name
      }
      crop_variety
    }
    ... on Sensor {
      permissionLevel
      moistureMethod
      current_state
      current_tension
      ...SensorOnlineSensor
      ...DevicesMapSensor
    }
    ... on ArchivedSensor {
      originalId
      ...DevicesMapArchivedSensor
    }
  }
  ${SensorOnlineSensorFragmentDoc}
  ${DevicesMapSensorFragmentDoc}
  ${DevicesMapArchivedSensorFragmentDoc}
`;
export const FieldFormSensorFragmentDoc = gql`
  fragment FieldFormSensor on Sensor {
    id
    name
    location {
      lat
      lng
    }
    current_state
    model {
      moistureDepths
      antennaLength
    }
    hasRainfallData
  }
`;
export const FieldFormGatewayFragmentDoc = gql`
  fragment FieldFormGateway on Gateway {
    id
    hasRainGauge
    location {
      lat
      lng
    }
  }
`;
export const EditFieldFieldFragmentDoc = gql`
  fragment EditFieldField on Field {
    id
    name
    sensorWeights {
      id
      weight
    }
    irrigationMetadata {
      irrigationType
      waterSource
      powerSource
      vendor
      acreage
    }
    crop_type {
      id
      name
    }
    crop_variety
    sensors {
      id
      ...FieldFormSensor
    }
    gateways {
      id
      ...FieldFormGateway
    }
    boundary {
      lat
      lng
    }
    boundarySoilStats {
      soil {
        id
        name
      }
      soilPercent
    }
    boundarySensorInfo {
      id
      soil {
        id
        name
      }
      soilPercent
    }
    yield {
      amount
      units
    }
  }
  ${FieldFormSensorFragmentDoc}
  ${FieldFormGatewayFragmentDoc}
`;
export const DevicesTableSensorFragmentDoc = gql`
  fragment DevicesTableSensor on Sensor {
    id
    hasDataAccess
    hasRainGauge
    userHasSensorAccess
    name
    battery
    rssi
    connectionStatus
    current_state
    current_tension
    moistureMethod
    permissionLevel
    planRequired
    field {
      id
      name
    }
    owner {
      id
      accountEmail
      name {
        first
        last
      }
    }
    ...SensorOnlineSensor
  }
  ${SensorOnlineSensorFragmentDoc}
`;
export const ForecastSensorFragmentDoc = gql`
  fragment ForecastSensor on Sensor {
    id
    weather {
      weeklyForecast {
        icon
        tempHigh
        tempLow
        precipAmount
        precipProbability
        time
      }
    }
  }
`;
export const GatewayOnlineGatewayFragmentDoc = gql`
  fragment GatewayOnlineGateway on Gateway {
    id
    gateway_battery
    cell_signal
    connectionStatus
  }
`;
export const DevicesTableGatewayFragmentDoc = gql`
  fragment DevicesTableGateway on Gateway {
    id
    name
    hasDataAccess
    hasRainGauge
    userHasSensorAccess
    cell_signal
    gateway_battery
    connectionStatus
    permissionLevel
    planRequired
    model {
      hasWeatherStation
      hasPressureSensor
    }
    owner {
      id
      accountEmail
      name {
        first
        last
      }
    }
    ...GatewayOnlineGateway
  }
  ${GatewayOnlineGatewayFragmentDoc}
`;
export const DevicesMapGatewayFragmentDoc = gql`
  fragment DevicesMapGateway on Gateway {
    id
    name
    hasRainGauge
    location {
      lat
      lng
    }
  }
`;
export const FieldInfoFieldFragmentDoc = gql`
  fragment FieldInfoField on Field {
    id
    name
    active
    permissionLevel
    current_state
    current_tension
    hoursToDry {
      prediction
      errors
    }
    crop_type {
      id
      name
    }
    crop_variety
    yield {
      amount
      units
    }
    sensors {
      id
      crop_variety
      plant_date
      harvest_date
      crop_type {
        id
        name
      }
      soil_type {
        id
        name
      }
      ...DevicesMapSensor
    }
    gateways {
      id
      ...DevicesMapGateway
    }
  }
  ${DevicesMapSensorFragmentDoc}
  ${DevicesMapGatewayFragmentDoc}
`;
export const WeatherStationChartFragmentDoc = gql`
  fragment WeatherStationChart on TrellisWeatherSensor {
    id
    name
    managementPlan {
      id
      alerts {
        type
        relationToThreshold
        threshold
      }
    }
    data {
      __typename
      ... on WeatherSensorData {
        dailyRainfall(tzoffset: $tzoffset) {
          t
          a
        }
        weather {
          time
          hum
          rnRate
          solarRad
          temp
          windDir
          windSpd
          wbTemp
          windErr
          rnRateErr
          solarRadErr
          tempErr
          mWindErr
          humErr
          rnCntErr
        }
      }
      ... on SensorNoPlan {
        message
      }
    }
  }
`;
export const HistoricalRainfallGraphFieldFragmentDoc = gql`
  fragment HistoricalRainfallGraphField on Field {
    id
    irrigationEvents {
      id
      amount
      date
      source
      note
    }
    sensors {
      id
      plant_date
      hasDataAccess
      hasRainfallData
      weather {
        historical {
          precipAmount
          time
        }
      }
    }
    rainfallSources {
      id
      name
      hasDataAccess
      hasRainGauge
      dataView {
        rainfall(tzoffset: $tzoffset) {
          t
          a
        }
      }
    }
    moistureData {
      ...ChartFieldMoistureDataResult
    }
    moistureProjection {
      data {
        t
        sm
      }
      errors
    }
  }
  ${ChartFieldMoistureDataResultFragmentDoc}
`;
export const FieldChartFieldFragmentDoc = gql`
  fragment FieldChartField on Field {
    id
    weatherStation {
      id
      ...WeatherStationChart
    }
    ...HistoricalRainfallGraphField
  }
  ${WeatherStationChartFragmentDoc}
  ${HistoricalRainfallGraphFieldFragmentDoc}
`;
export const CurrentWeatherSensorFragmentDoc = gql`
  fragment CurrentWeatherSensor on TrellisWeatherSensor {
    id
    managementPlan {
      id
      alerts {
        type
        relationToThreshold
        threshold
      }
    }
    data {
      __typename
      ... on WeatherSensorData {
        currentWeather {
          __typename
          ... on WeatherStationData {
            time
            hum
            rnRate
            solarRad
            temp
            windDir
            windSpd
            wbTemp
            windErr
            rnRateErr
            solarRadErr
            tempErr
            mWindErr
            humErr
            rnCntErr
          }
          ... on SensorOffline {
            message
          }
        }
        dailyRainfall(tzoffset: $tzoffset) {
          t
          a
        }
      }
      ... on SensorNoPlan {
        message
      }
    }
  }
`;
export const FieldTotalsFieldFragmentDoc = gql`
  fragment FieldTotalsField on Field {
    id
    name
    irrigationEvents {
      id
      amount
      date
      note
      source
    }
    sensors {
      id
      plant_date
      hasDataAccess
      weather {
        historical {
          precipAmount
          time
        }
      }
    }
    rainfallSources {
      id
      name
      hasRainGauge
      dataView {
        rainfall(tzoffset: $tzoffset) {
          t
          a
        }
      }
    }
  }
`;
export const CurrentPressureFieldFragmentDoc = gql`
  fragment CurrentPressureField on Field {
    id
    gateways {
      id
      model {
        hasPressureSensor
      }
      currentPressure {
        t
        avgP
      }
    }
  }
`;
export const FieldChartMenuFieldFragmentDoc = gql`
  fragment FieldChartMenuField on Field {
    id
    weatherStation {
      id
      ...CurrentWeatherSensor
    }
    ...FieldTotalsField
    ...CurrentPressureField
  }
  ${CurrentWeatherSensorFragmentDoc}
  ${FieldTotalsFieldFragmentDoc}
  ${CurrentPressureFieldFragmentDoc}
`;
export const FieldViewFieldFragmentDoc = gql`
  fragment FieldViewField on Field {
    id
    sensors {
      id
      ...DevicesTableSensor
      ...ForecastSensor
    }
    gateways {
      id
      ...DevicesTableGateway
    }
    ...FieldInfoField
    ...FieldChartField
    ...FieldChartMenuField
  }
  ${DevicesTableSensorFragmentDoc}
  ${ForecastSensorFragmentDoc}
  ${DevicesTableGatewayFragmentDoc}
  ${FieldInfoFieldFragmentDoc}
  ${FieldChartFieldFragmentDoc}
  ${FieldChartMenuFieldFragmentDoc}
`;
export const DevicesMapArchivedGatewayFragmentDoc = gql`
  fragment DevicesMapArchivedGateway on ArchivedGateway {
    id
    name
    hasRainGauge
    location {
      lat
      lng
    }
  }
`;
export const FieldInfoArchivedFieldFragmentDoc = gql`
  fragment FieldInfoArchivedField on ArchivedField {
    id
    name
    permissionLevel
    yield {
      amount
      units
    }
    cropType {
      id
      name
    }
    sensors {
      id
      crop_variety
      plant_date
      harvest_date
      crop_type {
        id
        name
      }
      soil_type {
        id
        name
      }
      ...DevicesMapArchivedSensor
    }
    gateways {
      id
      ...DevicesMapArchivedGateway
    }
  }
  ${DevicesMapArchivedSensorFragmentDoc}
  ${DevicesMapArchivedGatewayFragmentDoc}
`;
export const FieldCardFieldFragmentDoc = gql`
  fragment FieldCardField on Field {
    id
    name
    current_state
    current_tension
    hoursToDry {
      prediction
      errors
    }
    sensors {
      id
      hasDataAccess
      userHasSensorAccess
      moistureMethod
      crop_type {
        id
        name
      }
      ...DevicesMapSensor
    }
    gateways {
      id
      userHasSensorAccess
      hasDataAccess
      ...DevicesMapGateway
    }
  }
  ${DevicesMapSensorFragmentDoc}
  ${DevicesMapGatewayFragmentDoc}
`;
export const IrrigationSystemRowFragmentDoc = gql`
  fragment IrrigationSystemRow on TrellisPressureSensor {
    id
    name
    data {
      __typename
      ... on PressureSensorData {
        currentPressure {
          __typename
          ... on CurrentPressure {
            isOn
            scheduleResult {
              __typename
              ... on PressureOffSchedule {
                currentlyOnSchedule
              }
            }
          }
          ... on SensorOffline {
            message
          }
        }
      }
    }
  }
`;
export const DevicesMapPressureSensorFragmentDoc = gql`
  fragment DevicesMapPressureSensor on TrellisPressureSensor {
    id
    name
    location {
      lat
      lng
    }
    data {
      __typename
      ... on PressureSensorData {
        currentPressure {
          __typename
          ... on CurrentPressure {
            isOn
          }
          ... on SensorOffline {
            message
          }
        }
      }
      ... on SensorNoPlan {
        message
      }
    }
  }
`;
export const IrrigationSystemEditPressureSensorFragmentDoc = gql`
  fragment IrrigationSystemEditPressureSensor on TrellisPressureSensor {
    id
    name
    irrigationSystem {
      id
    }
    plan {
      timeZone
      expectedPressure
      stages {
        startDate
        onTimes {
          start
          end
        }
      }
    }
    ...DevicesMapPressureSensor
  }
  ${DevicesMapPressureSensorFragmentDoc}
`;
export const AvailableSensorsFragmentDoc = gql`
  fragment AvailableSensors on User {
    id
    pressureSensors {
      id
      ...IrrigationSystemEditPressureSensor
    }
  }
  ${IrrigationSystemEditPressureSensorFragmentDoc}
`;
export const IrrigationSystemCardFragmentDoc = gql`
  fragment IrrigationSystemCard on IrrigationSystem {
    id
    name
    sensors {
      id
      plan {
        __typename
      }
      data {
        __typename
        ... on PressureSensorData {
          currentPressure {
            __typename
            ... on CurrentPressure {
              scheduleResult {
                __typename
              }
            }
            ... on SensorOffline {
              message
            }
          }
        }
        ... on SensorNoPlan {
          message
        }
      }
      ...DevicesMapPressureSensor
    }
  }
  ${DevicesMapPressureSensorFragmentDoc}
`;
export const NotificationPopupNotificationFragmentDoc = gql`
  fragment NotificationPopupNotification on Notification {
    __typename
    id
    read
    ... on NotificationAnnouncement {
      popupToShow
    }
    ... on NotificationRelease {
      primary
      secondary
      blogPostURL: href
      header
      body
      img
    }
  }
`;
export const NotificationListNotificationFragmentDoc = gql`
  fragment NotificationListNotification on Notification {
    id
    timestamp
    read
    ... on NotificationDrySensor {
      sensorId
    }
    ... on NotificationAnnouncement {
      primary
      secondary
      href
      popupToShow
    }
    ... on NotificationSoilTypePopulate {
      sensorId
      soilType
      sensorName
    }
    ...NotificationPopupNotification
  }
  ${NotificationPopupNotificationFragmentDoc}
`;
export const SidebarUserFragmentDoc = gql`
  fragment SidebarUser on User {
    id
    weatherStations {
      id
    }
    pressureSensors {
      id
    }
  }
`;
export const PressureSensorFormFieldsFragmentDoc = gql`
  fragment PressureSensorFormFields on TrellisPressureSensor {
    id
    name
    location {
      lat
      lng
    }
  }
`;
export const FullPlanFragmentDoc = gql`
  fragment FullPlan on SensorManagementPlan {
    id
    name
    model {
      moistureDepths
    }
    stages {
      name
      wetTH
      dryTH
      weights
      DAP
    }
  }
`;
export const SensorEditFormSensorFragmentDoc = gql`
  fragment SensorEditFormSensor on Sensor {
    id
    name
    crop_variety
    plant_date
    harvest_date
    moistureMethod
    location {
      lat
      lng
    }
    soil_type {
      id
      name
      t_dry
      t_wet
      c_dry
      c_wet
      custom
      clay_percent
    }
    crop_type {
      id
      name
    }
    managementPlan {
      id
      ...FullPlan
    }
    compatibleManagementPlans {
      id
      ...FullPlan
    }
    model {
      moistureDepths
    }
  }
  ${FullPlanFragmentDoc}
`;
export const SensorEditFormCropFragmentDoc = gql`
  fragment SensorEditFormCrop on Crop {
    id
    name
    custom
  }
`;
export const SensorEditFormSoilFragmentDoc = gql`
  fragment SensorEditFormSoil on Soil {
    id
    name
    t_dry
    t_wet
    c_dry
    c_wet
    custom
    clay_percent
  }
`;
export const SensorInfoFragmentDoc = gql`
  fragment SensorInfo on Sensor {
    id
    name
    hasDataAccess
    field {
      id
    }
    model {
      moistureDepths
      temperatureDepths
      ecDepths
      hasRainGauge
      hasWeatherStation
    }
  }
`;
export const GatewayInfoFragmentDoc = gql`
  fragment GatewayInfo on Gateway {
    id
    name
    hasDataAccess
    fields {
      id
    }
    model {
      hasRainGauge
      hasWeatherStation
      hasPressureSensor
    }
  }
`;
export const ArchivedSensorInfoFragmentDoc = gql`
  fragment ArchivedSensorInfo on ArchivedSensor {
    id
    name
    hasDataAccess
    field {
      id
    }
    model {
      moistureDepths
      temperatureDepths
      ecDepths
      hasRainGauge
      hasWeatherStation
    }
  }
`;
export const ArchivedGatewayInfoFragmentDoc = gql`
  fragment ArchivedGatewayInfo on ArchivedGateway {
    id
    name
    hasDataAccess
    fields {
      id
    }
    model {
      hasRainGauge
      hasWeatherStation
      hasPressureSensor
    }
  }
`;
export const ManageUsersUserFragmentDoc = gql`
  fragment ManageUsersUser on HardwareUser {
    id
    name {
      first
      last
    }
    accountEmail
    permissionLevel
  }
`;
export const ManageUsersHardwareFragmentDoc = gql`
  fragment ManageUsersHardware on Hardware {
    id
    users {
      id
      ...ManageUsersUser
    }
  }
  ${ManageUsersUserFragmentDoc}
`;
export const ManageUsersFieldFragmentDoc = gql`
  fragment ManageUsersField on Field {
    id
    users {
      id
      ...ManageUsersUser
    }
  }
  ${ManageUsersUserFragmentDoc}
`;
export const ManageUserHardwareFragmentDoc = gql`
  fragment ManageUserHardware on Hardware {
    id
    name
    planName: plan
    hasRainGauge
    users {
      id
      accountEmail
      permissionLevel
    }
    owner {
      id
      accountEmail
    }
  }
`;
export const ManageUserFieldFragmentDoc = gql`
  fragment ManageUserField on Field {
    id
    name
    users {
      id
      accountEmail
      permissionLevel
    }
    owner {
      id
      accountEmail
    }
    sensors {
      id
      ...ManageUserHardware
    }
    gateways {
      id
      ...ManageUserHardware
    }
  }
  ${ManageUserHardwareFragmentDoc}
`;
export const NotificationsSettingsUserFragmentDoc = gql`
  fragment NotificationsSettingsUser on User {
    id
    email
    phone
    preferences {
      emailAlerts
      textAlerts
      triggerAlertThresholdHours
    }
  }
`;
export const WeatherStationDataFieldsFragmentDoc = gql`
  fragment WeatherStationDataFields on WeatherStationData {
    time
    hum
    rnRate
    solarRad
    temp
    wbTemp
    windDir
    windSpd
    windErr
    rnRateErr
    solarRadErr
    tempErr
    mWindErr
    humErr
    rnCntErr
  }
`;
export const WeatherManagementPlanFieldsFragmentDoc = gql`
  fragment WeatherManagementPlanFields on WeatherStationManagementPlan {
    id
    name
    alerts {
      type
      relationToThreshold
      threshold
    }
  }
`;
export const AnalyticsInfoDocument = gql`
  query AnalyticsInfo {
    me {
      id
      accountEmail
      name {
        first
        last
      }
    }
  }
`;
export type AnalyticsInfoComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    AnalyticsInfoQuery,
    AnalyticsInfoQueryVariables
  >,
  'query'
>;

export const AnalyticsInfoComponent = (props: AnalyticsInfoComponentProps) => (
  <ApolloReactComponents.Query<AnalyticsInfoQuery, AnalyticsInfoQueryVariables>
    query={AnalyticsInfoDocument}
    {...props}
  />
);

/**
 * __useAnalyticsInfoQuery__
 *
 * To run a query within a React component, call `useAnalyticsInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyticsInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useAnalyticsInfoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AnalyticsInfoQuery,
    AnalyticsInfoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    AnalyticsInfoQuery,
    AnalyticsInfoQueryVariables
  >(AnalyticsInfoDocument, baseOptions);
}
export function useAnalyticsInfoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AnalyticsInfoQuery,
    AnalyticsInfoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    AnalyticsInfoQuery,
    AnalyticsInfoQueryVariables
  >(AnalyticsInfoDocument, baseOptions);
}
export type AnalyticsInfoQueryHookResult = ReturnType<
  typeof useAnalyticsInfoQuery
>;
export type AnalyticsInfoLazyQueryHookResult = ReturnType<
  typeof useAnalyticsInfoLazyQuery
>;
export type AnalyticsInfoQueryResult = ApolloReactCommon.QueryResult<
  AnalyticsInfoQuery,
  AnalyticsInfoQueryVariables
>;
export const ArchiveUpdateSensorDocument = gql`
  mutation ArchiveUpdateSensor($id: String!, $input: UpdateSensorInput!) {
    updateSensor(sensorId: $id, input: $input) {
      sensor {
        id
        ...ArchiveSensorStepSensor
      }
    }
  }
  ${ArchiveSensorStepSensorFragmentDoc}
`;
export type ArchiveUpdateSensorMutationFn = ApolloReactCommon.MutationFunction<
  ArchiveUpdateSensorMutation,
  ArchiveUpdateSensorMutationVariables
>;
export type ArchiveUpdateSensorComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ArchiveUpdateSensorMutation,
    ArchiveUpdateSensorMutationVariables
  >,
  'mutation'
>;

export const ArchiveUpdateSensorComponent = (
  props: ArchiveUpdateSensorComponentProps
) => (
  <ApolloReactComponents.Mutation<
    ArchiveUpdateSensorMutation,
    ArchiveUpdateSensorMutationVariables
  >
    mutation={ArchiveUpdateSensorDocument}
    {...props}
  />
);

/**
 * __useArchiveUpdateSensorMutation__
 *
 * To run a mutation, you first call `useArchiveUpdateSensorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveUpdateSensorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveUpdateSensorMutation, { data, loading, error }] = useArchiveUpdateSensorMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArchiveUpdateSensorMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ArchiveUpdateSensorMutation,
    ArchiveUpdateSensorMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ArchiveUpdateSensorMutation,
    ArchiveUpdateSensorMutationVariables
  >(ArchiveUpdateSensorDocument, baseOptions);
}
export type ArchiveUpdateSensorMutationHookResult = ReturnType<
  typeof useArchiveUpdateSensorMutation
>;
export type ArchiveUpdateSensorMutationResult = ApolloReactCommon.MutationResult<
  ArchiveUpdateSensorMutation
>;
export type ArchiveUpdateSensorMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ArchiveUpdateSensorMutation,
  ArchiveUpdateSensorMutationVariables
>;
export const cropsDocument = gql`
  query crops {
    crops {
      id
      name
    }
  }
`;
export type cropsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<cropsQuery, cropsQueryVariables>,
  'query'
>;

export const cropsComponent = (props: cropsComponentProps) => (
  <ApolloReactComponents.Query<cropsQuery, cropsQueryVariables>
    query={cropsDocument}
    {...props}
  />
);

/**
 * __usecropsQuery__
 *
 * To run a query within a React component, call `usecropsQuery` and pass it any options that fit your needs.
 * When your component renders, `usecropsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usecropsQuery({
 *   variables: {
 *   },
 * });
 */
export function usecropsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    cropsQuery,
    cropsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<cropsQuery, cropsQueryVariables>(
    cropsDocument,
    baseOptions
  );
}
export function usecropsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    cropsQuery,
    cropsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<cropsQuery, cropsQueryVariables>(
    cropsDocument,
    baseOptions
  );
}
export type cropsQueryHookResult = ReturnType<typeof usecropsQuery>;
export type cropsLazyQueryHookResult = ReturnType<typeof usecropsLazyQuery>;
export type cropsQueryResult = ApolloReactCommon.QueryResult<
  cropsQuery,
  cropsQueryVariables
>;
export const FieldBoundaryInfoDocument = gql`
  query FieldBoundaryInfo($input: FieldBoundaryInfoInput!) {
    fieldBoundaryInfo(input: $input) {
      sensorsInBounds {
        id
        soil {
          id
          name
        }
        soilPercent
      }
      soilStats {
        soil {
          id
          name
        }
        soilPercent
      }
      calculatedAcreage
    }
  }
`;
export type FieldBoundaryInfoComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    FieldBoundaryInfoQuery,
    FieldBoundaryInfoQueryVariables
  >,
  'query'
> &
  (
    | { variables: FieldBoundaryInfoQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const FieldBoundaryInfoComponent = (
  props: FieldBoundaryInfoComponentProps
) => (
  <ApolloReactComponents.Query<
    FieldBoundaryInfoQuery,
    FieldBoundaryInfoQueryVariables
  >
    query={FieldBoundaryInfoDocument}
    {...props}
  />
);

/**
 * __useFieldBoundaryInfoQuery__
 *
 * To run a query within a React component, call `useFieldBoundaryInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useFieldBoundaryInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFieldBoundaryInfoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFieldBoundaryInfoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FieldBoundaryInfoQuery,
    FieldBoundaryInfoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    FieldBoundaryInfoQuery,
    FieldBoundaryInfoQueryVariables
  >(FieldBoundaryInfoDocument, baseOptions);
}
export function useFieldBoundaryInfoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FieldBoundaryInfoQuery,
    FieldBoundaryInfoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    FieldBoundaryInfoQuery,
    FieldBoundaryInfoQueryVariables
  >(FieldBoundaryInfoDocument, baseOptions);
}
export type FieldBoundaryInfoQueryHookResult = ReturnType<
  typeof useFieldBoundaryInfoQuery
>;
export type FieldBoundaryInfoLazyQueryHookResult = ReturnType<
  typeof useFieldBoundaryInfoLazyQuery
>;
export type FieldBoundaryInfoQueryResult = ApolloReactCommon.QueryResult<
  FieldBoundaryInfoQuery,
  FieldBoundaryInfoQueryVariables
>;
export const SensorGatewayDocument = gql`
  query SensorGateway($id: String!) {
    sensor(id: $id) {
      id
      gatewayId
    }
  }
`;
export type SensorGatewayComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    SensorGatewayQuery,
    SensorGatewayQueryVariables
  >,
  'query'
> &
  (
    | { variables: SensorGatewayQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const SensorGatewayComponent = (props: SensorGatewayComponentProps) => (
  <ApolloReactComponents.Query<SensorGatewayQuery, SensorGatewayQueryVariables>
    query={SensorGatewayDocument}
    {...props}
  />
);

/**
 * __useSensorGatewayQuery__
 *
 * To run a query within a React component, call `useSensorGatewayQuery` and pass it any options that fit your needs.
 * When your component renders, `useSensorGatewayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSensorGatewayQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSensorGatewayQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    SensorGatewayQuery,
    SensorGatewayQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    SensorGatewayQuery,
    SensorGatewayQueryVariables
  >(SensorGatewayDocument, baseOptions);
}
export function useSensorGatewayLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SensorGatewayQuery,
    SensorGatewayQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    SensorGatewayQuery,
    SensorGatewayQueryVariables
  >(SensorGatewayDocument, baseOptions);
}
export type SensorGatewayQueryHookResult = ReturnType<
  typeof useSensorGatewayQuery
>;
export type SensorGatewayLazyQueryHookResult = ReturnType<
  typeof useSensorGatewayLazyQuery
>;
export type SensorGatewayQueryResult = ApolloReactCommon.QueryResult<
  SensorGatewayQuery,
  SensorGatewayQueryVariables
>;
export const AddCropDocument = gql`
  mutation AddCrop($input: CropAddInput!) {
    cropAdd(input: $input) {
      crop {
        id
        name
        custom
      }
    }
  }
`;
export type AddCropMutationFn = ApolloReactCommon.MutationFunction<
  AddCropMutation,
  AddCropMutationVariables
>;
export type AddCropComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    AddCropMutation,
    AddCropMutationVariables
  >,
  'mutation'
>;

export const AddCropComponent = (props: AddCropComponentProps) => (
  <ApolloReactComponents.Mutation<AddCropMutation, AddCropMutationVariables>
    mutation={AddCropDocument}
    {...props}
  />
);

/**
 * __useAddCropMutation__
 *
 * To run a mutation, you first call `useAddCropMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCropMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCropMutation, { data, loading, error }] = useAddCropMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCropMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddCropMutation,
    AddCropMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AddCropMutation,
    AddCropMutationVariables
  >(AddCropDocument, baseOptions);
}
export type AddCropMutationHookResult = ReturnType<typeof useAddCropMutation>;
export type AddCropMutationResult = ApolloReactCommon.MutationResult<
  AddCropMutation
>;
export type AddCropMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddCropMutation,
  AddCropMutationVariables
>;
export const MockOneDocument = gql`
  query MockOne {
    me {
      email
    }
  }
`;
export type MockOneComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    MockOneQuery,
    MockOneQueryVariables
  >,
  'query'
>;

export const MockOneComponent = (props: MockOneComponentProps) => (
  <ApolloReactComponents.Query<MockOneQuery, MockOneQueryVariables>
    query={MockOneDocument}
    {...props}
  />
);

/**
 * __useMockOneQuery__
 *
 * To run a query within a React component, call `useMockOneQuery` and pass it any options that fit your needs.
 * When your component renders, `useMockOneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMockOneQuery({
 *   variables: {
 *   },
 * });
 */
export function useMockOneQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    MockOneQuery,
    MockOneQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<MockOneQuery, MockOneQueryVariables>(
    MockOneDocument,
    baseOptions
  );
}
export function useMockOneLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    MockOneQuery,
    MockOneQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<MockOneQuery, MockOneQueryVariables>(
    MockOneDocument,
    baseOptions
  );
}
export type MockOneQueryHookResult = ReturnType<typeof useMockOneQuery>;
export type MockOneLazyQueryHookResult = ReturnType<typeof useMockOneLazyQuery>;
export type MockOneQueryResult = ApolloReactCommon.QueryResult<
  MockOneQuery,
  MockOneQueryVariables
>;
export const MockTwoDocument = gql`
  query MockTwo {
    me {
      email
    }
  }
`;
export type MockTwoComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    MockTwoQuery,
    MockTwoQueryVariables
  >,
  'query'
>;

export const MockTwoComponent = (props: MockTwoComponentProps) => (
  <ApolloReactComponents.Query<MockTwoQuery, MockTwoQueryVariables>
    query={MockTwoDocument}
    {...props}
  />
);

/**
 * __useMockTwoQuery__
 *
 * To run a query within a React component, call `useMockTwoQuery` and pass it any options that fit your needs.
 * When your component renders, `useMockTwoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMockTwoQuery({
 *   variables: {
 *   },
 * });
 */
export function useMockTwoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    MockTwoQuery,
    MockTwoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<MockTwoQuery, MockTwoQueryVariables>(
    MockTwoDocument,
    baseOptions
  );
}
export function useMockTwoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    MockTwoQuery,
    MockTwoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<MockTwoQuery, MockTwoQueryVariables>(
    MockTwoDocument,
    baseOptions
  );
}
export type MockTwoQueryHookResult = ReturnType<typeof useMockTwoQuery>;
export type MockTwoLazyQueryHookResult = ReturnType<typeof useMockTwoLazyQuery>;
export type MockTwoQueryResult = ApolloReactCommon.QueryResult<
  MockTwoQuery,
  MockTwoQueryVariables
>;
export const MockThreeDocument = gql`
  query MockThree {
    me {
      id
      email
    }
  }
`;
export type MockThreeComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    MockThreeQuery,
    MockThreeQueryVariables
  >,
  'query'
>;

export const MockThreeComponent = (props: MockThreeComponentProps) => (
  <ApolloReactComponents.Query<MockThreeQuery, MockThreeQueryVariables>
    query={MockThreeDocument}
    {...props}
  />
);

/**
 * __useMockThreeQuery__
 *
 * To run a query within a React component, call `useMockThreeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMockThreeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMockThreeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMockThreeQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    MockThreeQuery,
    MockThreeQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<MockThreeQuery, MockThreeQueryVariables>(
    MockThreeDocument,
    baseOptions
  );
}
export function useMockThreeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    MockThreeQuery,
    MockThreeQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<MockThreeQuery, MockThreeQueryVariables>(
    MockThreeDocument,
    baseOptions
  );
}
export type MockThreeQueryHookResult = ReturnType<typeof useMockThreeQuery>;
export type MockThreeLazyQueryHookResult = ReturnType<
  typeof useMockThreeLazyQuery
>;
export type MockThreeQueryResult = ApolloReactCommon.QueryResult<
  MockThreeQuery,
  MockThreeQueryVariables
>;
export const MockFourDocument = gql`
  query MockFour {
    me {
      name {
        first
        last
      }
    }
  }
`;
export type MockFourComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    MockFourQuery,
    MockFourQueryVariables
  >,
  'query'
>;

export const MockFourComponent = (props: MockFourComponentProps) => (
  <ApolloReactComponents.Query<MockFourQuery, MockFourQueryVariables>
    query={MockFourDocument}
    {...props}
  />
);

/**
 * __useMockFourQuery__
 *
 * To run a query within a React component, call `useMockFourQuery` and pass it any options that fit your needs.
 * When your component renders, `useMockFourQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMockFourQuery({
 *   variables: {
 *   },
 * });
 */
export function useMockFourQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    MockFourQuery,
    MockFourQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<MockFourQuery, MockFourQueryVariables>(
    MockFourDocument,
    baseOptions
  );
}
export function useMockFourLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    MockFourQuery,
    MockFourQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<MockFourQuery, MockFourQueryVariables>(
    MockFourDocument,
    baseOptions
  );
}
export type MockFourQueryHookResult = ReturnType<typeof useMockFourQuery>;
export type MockFourLazyQueryHookResult = ReturnType<
  typeof useMockFourLazyQuery
>;
export type MockFourQueryResult = ApolloReactCommon.QueryResult<
  MockFourQuery,
  MockFourQueryVariables
>;
export const AddFieldDataDocument = gql`
  query AddFieldData {
    me {
      id
      sensors(onlyAvailable: true) {
        id
        ...FieldFormSensor
      }
      gateways(onlyAvailable: true) {
        id
        ...FieldFormGateway
      }
    }
    irrigationTypes {
      id
      name
    }
  }
  ${FieldFormSensorFragmentDoc}
  ${FieldFormGatewayFragmentDoc}
`;
export type AddFieldDataComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    AddFieldDataQuery,
    AddFieldDataQueryVariables
  >,
  'query'
>;

export const AddFieldDataComponent = (props: AddFieldDataComponentProps) => (
  <ApolloReactComponents.Query<AddFieldDataQuery, AddFieldDataQueryVariables>
    query={AddFieldDataDocument}
    {...props}
  />
);

/**
 * __useAddFieldDataQuery__
 *
 * To run a query within a React component, call `useAddFieldDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddFieldDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddFieldDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useAddFieldDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AddFieldDataQuery,
    AddFieldDataQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    AddFieldDataQuery,
    AddFieldDataQueryVariables
  >(AddFieldDataDocument, baseOptions);
}
export function useAddFieldDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AddFieldDataQuery,
    AddFieldDataQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    AddFieldDataQuery,
    AddFieldDataQueryVariables
  >(AddFieldDataDocument, baseOptions);
}
export type AddFieldDataQueryHookResult = ReturnType<
  typeof useAddFieldDataQuery
>;
export type AddFieldDataLazyQueryHookResult = ReturnType<
  typeof useAddFieldDataLazyQuery
>;
export type AddFieldDataQueryResult = ApolloReactCommon.QueryResult<
  AddFieldDataQuery,
  AddFieldDataQueryVariables
>;
export const AddFieldDocument = gql`
  mutation AddField($input: AddFieldInput!) {
    addField(input: $input) {
      success
    }
  }
`;
export type AddFieldMutationFn = ApolloReactCommon.MutationFunction<
  AddFieldMutation,
  AddFieldMutationVariables
>;
export type AddFieldComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    AddFieldMutation,
    AddFieldMutationVariables
  >,
  'mutation'
>;

export const AddFieldComponent = (props: AddFieldComponentProps) => (
  <ApolloReactComponents.Mutation<AddFieldMutation, AddFieldMutationVariables>
    mutation={AddFieldDocument}
    {...props}
  />
);

/**
 * __useAddFieldMutation__
 *
 * To run a mutation, you first call `useAddFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFieldMutation, { data, loading, error }] = useAddFieldMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddFieldMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddFieldMutation,
    AddFieldMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AddFieldMutation,
    AddFieldMutationVariables
  >(AddFieldDocument, baseOptions);
}
export type AddFieldMutationHookResult = ReturnType<typeof useAddFieldMutation>;
export type AddFieldMutationResult = ApolloReactCommon.MutationResult<
  AddFieldMutation
>;
export type AddFieldMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddFieldMutation,
  AddFieldMutationVariables
>;
export const ArchivesDocument = gql`
  query Archives {
    me {
      id
      fields {
        id
        name
      }
      archivedFields {
        id
        name
        archiveDate
        sensors {
          id
          harvest_date
        }
      }
      archivedSensors {
        id
        originalId
        name
        archiveDate
        harvest_date
      }
      archivedGateways {
        id
        originalId
        name
        archiveDate
      }
      archivedIrrigationSystems {
        id
        name
        archiveDate
      }
    }
  }
`;
export type ArchivesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ArchivesQuery,
    ArchivesQueryVariables
  >,
  'query'
>;

export const ArchivesComponent = (props: ArchivesComponentProps) => (
  <ApolloReactComponents.Query<ArchivesQuery, ArchivesQueryVariables>
    query={ArchivesDocument}
    {...props}
  />
);

/**
 * __useArchivesQuery__
 *
 * To run a query within a React component, call `useArchivesQuery` and pass it any options that fit your needs.
 * When your component renders, `useArchivesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArchivesQuery({
 *   variables: {
 *   },
 * });
 */
export function useArchivesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ArchivesQuery,
    ArchivesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<ArchivesQuery, ArchivesQueryVariables>(
    ArchivesDocument,
    baseOptions
  );
}
export function useArchivesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ArchivesQuery,
    ArchivesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<ArchivesQuery, ArchivesQueryVariables>(
    ArchivesDocument,
    baseOptions
  );
}
export type ArchivesQueryHookResult = ReturnType<typeof useArchivesQuery>;
export type ArchivesLazyQueryHookResult = ReturnType<
  typeof useArchivesLazyQuery
>;
export type ArchivesQueryResult = ApolloReactCommon.QueryResult<
  ArchivesQuery,
  ArchivesQueryVariables
>;
export const FieldForArchiveNewDocument = gql`
  query FieldForArchiveNew($fieldId: String!, $tzoffset: Int!) {
    field(id: $fieldId) {
      id
      name
      calculatedAcreage
      estimatedAcreage
      irrigationEvents {
        id
        amount
        source
        date
        note
      }
      irrigationMetadata {
        irrigationType
        waterSource
        powerSource
        vendor
        acreage
        dripStats {
          emitterFlow
          emitterSpacing
          dripDistance
        }
      }
      yield {
        amount
        units
      }
      crop_type {
        id
        name
        custom
      }
      sensors {
        id
        name
        crop_type {
          id
          name
        }
        soil_type {
          id
          name
        }
        hasRainGauge
        current_state
        location {
          lat
          lng
        }
        crop_variety
        plant_date
        harvest_date
        weather {
          historical {
            precipAmount
            time
          }
        }
      }
      gateways {
        id
        name
        hasRainGauge
        location {
          lat
          lng
        }
      }
      moistureData {
        __typename
        ... on FieldMoistureData {
          moistureAvg {
            sm
            t
          }
          moistureMethod
        }
        ... on FieldMoistureDataError {
          messages
        }
      }
      rainfallSources {
        id
        name
        dataView {
          rainfall(tzoffset: $tzoffset) {
            t
            a
          }
        }
      }
    }
    crops {
      name
      id
    }
    soils {
      name
      id
    }
  }
`;
export type FieldForArchiveNewComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    FieldForArchiveNewQuery,
    FieldForArchiveNewQueryVariables
  >,
  'query'
> &
  (
    | { variables: FieldForArchiveNewQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const FieldForArchiveNewComponent = (
  props: FieldForArchiveNewComponentProps
) => (
  <ApolloReactComponents.Query<
    FieldForArchiveNewQuery,
    FieldForArchiveNewQueryVariables
  >
    query={FieldForArchiveNewDocument}
    {...props}
  />
);

/**
 * __useFieldForArchiveNewQuery__
 *
 * To run a query within a React component, call `useFieldForArchiveNewQuery` and pass it any options that fit your needs.
 * When your component renders, `useFieldForArchiveNewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFieldForArchiveNewQuery({
 *   variables: {
 *      fieldId: // value for 'fieldId'
 *      tzoffset: // value for 'tzoffset'
 *   },
 * });
 */
export function useFieldForArchiveNewQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FieldForArchiveNewQuery,
    FieldForArchiveNewQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    FieldForArchiveNewQuery,
    FieldForArchiveNewQueryVariables
  >(FieldForArchiveNewDocument, baseOptions);
}
export function useFieldForArchiveNewLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FieldForArchiveNewQuery,
    FieldForArchiveNewQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    FieldForArchiveNewQuery,
    FieldForArchiveNewQueryVariables
  >(FieldForArchiveNewDocument, baseOptions);
}
export type FieldForArchiveNewQueryHookResult = ReturnType<
  typeof useFieldForArchiveNewQuery
>;
export type FieldForArchiveNewLazyQueryHookResult = ReturnType<
  typeof useFieldForArchiveNewLazyQuery
>;
export type FieldForArchiveNewQueryResult = ApolloReactCommon.QueryResult<
  FieldForArchiveNewQuery,
  FieldForArchiveNewQueryVariables
>;
export const ArchiveFieldNewDocument = gql`
  mutation ArchiveFieldNew($input: ArchiveFieldInput!) {
    archiveField(input: $input) {
      success
    }
  }
`;
export type ArchiveFieldNewMutationFn = ApolloReactCommon.MutationFunction<
  ArchiveFieldNewMutation,
  ArchiveFieldNewMutationVariables
>;
export type ArchiveFieldNewComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ArchiveFieldNewMutation,
    ArchiveFieldNewMutationVariables
  >,
  'mutation'
>;

export const ArchiveFieldNewComponent = (
  props: ArchiveFieldNewComponentProps
) => (
  <ApolloReactComponents.Mutation<
    ArchiveFieldNewMutation,
    ArchiveFieldNewMutationVariables
  >
    mutation={ArchiveFieldNewDocument}
    {...props}
  />
);

/**
 * __useArchiveFieldNewMutation__
 *
 * To run a mutation, you first call `useArchiveFieldNewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveFieldNewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveFieldNewMutation, { data, loading, error }] = useArchiveFieldNewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArchiveFieldNewMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ArchiveFieldNewMutation,
    ArchiveFieldNewMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ArchiveFieldNewMutation,
    ArchiveFieldNewMutationVariables
  >(ArchiveFieldNewDocument, baseOptions);
}
export type ArchiveFieldNewMutationHookResult = ReturnType<
  typeof useArchiveFieldNewMutation
>;
export type ArchiveFieldNewMutationResult = ApolloReactCommon.MutationResult<
  ArchiveFieldNewMutation
>;
export type ArchiveFieldNewMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ArchiveFieldNewMutation,
  ArchiveFieldNewMutationVariables
>;
export const ArchiveFieldUpdateInfoNewDocument = gql`
  mutation ArchiveFieldUpdateInfoNew($id: String!, $input: UpdateFieldInput!) {
    updateField(fieldId: $id, input: $input) {
      field {
        id
        irrigationMetadata {
          acreage
          irrigationType
          vendor
          powerSource
          waterSource
          dripStats {
            emitterFlow
            emitterSpacing
            dripDistance
          }
        }
      }
    }
  }
`;
export type ArchiveFieldUpdateInfoNewMutationFn = ApolloReactCommon.MutationFunction<
  ArchiveFieldUpdateInfoNewMutation,
  ArchiveFieldUpdateInfoNewMutationVariables
>;
export type ArchiveFieldUpdateInfoNewComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ArchiveFieldUpdateInfoNewMutation,
    ArchiveFieldUpdateInfoNewMutationVariables
  >,
  'mutation'
>;

export const ArchiveFieldUpdateInfoNewComponent = (
  props: ArchiveFieldUpdateInfoNewComponentProps
) => (
  <ApolloReactComponents.Mutation<
    ArchiveFieldUpdateInfoNewMutation,
    ArchiveFieldUpdateInfoNewMutationVariables
  >
    mutation={ArchiveFieldUpdateInfoNewDocument}
    {...props}
  />
);

/**
 * __useArchiveFieldUpdateInfoNewMutation__
 *
 * To run a mutation, you first call `useArchiveFieldUpdateInfoNewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveFieldUpdateInfoNewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveFieldUpdateInfoNewMutation, { data, loading, error }] = useArchiveFieldUpdateInfoNewMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArchiveFieldUpdateInfoNewMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ArchiveFieldUpdateInfoNewMutation,
    ArchiveFieldUpdateInfoNewMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ArchiveFieldUpdateInfoNewMutation,
    ArchiveFieldUpdateInfoNewMutationVariables
  >(ArchiveFieldUpdateInfoNewDocument, baseOptions);
}
export type ArchiveFieldUpdateInfoNewMutationHookResult = ReturnType<
  typeof useArchiveFieldUpdateInfoNewMutation
>;
export type ArchiveFieldUpdateInfoNewMutationResult = ApolloReactCommon.MutationResult<
  ArchiveFieldUpdateInfoNewMutation
>;
export type ArchiveFieldUpdateInfoNewMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ArchiveFieldUpdateInfoNewMutation,
  ArchiveFieldUpdateInfoNewMutationVariables
>;
export const ArchivedFieldDocument = gql`
  query ArchivedField($id: String!) {
    archivedField(id: $id) {
      id
      ...FieldInfoArchivedField
      sensors {
        id
        name
        hasRainGauge
        crop_variety
        plant_date
        harvest_date
        crop_type {
          id
          name
        }
        soil_type {
          id
          name
        }
        ...DevicesMapArchivedSensor
      }
      gateways {
        id
        name
        ...DevicesMapArchivedGateway
      }
    }
  }
  ${FieldInfoArchivedFieldFragmentDoc}
  ${DevicesMapArchivedSensorFragmentDoc}
  ${DevicesMapArchivedGatewayFragmentDoc}
`;
export type ArchivedFieldComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ArchivedFieldQuery,
    ArchivedFieldQueryVariables
  >,
  'query'
> &
  (
    | { variables: ArchivedFieldQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const ArchivedFieldComponent = (props: ArchivedFieldComponentProps) => (
  <ApolloReactComponents.Query<ArchivedFieldQuery, ArchivedFieldQueryVariables>
    query={ArchivedFieldDocument}
    {...props}
  />
);

/**
 * __useArchivedFieldQuery__
 *
 * To run a query within a React component, call `useArchivedFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useArchivedFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArchivedFieldQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArchivedFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ArchivedFieldQuery,
    ArchivedFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ArchivedFieldQuery,
    ArchivedFieldQueryVariables
  >(ArchivedFieldDocument, baseOptions);
}
export function useArchivedFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ArchivedFieldQuery,
    ArchivedFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ArchivedFieldQuery,
    ArchivedFieldQueryVariables
  >(ArchivedFieldDocument, baseOptions);
}
export type ArchivedFieldQueryHookResult = ReturnType<
  typeof useArchivedFieldQuery
>;
export type ArchivedFieldLazyQueryHookResult = ReturnType<
  typeof useArchivedFieldLazyQuery
>;
export type ArchivedFieldQueryResult = ApolloReactCommon.QueryResult<
  ArchivedFieldQuery,
  ArchivedFieldQueryVariables
>;
export const ArchivedHistoricalRainfallDocument = gql`
  query ArchivedHistoricalRainfall($fieldId: String!, $tzoffset: Int) {
    archivedField(id: $fieldId) {
      id
      ...HistoricalRainfallGraphArchivedField
    }
  }
  ${HistoricalRainfallGraphArchivedFieldFragmentDoc}
`;
export type ArchivedHistoricalRainfallComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ArchivedHistoricalRainfallQuery,
    ArchivedHistoricalRainfallQueryVariables
  >,
  'query'
> &
  (
    | { variables: ArchivedHistoricalRainfallQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const ArchivedHistoricalRainfallComponent = (
  props: ArchivedHistoricalRainfallComponentProps
) => (
  <ApolloReactComponents.Query<
    ArchivedHistoricalRainfallQuery,
    ArchivedHistoricalRainfallQueryVariables
  >
    query={ArchivedHistoricalRainfallDocument}
    {...props}
  />
);

/**
 * __useArchivedHistoricalRainfallQuery__
 *
 * To run a query within a React component, call `useArchivedHistoricalRainfallQuery` and pass it any options that fit your needs.
 * When your component renders, `useArchivedHistoricalRainfallQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArchivedHistoricalRainfallQuery({
 *   variables: {
 *      fieldId: // value for 'fieldId'
 *      tzoffset: // value for 'tzoffset'
 *   },
 * });
 */
export function useArchivedHistoricalRainfallQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ArchivedHistoricalRainfallQuery,
    ArchivedHistoricalRainfallQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ArchivedHistoricalRainfallQuery,
    ArchivedHistoricalRainfallQueryVariables
  >(ArchivedHistoricalRainfallDocument, baseOptions);
}
export function useArchivedHistoricalRainfallLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ArchivedHistoricalRainfallQuery,
    ArchivedHistoricalRainfallQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ArchivedHistoricalRainfallQuery,
    ArchivedHistoricalRainfallQueryVariables
  >(ArchivedHistoricalRainfallDocument, baseOptions);
}
export type ArchivedHistoricalRainfallQueryHookResult = ReturnType<
  typeof useArchivedHistoricalRainfallQuery
>;
export type ArchivedHistoricalRainfallLazyQueryHookResult = ReturnType<
  typeof useArchivedHistoricalRainfallLazyQuery
>;
export type ArchivedHistoricalRainfallQueryResult = ApolloReactCommon.QueryResult<
  ArchivedHistoricalRainfallQuery,
  ArchivedHistoricalRainfallQueryVariables
>;
export const ArchivedIrrigationSystemDocument = gql`
  query ArchivedIrrigationSystem($id: String!) {
    archivedIrrigationSystem(id: $id) {
      id
      name
      sensors {
        id
        name
        ...DevicesMapArchivedPressureSensor
      }
      ...IrrigationSystemChartArchivedIrrigationSystem
    }
  }
  ${DevicesMapArchivedPressureSensorFragmentDoc}
  ${IrrigationSystemChartArchivedIrrigationSystemFragmentDoc}
`;
export type ArchivedIrrigationSystemComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ArchivedIrrigationSystemQuery,
    ArchivedIrrigationSystemQueryVariables
  >,
  'query'
> &
  (
    | { variables: ArchivedIrrigationSystemQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const ArchivedIrrigationSystemComponent = (
  props: ArchivedIrrigationSystemComponentProps
) => (
  <ApolloReactComponents.Query<
    ArchivedIrrigationSystemQuery,
    ArchivedIrrigationSystemQueryVariables
  >
    query={ArchivedIrrigationSystemDocument}
    {...props}
  />
);

/**
 * __useArchivedIrrigationSystemQuery__
 *
 * To run a query within a React component, call `useArchivedIrrigationSystemQuery` and pass it any options that fit your needs.
 * When your component renders, `useArchivedIrrigationSystemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArchivedIrrigationSystemQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArchivedIrrigationSystemQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ArchivedIrrigationSystemQuery,
    ArchivedIrrigationSystemQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ArchivedIrrigationSystemQuery,
    ArchivedIrrigationSystemQueryVariables
  >(ArchivedIrrigationSystemDocument, baseOptions);
}
export function useArchivedIrrigationSystemLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ArchivedIrrigationSystemQuery,
    ArchivedIrrigationSystemQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ArchivedIrrigationSystemQuery,
    ArchivedIrrigationSystemQueryVariables
  >(ArchivedIrrigationSystemDocument, baseOptions);
}
export type ArchivedIrrigationSystemQueryHookResult = ReturnType<
  typeof useArchivedIrrigationSystemQuery
>;
export type ArchivedIrrigationSystemLazyQueryHookResult = ReturnType<
  typeof useArchivedIrrigationSystemLazyQuery
>;
export type ArchivedIrrigationSystemQueryResult = ApolloReactCommon.QueryResult<
  ArchivedIrrigationSystemQuery,
  ArchivedIrrigationSystemQueryVariables
>;
export const ArchivedPressureSensorDocument = gql`
  query ArchivedPressureSensor($id: String!) {
    archivedPressureSensor(id: $id) {
      id
      name
      ...DevicesMapArchivedPressureSensor
      ...ArchivePressureSensorChart
    }
  }
  ${DevicesMapArchivedPressureSensorFragmentDoc}
  ${ArchivePressureSensorChartFragmentDoc}
`;
export type ArchivedPressureSensorComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ArchivedPressureSensorQuery,
    ArchivedPressureSensorQueryVariables
  >,
  'query'
> &
  (
    | { variables: ArchivedPressureSensorQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const ArchivedPressureSensorComponent = (
  props: ArchivedPressureSensorComponentProps
) => (
  <ApolloReactComponents.Query<
    ArchivedPressureSensorQuery,
    ArchivedPressureSensorQueryVariables
  >
    query={ArchivedPressureSensorDocument}
    {...props}
  />
);

/**
 * __useArchivedPressureSensorQuery__
 *
 * To run a query within a React component, call `useArchivedPressureSensorQuery` and pass it any options that fit your needs.
 * When your component renders, `useArchivedPressureSensorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArchivedPressureSensorQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArchivedPressureSensorQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ArchivedPressureSensorQuery,
    ArchivedPressureSensorQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ArchivedPressureSensorQuery,
    ArchivedPressureSensorQueryVariables
  >(ArchivedPressureSensorDocument, baseOptions);
}
export function useArchivedPressureSensorLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ArchivedPressureSensorQuery,
    ArchivedPressureSensorQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ArchivedPressureSensorQuery,
    ArchivedPressureSensorQueryVariables
  >(ArchivedPressureSensorDocument, baseOptions);
}
export type ArchivedPressureSensorQueryHookResult = ReturnType<
  typeof useArchivedPressureSensorQuery
>;
export type ArchivedPressureSensorLazyQueryHookResult = ReturnType<
  typeof useArchivedPressureSensorLazyQuery
>;
export type ArchivedPressureSensorQueryResult = ApolloReactCommon.QueryResult<
  ArchivedPressureSensorQuery,
  ArchivedPressureSensorQueryVariables
>;
export const ArchivedSensorDocument = gql`
  query ArchivedSensor($sensorId: String!, $tzoffset: Int) {
    archivedSensor(id: $sensorId) {
      id
      ...SensorGraphs
      ...SensorHeaderData
    }
  }
  ${SensorGraphsFragmentDoc}
  ${SensorHeaderDataFragmentDoc}
`;
export type ArchivedSensorComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ArchivedSensorQuery,
    ArchivedSensorQueryVariables
  >,
  'query'
> &
  (
    | { variables: ArchivedSensorQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const ArchivedSensorComponent = (
  props: ArchivedSensorComponentProps
) => (
  <ApolloReactComponents.Query<
    ArchivedSensorQuery,
    ArchivedSensorQueryVariables
  >
    query={ArchivedSensorDocument}
    {...props}
  />
);

/**
 * __useArchivedSensorQuery__
 *
 * To run a query within a React component, call `useArchivedSensorQuery` and pass it any options that fit your needs.
 * When your component renders, `useArchivedSensorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArchivedSensorQuery({
 *   variables: {
 *      sensorId: // value for 'sensorId'
 *      tzoffset: // value for 'tzoffset'
 *   },
 * });
 */
export function useArchivedSensorQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ArchivedSensorQuery,
    ArchivedSensorQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ArchivedSensorQuery,
    ArchivedSensorQueryVariables
  >(ArchivedSensorDocument, baseOptions);
}
export function useArchivedSensorLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ArchivedSensorQuery,
    ArchivedSensorQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ArchivedSensorQuery,
    ArchivedSensorQueryVariables
  >(ArchivedSensorDocument, baseOptions);
}
export type ArchivedSensorQueryHookResult = ReturnType<
  typeof useArchivedSensorQuery
>;
export type ArchivedSensorLazyQueryHookResult = ReturnType<
  typeof useArchivedSensorLazyQuery
>;
export type ArchivedSensorQueryResult = ApolloReactCommon.QueryResult<
  ArchivedSensorQuery,
  ArchivedSensorQueryVariables
>;
export const EditFieldDataDocument = gql`
  query EditFieldData($id: String!) {
    me {
      id
      sensors(onlyAvailable: true) {
        id
        ...FieldFormSensor
      }
      gateways(onlyAvailable: true) {
        id
        ...FieldFormGateway
      }
    }
    field(id: $id) {
      id
      ...EditFieldField
    }
    irrigationTypes {
      id
      name
    }
  }
  ${FieldFormSensorFragmentDoc}
  ${FieldFormGatewayFragmentDoc}
  ${EditFieldFieldFragmentDoc}
`;
export type EditFieldDataComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    EditFieldDataQuery,
    EditFieldDataQueryVariables
  >,
  'query'
> &
  (
    | { variables: EditFieldDataQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const EditFieldDataComponent = (props: EditFieldDataComponentProps) => (
  <ApolloReactComponents.Query<EditFieldDataQuery, EditFieldDataQueryVariables>
    query={EditFieldDataDocument}
    {...props}
  />
);

/**
 * __useEditFieldDataQuery__
 *
 * To run a query within a React component, call `useEditFieldDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditFieldDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditFieldDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEditFieldDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    EditFieldDataQuery,
    EditFieldDataQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    EditFieldDataQuery,
    EditFieldDataQueryVariables
  >(EditFieldDataDocument, baseOptions);
}
export function useEditFieldDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    EditFieldDataQuery,
    EditFieldDataQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    EditFieldDataQuery,
    EditFieldDataQueryVariables
  >(EditFieldDataDocument, baseOptions);
}
export type EditFieldDataQueryHookResult = ReturnType<
  typeof useEditFieldDataQuery
>;
export type EditFieldDataLazyQueryHookResult = ReturnType<
  typeof useEditFieldDataLazyQuery
>;
export type EditFieldDataQueryResult = ApolloReactCommon.QueryResult<
  EditFieldDataQuery,
  EditFieldDataQueryVariables
>;
export const EditFieldDocument = gql`
  mutation EditField($fieldId: String!, $input: UpdateFieldInput!) {
    updateField(fieldId: $fieldId, input: $input) {
      field {
        id
        ...EditFieldField
      }
    }
  }
  ${EditFieldFieldFragmentDoc}
`;
export type EditFieldMutationFn = ApolloReactCommon.MutationFunction<
  EditFieldMutation,
  EditFieldMutationVariables
>;
export type EditFieldComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    EditFieldMutation,
    EditFieldMutationVariables
  >,
  'mutation'
>;

export const EditFieldComponent = (props: EditFieldComponentProps) => (
  <ApolloReactComponents.Mutation<EditFieldMutation, EditFieldMutationVariables>
    mutation={EditFieldDocument}
    {...props}
  />
);

/**
 * __useEditFieldMutation__
 *
 * To run a mutation, you first call `useEditFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editFieldMutation, { data, loading, error }] = useEditFieldMutation({
 *   variables: {
 *      fieldId: // value for 'fieldId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditFieldMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    EditFieldMutation,
    EditFieldMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    EditFieldMutation,
    EditFieldMutationVariables
  >(EditFieldDocument, baseOptions);
}
export type EditFieldMutationHookResult = ReturnType<
  typeof useEditFieldMutation
>;
export type EditFieldMutationResult = ApolloReactCommon.MutationResult<
  EditFieldMutation
>;
export type EditFieldMutationOptions = ApolloReactCommon.BaseMutationOptions<
  EditFieldMutation,
  EditFieldMutationVariables
>;
export const ActivationProgressDocument = gql`
  query ActivationProgress($input: ActivationProgressInput!) {
    activationProgress(input: $input) {
      id
      email
      name {
        first
        last
      }
      dataAccess {
        id
        plan
      }
      cardBrand
      cardLast4
      distributorName
      distributorEmail
      repName
      repEmail
      type
      progress {
        billing
        name
        password
        dataAccess
      }
      sensors {
        id
        name
        model {
          antennaLength
          moistureDepths
        }
      }
      gateways {
        id
        model {
          antennaLength
          hasRainGauge
          hasWeatherStation
        }
      }
    }
  }
`;
export type ActivationProgressComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ActivationProgressQuery,
    ActivationProgressQueryVariables
  >,
  'query'
> &
  (
    | { variables: ActivationProgressQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const ActivationProgressComponent = (
  props: ActivationProgressComponentProps
) => (
  <ApolloReactComponents.Query<
    ActivationProgressQuery,
    ActivationProgressQueryVariables
  >
    query={ActivationProgressDocument}
    {...props}
  />
);

/**
 * __useActivationProgressQuery__
 *
 * To run a query within a React component, call `useActivationProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivationProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivationProgressQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivationProgressQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ActivationProgressQuery,
    ActivationProgressQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ActivationProgressQuery,
    ActivationProgressQueryVariables
  >(ActivationProgressDocument, baseOptions);
}
export function useActivationProgressLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ActivationProgressQuery,
    ActivationProgressQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ActivationProgressQuery,
    ActivationProgressQueryVariables
  >(ActivationProgressDocument, baseOptions);
}
export type ActivationProgressQueryHookResult = ReturnType<
  typeof useActivationProgressQuery
>;
export type ActivationProgressLazyQueryHookResult = ReturnType<
  typeof useActivationProgressLazyQuery
>;
export type ActivationProgressQueryResult = ApolloReactCommon.QueryResult<
  ActivationProgressQuery,
  ActivationProgressQueryVariables
>;
export const AddCardToUserDocument = gql`
  mutation AddCardToUser($input: ActivationAddCardToUserInput!) {
    activationAddCardToUser(input: $input) {
      userId
    }
  }
`;
export type AddCardToUserMutationFn = ApolloReactCommon.MutationFunction<
  AddCardToUserMutation,
  AddCardToUserMutationVariables
>;
export type AddCardToUserComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    AddCardToUserMutation,
    AddCardToUserMutationVariables
  >,
  'mutation'
>;

export const AddCardToUserComponent = (props: AddCardToUserComponentProps) => (
  <ApolloReactComponents.Mutation<
    AddCardToUserMutation,
    AddCardToUserMutationVariables
  >
    mutation={AddCardToUserDocument}
    {...props}
  />
);

/**
 * __useAddCardToUserMutation__
 *
 * To run a mutation, you first call `useAddCardToUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCardToUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCardToUserMutation, { data, loading, error }] = useAddCardToUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCardToUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddCardToUserMutation,
    AddCardToUserMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AddCardToUserMutation,
    AddCardToUserMutationVariables
  >(AddCardToUserDocument, baseOptions);
}
export type AddCardToUserMutationHookResult = ReturnType<
  typeof useAddCardToUserMutation
>;
export type AddCardToUserMutationResult = ApolloReactCommon.MutationResult<
  AddCardToUserMutation
>;
export type AddCardToUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddCardToUserMutation,
  AddCardToUserMutationVariables
>;
export const SaveDataAccessDataDocument = gql`
  mutation SaveDataAccessData($input: ActivationChangeDataAccessInput!) {
    activationChangeDataAccess(input: $input) {
      userId
    }
  }
`;
export type SaveDataAccessDataMutationFn = ApolloReactCommon.MutationFunction<
  SaveDataAccessDataMutation,
  SaveDataAccessDataMutationVariables
>;
export type SaveDataAccessDataComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    SaveDataAccessDataMutation,
    SaveDataAccessDataMutationVariables
  >,
  'mutation'
>;

export const SaveDataAccessDataComponent = (
  props: SaveDataAccessDataComponentProps
) => (
  <ApolloReactComponents.Mutation<
    SaveDataAccessDataMutation,
    SaveDataAccessDataMutationVariables
  >
    mutation={SaveDataAccessDataDocument}
    {...props}
  />
);

/**
 * __useSaveDataAccessDataMutation__
 *
 * To run a mutation, you first call `useSaveDataAccessDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveDataAccessDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveDataAccessDataMutation, { data, loading, error }] = useSaveDataAccessDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveDataAccessDataMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SaveDataAccessDataMutation,
    SaveDataAccessDataMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    SaveDataAccessDataMutation,
    SaveDataAccessDataMutationVariables
  >(SaveDataAccessDataDocument, baseOptions);
}
export type SaveDataAccessDataMutationHookResult = ReturnType<
  typeof useSaveDataAccessDataMutation
>;
export type SaveDataAccessDataMutationResult = ApolloReactCommon.MutationResult<
  SaveDataAccessDataMutation
>;
export type SaveDataAccessDataMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SaveDataAccessDataMutation,
  SaveDataAccessDataMutationVariables
>;
export const ChangePasswordDocument = gql`
  mutation ChangePassword($input: ActivationChangePasswordInput!) {
    activationChangePassword(input: $input) {
      userId
    }
  }
`;
export type ChangePasswordMutationFn = ApolloReactCommon.MutationFunction<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>;
export type ChangePasswordComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
  >,
  'mutation'
>;

export const ChangePasswordComponent = (
  props: ChangePasswordComponentProps
) => (
  <ApolloReactComponents.Mutation<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
  >
    mutation={ChangePasswordDocument}
    {...props}
  />
);

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangePasswordMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
  >(ChangePasswordDocument, baseOptions);
}
export type ChangePasswordMutationHookResult = ReturnType<
  typeof useChangePasswordMutation
>;
export type ChangePasswordMutationResult = ApolloReactCommon.MutationResult<
  ChangePasswordMutation
>;
export type ChangePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>;
export const ActivationFinishDocument = gql`
  mutation ActivationFinish($input: ActivationFinishInput!) {
    activationFinish(input: $input) {
      userId
    }
  }
`;
export type ActivationFinishMutationFn = ApolloReactCommon.MutationFunction<
  ActivationFinishMutation,
  ActivationFinishMutationVariables
>;
export type ActivationFinishComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ActivationFinishMutation,
    ActivationFinishMutationVariables
  >,
  'mutation'
>;

export const ActivationFinishComponent = (
  props: ActivationFinishComponentProps
) => (
  <ApolloReactComponents.Mutation<
    ActivationFinishMutation,
    ActivationFinishMutationVariables
  >
    mutation={ActivationFinishDocument}
    {...props}
  />
);

/**
 * __useActivationFinishMutation__
 *
 * To run a mutation, you first call `useActivationFinishMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivationFinishMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activationFinishMutation, { data, loading, error }] = useActivationFinishMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivationFinishMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ActivationFinishMutation,
    ActivationFinishMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ActivationFinishMutation,
    ActivationFinishMutationVariables
  >(ActivationFinishDocument, baseOptions);
}
export type ActivationFinishMutationHookResult = ReturnType<
  typeof useActivationFinishMutation
>;
export type ActivationFinishMutationResult = ApolloReactCommon.MutationResult<
  ActivationFinishMutation
>;
export type ActivationFinishMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ActivationFinishMutation,
  ActivationFinishMutationVariables
>;
export const ActivationSharedFinishDocument = gql`
  mutation ActivationSharedFinish($input: ActivationSharedFinishInput!) {
    activationSharedFinish(input: $input) {
      userId
    }
  }
`;
export type ActivationSharedFinishMutationFn = ApolloReactCommon.MutationFunction<
  ActivationSharedFinishMutation,
  ActivationSharedFinishMutationVariables
>;
export type ActivationSharedFinishComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ActivationSharedFinishMutation,
    ActivationSharedFinishMutationVariables
  >,
  'mutation'
>;

export const ActivationSharedFinishComponent = (
  props: ActivationSharedFinishComponentProps
) => (
  <ApolloReactComponents.Mutation<
    ActivationSharedFinishMutation,
    ActivationSharedFinishMutationVariables
  >
    mutation={ActivationSharedFinishDocument}
    {...props}
  />
);

/**
 * __useActivationSharedFinishMutation__
 *
 * To run a mutation, you first call `useActivationSharedFinishMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivationSharedFinishMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activationSharedFinishMutation, { data, loading, error }] = useActivationSharedFinishMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivationSharedFinishMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ActivationSharedFinishMutation,
    ActivationSharedFinishMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ActivationSharedFinishMutation,
    ActivationSharedFinishMutationVariables
  >(ActivationSharedFinishDocument, baseOptions);
}
export type ActivationSharedFinishMutationHookResult = ReturnType<
  typeof useActivationSharedFinishMutation
>;
export type ActivationSharedFinishMutationResult = ApolloReactCommon.MutationResult<
  ActivationSharedFinishMutation
>;
export type ActivationSharedFinishMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ActivationSharedFinishMutation,
  ActivationSharedFinishMutationVariables
>;
export const ActivationPrepaidFinishDocument = gql`
  mutation ActivationPrepaidFinish($input: ActivationPrepaidFinishInput!) {
    activationPrepaidFinish(input: $input) {
      userId
    }
  }
`;
export type ActivationPrepaidFinishMutationFn = ApolloReactCommon.MutationFunction<
  ActivationPrepaidFinishMutation,
  ActivationPrepaidFinishMutationVariables
>;
export type ActivationPrepaidFinishComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ActivationPrepaidFinishMutation,
    ActivationPrepaidFinishMutationVariables
  >,
  'mutation'
>;

export const ActivationPrepaidFinishComponent = (
  props: ActivationPrepaidFinishComponentProps
) => (
  <ApolloReactComponents.Mutation<
    ActivationPrepaidFinishMutation,
    ActivationPrepaidFinishMutationVariables
  >
    mutation={ActivationPrepaidFinishDocument}
    {...props}
  />
);

/**
 * __useActivationPrepaidFinishMutation__
 *
 * To run a mutation, you first call `useActivationPrepaidFinishMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivationPrepaidFinishMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activationPrepaidFinishMutation, { data, loading, error }] = useActivationPrepaidFinishMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivationPrepaidFinishMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ActivationPrepaidFinishMutation,
    ActivationPrepaidFinishMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ActivationPrepaidFinishMutation,
    ActivationPrepaidFinishMutationVariables
  >(ActivationPrepaidFinishDocument, baseOptions);
}
export type ActivationPrepaidFinishMutationHookResult = ReturnType<
  typeof useActivationPrepaidFinishMutation
>;
export type ActivationPrepaidFinishMutationResult = ApolloReactCommon.MutationResult<
  ActivationPrepaidFinishMutation
>;
export type ActivationPrepaidFinishMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ActivationPrepaidFinishMutation,
  ActivationPrepaidFinishMutationVariables
>;
export const ChangeNameDocument = gql`
  mutation ChangeName($input: ActivationChangeNameInput!) {
    activationChangeName(input: $input) {
      userId
    }
  }
`;
export type ChangeNameMutationFn = ApolloReactCommon.MutationFunction<
  ChangeNameMutation,
  ChangeNameMutationVariables
>;
export type ChangeNameComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ChangeNameMutation,
    ChangeNameMutationVariables
  >,
  'mutation'
>;

export const ChangeNameComponent = (props: ChangeNameComponentProps) => (
  <ApolloReactComponents.Mutation<
    ChangeNameMutation,
    ChangeNameMutationVariables
  >
    mutation={ChangeNameDocument}
    {...props}
  />
);

/**
 * __useChangeNameMutation__
 *
 * To run a mutation, you first call `useChangeNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeNameMutation, { data, loading, error }] = useChangeNameMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeNameMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ChangeNameMutation,
    ChangeNameMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ChangeNameMutation,
    ChangeNameMutationVariables
  >(ChangeNameDocument, baseOptions);
}
export type ChangeNameMutationHookResult = ReturnType<
  typeof useChangeNameMutation
>;
export type ChangeNameMutationResult = ApolloReactCommon.MutationResult<
  ChangeNameMutation
>;
export type ChangeNameMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ChangeNameMutation,
  ChangeNameMutationVariables
>;
export const SendVerificationEmailDocument = gql`
  mutation SendVerificationEmail($input: SendVerificationEmailInput!) {
    sendVerificationEmail(input: $input) {
      success
    }
  }
`;
export type SendVerificationEmailMutationFn = ApolloReactCommon.MutationFunction<
  SendVerificationEmailMutation,
  SendVerificationEmailMutationVariables
>;
export type SendVerificationEmailComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    SendVerificationEmailMutation,
    SendVerificationEmailMutationVariables
  >,
  'mutation'
>;

export const SendVerificationEmailComponent = (
  props: SendVerificationEmailComponentProps
) => (
  <ApolloReactComponents.Mutation<
    SendVerificationEmailMutation,
    SendVerificationEmailMutationVariables
  >
    mutation={SendVerificationEmailDocument}
    {...props}
  />
);

/**
 * __useSendVerificationEmailMutation__
 *
 * To run a mutation, you first call `useSendVerificationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendVerificationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendVerificationEmailMutation, { data, loading, error }] = useSendVerificationEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendVerificationEmailMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SendVerificationEmailMutation,
    SendVerificationEmailMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    SendVerificationEmailMutation,
    SendVerificationEmailMutationVariables
  >(SendVerificationEmailDocument, baseOptions);
}
export type SendVerificationEmailMutationHookResult = ReturnType<
  typeof useSendVerificationEmailMutation
>;
export type SendVerificationEmailMutationResult = ApolloReactCommon.MutationResult<
  SendVerificationEmailMutation
>;
export type SendVerificationEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SendVerificationEmailMutation,
  SendVerificationEmailMutationVariables
>;
export const RegisterUserDocument = gql`
  mutation RegisterUser($input: RegisterUserInput!) {
    registerUser(input: $input) {
      success
    }
  }
`;
export type RegisterUserMutationFn = ApolloReactCommon.MutationFunction<
  RegisterUserMutation,
  RegisterUserMutationVariables
>;
export type RegisterUserComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    RegisterUserMutation,
    RegisterUserMutationVariables
  >,
  'mutation'
>;

export const RegisterUserComponent = (props: RegisterUserComponentProps) => (
  <ApolloReactComponents.Mutation<
    RegisterUserMutation,
    RegisterUserMutationVariables
  >
    mutation={RegisterUserDocument}
    {...props}
  />
);

/**
 * __useRegisterUserMutation__
 *
 * To run a mutation, you first call `useRegisterUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerUserMutation, { data, loading, error }] = useRegisterUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RegisterUserMutation,
    RegisterUserMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RegisterUserMutation,
    RegisterUserMutationVariables
  >(RegisterUserDocument, baseOptions);
}
export type RegisterUserMutationHookResult = ReturnType<
  typeof useRegisterUserMutation
>;
export type RegisterUserMutationResult = ApolloReactCommon.MutationResult<
  RegisterUserMutation
>;
export type RegisterUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RegisterUserMutation,
  RegisterUserMutationVariables
>;
export const FieldDocument = gql`
  query Field($fieldId: String!, $tzoffset: Int) {
    field(id: $fieldId) {
      id
      ...FieldViewField
    }
    me {
      id
      sensors(onlyAvailable: true) {
        id
        name
      }
      gateways(onlyAvailable: true) {
        id
      }
    }
  }
  ${FieldViewFieldFragmentDoc}
`;
export type FieldComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<FieldQuery, FieldQueryVariables>,
  'query'
> &
  ({ variables: FieldQueryVariables; skip?: boolean } | { skip: boolean });

export const FieldComponent = (props: FieldComponentProps) => (
  <ApolloReactComponents.Query<FieldQuery, FieldQueryVariables>
    query={FieldDocument}
    {...props}
  />
);

/**
 * __useFieldQuery__
 *
 * To run a query within a React component, call `useFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFieldQuery({
 *   variables: {
 *      fieldId: // value for 'fieldId'
 *      tzoffset: // value for 'tzoffset'
 *   },
 * });
 */
export function useFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FieldQuery,
    FieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<FieldQuery, FieldQueryVariables>(
    FieldDocument,
    baseOptions
  );
}
export function useFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FieldQuery,
    FieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<FieldQuery, FieldQueryVariables>(
    FieldDocument,
    baseOptions
  );
}
export type FieldQueryHookResult = ReturnType<typeof useFieldQuery>;
export type FieldLazyQueryHookResult = ReturnType<typeof useFieldLazyQuery>;
export type FieldQueryResult = ApolloReactCommon.QueryResult<
  FieldQuery,
  FieldQueryVariables
>;
export const FieldForArchiveDocument = gql`
  query FieldForArchive($fieldId: String!, $tzoffset: Int!) {
    field(id: $fieldId) {
      id
      name
      irrigationEvents {
        id
        amount
        source
        date
        note
      }
      irrigationMetadata {
        irrigationType
        waterSource
        powerSource
        vendor
        acreage
        dripStats {
          emitterFlow
          emitterSpacing
          dripDistance
        }
      }
      yield {
        amount
        units
      }
      sensors {
        id
        name
        crop_type {
          id
          name
        }
        soil_type {
          id
          name
        }
        crop_variety
        plant_date
        harvest_date
        weather {
          historical {
            precipAmount
            time
          }
        }
      }
      rainfallSources {
        id
        name
        hasRainGauge
        dataView {
          rainfall(tzoffset: $tzoffset) {
            t
            a
          }
        }
      }
    }
    crops {
      name
      id
    }
    soils {
      name
      id
    }
  }
`;
export type FieldForArchiveComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    FieldForArchiveQuery,
    FieldForArchiveQueryVariables
  >,
  'query'
> &
  (
    | { variables: FieldForArchiveQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const FieldForArchiveComponent = (
  props: FieldForArchiveComponentProps
) => (
  <ApolloReactComponents.Query<
    FieldForArchiveQuery,
    FieldForArchiveQueryVariables
  >
    query={FieldForArchiveDocument}
    {...props}
  />
);

/**
 * __useFieldForArchiveQuery__
 *
 * To run a query within a React component, call `useFieldForArchiveQuery` and pass it any options that fit your needs.
 * When your component renders, `useFieldForArchiveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFieldForArchiveQuery({
 *   variables: {
 *      fieldId: // value for 'fieldId'
 *      tzoffset: // value for 'tzoffset'
 *   },
 * });
 */
export function useFieldForArchiveQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FieldForArchiveQuery,
    FieldForArchiveQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    FieldForArchiveQuery,
    FieldForArchiveQueryVariables
  >(FieldForArchiveDocument, baseOptions);
}
export function useFieldForArchiveLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FieldForArchiveQuery,
    FieldForArchiveQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    FieldForArchiveQuery,
    FieldForArchiveQueryVariables
  >(FieldForArchiveDocument, baseOptions);
}
export type FieldForArchiveQueryHookResult = ReturnType<
  typeof useFieldForArchiveQuery
>;
export type FieldForArchiveLazyQueryHookResult = ReturnType<
  typeof useFieldForArchiveLazyQuery
>;
export type FieldForArchiveQueryResult = ApolloReactCommon.QueryResult<
  FieldForArchiveQuery,
  FieldForArchiveQueryVariables
>;
export const ArchiveFieldDocument = gql`
  mutation ArchiveField($input: ArchiveFieldInput!) {
    archiveField(input: $input) {
      success
    }
  }
`;
export type ArchiveFieldMutationFn = ApolloReactCommon.MutationFunction<
  ArchiveFieldMutation,
  ArchiveFieldMutationVariables
>;
export type ArchiveFieldComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ArchiveFieldMutation,
    ArchiveFieldMutationVariables
  >,
  'mutation'
>;

export const ArchiveFieldComponent = (props: ArchiveFieldComponentProps) => (
  <ApolloReactComponents.Mutation<
    ArchiveFieldMutation,
    ArchiveFieldMutationVariables
  >
    mutation={ArchiveFieldDocument}
    {...props}
  />
);

/**
 * __useArchiveFieldMutation__
 *
 * To run a mutation, you first call `useArchiveFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveFieldMutation, { data, loading, error }] = useArchiveFieldMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArchiveFieldMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ArchiveFieldMutation,
    ArchiveFieldMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ArchiveFieldMutation,
    ArchiveFieldMutationVariables
  >(ArchiveFieldDocument, baseOptions);
}
export type ArchiveFieldMutationHookResult = ReturnType<
  typeof useArchiveFieldMutation
>;
export type ArchiveFieldMutationResult = ApolloReactCommon.MutationResult<
  ArchiveFieldMutation
>;
export type ArchiveFieldMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ArchiveFieldMutation,
  ArchiveFieldMutationVariables
>;
export const ArchiveFieldUpdateInfoDocument = gql`
  mutation ArchiveFieldUpdateInfo($id: String!, $input: UpdateFieldInput!) {
    updateField(fieldId: $id, input: $input) {
      field {
        id
        irrigationMetadata {
          acreage
          irrigationType
          vendor
          powerSource
          waterSource
          dripStats {
            emitterFlow
            emitterSpacing
            dripDistance
          }
        }
      }
    }
  }
`;
export type ArchiveFieldUpdateInfoMutationFn = ApolloReactCommon.MutationFunction<
  ArchiveFieldUpdateInfoMutation,
  ArchiveFieldUpdateInfoMutationVariables
>;
export type ArchiveFieldUpdateInfoComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ArchiveFieldUpdateInfoMutation,
    ArchiveFieldUpdateInfoMutationVariables
  >,
  'mutation'
>;

export const ArchiveFieldUpdateInfoComponent = (
  props: ArchiveFieldUpdateInfoComponentProps
) => (
  <ApolloReactComponents.Mutation<
    ArchiveFieldUpdateInfoMutation,
    ArchiveFieldUpdateInfoMutationVariables
  >
    mutation={ArchiveFieldUpdateInfoDocument}
    {...props}
  />
);

/**
 * __useArchiveFieldUpdateInfoMutation__
 *
 * To run a mutation, you first call `useArchiveFieldUpdateInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveFieldUpdateInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveFieldUpdateInfoMutation, { data, loading, error }] = useArchiveFieldUpdateInfoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArchiveFieldUpdateInfoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ArchiveFieldUpdateInfoMutation,
    ArchiveFieldUpdateInfoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ArchiveFieldUpdateInfoMutation,
    ArchiveFieldUpdateInfoMutationVariables
  >(ArchiveFieldUpdateInfoDocument, baseOptions);
}
export type ArchiveFieldUpdateInfoMutationHookResult = ReturnType<
  typeof useArchiveFieldUpdateInfoMutation
>;
export type ArchiveFieldUpdateInfoMutationResult = ApolloReactCommon.MutationResult<
  ArchiveFieldUpdateInfoMutation
>;
export type ArchiveFieldUpdateInfoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ArchiveFieldUpdateInfoMutation,
  ArchiveFieldUpdateInfoMutationVariables
>;
export const DeleteFieldDocument = gql`
  mutation DeleteField($input: DeleteFieldInput!) {
    deleteField(input: $input) {
      success
    }
  }
`;
export type DeleteFieldMutationFn = ApolloReactCommon.MutationFunction<
  DeleteFieldMutation,
  DeleteFieldMutationVariables
>;
export type DeleteFieldComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteFieldMutation,
    DeleteFieldMutationVariables
  >,
  'mutation'
>;

export const DeleteFieldComponent = (props: DeleteFieldComponentProps) => (
  <ApolloReactComponents.Mutation<
    DeleteFieldMutation,
    DeleteFieldMutationVariables
  >
    mutation={DeleteFieldDocument}
    {...props}
  />
);

/**
 * __useDeleteFieldMutation__
 *
 * To run a mutation, you first call `useDeleteFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFieldMutation, { data, loading, error }] = useDeleteFieldMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteFieldMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteFieldMutation,
    DeleteFieldMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteFieldMutation,
    DeleteFieldMutationVariables
  >(DeleteFieldDocument, baseOptions);
}
export type DeleteFieldMutationHookResult = ReturnType<
  typeof useDeleteFieldMutation
>;
export type DeleteFieldMutationResult = ApolloReactCommon.MutationResult<
  DeleteFieldMutation
>;
export type DeleteFieldMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteFieldMutation,
  DeleteFieldMutationVariables
>;
export const AddIrrigationDocument = gql`
  mutation AddIrrigation($input: IrrigationEventAddInput!) {
    irrigationEventAdd(input: $input) {
      irrigationEvent {
        id
        amount
        date
        note
        source
      }
    }
  }
`;
export type AddIrrigationMutationFn = ApolloReactCommon.MutationFunction<
  AddIrrigationMutation,
  AddIrrigationMutationVariables
>;
export type AddIrrigationComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    AddIrrigationMutation,
    AddIrrigationMutationVariables
  >,
  'mutation'
>;

export const AddIrrigationComponent = (props: AddIrrigationComponentProps) => (
  <ApolloReactComponents.Mutation<
    AddIrrigationMutation,
    AddIrrigationMutationVariables
  >
    mutation={AddIrrigationDocument}
    {...props}
  />
);

/**
 * __useAddIrrigationMutation__
 *
 * To run a mutation, you first call `useAddIrrigationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddIrrigationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addIrrigationMutation, { data, loading, error }] = useAddIrrigationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddIrrigationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddIrrigationMutation,
    AddIrrigationMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AddIrrigationMutation,
    AddIrrigationMutationVariables
  >(AddIrrigationDocument, baseOptions);
}
export type AddIrrigationMutationHookResult = ReturnType<
  typeof useAddIrrigationMutation
>;
export type AddIrrigationMutationResult = ApolloReactCommon.MutationResult<
  AddIrrigationMutation
>;
export type AddIrrigationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddIrrigationMutation,
  AddIrrigationMutationVariables
>;
export const IrrigationDocument = gql`
  query Irrigation($fieldId: String!, $tzoffset: Int) {
    field(id: $fieldId) {
      id
      ...HistoricalRainfallGraphField
      irrigationMetadata {
        irrigationType
        waterSource
        powerSource
        acreage
        vendor
        dripStats {
          emitterFlow
          emitterSpacing
          dripDistance
        }
      }
    }
  }
  ${HistoricalRainfallGraphFieldFragmentDoc}
`;
export type IrrigationComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    IrrigationQuery,
    IrrigationQueryVariables
  >,
  'query'
> &
  ({ variables: IrrigationQueryVariables; skip?: boolean } | { skip: boolean });

export const IrrigationComponent = (props: IrrigationComponentProps) => (
  <ApolloReactComponents.Query<IrrigationQuery, IrrigationQueryVariables>
    query={IrrigationDocument}
    {...props}
  />
);

/**
 * __useIrrigationQuery__
 *
 * To run a query within a React component, call `useIrrigationQuery` and pass it any options that fit your needs.
 * When your component renders, `useIrrigationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIrrigationQuery({
 *   variables: {
 *      fieldId: // value for 'fieldId'
 *      tzoffset: // value for 'tzoffset'
 *   },
 * });
 */
export function useIrrigationQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    IrrigationQuery,
    IrrigationQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<IrrigationQuery, IrrigationQueryVariables>(
    IrrigationDocument,
    baseOptions
  );
}
export function useIrrigationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IrrigationQuery,
    IrrigationQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    IrrigationQuery,
    IrrigationQueryVariables
  >(IrrigationDocument, baseOptions);
}
export type IrrigationQueryHookResult = ReturnType<typeof useIrrigationQuery>;
export type IrrigationLazyQueryHookResult = ReturnType<
  typeof useIrrigationLazyQuery
>;
export type IrrigationQueryResult = ApolloReactCommon.QueryResult<
  IrrigationQuery,
  IrrigationQueryVariables
>;
export const UpdateIrrigationMetadataDocument = gql`
  mutation UpdateIrrigationMetadata(
    $fieldId: String!
    $input: UpdateFieldInput!
  ) {
    updateField(fieldId: $fieldId, input: $input) {
      success
      field {
        id
        irrigationMetadata {
          vendor
          irrigationType
          waterSource
          powerSource
          acreage
          dripStats {
            emitterFlow
            emitterSpacing
            dripDistance
          }
        }
      }
    }
  }
`;
export type UpdateIrrigationMetadataMutationFn = ApolloReactCommon.MutationFunction<
  UpdateIrrigationMetadataMutation,
  UpdateIrrigationMetadataMutationVariables
>;
export type UpdateIrrigationMetadataComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateIrrigationMetadataMutation,
    UpdateIrrigationMetadataMutationVariables
  >,
  'mutation'
>;

export const UpdateIrrigationMetadataComponent = (
  props: UpdateIrrigationMetadataComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateIrrigationMetadataMutation,
    UpdateIrrigationMetadataMutationVariables
  >
    mutation={UpdateIrrigationMetadataDocument}
    {...props}
  />
);

/**
 * __useUpdateIrrigationMetadataMutation__
 *
 * To run a mutation, you first call `useUpdateIrrigationMetadataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIrrigationMetadataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIrrigationMetadataMutation, { data, loading, error }] = useUpdateIrrigationMetadataMutation({
 *   variables: {
 *      fieldId: // value for 'fieldId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateIrrigationMetadataMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateIrrigationMetadataMutation,
    UpdateIrrigationMetadataMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateIrrigationMetadataMutation,
    UpdateIrrigationMetadataMutationVariables
  >(UpdateIrrigationMetadataDocument, baseOptions);
}
export type UpdateIrrigationMetadataMutationHookResult = ReturnType<
  typeof useUpdateIrrigationMetadataMutation
>;
export type UpdateIrrigationMetadataMutationResult = ApolloReactCommon.MutationResult<
  UpdateIrrigationMetadataMutation
>;
export type UpdateIrrigationMetadataMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateIrrigationMetadataMutation,
  UpdateIrrigationMetadataMutationVariables
>;
export const DeleteIrrigationDocument = gql`
  mutation DeleteIrrigation($input: IrrigationEventDeleteInput!) {
    irrigationEventDelete(input: $input) {
      success
    }
  }
`;
export type DeleteIrrigationMutationFn = ApolloReactCommon.MutationFunction<
  DeleteIrrigationMutation,
  DeleteIrrigationMutationVariables
>;
export type DeleteIrrigationComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteIrrigationMutation,
    DeleteIrrigationMutationVariables
  >,
  'mutation'
>;

export const DeleteIrrigationComponent = (
  props: DeleteIrrigationComponentProps
) => (
  <ApolloReactComponents.Mutation<
    DeleteIrrigationMutation,
    DeleteIrrigationMutationVariables
  >
    mutation={DeleteIrrigationDocument}
    {...props}
  />
);

/**
 * __useDeleteIrrigationMutation__
 *
 * To run a mutation, you first call `useDeleteIrrigationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteIrrigationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteIrrigationMutation, { data, loading, error }] = useDeleteIrrigationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteIrrigationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteIrrigationMutation,
    DeleteIrrigationMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteIrrigationMutation,
    DeleteIrrigationMutationVariables
  >(DeleteIrrigationDocument, baseOptions);
}
export type DeleteIrrigationMutationHookResult = ReturnType<
  typeof useDeleteIrrigationMutation
>;
export type DeleteIrrigationMutationResult = ApolloReactCommon.MutationResult<
  DeleteIrrigationMutation
>;
export type DeleteIrrigationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteIrrigationMutation,
  DeleteIrrigationMutationVariables
>;
export const UpdateIrrigationDocument = gql`
  mutation UpdateIrrigation($input: IrrigationEventUpdateInput!) {
    irrigationEventUpdate(input: $input) {
      irrigationEvent {
        id
        amount
        date
        note
        source
      }
    }
  }
`;
export type UpdateIrrigationMutationFn = ApolloReactCommon.MutationFunction<
  UpdateIrrigationMutation,
  UpdateIrrigationMutationVariables
>;
export type UpdateIrrigationComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateIrrigationMutation,
    UpdateIrrigationMutationVariables
  >,
  'mutation'
>;

export const UpdateIrrigationComponent = (
  props: UpdateIrrigationComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateIrrigationMutation,
    UpdateIrrigationMutationVariables
  >
    mutation={UpdateIrrigationDocument}
    {...props}
  />
);

/**
 * __useUpdateIrrigationMutation__
 *
 * To run a mutation, you first call `useUpdateIrrigationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIrrigationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIrrigationMutation, { data, loading, error }] = useUpdateIrrigationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateIrrigationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateIrrigationMutation,
    UpdateIrrigationMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateIrrigationMutation,
    UpdateIrrigationMutationVariables
  >(UpdateIrrigationDocument, baseOptions);
}
export type UpdateIrrigationMutationHookResult = ReturnType<
  typeof useUpdateIrrigationMutation
>;
export type UpdateIrrigationMutationResult = ApolloReactCommon.MutationResult<
  UpdateIrrigationMutation
>;
export type UpdateIrrigationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateIrrigationMutation,
  UpdateIrrigationMutationVariables
>;
export const FieldsDocument = gql`
  query Fields {
    me {
      id
      fields {
        id
        ...FieldCardField
      }
    }
  }
  ${FieldCardFieldFragmentDoc}
`;
export type FieldsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    FieldsQuery,
    FieldsQueryVariables
  >,
  'query'
>;

export const FieldsComponent = (props: FieldsComponentProps) => (
  <ApolloReactComponents.Query<FieldsQuery, FieldsQueryVariables>
    query={FieldsDocument}
    {...props}
  />
);

/**
 * __useFieldsQuery__
 *
 * To run a query within a React component, call `useFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFieldsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFieldsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FieldsQuery,
    FieldsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<FieldsQuery, FieldsQueryVariables>(
    FieldsDocument,
    baseOptions
  );
}
export function useFieldsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FieldsQuery,
    FieldsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<FieldsQuery, FieldsQueryVariables>(
    FieldsDocument,
    baseOptions
  );
}
export type FieldsQueryHookResult = ReturnType<typeof useFieldsQuery>;
export type FieldsLazyQueryHookResult = ReturnType<typeof useFieldsLazyQuery>;
export type FieldsQueryResult = ApolloReactCommon.QueryResult<
  FieldsQuery,
  FieldsQueryVariables
>;
export const GatewayDocument = gql`
  query Gateway($id: String!) {
    gateway(id: $id) {
      id
      name
      active
    }
  }
`;
export type GatewayComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GatewayQuery,
    GatewayQueryVariables
  >,
  'query'
> &
  ({ variables: GatewayQueryVariables; skip?: boolean } | { skip: boolean });

export const GatewayComponent = (props: GatewayComponentProps) => (
  <ApolloReactComponents.Query<GatewayQuery, GatewayQueryVariables>
    query={GatewayDocument}
    {...props}
  />
);

/**
 * __useGatewayQuery__
 *
 * To run a query within a React component, call `useGatewayQuery` and pass it any options that fit your needs.
 * When your component renders, `useGatewayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGatewayQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGatewayQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GatewayQuery,
    GatewayQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GatewayQuery, GatewayQueryVariables>(
    GatewayDocument,
    baseOptions
  );
}
export function useGatewayLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GatewayQuery,
    GatewayQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GatewayQuery, GatewayQueryVariables>(
    GatewayDocument,
    baseOptions
  );
}
export type GatewayQueryHookResult = ReturnType<typeof useGatewayQuery>;
export type GatewayLazyQueryHookResult = ReturnType<typeof useGatewayLazyQuery>;
export type GatewayQueryResult = ApolloReactCommon.QueryResult<
  GatewayQuery,
  GatewayQueryVariables
>;
export const ActivateGatewayDocument = gql`
  mutation ActivateGateway($id: String!) {
    updateGateway(input: { id: $id, active: true }) {
      gateway {
        id
        active
      }
    }
  }
`;
export type ActivateGatewayMutationFn = ApolloReactCommon.MutationFunction<
  ActivateGatewayMutation,
  ActivateGatewayMutationVariables
>;
export type ActivateGatewayComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ActivateGatewayMutation,
    ActivateGatewayMutationVariables
  >,
  'mutation'
>;

export const ActivateGatewayComponent = (
  props: ActivateGatewayComponentProps
) => (
  <ApolloReactComponents.Mutation<
    ActivateGatewayMutation,
    ActivateGatewayMutationVariables
  >
    mutation={ActivateGatewayDocument}
    {...props}
  />
);

/**
 * __useActivateGatewayMutation__
 *
 * To run a mutation, you first call `useActivateGatewayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateGatewayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateGatewayMutation, { data, loading, error }] = useActivateGatewayMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useActivateGatewayMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ActivateGatewayMutation,
    ActivateGatewayMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ActivateGatewayMutation,
    ActivateGatewayMutationVariables
  >(ActivateGatewayDocument, baseOptions);
}
export type ActivateGatewayMutationHookResult = ReturnType<
  typeof useActivateGatewayMutation
>;
export type ActivateGatewayMutationResult = ApolloReactCommon.MutationResult<
  ActivateGatewayMutation
>;
export type ActivateGatewayMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ActivateGatewayMutation,
  ActivateGatewayMutationVariables
>;
export const DevicesDocument = gql`
  query Devices {
    me {
      id
      sensors {
        id
        active
        ...DevicesTableSensor
        ...DevicesMapSensor
      }
      gateways {
        id
        active
        ...DevicesTableGateway
        ...DevicesMapGateway
      }
    }
  }
  ${DevicesTableSensorFragmentDoc}
  ${DevicesMapSensorFragmentDoc}
  ${DevicesTableGatewayFragmentDoc}
  ${DevicesMapGatewayFragmentDoc}
`;
export type DevicesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    DevicesQuery,
    DevicesQueryVariables
  >,
  'query'
>;

export const DevicesComponent = (props: DevicesComponentProps) => (
  <ApolloReactComponents.Query<DevicesQuery, DevicesQueryVariables>
    query={DevicesDocument}
    {...props}
  />
);

/**
 * __useDevicesQuery__
 *
 * To run a query within a React component, call `useDevicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDevicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDevicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useDevicesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    DevicesQuery,
    DevicesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<DevicesQuery, DevicesQueryVariables>(
    DevicesDocument,
    baseOptions
  );
}
export function useDevicesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    DevicesQuery,
    DevicesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<DevicesQuery, DevicesQueryVariables>(
    DevicesDocument,
    baseOptions
  );
}
export type DevicesQueryHookResult = ReturnType<typeof useDevicesQuery>;
export type DevicesLazyQueryHookResult = ReturnType<typeof useDevicesLazyQuery>;
export type DevicesQueryResult = ApolloReactCommon.QueryResult<
  DevicesQuery,
  DevicesQueryVariables
>;
export const IrrigationSystemDocument = gql`
  query IrrigationSystem($id: String!) {
    irrigationSystem(id: $id) {
      id
      name
      sensors {
        id
        data {
          __typename
          ... on PressureSensorData {
            currentPressure {
              __typename
              ... on CurrentPressure {
                scheduleResult {
                  __typename
                  ... on PressureOffSchedule {
                    offScheduleTimes {
                      time
                      shouldBeOn
                      type
                    }
                  }
                }
              }
              ... on SensorOffline {
                message
              }
            }
          }
        }
        ...DevicesMapPressureSensor
        ...IrrigationSystemRow
      }
      ...IrrigationSystemChartIrrigationSystem
    }
  }
  ${DevicesMapPressureSensorFragmentDoc}
  ${IrrigationSystemRowFragmentDoc}
  ${IrrigationSystemChartIrrigationSystemFragmentDoc}
`;
export type IrrigationSystemComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    IrrigationSystemQuery,
    IrrigationSystemQueryVariables
  >,
  'query'
> &
  (
    | { variables: IrrigationSystemQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const IrrigationSystemComponent = (
  props: IrrigationSystemComponentProps
) => (
  <ApolloReactComponents.Query<
    IrrigationSystemQuery,
    IrrigationSystemQueryVariables
  >
    query={IrrigationSystemDocument}
    {...props}
  />
);

/**
 * __useIrrigationSystemQuery__
 *
 * To run a query within a React component, call `useIrrigationSystemQuery` and pass it any options that fit your needs.
 * When your component renders, `useIrrigationSystemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIrrigationSystemQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useIrrigationSystemQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    IrrigationSystemQuery,
    IrrigationSystemQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    IrrigationSystemQuery,
    IrrigationSystemQueryVariables
  >(IrrigationSystemDocument, baseOptions);
}
export function useIrrigationSystemLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IrrigationSystemQuery,
    IrrigationSystemQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    IrrigationSystemQuery,
    IrrigationSystemQueryVariables
  >(IrrigationSystemDocument, baseOptions);
}
export type IrrigationSystemQueryHookResult = ReturnType<
  typeof useIrrigationSystemQuery
>;
export type IrrigationSystemLazyQueryHookResult = ReturnType<
  typeof useIrrigationSystemLazyQuery
>;
export type IrrigationSystemQueryResult = ApolloReactCommon.QueryResult<
  IrrigationSystemQuery,
  IrrigationSystemQueryVariables
>;
export const DeleteIrrigationSystemDocument = gql`
  mutation DeleteIrrigationSystem($id: String!) {
    irrigationSystemDelete(id: $id) {
      success
    }
  }
`;
export type DeleteIrrigationSystemMutationFn = ApolloReactCommon.MutationFunction<
  DeleteIrrigationSystemMutation,
  DeleteIrrigationSystemMutationVariables
>;
export type DeleteIrrigationSystemComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteIrrigationSystemMutation,
    DeleteIrrigationSystemMutationVariables
  >,
  'mutation'
>;

export const DeleteIrrigationSystemComponent = (
  props: DeleteIrrigationSystemComponentProps
) => (
  <ApolloReactComponents.Mutation<
    DeleteIrrigationSystemMutation,
    DeleteIrrigationSystemMutationVariables
  >
    mutation={DeleteIrrigationSystemDocument}
    {...props}
  />
);

/**
 * __useDeleteIrrigationSystemMutation__
 *
 * To run a mutation, you first call `useDeleteIrrigationSystemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteIrrigationSystemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteIrrigationSystemMutation, { data, loading, error }] = useDeleteIrrigationSystemMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteIrrigationSystemMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteIrrigationSystemMutation,
    DeleteIrrigationSystemMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteIrrigationSystemMutation,
    DeleteIrrigationSystemMutationVariables
  >(DeleteIrrigationSystemDocument, baseOptions);
}
export type DeleteIrrigationSystemMutationHookResult = ReturnType<
  typeof useDeleteIrrigationSystemMutation
>;
export type DeleteIrrigationSystemMutationResult = ApolloReactCommon.MutationResult<
  DeleteIrrigationSystemMutation
>;
export type DeleteIrrigationSystemMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteIrrigationSystemMutation,
  DeleteIrrigationSystemMutationVariables
>;
export const ArchiveIrrigationSystemDocument = gql`
  mutation ArchiveIrrigationSystem($input: ArchiveIrrigationSystemInput!) {
    archiveIrrigationSystem(input: $input) {
      success
    }
  }
`;
export type ArchiveIrrigationSystemMutationFn = ApolloReactCommon.MutationFunction<
  ArchiveIrrigationSystemMutation,
  ArchiveIrrigationSystemMutationVariables
>;
export type ArchiveIrrigationSystemComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ArchiveIrrigationSystemMutation,
    ArchiveIrrigationSystemMutationVariables
  >,
  'mutation'
>;

export const ArchiveIrrigationSystemComponent = (
  props: ArchiveIrrigationSystemComponentProps
) => (
  <ApolloReactComponents.Mutation<
    ArchiveIrrigationSystemMutation,
    ArchiveIrrigationSystemMutationVariables
  >
    mutation={ArchiveIrrigationSystemDocument}
    {...props}
  />
);

/**
 * __useArchiveIrrigationSystemMutation__
 *
 * To run a mutation, you first call `useArchiveIrrigationSystemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveIrrigationSystemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveIrrigationSystemMutation, { data, loading, error }] = useArchiveIrrigationSystemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArchiveIrrigationSystemMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ArchiveIrrigationSystemMutation,
    ArchiveIrrigationSystemMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ArchiveIrrigationSystemMutation,
    ArchiveIrrigationSystemMutationVariables
  >(ArchiveIrrigationSystemDocument, baseOptions);
}
export type ArchiveIrrigationSystemMutationHookResult = ReturnType<
  typeof useArchiveIrrigationSystemMutation
>;
export type ArchiveIrrigationSystemMutationResult = ApolloReactCommon.MutationResult<
  ArchiveIrrigationSystemMutation
>;
export type ArchiveIrrigationSystemMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ArchiveIrrigationSystemMutation,
  ArchiveIrrigationSystemMutationVariables
>;
export const IrrigationSystemFormAddDocument = gql`
  query IrrigationSystemFormAdd {
    me {
      id
      ...AvailableSensors
    }
  }
  ${AvailableSensorsFragmentDoc}
`;
export type IrrigationSystemFormAddComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    IrrigationSystemFormAddQuery,
    IrrigationSystemFormAddQueryVariables
  >,
  'query'
>;

export const IrrigationSystemFormAddComponent = (
  props: IrrigationSystemFormAddComponentProps
) => (
  <ApolloReactComponents.Query<
    IrrigationSystemFormAddQuery,
    IrrigationSystemFormAddQueryVariables
  >
    query={IrrigationSystemFormAddDocument}
    {...props}
  />
);

/**
 * __useIrrigationSystemFormAddQuery__
 *
 * To run a query within a React component, call `useIrrigationSystemFormAddQuery` and pass it any options that fit your needs.
 * When your component renders, `useIrrigationSystemFormAddQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIrrigationSystemFormAddQuery({
 *   variables: {
 *   },
 * });
 */
export function useIrrigationSystemFormAddQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    IrrigationSystemFormAddQuery,
    IrrigationSystemFormAddQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    IrrigationSystemFormAddQuery,
    IrrigationSystemFormAddQueryVariables
  >(IrrigationSystemFormAddDocument, baseOptions);
}
export function useIrrigationSystemFormAddLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IrrigationSystemFormAddQuery,
    IrrigationSystemFormAddQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    IrrigationSystemFormAddQuery,
    IrrigationSystemFormAddQueryVariables
  >(IrrigationSystemFormAddDocument, baseOptions);
}
export type IrrigationSystemFormAddQueryHookResult = ReturnType<
  typeof useIrrigationSystemFormAddQuery
>;
export type IrrigationSystemFormAddLazyQueryHookResult = ReturnType<
  typeof useIrrigationSystemFormAddLazyQuery
>;
export type IrrigationSystemFormAddQueryResult = ApolloReactCommon.QueryResult<
  IrrigationSystemFormAddQuery,
  IrrigationSystemFormAddQueryVariables
>;
export const IrrigationSystemFormUpdateDocument = gql`
  query IrrigationSystemFormUpdate($id: String!) {
    irrigationSystem(id: $id) {
      id
      name
      sensors {
        id
        ...IrrigationSystemEditPressureSensor
      }
    }
    me {
      id
      ...AvailableSensors
    }
  }
  ${IrrigationSystemEditPressureSensorFragmentDoc}
  ${AvailableSensorsFragmentDoc}
`;
export type IrrigationSystemFormUpdateComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    IrrigationSystemFormUpdateQuery,
    IrrigationSystemFormUpdateQueryVariables
  >,
  'query'
> &
  (
    | { variables: IrrigationSystemFormUpdateQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const IrrigationSystemFormUpdateComponent = (
  props: IrrigationSystemFormUpdateComponentProps
) => (
  <ApolloReactComponents.Query<
    IrrigationSystemFormUpdateQuery,
    IrrigationSystemFormUpdateQueryVariables
  >
    query={IrrigationSystemFormUpdateDocument}
    {...props}
  />
);

/**
 * __useIrrigationSystemFormUpdateQuery__
 *
 * To run a query within a React component, call `useIrrigationSystemFormUpdateQuery` and pass it any options that fit your needs.
 * When your component renders, `useIrrigationSystemFormUpdateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIrrigationSystemFormUpdateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useIrrigationSystemFormUpdateQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    IrrigationSystemFormUpdateQuery,
    IrrigationSystemFormUpdateQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    IrrigationSystemFormUpdateQuery,
    IrrigationSystemFormUpdateQueryVariables
  >(IrrigationSystemFormUpdateDocument, baseOptions);
}
export function useIrrigationSystemFormUpdateLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IrrigationSystemFormUpdateQuery,
    IrrigationSystemFormUpdateQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    IrrigationSystemFormUpdateQuery,
    IrrigationSystemFormUpdateQueryVariables
  >(IrrigationSystemFormUpdateDocument, baseOptions);
}
export type IrrigationSystemFormUpdateQueryHookResult = ReturnType<
  typeof useIrrigationSystemFormUpdateQuery
>;
export type IrrigationSystemFormUpdateLazyQueryHookResult = ReturnType<
  typeof useIrrigationSystemFormUpdateLazyQuery
>;
export type IrrigationSystemFormUpdateQueryResult = ApolloReactCommon.QueryResult<
  IrrigationSystemFormUpdateQuery,
  IrrigationSystemFormUpdateQueryVariables
>;
export const SystemBoundaryInfoDocument = gql`
  query SystemBoundaryInfo($input: FieldBoundaryInfoInput!) {
    fieldBoundaryInfo(input: $input) {
      sensorsInBounds {
        id
      }
    }
  }
`;
export type SystemBoundaryInfoComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    SystemBoundaryInfoQuery,
    SystemBoundaryInfoQueryVariables
  >,
  'query'
> &
  (
    | { variables: SystemBoundaryInfoQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const SystemBoundaryInfoComponent = (
  props: SystemBoundaryInfoComponentProps
) => (
  <ApolloReactComponents.Query<
    SystemBoundaryInfoQuery,
    SystemBoundaryInfoQueryVariables
  >
    query={SystemBoundaryInfoDocument}
    {...props}
  />
);

/**
 * __useSystemBoundaryInfoQuery__
 *
 * To run a query within a React component, call `useSystemBoundaryInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useSystemBoundaryInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSystemBoundaryInfoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSystemBoundaryInfoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    SystemBoundaryInfoQuery,
    SystemBoundaryInfoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    SystemBoundaryInfoQuery,
    SystemBoundaryInfoQueryVariables
  >(SystemBoundaryInfoDocument, baseOptions);
}
export function useSystemBoundaryInfoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SystemBoundaryInfoQuery,
    SystemBoundaryInfoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    SystemBoundaryInfoQuery,
    SystemBoundaryInfoQueryVariables
  >(SystemBoundaryInfoDocument, baseOptions);
}
export type SystemBoundaryInfoQueryHookResult = ReturnType<
  typeof useSystemBoundaryInfoQuery
>;
export type SystemBoundaryInfoLazyQueryHookResult = ReturnType<
  typeof useSystemBoundaryInfoLazyQuery
>;
export type SystemBoundaryInfoQueryResult = ApolloReactCommon.QueryResult<
  SystemBoundaryInfoQuery,
  SystemBoundaryInfoQueryVariables
>;
export const CreateIrrigationSystemDocument = gql`
  mutation CreateIrrigationSystem($input: IrrigationSystemAddInput!) {
    irrigationSystemAdd(input: $input) {
      id
    }
  }
`;
export type CreateIrrigationSystemMutationFn = ApolloReactCommon.MutationFunction<
  CreateIrrigationSystemMutation,
  CreateIrrigationSystemMutationVariables
>;
export type CreateIrrigationSystemComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateIrrigationSystemMutation,
    CreateIrrigationSystemMutationVariables
  >,
  'mutation'
>;

export const CreateIrrigationSystemComponent = (
  props: CreateIrrigationSystemComponentProps
) => (
  <ApolloReactComponents.Mutation<
    CreateIrrigationSystemMutation,
    CreateIrrigationSystemMutationVariables
  >
    mutation={CreateIrrigationSystemDocument}
    {...props}
  />
);

/**
 * __useCreateIrrigationSystemMutation__
 *
 * To run a mutation, you first call `useCreateIrrigationSystemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIrrigationSystemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIrrigationSystemMutation, { data, loading, error }] = useCreateIrrigationSystemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateIrrigationSystemMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateIrrigationSystemMutation,
    CreateIrrigationSystemMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateIrrigationSystemMutation,
    CreateIrrigationSystemMutationVariables
  >(CreateIrrigationSystemDocument, baseOptions);
}
export type CreateIrrigationSystemMutationHookResult = ReturnType<
  typeof useCreateIrrigationSystemMutation
>;
export type CreateIrrigationSystemMutationResult = ApolloReactCommon.MutationResult<
  CreateIrrigationSystemMutation
>;
export type CreateIrrigationSystemMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateIrrigationSystemMutation,
  CreateIrrigationSystemMutationVariables
>;
export const UpdateIrrigationSystemDocument = gql`
  mutation UpdateIrrigationSystem(
    $id: String!
    $input: IrrigationSystemUpdateInput!
  ) {
    irrigationSystemUpdate(id: $id, input: $input) {
      id
      name
      sensors {
        id
        name
        plan {
          expectedPressure
          stages {
            startDate
            onTimes {
              start
              end
            }
          }
        }
      }
    }
  }
`;
export type UpdateIrrigationSystemMutationFn = ApolloReactCommon.MutationFunction<
  UpdateIrrigationSystemMutation,
  UpdateIrrigationSystemMutationVariables
>;
export type UpdateIrrigationSystemComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateIrrigationSystemMutation,
    UpdateIrrigationSystemMutationVariables
  >,
  'mutation'
>;

export const UpdateIrrigationSystemComponent = (
  props: UpdateIrrigationSystemComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateIrrigationSystemMutation,
    UpdateIrrigationSystemMutationVariables
  >
    mutation={UpdateIrrigationSystemDocument}
    {...props}
  />
);

/**
 * __useUpdateIrrigationSystemMutation__
 *
 * To run a mutation, you first call `useUpdateIrrigationSystemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIrrigationSystemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIrrigationSystemMutation, { data, loading, error }] = useUpdateIrrigationSystemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateIrrigationSystemMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateIrrigationSystemMutation,
    UpdateIrrigationSystemMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateIrrigationSystemMutation,
    UpdateIrrigationSystemMutationVariables
  >(UpdateIrrigationSystemDocument, baseOptions);
}
export type UpdateIrrigationSystemMutationHookResult = ReturnType<
  typeof useUpdateIrrigationSystemMutation
>;
export type UpdateIrrigationSystemMutationResult = ApolloReactCommon.MutationResult<
  UpdateIrrigationSystemMutation
>;
export type UpdateIrrigationSystemMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateIrrigationSystemMutation,
  UpdateIrrigationSystemMutationVariables
>;
export const UpdatePressureSensorNameDocument = gql`
  mutation UpdatePressureSensorName($id: String!, $name: String!) {
    pressureSensorUpdate(id: $id, input: { name: $name }) {
      id
      name
    }
  }
`;
export type UpdatePressureSensorNameMutationFn = ApolloReactCommon.MutationFunction<
  UpdatePressureSensorNameMutation,
  UpdatePressureSensorNameMutationVariables
>;
export type UpdatePressureSensorNameComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdatePressureSensorNameMutation,
    UpdatePressureSensorNameMutationVariables
  >,
  'mutation'
>;

export const UpdatePressureSensorNameComponent = (
  props: UpdatePressureSensorNameComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdatePressureSensorNameMutation,
    UpdatePressureSensorNameMutationVariables
  >
    mutation={UpdatePressureSensorNameDocument}
    {...props}
  />
);

/**
 * __useUpdatePressureSensorNameMutation__
 *
 * To run a mutation, you first call `useUpdatePressureSensorNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePressureSensorNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePressureSensorNameMutation, { data, loading, error }] = useUpdatePressureSensorNameMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdatePressureSensorNameMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdatePressureSensorNameMutation,
    UpdatePressureSensorNameMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdatePressureSensorNameMutation,
    UpdatePressureSensorNameMutationVariables
  >(UpdatePressureSensorNameDocument, baseOptions);
}
export type UpdatePressureSensorNameMutationHookResult = ReturnType<
  typeof useUpdatePressureSensorNameMutation
>;
export type UpdatePressureSensorNameMutationResult = ApolloReactCommon.MutationResult<
  UpdatePressureSensorNameMutation
>;
export type UpdatePressureSensorNameMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdatePressureSensorNameMutation,
  UpdatePressureSensorNameMutationVariables
>;
export const IrrigationSystemsDocument = gql`
  query IrrigationSystems {
    me {
      id
      irrigationSystems {
        id
        ...IrrigationSystemCard
      }
    }
  }
  ${IrrigationSystemCardFragmentDoc}
`;
export type IrrigationSystemsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    IrrigationSystemsQuery,
    IrrigationSystemsQueryVariables
  >,
  'query'
>;

export const IrrigationSystemsComponent = (
  props: IrrigationSystemsComponentProps
) => (
  <ApolloReactComponents.Query<
    IrrigationSystemsQuery,
    IrrigationSystemsQueryVariables
  >
    query={IrrigationSystemsDocument}
    {...props}
  />
);

/**
 * __useIrrigationSystemsQuery__
 *
 * To run a query within a React component, call `useIrrigationSystemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIrrigationSystemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIrrigationSystemsQuery({
 *   variables: {
 *   },
 * });
 */
export function useIrrigationSystemsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    IrrigationSystemsQuery,
    IrrigationSystemsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    IrrigationSystemsQuery,
    IrrigationSystemsQueryVariables
  >(IrrigationSystemsDocument, baseOptions);
}
export function useIrrigationSystemsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IrrigationSystemsQuery,
    IrrigationSystemsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    IrrigationSystemsQuery,
    IrrigationSystemsQueryVariables
  >(IrrigationSystemsDocument, baseOptions);
}
export type IrrigationSystemsQueryHookResult = ReturnType<
  typeof useIrrigationSystemsQuery
>;
export type IrrigationSystemsLazyQueryHookResult = ReturnType<
  typeof useIrrigationSystemsLazyQuery
>;
export type IrrigationSystemsQueryResult = ApolloReactCommon.QueryResult<
  IrrigationSystemsQuery,
  IrrigationSystemsQueryVariables
>;
export const GetIssuesDocument = gql`
  query GetIssues {
    me {
      id
      sensors {
        id
        name
        plant_date
        soil_type {
          id
        }
        crop_type {
          id
        }
        field {
          id
        }
      }
    }
  }
`;
export type GetIssuesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetIssuesQuery,
    GetIssuesQueryVariables
  >,
  'query'
>;

export const GetIssuesComponent = (props: GetIssuesComponentProps) => (
  <ApolloReactComponents.Query<GetIssuesQuery, GetIssuesQueryVariables>
    query={GetIssuesDocument}
    {...props}
  />
);

/**
 * __useGetIssuesQuery__
 *
 * To run a query within a React component, call `useGetIssuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIssuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIssuesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetIssuesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetIssuesQuery,
    GetIssuesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetIssuesQuery, GetIssuesQueryVariables>(
    GetIssuesDocument,
    baseOptions
  );
}
export function useGetIssuesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetIssuesQuery,
    GetIssuesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetIssuesQuery, GetIssuesQueryVariables>(
    GetIssuesDocument,
    baseOptions
  );
}
export type GetIssuesQueryHookResult = ReturnType<typeof useGetIssuesQuery>;
export type GetIssuesLazyQueryHookResult = ReturnType<
  typeof useGetIssuesLazyQuery
>;
export type GetIssuesQueryResult = ApolloReactCommon.QueryResult<
  GetIssuesQuery,
  GetIssuesQueryVariables
>;
export const NotificationsDocument = gql`
  query Notifications {
    me {
      id
      hasAccountIssues
      notifications {
        id
        ...NotificationListNotification
      }
    }
  }
  ${NotificationListNotificationFragmentDoc}
`;
export type NotificationsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    NotificationsQuery,
    NotificationsQueryVariables
  >,
  'query'
>;

export const NotificationsComponent = (props: NotificationsComponentProps) => (
  <ApolloReactComponents.Query<NotificationsQuery, NotificationsQueryVariables>
    query={NotificationsDocument}
    {...props}
  />
);

/**
 * __useNotificationsQuery__
 *
 * To run a query within a React component, call `useNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useNotificationsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    NotificationsQuery,
    NotificationsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    NotificationsQuery,
    NotificationsQueryVariables
  >(NotificationsDocument, baseOptions);
}
export function useNotificationsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    NotificationsQuery,
    NotificationsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    NotificationsQuery,
    NotificationsQueryVariables
  >(NotificationsDocument, baseOptions);
}
export type NotificationsQueryHookResult = ReturnType<
  typeof useNotificationsQuery
>;
export type NotificationsLazyQueryHookResult = ReturnType<
  typeof useNotificationsLazyQuery
>;
export type NotificationsQueryResult = ApolloReactCommon.QueryResult<
  NotificationsQuery,
  NotificationsQueryVariables
>;
export const MarkAsReadDocument = gql`
  mutation MarkAsRead($input: NotificationMarkAsReadInput) {
    notificationMarkAsRead(input: $input) {
      success
      notifications {
        id
        read
      }
    }
  }
`;
export type MarkAsReadMutationFn = ApolloReactCommon.MutationFunction<
  MarkAsReadMutation,
  MarkAsReadMutationVariables
>;
export type MarkAsReadComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    MarkAsReadMutation,
    MarkAsReadMutationVariables
  >,
  'mutation'
>;

export const MarkAsReadComponent = (props: MarkAsReadComponentProps) => (
  <ApolloReactComponents.Mutation<
    MarkAsReadMutation,
    MarkAsReadMutationVariables
  >
    mutation={MarkAsReadDocument}
    {...props}
  />
);

/**
 * __useMarkAsReadMutation__
 *
 * To run a mutation, you first call `useMarkAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAsReadMutation, { data, loading, error }] = useMarkAsReadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkAsReadMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    MarkAsReadMutation,
    MarkAsReadMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    MarkAsReadMutation,
    MarkAsReadMutationVariables
  >(MarkAsReadDocument, baseOptions);
}
export type MarkAsReadMutationHookResult = ReturnType<
  typeof useMarkAsReadMutation
>;
export type MarkAsReadMutationResult = ApolloReactCommon.MutationResult<
  MarkAsReadMutation
>;
export type MarkAsReadMutationOptions = ApolloReactCommon.BaseMutationOptions<
  MarkAsReadMutation,
  MarkAsReadMutationVariables
>;
export const OverlayDocument = gql`
  query Overlay(
    $sensorId: String!
    $fieldId: String!
    $archivedSensorId: String!
    $fetchSensor: Boolean!
    $fetchArchivedSensor: Boolean!
    $fetchField: Boolean!
  ) {
    me {
      id
      name {
        first
        last
      }
      hasAccountIssues
      notifications {
        id
        read
        ...NotificationPopupNotification
      }
      ...SidebarUser
    }
    sensor(id: $sensorId) @include(if: $fetchSensor) {
      id
      name
    }
    archivedSensor(id: $archivedSensorId) @include(if: $fetchArchivedSensor) {
      id
      name
    }
    field(id: $fieldId) @include(if: $fetchField) {
      id
      name
    }
  }
  ${NotificationPopupNotificationFragmentDoc}
  ${SidebarUserFragmentDoc}
`;
export type OverlayComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    OverlayQuery,
    OverlayQueryVariables
  >,
  'query'
> &
  ({ variables: OverlayQueryVariables; skip?: boolean } | { skip: boolean });

export const OverlayComponent = (props: OverlayComponentProps) => (
  <ApolloReactComponents.Query<OverlayQuery, OverlayQueryVariables>
    query={OverlayDocument}
    {...props}
  />
);

/**
 * __useOverlayQuery__
 *
 * To run a query within a React component, call `useOverlayQuery` and pass it any options that fit your needs.
 * When your component renders, `useOverlayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOverlayQuery({
 *   variables: {
 *      sensorId: // value for 'sensorId'
 *      fieldId: // value for 'fieldId'
 *      archivedSensorId: // value for 'archivedSensorId'
 *      fetchSensor: // value for 'fetchSensor'
 *      fetchArchivedSensor: // value for 'fetchArchivedSensor'
 *      fetchField: // value for 'fetchField'
 *   },
 * });
 */
export function useOverlayQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    OverlayQuery,
    OverlayQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<OverlayQuery, OverlayQueryVariables>(
    OverlayDocument,
    baseOptions
  );
}
export function useOverlayLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OverlayQuery,
    OverlayQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<OverlayQuery, OverlayQueryVariables>(
    OverlayDocument,
    baseOptions
  );
}
export type OverlayQueryHookResult = ReturnType<typeof useOverlayQuery>;
export type OverlayLazyQueryHookResult = ReturnType<typeof useOverlayLazyQuery>;
export type OverlayQueryResult = ApolloReactCommon.QueryResult<
  OverlayQuery,
  OverlayQueryVariables
>;
export const PressureSensorDocument = gql`
  query PressureSensor($id: String!) {
    pressureSensor(id: $id) {
      id
      name
      permissionLevel
      ...DevicesMapPressureSensor
      ...PressureSensorChart
    }
  }
  ${DevicesMapPressureSensorFragmentDoc}
  ${PressureSensorChartFragmentDoc}
`;
export type PressureSensorComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    PressureSensorQuery,
    PressureSensorQueryVariables
  >,
  'query'
> &
  (
    | { variables: PressureSensorQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const PressureSensorComponent = (
  props: PressureSensorComponentProps
) => (
  <ApolloReactComponents.Query<
    PressureSensorQuery,
    PressureSensorQueryVariables
  >
    query={PressureSensorDocument}
    {...props}
  />
);

/**
 * __usePressureSensorQuery__
 *
 * To run a query within a React component, call `usePressureSensorQuery` and pass it any options that fit your needs.
 * When your component renders, `usePressureSensorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePressureSensorQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePressureSensorQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    PressureSensorQuery,
    PressureSensorQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    PressureSensorQuery,
    PressureSensorQueryVariables
  >(PressureSensorDocument, baseOptions);
}
export function usePressureSensorLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PressureSensorQuery,
    PressureSensorQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    PressureSensorQuery,
    PressureSensorQueryVariables
  >(PressureSensorDocument, baseOptions);
}
export type PressureSensorQueryHookResult = ReturnType<
  typeof usePressureSensorQuery
>;
export type PressureSensorLazyQueryHookResult = ReturnType<
  typeof usePressureSensorLazyQuery
>;
export type PressureSensorQueryResult = ApolloReactCommon.QueryResult<
  PressureSensorQuery,
  PressureSensorQueryVariables
>;
export const PressureSensorFormDocument = gql`
  query PressureSensorForm($id: String!) {
    pressureSensor(id: $id) {
      id
      ...PressureSensorFormFields
    }
  }
  ${PressureSensorFormFieldsFragmentDoc}
`;
export type PressureSensorFormComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    PressureSensorFormQuery,
    PressureSensorFormQueryVariables
  >,
  'query'
> &
  (
    | { variables: PressureSensorFormQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const PressureSensorFormComponent = (
  props: PressureSensorFormComponentProps
) => (
  <ApolloReactComponents.Query<
    PressureSensorFormQuery,
    PressureSensorFormQueryVariables
  >
    query={PressureSensorFormDocument}
    {...props}
  />
);

/**
 * __usePressureSensorFormQuery__
 *
 * To run a query within a React component, call `usePressureSensorFormQuery` and pass it any options that fit your needs.
 * When your component renders, `usePressureSensorFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePressureSensorFormQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePressureSensorFormQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    PressureSensorFormQuery,
    PressureSensorFormQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    PressureSensorFormQuery,
    PressureSensorFormQueryVariables
  >(PressureSensorFormDocument, baseOptions);
}
export function usePressureSensorFormLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PressureSensorFormQuery,
    PressureSensorFormQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    PressureSensorFormQuery,
    PressureSensorFormQueryVariables
  >(PressureSensorFormDocument, baseOptions);
}
export type PressureSensorFormQueryHookResult = ReturnType<
  typeof usePressureSensorFormQuery
>;
export type PressureSensorFormLazyQueryHookResult = ReturnType<
  typeof usePressureSensorFormLazyQuery
>;
export type PressureSensorFormQueryResult = ApolloReactCommon.QueryResult<
  PressureSensorFormQuery,
  PressureSensorFormQueryVariables
>;
export const UpdatePressureSensorDocument = gql`
  mutation UpdatePressureSensor(
    $id: String!
    $input: PressureSensorUpdateInput!
  ) {
    pressureSensorUpdate(id: $id, input: $input) {
      id
      ...PressureSensorFormFields
    }
  }
  ${PressureSensorFormFieldsFragmentDoc}
`;
export type UpdatePressureSensorMutationFn = ApolloReactCommon.MutationFunction<
  UpdatePressureSensorMutation,
  UpdatePressureSensorMutationVariables
>;
export type UpdatePressureSensorComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdatePressureSensorMutation,
    UpdatePressureSensorMutationVariables
  >,
  'mutation'
>;

export const UpdatePressureSensorComponent = (
  props: UpdatePressureSensorComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdatePressureSensorMutation,
    UpdatePressureSensorMutationVariables
  >
    mutation={UpdatePressureSensorDocument}
    {...props}
  />
);

/**
 * __useUpdatePressureSensorMutation__
 *
 * To run a mutation, you first call `useUpdatePressureSensorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePressureSensorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePressureSensorMutation, { data, loading, error }] = useUpdatePressureSensorMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePressureSensorMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdatePressureSensorMutation,
    UpdatePressureSensorMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdatePressureSensorMutation,
    UpdatePressureSensorMutationVariables
  >(UpdatePressureSensorDocument, baseOptions);
}
export type UpdatePressureSensorMutationHookResult = ReturnType<
  typeof useUpdatePressureSensorMutation
>;
export type UpdatePressureSensorMutationResult = ApolloReactCommon.MutationResult<
  UpdatePressureSensorMutation
>;
export type UpdatePressureSensorMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdatePressureSensorMutation,
  UpdatePressureSensorMutationVariables
>;
export const SensorDocument = gql`
  query Sensor($sensorId: String!, $tzoffset: Int) {
    sensor(id: $sensorId) {
      id
      active
      name
      permissionLevel
      crop_type {
        id
        name
      }
      ...SensorGraphs
      ...SensorHeaderData
    }
  }
  ${SensorGraphsFragmentDoc}
  ${SensorHeaderDataFragmentDoc}
`;
export type SensorComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    SensorQuery,
    SensorQueryVariables
  >,
  'query'
> &
  ({ variables: SensorQueryVariables; skip?: boolean } | { skip: boolean });

export const SensorComponent = (props: SensorComponentProps) => (
  <ApolloReactComponents.Query<SensorQuery, SensorQueryVariables>
    query={SensorDocument}
    {...props}
  />
);

/**
 * __useSensorQuery__
 *
 * To run a query within a React component, call `useSensorQuery` and pass it any options that fit your needs.
 * When your component renders, `useSensorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSensorQuery({
 *   variables: {
 *      sensorId: // value for 'sensorId'
 *      tzoffset: // value for 'tzoffset'
 *   },
 * });
 */
export function useSensorQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    SensorQuery,
    SensorQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<SensorQuery, SensorQueryVariables>(
    SensorDocument,
    baseOptions
  );
}
export function useSensorLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SensorQuery,
    SensorQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<SensorQuery, SensorQueryVariables>(
    SensorDocument,
    baseOptions
  );
}
export type SensorQueryHookResult = ReturnType<typeof useSensorQuery>;
export type SensorLazyQueryHookResult = ReturnType<typeof useSensorLazyQuery>;
export type SensorQueryResult = ApolloReactCommon.QueryResult<
  SensorQuery,
  SensorQueryVariables
>;
export const ArchiveSensorDocument = gql`
  mutation ArchiveSensor($input: ArchiveSensorInput!) {
    archiveSensor(input: $input) {
      success
    }
  }
`;
export type ArchiveSensorMutationFn = ApolloReactCommon.MutationFunction<
  ArchiveSensorMutation,
  ArchiveSensorMutationVariables
>;
export type ArchiveSensorComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ArchiveSensorMutation,
    ArchiveSensorMutationVariables
  >,
  'mutation'
>;

export const ArchiveSensorComponent = (props: ArchiveSensorComponentProps) => (
  <ApolloReactComponents.Mutation<
    ArchiveSensorMutation,
    ArchiveSensorMutationVariables
  >
    mutation={ArchiveSensorDocument}
    {...props}
  />
);

/**
 * __useArchiveSensorMutation__
 *
 * To run a mutation, you first call `useArchiveSensorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveSensorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveSensorMutation, { data, loading, error }] = useArchiveSensorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArchiveSensorMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ArchiveSensorMutation,
    ArchiveSensorMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ArchiveSensorMutation,
    ArchiveSensorMutationVariables
  >(ArchiveSensorDocument, baseOptions);
}
export type ArchiveSensorMutationHookResult = ReturnType<
  typeof useArchiveSensorMutation
>;
export type ArchiveSensorMutationResult = ApolloReactCommon.MutationResult<
  ArchiveSensorMutation
>;
export type ArchiveSensorMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ArchiveSensorMutation,
  ArchiveSensorMutationVariables
>;
export const ArchiveSensorInfoDocument = gql`
  query ArchiveSensorInfo($id: String!) {
    sensor(id: $id) {
      id
      ...ArchiveSensorStepSensor
    }
    crops {
      id
      name
    }
    soils {
      id
      name
    }
  }
  ${ArchiveSensorStepSensorFragmentDoc}
`;
export type ArchiveSensorInfoComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ArchiveSensorInfoQuery,
    ArchiveSensorInfoQueryVariables
  >,
  'query'
> &
  (
    | { variables: ArchiveSensorInfoQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const ArchiveSensorInfoComponent = (
  props: ArchiveSensorInfoComponentProps
) => (
  <ApolloReactComponents.Query<
    ArchiveSensorInfoQuery,
    ArchiveSensorInfoQueryVariables
  >
    query={ArchiveSensorInfoDocument}
    {...props}
  />
);

/**
 * __useArchiveSensorInfoQuery__
 *
 * To run a query within a React component, call `useArchiveSensorInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useArchiveSensorInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArchiveSensorInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArchiveSensorInfoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ArchiveSensorInfoQuery,
    ArchiveSensorInfoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ArchiveSensorInfoQuery,
    ArchiveSensorInfoQueryVariables
  >(ArchiveSensorInfoDocument, baseOptions);
}
export function useArchiveSensorInfoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ArchiveSensorInfoQuery,
    ArchiveSensorInfoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ArchiveSensorInfoQuery,
    ArchiveSensorInfoQueryVariables
  >(ArchiveSensorInfoDocument, baseOptions);
}
export type ArchiveSensorInfoQueryHookResult = ReturnType<
  typeof useArchiveSensorInfoQuery
>;
export type ArchiveSensorInfoLazyQueryHookResult = ReturnType<
  typeof useArchiveSensorInfoLazyQuery
>;
export type ArchiveSensorInfoQueryResult = ApolloReactCommon.QueryResult<
  ArchiveSensorInfoQuery,
  ArchiveSensorInfoQueryVariables
>;
export const ActivateSensorDocument = gql`
  mutation ActivateSensor($id: String!) {
    updateSensor(sensorId: $id, input: { active: true }) {
      sensor {
        id
        active
      }
    }
  }
`;
export type ActivateSensorMutationFn = ApolloReactCommon.MutationFunction<
  ActivateSensorMutation,
  ActivateSensorMutationVariables
>;
export type ActivateSensorComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ActivateSensorMutation,
    ActivateSensorMutationVariables
  >,
  'mutation'
>;

export const ActivateSensorComponent = (
  props: ActivateSensorComponentProps
) => (
  <ApolloReactComponents.Mutation<
    ActivateSensorMutation,
    ActivateSensorMutationVariables
  >
    mutation={ActivateSensorDocument}
    {...props}
  />
);

/**
 * __useActivateSensorMutation__
 *
 * To run a mutation, you first call `useActivateSensorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateSensorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateSensorMutation, { data, loading, error }] = useActivateSensorMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useActivateSensorMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ActivateSensorMutation,
    ActivateSensorMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ActivateSensorMutation,
    ActivateSensorMutationVariables
  >(ActivateSensorDocument, baseOptions);
}
export type ActivateSensorMutationHookResult = ReturnType<
  typeof useActivateSensorMutation
>;
export type ActivateSensorMutationResult = ApolloReactCommon.MutationResult<
  ActivateSensorMutation
>;
export type ActivateSensorMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ActivateSensorMutation,
  ActivateSensorMutationVariables
>;
export const SensorEditDocument = gql`
  query SensorEdit($id: String!) {
    sensor(id: $id) {
      id
      ...SensorEditFormSensor
    }
    crops {
      id
      ...SensorEditFormCrop
    }
    soils {
      id
      ...SensorEditFormSoil
    }
  }
  ${SensorEditFormSensorFragmentDoc}
  ${SensorEditFormCropFragmentDoc}
  ${SensorEditFormSoilFragmentDoc}
`;
export type SensorEditComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    SensorEditQuery,
    SensorEditQueryVariables
  >,
  'query'
> &
  ({ variables: SensorEditQueryVariables; skip?: boolean } | { skip: boolean });

export const SensorEditComponent = (props: SensorEditComponentProps) => (
  <ApolloReactComponents.Query<SensorEditQuery, SensorEditQueryVariables>
    query={SensorEditDocument}
    {...props}
  />
);

/**
 * __useSensorEditQuery__
 *
 * To run a query within a React component, call `useSensorEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useSensorEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSensorEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSensorEditQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    SensorEditQuery,
    SensorEditQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<SensorEditQuery, SensorEditQueryVariables>(
    SensorEditDocument,
    baseOptions
  );
}
export function useSensorEditLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SensorEditQuery,
    SensorEditQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    SensorEditQuery,
    SensorEditQueryVariables
  >(SensorEditDocument, baseOptions);
}
export type SensorEditQueryHookResult = ReturnType<typeof useSensorEditQuery>;
export type SensorEditLazyQueryHookResult = ReturnType<
  typeof useSensorEditLazyQuery
>;
export type SensorEditQueryResult = ApolloReactCommon.QueryResult<
  SensorEditQuery,
  SensorEditQueryVariables
>;
export const UpdateSensorDocument = gql`
  mutation UpdateSensor($sensorId: String!, $input: UpdateSensorInput!) {
    updateSensor(sensorId: $sensorId, input: $input) {
      sensor {
        id
        ...SensorEditFormSensor
      }
    }
  }
  ${SensorEditFormSensorFragmentDoc}
`;
export type UpdateSensorMutationFn = ApolloReactCommon.MutationFunction<
  UpdateSensorMutation,
  UpdateSensorMutationVariables
>;
export type UpdateSensorComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateSensorMutation,
    UpdateSensorMutationVariables
  >,
  'mutation'
>;

export const UpdateSensorComponent = (props: UpdateSensorComponentProps) => (
  <ApolloReactComponents.Mutation<
    UpdateSensorMutation,
    UpdateSensorMutationVariables
  >
    mutation={UpdateSensorDocument}
    {...props}
  />
);

/**
 * __useUpdateSensorMutation__
 *
 * To run a mutation, you first call `useUpdateSensorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSensorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSensorMutation, { data, loading, error }] = useUpdateSensorMutation({
 *   variables: {
 *      sensorId: // value for 'sensorId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSensorMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateSensorMutation,
    UpdateSensorMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateSensorMutation,
    UpdateSensorMutationVariables
  >(UpdateSensorDocument, baseOptions);
}
export type UpdateSensorMutationHookResult = ReturnType<
  typeof useUpdateSensorMutation
>;
export type UpdateSensorMutationResult = ApolloReactCommon.MutationResult<
  UpdateSensorMutation
>;
export type UpdateSensorMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateSensorMutation,
  UpdateSensorMutationVariables
>;
export const DeleteManagementPlanDocument = gql`
  mutation DeleteManagementPlan($planId: String!) {
    sensorManagementPlanDelete(planId: $planId) {
      success
    }
  }
`;
export type DeleteManagementPlanMutationFn = ApolloReactCommon.MutationFunction<
  DeleteManagementPlanMutation,
  DeleteManagementPlanMutationVariables
>;
export type DeleteManagementPlanComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteManagementPlanMutation,
    DeleteManagementPlanMutationVariables
  >,
  'mutation'
>;

export const DeleteManagementPlanComponent = (
  props: DeleteManagementPlanComponentProps
) => (
  <ApolloReactComponents.Mutation<
    DeleteManagementPlanMutation,
    DeleteManagementPlanMutationVariables
  >
    mutation={DeleteManagementPlanDocument}
    {...props}
  />
);

/**
 * __useDeleteManagementPlanMutation__
 *
 * To run a mutation, you first call `useDeleteManagementPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManagementPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManagementPlanMutation, { data, loading, error }] = useDeleteManagementPlanMutation({
 *   variables: {
 *      planId: // value for 'planId'
 *   },
 * });
 */
export function useDeleteManagementPlanMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteManagementPlanMutation,
    DeleteManagementPlanMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteManagementPlanMutation,
    DeleteManagementPlanMutationVariables
  >(DeleteManagementPlanDocument, baseOptions);
}
export type DeleteManagementPlanMutationHookResult = ReturnType<
  typeof useDeleteManagementPlanMutation
>;
export type DeleteManagementPlanMutationResult = ApolloReactCommon.MutationResult<
  DeleteManagementPlanMutation
>;
export type DeleteManagementPlanMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteManagementPlanMutation,
  DeleteManagementPlanMutationVariables
>;
export const CropDeleteDocument = gql`
  mutation CropDelete($input: CropDeleteInput!) {
    cropDelete(input: $input) {
      success
    }
  }
`;
export type CropDeleteMutationFn = ApolloReactCommon.MutationFunction<
  CropDeleteMutation,
  CropDeleteMutationVariables
>;
export type CropDeleteComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CropDeleteMutation,
    CropDeleteMutationVariables
  >,
  'mutation'
>;

export const CropDeleteComponent = (props: CropDeleteComponentProps) => (
  <ApolloReactComponents.Mutation<
    CropDeleteMutation,
    CropDeleteMutationVariables
  >
    mutation={CropDeleteDocument}
    {...props}
  />
);

/**
 * __useCropDeleteMutation__
 *
 * To run a mutation, you first call `useCropDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCropDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cropDeleteMutation, { data, loading, error }] = useCropDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCropDeleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CropDeleteMutation,
    CropDeleteMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CropDeleteMutation,
    CropDeleteMutationVariables
  >(CropDeleteDocument, baseOptions);
}
export type CropDeleteMutationHookResult = ReturnType<
  typeof useCropDeleteMutation
>;
export type CropDeleteMutationResult = ApolloReactCommon.MutationResult<
  CropDeleteMutation
>;
export type CropDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CropDeleteMutation,
  CropDeleteMutationVariables
>;
export const CropAddDocument = gql`
  mutation CropAdd($input: CropAddInput!) {
    cropAdd(input: $input) {
      crop {
        id
        name
        custom
      }
    }
  }
`;
export type CropAddMutationFn = ApolloReactCommon.MutationFunction<
  CropAddMutation,
  CropAddMutationVariables
>;
export type CropAddComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CropAddMutation,
    CropAddMutationVariables
  >,
  'mutation'
>;

export const CropAddComponent = (props: CropAddComponentProps) => (
  <ApolloReactComponents.Mutation<CropAddMutation, CropAddMutationVariables>
    mutation={CropAddDocument}
    {...props}
  />
);

/**
 * __useCropAddMutation__
 *
 * To run a mutation, you first call `useCropAddMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCropAddMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cropAddMutation, { data, loading, error }] = useCropAddMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCropAddMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CropAddMutation,
    CropAddMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CropAddMutation,
    CropAddMutationVariables
  >(CropAddDocument, baseOptions);
}
export type CropAddMutationHookResult = ReturnType<typeof useCropAddMutation>;
export type CropAddMutationResult = ApolloReactCommon.MutationResult<
  CropAddMutation
>;
export type CropAddMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CropAddMutation,
  CropAddMutationVariables
>;
export const UpdateManagementPlanDocument = gql`
  mutation UpdateManagementPlan(
    $planId: String!
    $input: SensorManagementPlanInput!
  ) {
    sensorManagementPlanUpdate(input: $input, planId: $planId) {
      sensorManagementPlan {
        id
        ...FullPlan
      }
    }
  }
  ${FullPlanFragmentDoc}
`;
export type UpdateManagementPlanMutationFn = ApolloReactCommon.MutationFunction<
  UpdateManagementPlanMutation,
  UpdateManagementPlanMutationVariables
>;
export type UpdateManagementPlanComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateManagementPlanMutation,
    UpdateManagementPlanMutationVariables
  >,
  'mutation'
>;

export const UpdateManagementPlanComponent = (
  props: UpdateManagementPlanComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateManagementPlanMutation,
    UpdateManagementPlanMutationVariables
  >
    mutation={UpdateManagementPlanDocument}
    {...props}
  />
);

/**
 * __useUpdateManagementPlanMutation__
 *
 * To run a mutation, you first call `useUpdateManagementPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateManagementPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateManagementPlanMutation, { data, loading, error }] = useUpdateManagementPlanMutation({
 *   variables: {
 *      planId: // value for 'planId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateManagementPlanMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateManagementPlanMutation,
    UpdateManagementPlanMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateManagementPlanMutation,
    UpdateManagementPlanMutationVariables
  >(UpdateManagementPlanDocument, baseOptions);
}
export type UpdateManagementPlanMutationHookResult = ReturnType<
  typeof useUpdateManagementPlanMutation
>;
export type UpdateManagementPlanMutationResult = ApolloReactCommon.MutationResult<
  UpdateManagementPlanMutation
>;
export type UpdateManagementPlanMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateManagementPlanMutation,
  UpdateManagementPlanMutationVariables
>;
export const AddManagementPlanDocument = gql`
  mutation AddManagementPlan(
    $sensorId: String!
    $input: SensorManagementPlanInput!
  ) {
    sensorManagementPlanAdd(sensorId: $sensorId, input: $input) {
      sensorManagementPlan {
        id
        ...FullPlan
      }
      sensor {
        id
        managementPlan {
          id
        }
        compatibleManagementPlans {
          id
        }
      }
    }
  }
  ${FullPlanFragmentDoc}
`;
export type AddManagementPlanMutationFn = ApolloReactCommon.MutationFunction<
  AddManagementPlanMutation,
  AddManagementPlanMutationVariables
>;
export type AddManagementPlanComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    AddManagementPlanMutation,
    AddManagementPlanMutationVariables
  >,
  'mutation'
>;

export const AddManagementPlanComponent = (
  props: AddManagementPlanComponentProps
) => (
  <ApolloReactComponents.Mutation<
    AddManagementPlanMutation,
    AddManagementPlanMutationVariables
  >
    mutation={AddManagementPlanDocument}
    {...props}
  />
);

/**
 * __useAddManagementPlanMutation__
 *
 * To run a mutation, you first call `useAddManagementPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddManagementPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addManagementPlanMutation, { data, loading, error }] = useAddManagementPlanMutation({
 *   variables: {
 *      sensorId: // value for 'sensorId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddManagementPlanMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddManagementPlanMutation,
    AddManagementPlanMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AddManagementPlanMutation,
    AddManagementPlanMutationVariables
  >(AddManagementPlanDocument, baseOptions);
}
export type AddManagementPlanMutationHookResult = ReturnType<
  typeof useAddManagementPlanMutation
>;
export type AddManagementPlanMutationResult = ApolloReactCommon.MutationResult<
  AddManagementPlanMutation
>;
export type AddManagementPlanMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddManagementPlanMutation,
  AddManagementPlanMutationVariables
>;
export const AddSoilDocument = gql`
  mutation AddSoil($input: AddSoilInput!) {
    addSoil(input: $input) {
      soil {
        id
        name
        t_dry
        t_wet
        c_dry
        c_wet
        custom
        clay_percent
      }
    }
  }
`;
export type AddSoilMutationFn = ApolloReactCommon.MutationFunction<
  AddSoilMutation,
  AddSoilMutationVariables
>;
export type AddSoilComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    AddSoilMutation,
    AddSoilMutationVariables
  >,
  'mutation'
>;

export const AddSoilComponent = (props: AddSoilComponentProps) => (
  <ApolloReactComponents.Mutation<AddSoilMutation, AddSoilMutationVariables>
    mutation={AddSoilDocument}
    {...props}
  />
);

/**
 * __useAddSoilMutation__
 *
 * To run a mutation, you first call `useAddSoilMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSoilMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSoilMutation, { data, loading, error }] = useAddSoilMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddSoilMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddSoilMutation,
    AddSoilMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AddSoilMutation,
    AddSoilMutationVariables
  >(AddSoilDocument, baseOptions);
}
export type AddSoilMutationHookResult = ReturnType<typeof useAddSoilMutation>;
export type AddSoilMutationResult = ApolloReactCommon.MutationResult<
  AddSoilMutation
>;
export type AddSoilMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddSoilMutation,
  AddSoilMutationVariables
>;
export const SoilsForAddDocument = gql`
  query SoilsForAdd {
    soils {
      id
      name
      t_dry
      t_wet
      c_dry
      c_wet
      custom
      clay_percent
    }
  }
`;
export type SoilsForAddComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    SoilsForAddQuery,
    SoilsForAddQueryVariables
  >,
  'query'
>;

export const SoilsForAddComponent = (props: SoilsForAddComponentProps) => (
  <ApolloReactComponents.Query<SoilsForAddQuery, SoilsForAddQueryVariables>
    query={SoilsForAddDocument}
    {...props}
  />
);

/**
 * __useSoilsForAddQuery__
 *
 * To run a query within a React component, call `useSoilsForAddQuery` and pass it any options that fit your needs.
 * When your component renders, `useSoilsForAddQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSoilsForAddQuery({
 *   variables: {
 *   },
 * });
 */
export function useSoilsForAddQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    SoilsForAddQuery,
    SoilsForAddQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<SoilsForAddQuery, SoilsForAddQueryVariables>(
    SoilsForAddDocument,
    baseOptions
  );
}
export function useSoilsForAddLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SoilsForAddQuery,
    SoilsForAddQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    SoilsForAddQuery,
    SoilsForAddQueryVariables
  >(SoilsForAddDocument, baseOptions);
}
export type SoilsForAddQueryHookResult = ReturnType<typeof useSoilsForAddQuery>;
export type SoilsForAddLazyQueryHookResult = ReturnType<
  typeof useSoilsForAddLazyQuery
>;
export type SoilsForAddQueryResult = ApolloReactCommon.QueryResult<
  SoilsForAddQuery,
  SoilsForAddQueryVariables
>;
export const DeleteSoilDocument = gql`
  mutation DeleteSoil($input: DeleteSoilInput!) {
    deleteSoil(input: $input) {
      id
    }
  }
`;
export type DeleteSoilMutationFn = ApolloReactCommon.MutationFunction<
  DeleteSoilMutation,
  DeleteSoilMutationVariables
>;
export type DeleteSoilComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteSoilMutation,
    DeleteSoilMutationVariables
  >,
  'mutation'
>;

export const DeleteSoilComponent = (props: DeleteSoilComponentProps) => (
  <ApolloReactComponents.Mutation<
    DeleteSoilMutation,
    DeleteSoilMutationVariables
  >
    mutation={DeleteSoilDocument}
    {...props}
  />
);

/**
 * __useDeleteSoilMutation__
 *
 * To run a mutation, you first call `useDeleteSoilMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSoilMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSoilMutation, { data, loading, error }] = useDeleteSoilMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSoilMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteSoilMutation,
    DeleteSoilMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteSoilMutation,
    DeleteSoilMutationVariables
  >(DeleteSoilDocument, baseOptions);
}
export type DeleteSoilMutationHookResult = ReturnType<
  typeof useDeleteSoilMutation
>;
export type DeleteSoilMutationResult = ApolloReactCommon.MutationResult<
  DeleteSoilMutation
>;
export type DeleteSoilMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteSoilMutation,
  DeleteSoilMutationVariables
>;
export const UpdateSoilDocument = gql`
  mutation UpdateSoil($input: UpdateSoilInput!) {
    updateSoil(input: $input) {
      soil {
        id
        name
        t_dry
        t_wet
        c_dry
        c_wet
        custom
        clay_percent
      }
    }
  }
`;
export type UpdateSoilMutationFn = ApolloReactCommon.MutationFunction<
  UpdateSoilMutation,
  UpdateSoilMutationVariables
>;
export type UpdateSoilComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateSoilMutation,
    UpdateSoilMutationVariables
  >,
  'mutation'
>;

export const UpdateSoilComponent = (props: UpdateSoilComponentProps) => (
  <ApolloReactComponents.Mutation<
    UpdateSoilMutation,
    UpdateSoilMutationVariables
  >
    mutation={UpdateSoilDocument}
    {...props}
  />
);

/**
 * __useUpdateSoilMutation__
 *
 * To run a mutation, you first call `useUpdateSoilMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSoilMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSoilMutation, { data, loading, error }] = useUpdateSoilMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSoilMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateSoilMutation,
    UpdateSoilMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateSoilMutation,
    UpdateSoilMutationVariables
  >(UpdateSoilDocument, baseOptions);
}
export type UpdateSoilMutationHookResult = ReturnType<
  typeof useUpdateSoilMutation
>;
export type UpdateSoilMutationResult = ApolloReactCommon.MutationResult<
  UpdateSoilMutation
>;
export type UpdateSoilMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateSoilMutation,
  UpdateSoilMutationVariables
>;
export const SoilsForDeleteDocument = gql`
  query SoilsForDelete {
    soils {
      id
    }
  }
`;
export type SoilsForDeleteComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    SoilsForDeleteQuery,
    SoilsForDeleteQueryVariables
  >,
  'query'
>;

export const SoilsForDeleteComponent = (
  props: SoilsForDeleteComponentProps
) => (
  <ApolloReactComponents.Query<
    SoilsForDeleteQuery,
    SoilsForDeleteQueryVariables
  >
    query={SoilsForDeleteDocument}
    {...props}
  />
);

/**
 * __useSoilsForDeleteQuery__
 *
 * To run a query within a React component, call `useSoilsForDeleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useSoilsForDeleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSoilsForDeleteQuery({
 *   variables: {
 *   },
 * });
 */
export function useSoilsForDeleteQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    SoilsForDeleteQuery,
    SoilsForDeleteQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    SoilsForDeleteQuery,
    SoilsForDeleteQueryVariables
  >(SoilsForDeleteDocument, baseOptions);
}
export function useSoilsForDeleteLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SoilsForDeleteQuery,
    SoilsForDeleteQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    SoilsForDeleteQuery,
    SoilsForDeleteQueryVariables
  >(SoilsForDeleteDocument, baseOptions);
}
export type SoilsForDeleteQueryHookResult = ReturnType<
  typeof useSoilsForDeleteQuery
>;
export type SoilsForDeleteLazyQueryHookResult = ReturnType<
  typeof useSoilsForDeleteLazyQuery
>;
export type SoilsForDeleteQueryResult = ApolloReactCommon.QueryResult<
  SoilsForDeleteQuery,
  SoilsForDeleteQueryVariables
>;
export const SoilsForUpdateDocument = gql`
  query SoilsForUpdate {
    soils {
      id
      name
      t_dry
      t_wet
      c_dry
      c_wet
      custom
      clay_percent
    }
  }
`;
export type SoilsForUpdateComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    SoilsForUpdateQuery,
    SoilsForUpdateQueryVariables
  >,
  'query'
>;

export const SoilsForUpdateComponent = (
  props: SoilsForUpdateComponentProps
) => (
  <ApolloReactComponents.Query<
    SoilsForUpdateQuery,
    SoilsForUpdateQueryVariables
  >
    query={SoilsForUpdateDocument}
    {...props}
  />
);

/**
 * __useSoilsForUpdateQuery__
 *
 * To run a query within a React component, call `useSoilsForUpdateQuery` and pass it any options that fit your needs.
 * When your component renders, `useSoilsForUpdateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSoilsForUpdateQuery({
 *   variables: {
 *   },
 * });
 */
export function useSoilsForUpdateQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    SoilsForUpdateQuery,
    SoilsForUpdateQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    SoilsForUpdateQuery,
    SoilsForUpdateQueryVariables
  >(SoilsForUpdateDocument, baseOptions);
}
export function useSoilsForUpdateLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SoilsForUpdateQuery,
    SoilsForUpdateQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    SoilsForUpdateQuery,
    SoilsForUpdateQueryVariables
  >(SoilsForUpdateDocument, baseOptions);
}
export type SoilsForUpdateQueryHookResult = ReturnType<
  typeof useSoilsForUpdateQuery
>;
export type SoilsForUpdateLazyQueryHookResult = ReturnType<
  typeof useSoilsForUpdateLazyQuery
>;
export type SoilsForUpdateQueryResult = ApolloReactCommon.QueryResult<
  SoilsForUpdateQuery,
  SoilsForUpdateQueryVariables
>;
export const ExportInfoDocument = gql`
  query ExportInfo {
    me {
      id
      fields {
        id
        name
      }
      sensors {
        id
        ...SensorInfo
      }
      gateways {
        id
        ...GatewayInfo
      }
      archivedFields {
        id
        name
        sensors {
          id
          ...ArchivedSensorInfo
        }
        gateways {
          id
          ...ArchivedGatewayInfo
        }
      }
      archivedSensors {
        id
        ...ArchivedSensorInfo
      }
      archivedGateways {
        id
        ...ArchivedGatewayInfo
      }
    }
  }
  ${SensorInfoFragmentDoc}
  ${GatewayInfoFragmentDoc}
  ${ArchivedSensorInfoFragmentDoc}
  ${ArchivedGatewayInfoFragmentDoc}
`;
export type ExportInfoComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ExportInfoQuery,
    ExportInfoQueryVariables
  >,
  'query'
>;

export const ExportInfoComponent = (props: ExportInfoComponentProps) => (
  <ApolloReactComponents.Query<ExportInfoQuery, ExportInfoQueryVariables>
    query={ExportInfoDocument}
    {...props}
  />
);

/**
 * __useExportInfoQuery__
 *
 * To run a query within a React component, call `useExportInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useExportInfoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ExportInfoQuery,
    ExportInfoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<ExportInfoQuery, ExportInfoQueryVariables>(
    ExportInfoDocument,
    baseOptions
  );
}
export function useExportInfoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ExportInfoQuery,
    ExportInfoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ExportInfoQuery,
    ExportInfoQueryVariables
  >(ExportInfoDocument, baseOptions);
}
export type ExportInfoQueryHookResult = ReturnType<typeof useExportInfoQuery>;
export type ExportInfoLazyQueryHookResult = ReturnType<
  typeof useExportInfoLazyQuery
>;
export type ExportInfoQueryResult = ApolloReactCommon.QueryResult<
  ExportInfoQuery,
  ExportInfoQueryVariables
>;
export const ExportSensorSoilDataDocument = gql`
  query ExportSensorSoilData($id: String!) {
    sensor(id: $id) {
      id
      model {
        moistureMethod
        moistureDepths
        temperatureDepths
        ecDepths
      }
      dataView {
        soil {
          t
          sm
          st
          ec
        }
      }
      soil_type {
        id
        t_dry
        t_wet
        c_dry
        c_wet
      }
    }
  }
`;
export type ExportSensorSoilDataComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ExportSensorSoilDataQuery,
    ExportSensorSoilDataQueryVariables
  >,
  'query'
> &
  (
    | { variables: ExportSensorSoilDataQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const ExportSensorSoilDataComponent = (
  props: ExportSensorSoilDataComponentProps
) => (
  <ApolloReactComponents.Query<
    ExportSensorSoilDataQuery,
    ExportSensorSoilDataQueryVariables
  >
    query={ExportSensorSoilDataDocument}
    {...props}
  />
);

/**
 * __useExportSensorSoilDataQuery__
 *
 * To run a query within a React component, call `useExportSensorSoilDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportSensorSoilDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportSensorSoilDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExportSensorSoilDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ExportSensorSoilDataQuery,
    ExportSensorSoilDataQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ExportSensorSoilDataQuery,
    ExportSensorSoilDataQueryVariables
  >(ExportSensorSoilDataDocument, baseOptions);
}
export function useExportSensorSoilDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ExportSensorSoilDataQuery,
    ExportSensorSoilDataQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ExportSensorSoilDataQuery,
    ExportSensorSoilDataQueryVariables
  >(ExportSensorSoilDataDocument, baseOptions);
}
export type ExportSensorSoilDataQueryHookResult = ReturnType<
  typeof useExportSensorSoilDataQuery
>;
export type ExportSensorSoilDataLazyQueryHookResult = ReturnType<
  typeof useExportSensorSoilDataLazyQuery
>;
export type ExportSensorSoilDataQueryResult = ApolloReactCommon.QueryResult<
  ExportSensorSoilDataQuery,
  ExportSensorSoilDataQueryVariables
>;
export const ExportArchivedSensorSoilDataDocument = gql`
  query ExportArchivedSensorSoilData($id: String!) {
    sensor: archivedSensor(id: $id) {
      id
      model {
        moistureMethod
        moistureDepths
        temperatureDepths
        ecDepths
      }
      dataView {
        soil {
          t
          sm
          st
          ec
        }
      }
      soil_type {
        id
        t_dry
        t_wet
        c_dry
        c_wet
      }
    }
  }
`;
export type ExportArchivedSensorSoilDataComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ExportArchivedSensorSoilDataQuery,
    ExportArchivedSensorSoilDataQueryVariables
  >,
  'query'
> &
  (
    | { variables: ExportArchivedSensorSoilDataQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const ExportArchivedSensorSoilDataComponent = (
  props: ExportArchivedSensorSoilDataComponentProps
) => (
  <ApolloReactComponents.Query<
    ExportArchivedSensorSoilDataQuery,
    ExportArchivedSensorSoilDataQueryVariables
  >
    query={ExportArchivedSensorSoilDataDocument}
    {...props}
  />
);

/**
 * __useExportArchivedSensorSoilDataQuery__
 *
 * To run a query within a React component, call `useExportArchivedSensorSoilDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportArchivedSensorSoilDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportArchivedSensorSoilDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExportArchivedSensorSoilDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ExportArchivedSensorSoilDataQuery,
    ExportArchivedSensorSoilDataQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ExportArchivedSensorSoilDataQuery,
    ExportArchivedSensorSoilDataQueryVariables
  >(ExportArchivedSensorSoilDataDocument, baseOptions);
}
export function useExportArchivedSensorSoilDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ExportArchivedSensorSoilDataQuery,
    ExportArchivedSensorSoilDataQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ExportArchivedSensorSoilDataQuery,
    ExportArchivedSensorSoilDataQueryVariables
  >(ExportArchivedSensorSoilDataDocument, baseOptions);
}
export type ExportArchivedSensorSoilDataQueryHookResult = ReturnType<
  typeof useExportArchivedSensorSoilDataQuery
>;
export type ExportArchivedSensorSoilDataLazyQueryHookResult = ReturnType<
  typeof useExportArchivedSensorSoilDataLazyQuery
>;
export type ExportArchivedSensorSoilDataQueryResult = ApolloReactCommon.QueryResult<
  ExportArchivedSensorSoilDataQuery,
  ExportArchivedSensorSoilDataQueryVariables
>;
export const ExportGatewayWeatherDataDocument = gql`
  query ExportGatewayWeatherData($id: String!) {
    gateway(id: $id) {
      id
      dataView {
        weather {
          time
          hum
          mWindDir
          mWindSpd
          rnCnt
          rnRate
          rnTotal
          solarRad
          temp
          windDir
          windSpd
        }
      }
    }
  }
`;
export type ExportGatewayWeatherDataComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ExportGatewayWeatherDataQuery,
    ExportGatewayWeatherDataQueryVariables
  >,
  'query'
> &
  (
    | { variables: ExportGatewayWeatherDataQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const ExportGatewayWeatherDataComponent = (
  props: ExportGatewayWeatherDataComponentProps
) => (
  <ApolloReactComponents.Query<
    ExportGatewayWeatherDataQuery,
    ExportGatewayWeatherDataQueryVariables
  >
    query={ExportGatewayWeatherDataDocument}
    {...props}
  />
);

/**
 * __useExportGatewayWeatherDataQuery__
 *
 * To run a query within a React component, call `useExportGatewayWeatherDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportGatewayWeatherDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportGatewayWeatherDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExportGatewayWeatherDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ExportGatewayWeatherDataQuery,
    ExportGatewayWeatherDataQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ExportGatewayWeatherDataQuery,
    ExportGatewayWeatherDataQueryVariables
  >(ExportGatewayWeatherDataDocument, baseOptions);
}
export function useExportGatewayWeatherDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ExportGatewayWeatherDataQuery,
    ExportGatewayWeatherDataQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ExportGatewayWeatherDataQuery,
    ExportGatewayWeatherDataQueryVariables
  >(ExportGatewayWeatherDataDocument, baseOptions);
}
export type ExportGatewayWeatherDataQueryHookResult = ReturnType<
  typeof useExportGatewayWeatherDataQuery
>;
export type ExportGatewayWeatherDataLazyQueryHookResult = ReturnType<
  typeof useExportGatewayWeatherDataLazyQuery
>;
export type ExportGatewayWeatherDataQueryResult = ApolloReactCommon.QueryResult<
  ExportGatewayWeatherDataQuery,
  ExportGatewayWeatherDataQueryVariables
>;
export const ExportArchivedGatewayWeatherDataDocument = gql`
  query ExportArchivedGatewayWeatherData($id: String!) {
    gateway: archivedGateway(id: $id) {
      id
      dataView {
        weather {
          time
          hum
          mWindDir
          mWindSpd
          rnCnt
          rnRate
          rnTotal
          solarRad
          temp
          windDir
          windSpd
        }
      }
    }
  }
`;
export type ExportArchivedGatewayWeatherDataComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ExportArchivedGatewayWeatherDataQuery,
    ExportArchivedGatewayWeatherDataQueryVariables
  >,
  'query'
> &
  (
    | {
        variables: ExportArchivedGatewayWeatherDataQueryVariables;
        skip?: boolean;
      }
    | { skip: boolean }
  );

export const ExportArchivedGatewayWeatherDataComponent = (
  props: ExportArchivedGatewayWeatherDataComponentProps
) => (
  <ApolloReactComponents.Query<
    ExportArchivedGatewayWeatherDataQuery,
    ExportArchivedGatewayWeatherDataQueryVariables
  >
    query={ExportArchivedGatewayWeatherDataDocument}
    {...props}
  />
);

/**
 * __useExportArchivedGatewayWeatherDataQuery__
 *
 * To run a query within a React component, call `useExportArchivedGatewayWeatherDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportArchivedGatewayWeatherDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportArchivedGatewayWeatherDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExportArchivedGatewayWeatherDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ExportArchivedGatewayWeatherDataQuery,
    ExportArchivedGatewayWeatherDataQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ExportArchivedGatewayWeatherDataQuery,
    ExportArchivedGatewayWeatherDataQueryVariables
  >(ExportArchivedGatewayWeatherDataDocument, baseOptions);
}
export function useExportArchivedGatewayWeatherDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ExportArchivedGatewayWeatherDataQuery,
    ExportArchivedGatewayWeatherDataQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ExportArchivedGatewayWeatherDataQuery,
    ExportArchivedGatewayWeatherDataQueryVariables
  >(ExportArchivedGatewayWeatherDataDocument, baseOptions);
}
export type ExportArchivedGatewayWeatherDataQueryHookResult = ReturnType<
  typeof useExportArchivedGatewayWeatherDataQuery
>;
export type ExportArchivedGatewayWeatherDataLazyQueryHookResult = ReturnType<
  typeof useExportArchivedGatewayWeatherDataLazyQuery
>;
export type ExportArchivedGatewayWeatherDataQueryResult = ApolloReactCommon.QueryResult<
  ExportArchivedGatewayWeatherDataQuery,
  ExportArchivedGatewayWeatherDataQueryVariables
>;
export const ExportSensorRainfallDataDocument = gql`
  query ExportSensorRainfallData($id: String!) {
    sensor(id: $id) {
      id
      dataView {
        rainfall {
          t
          a
        }
      }
    }
  }
`;
export type ExportSensorRainfallDataComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ExportSensorRainfallDataQuery,
    ExportSensorRainfallDataQueryVariables
  >,
  'query'
> &
  (
    | { variables: ExportSensorRainfallDataQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const ExportSensorRainfallDataComponent = (
  props: ExportSensorRainfallDataComponentProps
) => (
  <ApolloReactComponents.Query<
    ExportSensorRainfallDataQuery,
    ExportSensorRainfallDataQueryVariables
  >
    query={ExportSensorRainfallDataDocument}
    {...props}
  />
);

/**
 * __useExportSensorRainfallDataQuery__
 *
 * To run a query within a React component, call `useExportSensorRainfallDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportSensorRainfallDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportSensorRainfallDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExportSensorRainfallDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ExportSensorRainfallDataQuery,
    ExportSensorRainfallDataQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ExportSensorRainfallDataQuery,
    ExportSensorRainfallDataQueryVariables
  >(ExportSensorRainfallDataDocument, baseOptions);
}
export function useExportSensorRainfallDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ExportSensorRainfallDataQuery,
    ExportSensorRainfallDataQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ExportSensorRainfallDataQuery,
    ExportSensorRainfallDataQueryVariables
  >(ExportSensorRainfallDataDocument, baseOptions);
}
export type ExportSensorRainfallDataQueryHookResult = ReturnType<
  typeof useExportSensorRainfallDataQuery
>;
export type ExportSensorRainfallDataLazyQueryHookResult = ReturnType<
  typeof useExportSensorRainfallDataLazyQuery
>;
export type ExportSensorRainfallDataQueryResult = ApolloReactCommon.QueryResult<
  ExportSensorRainfallDataQuery,
  ExportSensorRainfallDataQueryVariables
>;
export const ExportArchivedSensorRainfallDataDocument = gql`
  query ExportArchivedSensorRainfallData($id: String!) {
    sensor: archivedSensor(id: $id) {
      id
      dataView {
        rainfall {
          t
          a
        }
      }
    }
  }
`;
export type ExportArchivedSensorRainfallDataComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ExportArchivedSensorRainfallDataQuery,
    ExportArchivedSensorRainfallDataQueryVariables
  >,
  'query'
> &
  (
    | {
        variables: ExportArchivedSensorRainfallDataQueryVariables;
        skip?: boolean;
      }
    | { skip: boolean }
  );

export const ExportArchivedSensorRainfallDataComponent = (
  props: ExportArchivedSensorRainfallDataComponentProps
) => (
  <ApolloReactComponents.Query<
    ExportArchivedSensorRainfallDataQuery,
    ExportArchivedSensorRainfallDataQueryVariables
  >
    query={ExportArchivedSensorRainfallDataDocument}
    {...props}
  />
);

/**
 * __useExportArchivedSensorRainfallDataQuery__
 *
 * To run a query within a React component, call `useExportArchivedSensorRainfallDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportArchivedSensorRainfallDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportArchivedSensorRainfallDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExportArchivedSensorRainfallDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ExportArchivedSensorRainfallDataQuery,
    ExportArchivedSensorRainfallDataQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ExportArchivedSensorRainfallDataQuery,
    ExportArchivedSensorRainfallDataQueryVariables
  >(ExportArchivedSensorRainfallDataDocument, baseOptions);
}
export function useExportArchivedSensorRainfallDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ExportArchivedSensorRainfallDataQuery,
    ExportArchivedSensorRainfallDataQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ExportArchivedSensorRainfallDataQuery,
    ExportArchivedSensorRainfallDataQueryVariables
  >(ExportArchivedSensorRainfallDataDocument, baseOptions);
}
export type ExportArchivedSensorRainfallDataQueryHookResult = ReturnType<
  typeof useExportArchivedSensorRainfallDataQuery
>;
export type ExportArchivedSensorRainfallDataLazyQueryHookResult = ReturnType<
  typeof useExportArchivedSensorRainfallDataLazyQuery
>;
export type ExportArchivedSensorRainfallDataQueryResult = ApolloReactCommon.QueryResult<
  ExportArchivedSensorRainfallDataQuery,
  ExportArchivedSensorRainfallDataQueryVariables
>;
export const ExportGatewayRainfallDataDocument = gql`
  query ExportGatewayRainfallData($id: String!) {
    gateway(id: $id) {
      id
      dataView {
        rainfall {
          t
          a
        }
      }
    }
  }
`;
export type ExportGatewayRainfallDataComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ExportGatewayRainfallDataQuery,
    ExportGatewayRainfallDataQueryVariables
  >,
  'query'
> &
  (
    | { variables: ExportGatewayRainfallDataQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const ExportGatewayRainfallDataComponent = (
  props: ExportGatewayRainfallDataComponentProps
) => (
  <ApolloReactComponents.Query<
    ExportGatewayRainfallDataQuery,
    ExportGatewayRainfallDataQueryVariables
  >
    query={ExportGatewayRainfallDataDocument}
    {...props}
  />
);

/**
 * __useExportGatewayRainfallDataQuery__
 *
 * To run a query within a React component, call `useExportGatewayRainfallDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportGatewayRainfallDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportGatewayRainfallDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExportGatewayRainfallDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ExportGatewayRainfallDataQuery,
    ExportGatewayRainfallDataQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ExportGatewayRainfallDataQuery,
    ExportGatewayRainfallDataQueryVariables
  >(ExportGatewayRainfallDataDocument, baseOptions);
}
export function useExportGatewayRainfallDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ExportGatewayRainfallDataQuery,
    ExportGatewayRainfallDataQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ExportGatewayRainfallDataQuery,
    ExportGatewayRainfallDataQueryVariables
  >(ExportGatewayRainfallDataDocument, baseOptions);
}
export type ExportGatewayRainfallDataQueryHookResult = ReturnType<
  typeof useExportGatewayRainfallDataQuery
>;
export type ExportGatewayRainfallDataLazyQueryHookResult = ReturnType<
  typeof useExportGatewayRainfallDataLazyQuery
>;
export type ExportGatewayRainfallDataQueryResult = ApolloReactCommon.QueryResult<
  ExportGatewayRainfallDataQuery,
  ExportGatewayRainfallDataQueryVariables
>;
export const ExportArchivedGatewayRainfallDataDocument = gql`
  query ExportArchivedGatewayRainfallData($id: String!) {
    gateway: archivedGateway(id: $id) {
      id
      dataView {
        rainfall {
          t
          a
        }
      }
    }
  }
`;
export type ExportArchivedGatewayRainfallDataComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ExportArchivedGatewayRainfallDataQuery,
    ExportArchivedGatewayRainfallDataQueryVariables
  >,
  'query'
> &
  (
    | {
        variables: ExportArchivedGatewayRainfallDataQueryVariables;
        skip?: boolean;
      }
    | { skip: boolean }
  );

export const ExportArchivedGatewayRainfallDataComponent = (
  props: ExportArchivedGatewayRainfallDataComponentProps
) => (
  <ApolloReactComponents.Query<
    ExportArchivedGatewayRainfallDataQuery,
    ExportArchivedGatewayRainfallDataQueryVariables
  >
    query={ExportArchivedGatewayRainfallDataDocument}
    {...props}
  />
);

/**
 * __useExportArchivedGatewayRainfallDataQuery__
 *
 * To run a query within a React component, call `useExportArchivedGatewayRainfallDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportArchivedGatewayRainfallDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportArchivedGatewayRainfallDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExportArchivedGatewayRainfallDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ExportArchivedGatewayRainfallDataQuery,
    ExportArchivedGatewayRainfallDataQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ExportArchivedGatewayRainfallDataQuery,
    ExportArchivedGatewayRainfallDataQueryVariables
  >(ExportArchivedGatewayRainfallDataDocument, baseOptions);
}
export function useExportArchivedGatewayRainfallDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ExportArchivedGatewayRainfallDataQuery,
    ExportArchivedGatewayRainfallDataQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ExportArchivedGatewayRainfallDataQuery,
    ExportArchivedGatewayRainfallDataQueryVariables
  >(ExportArchivedGatewayRainfallDataDocument, baseOptions);
}
export type ExportArchivedGatewayRainfallDataQueryHookResult = ReturnType<
  typeof useExportArchivedGatewayRainfallDataQuery
>;
export type ExportArchivedGatewayRainfallDataLazyQueryHookResult = ReturnType<
  typeof useExportArchivedGatewayRainfallDataLazyQuery
>;
export type ExportArchivedGatewayRainfallDataQueryResult = ApolloReactCommon.QueryResult<
  ExportArchivedGatewayRainfallDataQuery,
  ExportArchivedGatewayRainfallDataQueryVariables
>;
export const ExportFieldIrrigationDataDocument = gql`
  query ExportFieldIrrigationData($id: String!) {
    field(id: $id) {
      id
      irrigationEvents {
        id
        amount
        date
        note
      }
    }
  }
`;
export type ExportFieldIrrigationDataComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ExportFieldIrrigationDataQuery,
    ExportFieldIrrigationDataQueryVariables
  >,
  'query'
> &
  (
    | { variables: ExportFieldIrrigationDataQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const ExportFieldIrrigationDataComponent = (
  props: ExportFieldIrrigationDataComponentProps
) => (
  <ApolloReactComponents.Query<
    ExportFieldIrrigationDataQuery,
    ExportFieldIrrigationDataQueryVariables
  >
    query={ExportFieldIrrigationDataDocument}
    {...props}
  />
);

/**
 * __useExportFieldIrrigationDataQuery__
 *
 * To run a query within a React component, call `useExportFieldIrrigationDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportFieldIrrigationDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportFieldIrrigationDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExportFieldIrrigationDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ExportFieldIrrigationDataQuery,
    ExportFieldIrrigationDataQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ExportFieldIrrigationDataQuery,
    ExportFieldIrrigationDataQueryVariables
  >(ExportFieldIrrigationDataDocument, baseOptions);
}
export function useExportFieldIrrigationDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ExportFieldIrrigationDataQuery,
    ExportFieldIrrigationDataQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ExportFieldIrrigationDataQuery,
    ExportFieldIrrigationDataQueryVariables
  >(ExportFieldIrrigationDataDocument, baseOptions);
}
export type ExportFieldIrrigationDataQueryHookResult = ReturnType<
  typeof useExportFieldIrrigationDataQuery
>;
export type ExportFieldIrrigationDataLazyQueryHookResult = ReturnType<
  typeof useExportFieldIrrigationDataLazyQuery
>;
export type ExportFieldIrrigationDataQueryResult = ApolloReactCommon.QueryResult<
  ExportFieldIrrigationDataQuery,
  ExportFieldIrrigationDataQueryVariables
>;
export const ExportArchivedFieldIrrigationDataDocument = gql`
  query ExportArchivedFieldIrrigationData($id: String!) {
    field: archivedField(id: $id) {
      id
      irrigationEvents {
        id
        amount
        date
        note
      }
    }
  }
`;
export type ExportArchivedFieldIrrigationDataComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ExportArchivedFieldIrrigationDataQuery,
    ExportArchivedFieldIrrigationDataQueryVariables
  >,
  'query'
> &
  (
    | {
        variables: ExportArchivedFieldIrrigationDataQueryVariables;
        skip?: boolean;
      }
    | { skip: boolean }
  );

export const ExportArchivedFieldIrrigationDataComponent = (
  props: ExportArchivedFieldIrrigationDataComponentProps
) => (
  <ApolloReactComponents.Query<
    ExportArchivedFieldIrrigationDataQuery,
    ExportArchivedFieldIrrigationDataQueryVariables
  >
    query={ExportArchivedFieldIrrigationDataDocument}
    {...props}
  />
);

/**
 * __useExportArchivedFieldIrrigationDataQuery__
 *
 * To run a query within a React component, call `useExportArchivedFieldIrrigationDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportArchivedFieldIrrigationDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportArchivedFieldIrrigationDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExportArchivedFieldIrrigationDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ExportArchivedFieldIrrigationDataQuery,
    ExportArchivedFieldIrrigationDataQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ExportArchivedFieldIrrigationDataQuery,
    ExportArchivedFieldIrrigationDataQueryVariables
  >(ExportArchivedFieldIrrigationDataDocument, baseOptions);
}
export function useExportArchivedFieldIrrigationDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ExportArchivedFieldIrrigationDataQuery,
    ExportArchivedFieldIrrigationDataQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ExportArchivedFieldIrrigationDataQuery,
    ExportArchivedFieldIrrigationDataQueryVariables
  >(ExportArchivedFieldIrrigationDataDocument, baseOptions);
}
export type ExportArchivedFieldIrrigationDataQueryHookResult = ReturnType<
  typeof useExportArchivedFieldIrrigationDataQuery
>;
export type ExportArchivedFieldIrrigationDataLazyQueryHookResult = ReturnType<
  typeof useExportArchivedFieldIrrigationDataLazyQuery
>;
export type ExportArchivedFieldIrrigationDataQueryResult = ApolloReactCommon.QueryResult<
  ExportArchivedFieldIrrigationDataQuery,
  ExportArchivedFieldIrrigationDataQueryVariables
>;
export const ExportSensorNOAADataDocument = gql`
  query ExportSensorNOAAData($id: String!) {
    sensor(id: $id) {
      id
      weather {
        historical {
          time
          precipAmount
        }
      }
    }
  }
`;
export type ExportSensorNOAADataComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ExportSensorNOAADataQuery,
    ExportSensorNOAADataQueryVariables
  >,
  'query'
> &
  (
    | { variables: ExportSensorNOAADataQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const ExportSensorNOAADataComponent = (
  props: ExportSensorNOAADataComponentProps
) => (
  <ApolloReactComponents.Query<
    ExportSensorNOAADataQuery,
    ExportSensorNOAADataQueryVariables
  >
    query={ExportSensorNOAADataDocument}
    {...props}
  />
);

/**
 * __useExportSensorNOAADataQuery__
 *
 * To run a query within a React component, call `useExportSensorNOAADataQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportSensorNOAADataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportSensorNOAADataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExportSensorNOAADataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ExportSensorNOAADataQuery,
    ExportSensorNOAADataQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ExportSensorNOAADataQuery,
    ExportSensorNOAADataQueryVariables
  >(ExportSensorNOAADataDocument, baseOptions);
}
export function useExportSensorNOAADataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ExportSensorNOAADataQuery,
    ExportSensorNOAADataQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ExportSensorNOAADataQuery,
    ExportSensorNOAADataQueryVariables
  >(ExportSensorNOAADataDocument, baseOptions);
}
export type ExportSensorNOAADataQueryHookResult = ReturnType<
  typeof useExportSensorNOAADataQuery
>;
export type ExportSensorNOAADataLazyQueryHookResult = ReturnType<
  typeof useExportSensorNOAADataLazyQuery
>;
export type ExportSensorNOAADataQueryResult = ApolloReactCommon.QueryResult<
  ExportSensorNOAADataQuery,
  ExportSensorNOAADataQueryVariables
>;
export const ExportArchivedSensorNOAADataDocument = gql`
  query ExportArchivedSensorNOAAData($id: String!) {
    sensor: archivedSensor(id: $id) {
      id
      weather {
        historical {
          time
          precipAmount
        }
      }
    }
  }
`;
export type ExportArchivedSensorNOAADataComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ExportArchivedSensorNOAADataQuery,
    ExportArchivedSensorNOAADataQueryVariables
  >,
  'query'
> &
  (
    | { variables: ExportArchivedSensorNOAADataQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const ExportArchivedSensorNOAADataComponent = (
  props: ExportArchivedSensorNOAADataComponentProps
) => (
  <ApolloReactComponents.Query<
    ExportArchivedSensorNOAADataQuery,
    ExportArchivedSensorNOAADataQueryVariables
  >
    query={ExportArchivedSensorNOAADataDocument}
    {...props}
  />
);

/**
 * __useExportArchivedSensorNOAADataQuery__
 *
 * To run a query within a React component, call `useExportArchivedSensorNOAADataQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportArchivedSensorNOAADataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportArchivedSensorNOAADataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExportArchivedSensorNOAADataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ExportArchivedSensorNOAADataQuery,
    ExportArchivedSensorNOAADataQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ExportArchivedSensorNOAADataQuery,
    ExportArchivedSensorNOAADataQueryVariables
  >(ExportArchivedSensorNOAADataDocument, baseOptions);
}
export function useExportArchivedSensorNOAADataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ExportArchivedSensorNOAADataQuery,
    ExportArchivedSensorNOAADataQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ExportArchivedSensorNOAADataQuery,
    ExportArchivedSensorNOAADataQueryVariables
  >(ExportArchivedSensorNOAADataDocument, baseOptions);
}
export type ExportArchivedSensorNOAADataQueryHookResult = ReturnType<
  typeof useExportArchivedSensorNOAADataQuery
>;
export type ExportArchivedSensorNOAADataLazyQueryHookResult = ReturnType<
  typeof useExportArchivedSensorNOAADataLazyQuery
>;
export type ExportArchivedSensorNOAADataQueryResult = ApolloReactCommon.QueryResult<
  ExportArchivedSensorNOAADataQuery,
  ExportArchivedSensorNOAADataQueryVariables
>;
export const ExportGatewayPressureDataDocument = gql`
  query ExportGatewayPressureData($id: String!) {
    pressureSensor(id: $id) {
      id
      data {
        __typename
        ... on PressureSensorData {
          pressure {
            t
            avgP
            rt
          }
        }
      }
    }
  }
`;
export type ExportGatewayPressureDataComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ExportGatewayPressureDataQuery,
    ExportGatewayPressureDataQueryVariables
  >,
  'query'
> &
  (
    | { variables: ExportGatewayPressureDataQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const ExportGatewayPressureDataComponent = (
  props: ExportGatewayPressureDataComponentProps
) => (
  <ApolloReactComponents.Query<
    ExportGatewayPressureDataQuery,
    ExportGatewayPressureDataQueryVariables
  >
    query={ExportGatewayPressureDataDocument}
    {...props}
  />
);

/**
 * __useExportGatewayPressureDataQuery__
 *
 * To run a query within a React component, call `useExportGatewayPressureDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportGatewayPressureDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportGatewayPressureDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExportGatewayPressureDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ExportGatewayPressureDataQuery,
    ExportGatewayPressureDataQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ExportGatewayPressureDataQuery,
    ExportGatewayPressureDataQueryVariables
  >(ExportGatewayPressureDataDocument, baseOptions);
}
export function useExportGatewayPressureDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ExportGatewayPressureDataQuery,
    ExportGatewayPressureDataQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ExportGatewayPressureDataQuery,
    ExportGatewayPressureDataQueryVariables
  >(ExportGatewayPressureDataDocument, baseOptions);
}
export type ExportGatewayPressureDataQueryHookResult = ReturnType<
  typeof useExportGatewayPressureDataQuery
>;
export type ExportGatewayPressureDataLazyQueryHookResult = ReturnType<
  typeof useExportGatewayPressureDataLazyQuery
>;
export type ExportGatewayPressureDataQueryResult = ApolloReactCommon.QueryResult<
  ExportGatewayPressureDataQuery,
  ExportGatewayPressureDataQueryVariables
>;
export const InvoicesDocument = gql`
  query Invoices {
    me {
      id
      invoices {
        date
        amount
      }
      upcomingInvoice {
        date
        amount
      }
    }
  }
`;
export type InvoicesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    InvoicesQuery,
    InvoicesQueryVariables
  >,
  'query'
>;

export const InvoicesComponent = (props: InvoicesComponentProps) => (
  <ApolloReactComponents.Query<InvoicesQuery, InvoicesQueryVariables>
    query={InvoicesDocument}
    {...props}
  />
);

/**
 * __useInvoicesQuery__
 *
 * To run a query within a React component, call `useInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useInvoicesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    InvoicesQuery,
    InvoicesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<InvoicesQuery, InvoicesQueryVariables>(
    InvoicesDocument,
    baseOptions
  );
}
export function useInvoicesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    InvoicesQuery,
    InvoicesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<InvoicesQuery, InvoicesQueryVariables>(
    InvoicesDocument,
    baseOptions
  );
}
export type InvoicesQueryHookResult = ReturnType<typeof useInvoicesQuery>;
export type InvoicesLazyQueryHookResult = ReturnType<
  typeof useInvoicesLazyQuery
>;
export type InvoicesQueryResult = ApolloReactCommon.QueryResult<
  InvoicesQuery,
  InvoicesQueryVariables
>;
export const ManageUsersDocument = gql`
  query ManageUsers {
    me {
      id
      sensors(onlyAdmin: true) {
        id
        ...ManageUsersHardware
      }
      gateways(onlyAdmin: true) {
        id
        ...ManageUsersHardware
      }
      fields(onlyAdmin: true) {
        id
        ...ManageUsersField
      }
    }
  }
  ${ManageUsersHardwareFragmentDoc}
  ${ManageUsersFieldFragmentDoc}
`;
export type ManageUsersComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ManageUsersQuery,
    ManageUsersQueryVariables
  >,
  'query'
>;

export const ManageUsersComponent = (props: ManageUsersComponentProps) => (
  <ApolloReactComponents.Query<ManageUsersQuery, ManageUsersQueryVariables>
    query={ManageUsersDocument}
    {...props}
  />
);

/**
 * __useManageUsersQuery__
 *
 * To run a query within a React component, call `useManageUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useManageUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManageUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useManageUsersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ManageUsersQuery,
    ManageUsersQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<ManageUsersQuery, ManageUsersQueryVariables>(
    ManageUsersDocument,
    baseOptions
  );
}
export function useManageUsersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ManageUsersQuery,
    ManageUsersQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ManageUsersQuery,
    ManageUsersQueryVariables
  >(ManageUsersDocument, baseOptions);
}
export type ManageUsersQueryHookResult = ReturnType<typeof useManageUsersQuery>;
export type ManageUsersLazyQueryHookResult = ReturnType<
  typeof useManageUsersLazyQuery
>;
export type ManageUsersQueryResult = ApolloReactCommon.QueryResult<
  ManageUsersQuery,
  ManageUsersQueryVariables
>;
export const UpdateSensorSharingDocument = gql`
  mutation UpdateSensorSharing($input: UpdateSensorSharingInput!) {
    updateSensorSharing(input: $input) {
      sensor {
        id
        ...ManageUserHardware
        ...ManageUsersHardware
      }
    }
  }
  ${ManageUserHardwareFragmentDoc}
  ${ManageUsersHardwareFragmentDoc}
`;
export type UpdateSensorSharingMutationFn = ApolloReactCommon.MutationFunction<
  UpdateSensorSharingMutation,
  UpdateSensorSharingMutationVariables
>;
export type UpdateSensorSharingComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateSensorSharingMutation,
    UpdateSensorSharingMutationVariables
  >,
  'mutation'
>;

export const UpdateSensorSharingComponent = (
  props: UpdateSensorSharingComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateSensorSharingMutation,
    UpdateSensorSharingMutationVariables
  >
    mutation={UpdateSensorSharingDocument}
    {...props}
  />
);

/**
 * __useUpdateSensorSharingMutation__
 *
 * To run a mutation, you first call `useUpdateSensorSharingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSensorSharingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSensorSharingMutation, { data, loading, error }] = useUpdateSensorSharingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSensorSharingMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateSensorSharingMutation,
    UpdateSensorSharingMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateSensorSharingMutation,
    UpdateSensorSharingMutationVariables
  >(UpdateSensorSharingDocument, baseOptions);
}
export type UpdateSensorSharingMutationHookResult = ReturnType<
  typeof useUpdateSensorSharingMutation
>;
export type UpdateSensorSharingMutationResult = ApolloReactCommon.MutationResult<
  UpdateSensorSharingMutation
>;
export type UpdateSensorSharingMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateSensorSharingMutation,
  UpdateSensorSharingMutationVariables
>;
export const UpdateGatewaySharingDocument = gql`
  mutation UpdateGatewaySharing($input: UpdateGatewaySharingInput!) {
    updateGatewaySharing(input: $input) {
      gateway {
        id
        ...ManageUserHardware
        ...ManageUsersHardware
      }
    }
  }
  ${ManageUserHardwareFragmentDoc}
  ${ManageUsersHardwareFragmentDoc}
`;
export type UpdateGatewaySharingMutationFn = ApolloReactCommon.MutationFunction<
  UpdateGatewaySharingMutation,
  UpdateGatewaySharingMutationVariables
>;
export type UpdateGatewaySharingComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateGatewaySharingMutation,
    UpdateGatewaySharingMutationVariables
  >,
  'mutation'
>;

export const UpdateGatewaySharingComponent = (
  props: UpdateGatewaySharingComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateGatewaySharingMutation,
    UpdateGatewaySharingMutationVariables
  >
    mutation={UpdateGatewaySharingDocument}
    {...props}
  />
);

/**
 * __useUpdateGatewaySharingMutation__
 *
 * To run a mutation, you first call `useUpdateGatewaySharingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGatewaySharingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGatewaySharingMutation, { data, loading, error }] = useUpdateGatewaySharingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateGatewaySharingMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateGatewaySharingMutation,
    UpdateGatewaySharingMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateGatewaySharingMutation,
    UpdateGatewaySharingMutationVariables
  >(UpdateGatewaySharingDocument, baseOptions);
}
export type UpdateGatewaySharingMutationHookResult = ReturnType<
  typeof useUpdateGatewaySharingMutation
>;
export type UpdateGatewaySharingMutationResult = ApolloReactCommon.MutationResult<
  UpdateGatewaySharingMutation
>;
export type UpdateGatewaySharingMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateGatewaySharingMutation,
  UpdateGatewaySharingMutationVariables
>;
export const FieldSharingUpdateDocument = gql`
  mutation FieldSharingUpdate($input: FieldSharingUpdateInput!) {
    fieldSharingUpdate(input: $input) {
      success
      field {
        id
        ...ManageUserField
        ...ManageUsersField
      }
    }
  }
  ${ManageUserFieldFragmentDoc}
  ${ManageUsersFieldFragmentDoc}
`;
export type FieldSharingUpdateMutationFn = ApolloReactCommon.MutationFunction<
  FieldSharingUpdateMutation,
  FieldSharingUpdateMutationVariables
>;
export type FieldSharingUpdateComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    FieldSharingUpdateMutation,
    FieldSharingUpdateMutationVariables
  >,
  'mutation'
>;

export const FieldSharingUpdateComponent = (
  props: FieldSharingUpdateComponentProps
) => (
  <ApolloReactComponents.Mutation<
    FieldSharingUpdateMutation,
    FieldSharingUpdateMutationVariables
  >
    mutation={FieldSharingUpdateDocument}
    {...props}
  />
);

/**
 * __useFieldSharingUpdateMutation__
 *
 * To run a mutation, you first call `useFieldSharingUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFieldSharingUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fieldSharingUpdateMutation, { data, loading, error }] = useFieldSharingUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFieldSharingUpdateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    FieldSharingUpdateMutation,
    FieldSharingUpdateMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    FieldSharingUpdateMutation,
    FieldSharingUpdateMutationVariables
  >(FieldSharingUpdateDocument, baseOptions);
}
export type FieldSharingUpdateMutationHookResult = ReturnType<
  typeof useFieldSharingUpdateMutation
>;
export type FieldSharingUpdateMutationResult = ApolloReactCommon.MutationResult<
  FieldSharingUpdateMutation
>;
export type FieldSharingUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  FieldSharingUpdateMutation,
  FieldSharingUpdateMutationVariables
>;
export const HardwareSharingDocument = gql`
  query HardwareSharing {
    me {
      id
      sensors(onlyAdmin: true) {
        id
        ...ManageUserHardware
      }
      gateways(onlyAdmin: true) {
        id
        ...ManageUserHardware
      }
      fields(onlyAdmin: true) {
        id
        ...ManageUserField
      }
    }
  }
  ${ManageUserHardwareFragmentDoc}
  ${ManageUserFieldFragmentDoc}
`;
export type HardwareSharingComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    HardwareSharingQuery,
    HardwareSharingQueryVariables
  >,
  'query'
>;

export const HardwareSharingComponent = (
  props: HardwareSharingComponentProps
) => (
  <ApolloReactComponents.Query<
    HardwareSharingQuery,
    HardwareSharingQueryVariables
  >
    query={HardwareSharingDocument}
    {...props}
  />
);

/**
 * __useHardwareSharingQuery__
 *
 * To run a query within a React component, call `useHardwareSharingQuery` and pass it any options that fit your needs.
 * When your component renders, `useHardwareSharingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHardwareSharingQuery({
 *   variables: {
 *   },
 * });
 */
export function useHardwareSharingQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    HardwareSharingQuery,
    HardwareSharingQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    HardwareSharingQuery,
    HardwareSharingQueryVariables
  >(HardwareSharingDocument, baseOptions);
}
export function useHardwareSharingLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    HardwareSharingQuery,
    HardwareSharingQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    HardwareSharingQuery,
    HardwareSharingQueryVariables
  >(HardwareSharingDocument, baseOptions);
}
export type HardwareSharingQueryHookResult = ReturnType<
  typeof useHardwareSharingQuery
>;
export type HardwareSharingLazyQueryHookResult = ReturnType<
  typeof useHardwareSharingLazyQuery
>;
export type HardwareSharingQueryResult = ApolloReactCommon.QueryResult<
  HardwareSharingQuery,
  HardwareSharingQueryVariables
>;
export const NotificationSettingsDocument = gql`
  query NotificationSettings {
    me {
      id
      ...NotificationsSettingsUser
    }
  }
  ${NotificationsSettingsUserFragmentDoc}
`;
export type NotificationSettingsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    NotificationSettingsQuery,
    NotificationSettingsQueryVariables
  >,
  'query'
>;

export const NotificationSettingsComponent = (
  props: NotificationSettingsComponentProps
) => (
  <ApolloReactComponents.Query<
    NotificationSettingsQuery,
    NotificationSettingsQueryVariables
  >
    query={NotificationSettingsDocument}
    {...props}
  />
);

/**
 * __useNotificationSettingsQuery__
 *
 * To run a query within a React component, call `useNotificationSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useNotificationSettingsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    NotificationSettingsQuery,
    NotificationSettingsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    NotificationSettingsQuery,
    NotificationSettingsQueryVariables
  >(NotificationSettingsDocument, baseOptions);
}
export function useNotificationSettingsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    NotificationSettingsQuery,
    NotificationSettingsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    NotificationSettingsQuery,
    NotificationSettingsQueryVariables
  >(NotificationSettingsDocument, baseOptions);
}
export type NotificationSettingsQueryHookResult = ReturnType<
  typeof useNotificationSettingsQuery
>;
export type NotificationSettingsLazyQueryHookResult = ReturnType<
  typeof useNotificationSettingsLazyQuery
>;
export type NotificationSettingsQueryResult = ApolloReactCommon.QueryResult<
  NotificationSettingsQuery,
  NotificationSettingsQueryVariables
>;
export const UpdateUserPreferencesDocument = gql`
  mutation UpdateUserPreferences($input: UpdateUserPreferencesInput!) {
    updateUserPreferences(input: $input) {
      me {
        id
        ...NotificationsSettingsUser
      }
    }
  }
  ${NotificationsSettingsUserFragmentDoc}
`;
export type UpdateUserPreferencesMutationFn = ApolloReactCommon.MutationFunction<
  UpdateUserPreferencesMutation,
  UpdateUserPreferencesMutationVariables
>;
export type UpdateUserPreferencesComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateUserPreferencesMutation,
    UpdateUserPreferencesMutationVariables
  >,
  'mutation'
>;

export const UpdateUserPreferencesComponent = (
  props: UpdateUserPreferencesComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateUserPreferencesMutation,
    UpdateUserPreferencesMutationVariables
  >
    mutation={UpdateUserPreferencesDocument}
    {...props}
  />
);

/**
 * __useUpdateUserPreferencesMutation__
 *
 * To run a mutation, you first call `useUpdateUserPreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserPreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserPreferencesMutation, { data, loading, error }] = useUpdateUserPreferencesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserPreferencesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateUserPreferencesMutation,
    UpdateUserPreferencesMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateUserPreferencesMutation,
    UpdateUserPreferencesMutationVariables
  >(UpdateUserPreferencesDocument, baseOptions);
}
export type UpdateUserPreferencesMutationHookResult = ReturnType<
  typeof useUpdateUserPreferencesMutation
>;
export type UpdateUserPreferencesMutationResult = ApolloReactCommon.MutationResult<
  UpdateUserPreferencesMutation
>;
export type UpdateUserPreferencesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateUserPreferencesMutation,
  UpdateUserPreferencesMutationVariables
>;
export const ProfileDocument = gql`
  query Profile {
    me {
      id
      name {
        first
        last
      }
      accountEmail
      prepaid
      prepaidStart
      prepaidUntil
      upcomingInvoice {
        date
        amount
      }
      stripeSubscription {
        id
        canceled
        periodEnd
      }
      cards {
        id
        brand
        last4
      }
      managedServiceStatuses {
        orderId
        invoiceUrl
        initialPaymentMade
        subscriptionStarted
        prepaid
      }
      sensors(onlyOwned: true) {
        id
        activePlan
        permissionLevel
      }
      gateways(onlyOwned: true) {
        id
        activePlan
        permissionLevel
        model {
          hasRainGauge
          hasWeatherStation
        }
      }
    }
  }
`;
export type ProfileComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ProfileQuery,
    ProfileQueryVariables
  >,
  'query'
>;

export const ProfileComponent = (props: ProfileComponentProps) => (
  <ApolloReactComponents.Query<ProfileQuery, ProfileQueryVariables>
    query={ProfileDocument}
    {...props}
  />
);

/**
 * __useProfileQuery__
 *
 * To run a query within a React component, call `useProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfileQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ProfileQuery,
    ProfileQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<ProfileQuery, ProfileQueryVariables>(
    ProfileDocument,
    baseOptions
  );
}
export function useProfileLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ProfileQuery,
    ProfileQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<ProfileQuery, ProfileQueryVariables>(
    ProfileDocument,
    baseOptions
  );
}
export type ProfileQueryHookResult = ReturnType<typeof useProfileQuery>;
export type ProfileLazyQueryHookResult = ReturnType<typeof useProfileLazyQuery>;
export type ProfileQueryResult = ApolloReactCommon.QueryResult<
  ProfileQuery,
  ProfileQueryVariables
>;
export const UpdateProfileDocument = gql`
  mutation UpdateProfile($input: UpdateUserProfileInput!) {
    updateUserProfile(input: $input) {
      id
    }
  }
`;
export type UpdateProfileMutationFn = ApolloReactCommon.MutationFunction<
  UpdateProfileMutation,
  UpdateProfileMutationVariables
>;
export type UpdateProfileComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateProfileMutation,
    UpdateProfileMutationVariables
  >,
  'mutation'
>;

export const UpdateProfileComponent = (props: UpdateProfileComponentProps) => (
  <ApolloReactComponents.Mutation<
    UpdateProfileMutation,
    UpdateProfileMutationVariables
  >
    mutation={UpdateProfileDocument}
    {...props}
  />
);

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProfileMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateProfileMutation,
    UpdateProfileMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateProfileMutation,
    UpdateProfileMutationVariables
  >(UpdateProfileDocument, baseOptions);
}
export type UpdateProfileMutationHookResult = ReturnType<
  typeof useUpdateProfileMutation
>;
export type UpdateProfileMutationResult = ApolloReactCommon.MutationResult<
  UpdateProfileMutation
>;
export type UpdateProfileMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateProfileMutation,
  UpdateProfileMutationVariables
>;
export const SubscriptionsDocument = gql`
  query Subscriptions {
    me {
      id
      name {
        first
        last
      }
      accountEmail
      prepaid
      prepaidStart
      prepaidUntil
      upcomingInvoice {
        date
        amount
      }
      stripeSubscription {
        id
        canceled
        periodEnd
      }
      cards {
        id
        brand
        last4
      }
      managedServiceStatuses {
        orderId
        invoiceUrl
        initialPaymentMade
        subscriptionStarted
        prepaid
      }
      sensors(onlyOwned: true) {
        id
        activePlan
        permissionLevel
      }
      gateways(onlyOwned: true) {
        id
        activePlan
        permissionLevel
        model {
          hasRainGauge
          hasWeatherStation
        }
      }
    }
  }
`;
export type SubscriptionsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    SubscriptionsQuery,
    SubscriptionsQueryVariables
  >,
  'query'
>;

export const SubscriptionsComponent = (props: SubscriptionsComponentProps) => (
  <ApolloReactComponents.Query<SubscriptionsQuery, SubscriptionsQueryVariables>
    query={SubscriptionsDocument}
    {...props}
  />
);

/**
 * __useSubscriptionsQuery__
 *
 * To run a query within a React component, call `useSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSubscriptionsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    SubscriptionsQuery,
    SubscriptionsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    SubscriptionsQuery,
    SubscriptionsQueryVariables
  >(SubscriptionsDocument, baseOptions);
}
export function useSubscriptionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SubscriptionsQuery,
    SubscriptionsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    SubscriptionsQuery,
    SubscriptionsQueryVariables
  >(SubscriptionsDocument, baseOptions);
}
export type SubscriptionsQueryHookResult = ReturnType<
  typeof useSubscriptionsQuery
>;
export type SubscriptionsLazyQueryHookResult = ReturnType<
  typeof useSubscriptionsLazyQuery
>;
export type SubscriptionsQueryResult = ApolloReactCommon.QueryResult<
  SubscriptionsQuery,
  SubscriptionsQueryVariables
>;
export const StartManagedServiceSubscriptionDocument = gql`
  mutation StartManagedServiceSubscription($input: StartManagedServiceInput!) {
    startManagedService(input: $input) {
      success
    }
  }
`;
export type StartManagedServiceSubscriptionMutationFn = ApolloReactCommon.MutationFunction<
  StartManagedServiceSubscriptionMutation,
  StartManagedServiceSubscriptionMutationVariables
>;
export type StartManagedServiceSubscriptionComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    StartManagedServiceSubscriptionMutation,
    StartManagedServiceSubscriptionMutationVariables
  >,
  'mutation'
>;

export const StartManagedServiceSubscriptionComponent = (
  props: StartManagedServiceSubscriptionComponentProps
) => (
  <ApolloReactComponents.Mutation<
    StartManagedServiceSubscriptionMutation,
    StartManagedServiceSubscriptionMutationVariables
  >
    mutation={StartManagedServiceSubscriptionDocument}
    {...props}
  />
);

/**
 * __useStartManagedServiceSubscriptionMutation__
 *
 * To run a mutation, you first call `useStartManagedServiceSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartManagedServiceSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startManagedServiceSubscriptionMutation, { data, loading, error }] = useStartManagedServiceSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartManagedServiceSubscriptionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    StartManagedServiceSubscriptionMutation,
    StartManagedServiceSubscriptionMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    StartManagedServiceSubscriptionMutation,
    StartManagedServiceSubscriptionMutationVariables
  >(StartManagedServiceSubscriptionDocument, baseOptions);
}
export type StartManagedServiceSubscriptionMutationHookResult = ReturnType<
  typeof useStartManagedServiceSubscriptionMutation
>;
export type StartManagedServiceSubscriptionMutationResult = ApolloReactCommon.MutationResult<
  StartManagedServiceSubscriptionMutation
>;
export type StartManagedServiceSubscriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  StartManagedServiceSubscriptionMutation,
  StartManagedServiceSubscriptionMutationVariables
>;
export const StripeBillingPortalDocument = gql`
  mutation StripeBillingPortal($input: String!) {
    stripeBillingPortal(userId: $input) {
      sessionUrl
    }
  }
`;
export type StripeBillingPortalMutationFn = ApolloReactCommon.MutationFunction<
  StripeBillingPortalMutation,
  StripeBillingPortalMutationVariables
>;
export type StripeBillingPortalComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    StripeBillingPortalMutation,
    StripeBillingPortalMutationVariables
  >,
  'mutation'
>;

export const StripeBillingPortalComponent = (
  props: StripeBillingPortalComponentProps
) => (
  <ApolloReactComponents.Mutation<
    StripeBillingPortalMutation,
    StripeBillingPortalMutationVariables
  >
    mutation={StripeBillingPortalDocument}
    {...props}
  />
);

/**
 * __useStripeBillingPortalMutation__
 *
 * To run a mutation, you first call `useStripeBillingPortalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStripeBillingPortalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stripeBillingPortalMutation, { data, loading, error }] = useStripeBillingPortalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStripeBillingPortalMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    StripeBillingPortalMutation,
    StripeBillingPortalMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    StripeBillingPortalMutation,
    StripeBillingPortalMutationVariables
  >(StripeBillingPortalDocument, baseOptions);
}
export type StripeBillingPortalMutationHookResult = ReturnType<
  typeof useStripeBillingPortalMutation
>;
export type StripeBillingPortalMutationResult = ApolloReactCommon.MutationResult<
  StripeBillingPortalMutation
>;
export type StripeBillingPortalMutationOptions = ApolloReactCommon.BaseMutationOptions<
  StripeBillingPortalMutation,
  StripeBillingPortalMutationVariables
>;
export const ChangeCardDocument = gql`
  mutation ChangeCard($input: ChangeCardInput!) {
    changeCard(input: $input) {
      id
    }
  }
`;
export type ChangeCardMutationFn = ApolloReactCommon.MutationFunction<
  ChangeCardMutation,
  ChangeCardMutationVariables
>;
export type ChangeCardComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ChangeCardMutation,
    ChangeCardMutationVariables
  >,
  'mutation'
>;

export const ChangeCardComponent = (props: ChangeCardComponentProps) => (
  <ApolloReactComponents.Mutation<
    ChangeCardMutation,
    ChangeCardMutationVariables
  >
    mutation={ChangeCardDocument}
    {...props}
  />
);

/**
 * __useChangeCardMutation__
 *
 * To run a mutation, you first call `useChangeCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeCardMutation, { data, loading, error }] = useChangeCardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeCardMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ChangeCardMutation,
    ChangeCardMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ChangeCardMutation,
    ChangeCardMutationVariables
  >(ChangeCardDocument, baseOptions);
}
export type ChangeCardMutationHookResult = ReturnType<
  typeof useChangeCardMutation
>;
export type ChangeCardMutationResult = ApolloReactCommon.MutationResult<
  ChangeCardMutation
>;
export type ChangeCardMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ChangeCardMutation,
  ChangeCardMutationVariables
>;
export const DataPlanSensorsDocument = gql`
  query DataPlanSensors {
    me {
      id
      sensors(onlyOwned: true) {
        id
        name
        planActiveUntil
        planCancelDate
        activePlan
      }
      gateways(onlyOwned: true) {
        id
        name
        planActiveUntil
        planCancelDate
        activePlan
        planRequired
      }
    }
  }
`;
export type DataPlanSensorsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    DataPlanSensorsQuery,
    DataPlanSensorsQueryVariables
  >,
  'query'
>;

export const DataPlanSensorsComponent = (
  props: DataPlanSensorsComponentProps
) => (
  <ApolloReactComponents.Query<
    DataPlanSensorsQuery,
    DataPlanSensorsQueryVariables
  >
    query={DataPlanSensorsDocument}
    {...props}
  />
);

/**
 * __useDataPlanSensorsQuery__
 *
 * To run a query within a React component, call `useDataPlanSensorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataPlanSensorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataPlanSensorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDataPlanSensorsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    DataPlanSensorsQuery,
    DataPlanSensorsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    DataPlanSensorsQuery,
    DataPlanSensorsQueryVariables
  >(DataPlanSensorsDocument, baseOptions);
}
export function useDataPlanSensorsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    DataPlanSensorsQuery,
    DataPlanSensorsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    DataPlanSensorsQuery,
    DataPlanSensorsQueryVariables
  >(DataPlanSensorsDocument, baseOptions);
}
export type DataPlanSensorsQueryHookResult = ReturnType<
  typeof useDataPlanSensorsQuery
>;
export type DataPlanSensorsLazyQueryHookResult = ReturnType<
  typeof useDataPlanSensorsLazyQuery
>;
export type DataPlanSensorsQueryResult = ApolloReactCommon.QueryResult<
  DataPlanSensorsQuery,
  DataPlanSensorsQueryVariables
>;
export const UpdateDataPlanDocument = gql`
  mutation UpdateDataPlan($input: UpdateDataPlanInput!) {
    updateDataPlan(input: $input) {
      id
    }
  }
`;
export type UpdateDataPlanMutationFn = ApolloReactCommon.MutationFunction<
  UpdateDataPlanMutation,
  UpdateDataPlanMutationVariables
>;
export type UpdateDataPlanComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateDataPlanMutation,
    UpdateDataPlanMutationVariables
  >,
  'mutation'
>;

export const UpdateDataPlanComponent = (
  props: UpdateDataPlanComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateDataPlanMutation,
    UpdateDataPlanMutationVariables
  >
    mutation={UpdateDataPlanDocument}
    {...props}
  />
);

/**
 * __useUpdateDataPlanMutation__
 *
 * To run a mutation, you first call `useUpdateDataPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDataPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDataPlanMutation, { data, loading, error }] = useUpdateDataPlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDataPlanMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateDataPlanMutation,
    UpdateDataPlanMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateDataPlanMutation,
    UpdateDataPlanMutationVariables
  >(UpdateDataPlanDocument, baseOptions);
}
export type UpdateDataPlanMutationHookResult = ReturnType<
  typeof useUpdateDataPlanMutation
>;
export type UpdateDataPlanMutationResult = ApolloReactCommon.MutationResult<
  UpdateDataPlanMutation
>;
export type UpdateDataPlanMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateDataPlanMutation,
  UpdateDataPlanMutationVariables
>;
export const WeatherStationDocument = gql`
  query WeatherStation($id: String!, $tzoffset: Int!) {
    weatherStation(id: $id) {
      id
      name
      permissionLevel
      location {
        lat
        lng
      }
      fields {
        id
        name
      }
      ...CurrentWeatherSensor
      ...WeatherStationChart
    }
  }
  ${CurrentWeatherSensorFragmentDoc}
  ${WeatherStationChartFragmentDoc}
`;
export type WeatherStationComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    WeatherStationQuery,
    WeatherStationQueryVariables
  >,
  'query'
> &
  (
    | { variables: WeatherStationQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const WeatherStationComponent = (
  props: WeatherStationComponentProps
) => (
  <ApolloReactComponents.Query<
    WeatherStationQuery,
    WeatherStationQueryVariables
  >
    query={WeatherStationDocument}
    {...props}
  />
);

/**
 * __useWeatherStationQuery__
 *
 * To run a query within a React component, call `useWeatherStationQuery` and pass it any options that fit your needs.
 * When your component renders, `useWeatherStationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWeatherStationQuery({
 *   variables: {
 *      id: // value for 'id'
 *      tzoffset: // value for 'tzoffset'
 *   },
 * });
 */
export function useWeatherStationQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    WeatherStationQuery,
    WeatherStationQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    WeatherStationQuery,
    WeatherStationQueryVariables
  >(WeatherStationDocument, baseOptions);
}
export function useWeatherStationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    WeatherStationQuery,
    WeatherStationQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    WeatherStationQuery,
    WeatherStationQueryVariables
  >(WeatherStationDocument, baseOptions);
}
export type WeatherStationQueryHookResult = ReturnType<
  typeof useWeatherStationQuery
>;
export type WeatherStationLazyQueryHookResult = ReturnType<
  typeof useWeatherStationLazyQuery
>;
export type WeatherStationQueryResult = ApolloReactCommon.QueryResult<
  WeatherStationQuery,
  WeatherStationQueryVariables
>;
export const AddWeatherStationManagementPlanDocument = gql`
  mutation AddWeatherStationManagementPlan(
    $input: WeatherStationManagementPlanInput!
  ) {
    weatherStationManagementPlanAdd(input: $input) {
      weatherStationManagementPlan {
        id
      }
    }
  }
`;
export type AddWeatherStationManagementPlanMutationFn = ApolloReactCommon.MutationFunction<
  AddWeatherStationManagementPlanMutation,
  AddWeatherStationManagementPlanMutationVariables
>;
export type AddWeatherStationManagementPlanComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    AddWeatherStationManagementPlanMutation,
    AddWeatherStationManagementPlanMutationVariables
  >,
  'mutation'
>;

export const AddWeatherStationManagementPlanComponent = (
  props: AddWeatherStationManagementPlanComponentProps
) => (
  <ApolloReactComponents.Mutation<
    AddWeatherStationManagementPlanMutation,
    AddWeatherStationManagementPlanMutationVariables
  >
    mutation={AddWeatherStationManagementPlanDocument}
    {...props}
  />
);

/**
 * __useAddWeatherStationManagementPlanMutation__
 *
 * To run a mutation, you first call `useAddWeatherStationManagementPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddWeatherStationManagementPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addWeatherStationManagementPlanMutation, { data, loading, error }] = useAddWeatherStationManagementPlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddWeatherStationManagementPlanMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddWeatherStationManagementPlanMutation,
    AddWeatherStationManagementPlanMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AddWeatherStationManagementPlanMutation,
    AddWeatherStationManagementPlanMutationVariables
  >(AddWeatherStationManagementPlanDocument, baseOptions);
}
export type AddWeatherStationManagementPlanMutationHookResult = ReturnType<
  typeof useAddWeatherStationManagementPlanMutation
>;
export type AddWeatherStationManagementPlanMutationResult = ApolloReactCommon.MutationResult<
  AddWeatherStationManagementPlanMutation
>;
export type AddWeatherStationManagementPlanMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddWeatherStationManagementPlanMutation,
  AddWeatherStationManagementPlanMutationVariables
>;
export const UpdateWeatherStationManagementPlanDocument = gql`
  mutation UpdateWeatherStationManagementPlan(
    $input: WeatherStationManagementPlanInput!
    $planId: String!
  ) {
    weatherStationManagementPlanUpdate(input: $input, planId: $planId) {
      success
    }
  }
`;
export type UpdateWeatherStationManagementPlanMutationFn = ApolloReactCommon.MutationFunction<
  UpdateWeatherStationManagementPlanMutation,
  UpdateWeatherStationManagementPlanMutationVariables
>;
export type UpdateWeatherStationManagementPlanComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateWeatherStationManagementPlanMutation,
    UpdateWeatherStationManagementPlanMutationVariables
  >,
  'mutation'
>;

export const UpdateWeatherStationManagementPlanComponent = (
  props: UpdateWeatherStationManagementPlanComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateWeatherStationManagementPlanMutation,
    UpdateWeatherStationManagementPlanMutationVariables
  >
    mutation={UpdateWeatherStationManagementPlanDocument}
    {...props}
  />
);

/**
 * __useUpdateWeatherStationManagementPlanMutation__
 *
 * To run a mutation, you first call `useUpdateWeatherStationManagementPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWeatherStationManagementPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWeatherStationManagementPlanMutation, { data, loading, error }] = useUpdateWeatherStationManagementPlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *      planId: // value for 'planId'
 *   },
 * });
 */
export function useUpdateWeatherStationManagementPlanMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateWeatherStationManagementPlanMutation,
    UpdateWeatherStationManagementPlanMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateWeatherStationManagementPlanMutation,
    UpdateWeatherStationManagementPlanMutationVariables
  >(UpdateWeatherStationManagementPlanDocument, baseOptions);
}
export type UpdateWeatherStationManagementPlanMutationHookResult = ReturnType<
  typeof useUpdateWeatherStationManagementPlanMutation
>;
export type UpdateWeatherStationManagementPlanMutationResult = ApolloReactCommon.MutationResult<
  UpdateWeatherStationManagementPlanMutation
>;
export type UpdateWeatherStationManagementPlanMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateWeatherStationManagementPlanMutation,
  UpdateWeatherStationManagementPlanMutationVariables
>;
export const WeatherStationEditDocument = gql`
  query WeatherStationEdit($id: String!) {
    weatherStation(id: $id) {
      id
      name
      location {
        lat
        lng
      }
      managementPlan {
        id
        ...WeatherManagementPlanFields
      }
    }
    me {
      id
      weatherManagementPlans {
        id
        ...WeatherManagementPlanFields
      }
    }
  }
  ${WeatherManagementPlanFieldsFragmentDoc}
`;
export type WeatherStationEditComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    WeatherStationEditQuery,
    WeatherStationEditQueryVariables
  >,
  'query'
> &
  (
    | { variables: WeatherStationEditQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const WeatherStationEditComponent = (
  props: WeatherStationEditComponentProps
) => (
  <ApolloReactComponents.Query<
    WeatherStationEditQuery,
    WeatherStationEditQueryVariables
  >
    query={WeatherStationEditDocument}
    {...props}
  />
);

/**
 * __useWeatherStationEditQuery__
 *
 * To run a query within a React component, call `useWeatherStationEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useWeatherStationEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWeatherStationEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWeatherStationEditQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    WeatherStationEditQuery,
    WeatherStationEditQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    WeatherStationEditQuery,
    WeatherStationEditQueryVariables
  >(WeatherStationEditDocument, baseOptions);
}
export function useWeatherStationEditLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    WeatherStationEditQuery,
    WeatherStationEditQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    WeatherStationEditQuery,
    WeatherStationEditQueryVariables
  >(WeatherStationEditDocument, baseOptions);
}
export type WeatherStationEditQueryHookResult = ReturnType<
  typeof useWeatherStationEditQuery
>;
export type WeatherStationEditLazyQueryHookResult = ReturnType<
  typeof useWeatherStationEditLazyQuery
>;
export type WeatherStationEditQueryResult = ApolloReactCommon.QueryResult<
  WeatherStationEditQuery,
  WeatherStationEditQueryVariables
>;
export const DeleteWeatherStationManagementPlanDocument = gql`
  mutation DeleteWeatherStationManagementPlan($planId: String!) {
    weatherStationManagementPlanDelete(planId: $planId) {
      success
    }
  }
`;
export type DeleteWeatherStationManagementPlanMutationFn = ApolloReactCommon.MutationFunction<
  DeleteWeatherStationManagementPlanMutation,
  DeleteWeatherStationManagementPlanMutationVariables
>;
export type DeleteWeatherStationManagementPlanComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteWeatherStationManagementPlanMutation,
    DeleteWeatherStationManagementPlanMutationVariables
  >,
  'mutation'
>;

export const DeleteWeatherStationManagementPlanComponent = (
  props: DeleteWeatherStationManagementPlanComponentProps
) => (
  <ApolloReactComponents.Mutation<
    DeleteWeatherStationManagementPlanMutation,
    DeleteWeatherStationManagementPlanMutationVariables
  >
    mutation={DeleteWeatherStationManagementPlanDocument}
    {...props}
  />
);

/**
 * __useDeleteWeatherStationManagementPlanMutation__
 *
 * To run a mutation, you first call `useDeleteWeatherStationManagementPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWeatherStationManagementPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWeatherStationManagementPlanMutation, { data, loading, error }] = useDeleteWeatherStationManagementPlanMutation({
 *   variables: {
 *      planId: // value for 'planId'
 *   },
 * });
 */
export function useDeleteWeatherStationManagementPlanMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteWeatherStationManagementPlanMutation,
    DeleteWeatherStationManagementPlanMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteWeatherStationManagementPlanMutation,
    DeleteWeatherStationManagementPlanMutationVariables
  >(DeleteWeatherStationManagementPlanDocument, baseOptions);
}
export type DeleteWeatherStationManagementPlanMutationHookResult = ReturnType<
  typeof useDeleteWeatherStationManagementPlanMutation
>;
export type DeleteWeatherStationManagementPlanMutationResult = ApolloReactCommon.MutationResult<
  DeleteWeatherStationManagementPlanMutation
>;
export type DeleteWeatherStationManagementPlanMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteWeatherStationManagementPlanMutation,
  DeleteWeatherStationManagementPlanMutationVariables
>;
export const UpdateWeatherStationDocument = gql`
  mutation UpdateWeatherStation(
    $id: String!
    $input: WeatherStationUpdateInput!
  ) {
    weatherStationUpdate(id: $id, input: $input) {
      weatherStation {
        id
        name
        location {
          lat
          lng
        }
        managementPlan {
          id
        }
      }
    }
  }
`;
export type UpdateWeatherStationMutationFn = ApolloReactCommon.MutationFunction<
  UpdateWeatherStationMutation,
  UpdateWeatherStationMutationVariables
>;
export type UpdateWeatherStationComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateWeatherStationMutation,
    UpdateWeatherStationMutationVariables
  >,
  'mutation'
>;

export const UpdateWeatherStationComponent = (
  props: UpdateWeatherStationComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateWeatherStationMutation,
    UpdateWeatherStationMutationVariables
  >
    mutation={UpdateWeatherStationDocument}
    {...props}
  />
);

/**
 * __useUpdateWeatherStationMutation__
 *
 * To run a mutation, you first call `useUpdateWeatherStationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWeatherStationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWeatherStationMutation, { data, loading, error }] = useUpdateWeatherStationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWeatherStationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateWeatherStationMutation,
    UpdateWeatherStationMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateWeatherStationMutation,
    UpdateWeatherStationMutationVariables
  >(UpdateWeatherStationDocument, baseOptions);
}
export type UpdateWeatherStationMutationHookResult = ReturnType<
  typeof useUpdateWeatherStationMutation
>;
export type UpdateWeatherStationMutationResult = ApolloReactCommon.MutationResult<
  UpdateWeatherStationMutation
>;
export type UpdateWeatherStationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateWeatherStationMutation,
  UpdateWeatherStationMutationVariables
>;
export const WeatherStationListDocument = gql`
  query WeatherStationList {
    me {
      id
      weatherStations {
        id
        name
        location {
          lat
          lng
        }
        managementPlan {
          id
          alerts {
            type
            relationToThreshold
            threshold
          }
        }
        data {
          ... on WeatherSensorData {
            currentWeather {
              ... on WeatherStationData {
                time
                hum
                rnRate
                solarRad
                temp
                wbTemp
                windDir
                windSpd
                windErr
                rnRateErr
                solarRadErr
                tempErr
                mWindErr
                humErr
                rnCntErr
              }
              ... on SensorOffline {
                message
              }
            }
          }
          ... on SensorNoPlan {
            message
          }
        }
      }
    }
  }
`;
export type WeatherStationListComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    WeatherStationListQuery,
    WeatherStationListQueryVariables
  >,
  'query'
>;

export const WeatherStationListComponent = (
  props: WeatherStationListComponentProps
) => (
  <ApolloReactComponents.Query<
    WeatherStationListQuery,
    WeatherStationListQueryVariables
  >
    query={WeatherStationListDocument}
    {...props}
  />
);

/**
 * __useWeatherStationListQuery__
 *
 * To run a query within a React component, call `useWeatherStationListQuery` and pass it any options that fit your needs.
 * When your component renders, `useWeatherStationListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWeatherStationListQuery({
 *   variables: {
 *   },
 * });
 */
export function useWeatherStationListQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    WeatherStationListQuery,
    WeatherStationListQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    WeatherStationListQuery,
    WeatherStationListQueryVariables
  >(WeatherStationListDocument, baseOptions);
}
export function useWeatherStationListLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    WeatherStationListQuery,
    WeatherStationListQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    WeatherStationListQuery,
    WeatherStationListQueryVariables
  >(WeatherStationListDocument, baseOptions);
}
export type WeatherStationListQueryHookResult = ReturnType<
  typeof useWeatherStationListQuery
>;
export type WeatherStationListLazyQueryHookResult = ReturnType<
  typeof useWeatherStationListLazyQuery
>;
export type WeatherStationListQueryResult = ApolloReactCommon.QueryResult<
  WeatherStationListQuery,
  WeatherStationListQueryVariables
>;
