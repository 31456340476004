import React, { Suspense, lazy } from 'react';
import Loading from 'components/Loading/Loading';

const WeatherStation = lazy(() =>
  import(/* webpackChunkName: "WeatherStation" */ './WeatherStation')
);

const LoadableWeatherStation: React.FC = props => (
  <Suspense fallback={<Loading />}>
    <WeatherStation {...props} />
  </Suspense>
);

export default LoadableWeatherStation;
