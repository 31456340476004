import React from 'react';
import styled from 'styled-components';

import { useIsMobile } from 'providers/Responsive';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

import CloseIcon from '@material-ui/icons/Close';

const irrigationImg = require('./assets/irrigation_recommendation.png');

interface IrrigationRecommendationReleaseProps {
  onClose: () => void;
}

const IrrigationRecommendationRelease: React.FC<IrrigationRecommendationReleaseProps> = (
  props
) => {
  const isMobile = useIsMobile();
  return (
    <Container>
      <HeaderBar isMobile={isMobile}>
        {isMobile && (
          <IconButton onClick={props.onClose} style={{ color: 'white' }}>
            <CloseIcon />
          </IconButton>
        )}
        <HeaderText>New Feature: Irrigation Recommendations</HeaderText>
      </HeaderBar>
      <Body>
        <BodyText bold>
          We just launched a new feature that will help you make better
          irrigation decisions!
        </BodyText>
        <BodyText>
          Have you ever wondered how to make decisions based on your sensor
          data? To tackle that question, Trellis has added a few new features
          that will help turn the sensor data into actionable, field-level
          information.
        </BodyText>
        <Image src={irrigationImg} />
        <ButtonContainer>
          <Button
            variant="contained"
            color="primary"
            href="https://support.mytrellis.com/portal/kb/articles/trellis-irrigation-recommendations"
            target="_blank"
          >
            <ButtonText>Find out more!</ButtonText>
          </Button>
          <Button
            onClick={props.onClose}
            style={{ marginTop: 24, color: '#555', textTransform: 'none' }}
          >
            Close
          </Button>
        </ButtonContainer>
      </Body>
    </Container>
  );
};

const Container = styled.div``;

const HeaderBar = styled.div<{ isMobile?: boolean }>`
  width: 100%;
  padding: ${(p) => (p.isMobile ? '8px' : '24px')};
  background-color: ${(p) => p.theme.colors.primary};
  color: white;

  display: flex;
  flex-direction: row;
  align-items: center;
`;

const HeaderText = styled.div`
  font-size: ${(p) => p.theme.fontSizes.title};
  font-weight: ${(p) => p.theme.weights.bold};
  letter-spacing: 2px;
  text-align: center;
`;

const Body = styled.div`
  padding: 12px;
`;

const BodyText = styled.div<{ bold?: boolean }>`
  margin-bottom: 16px;
  ${(p) => (p.bold ? `font-weight: ${p.theme.weights.bold}` : '')}
`;

const ButtonText = styled.span`
  color: white;
  text-transform: none;
  font-size: ${(p) => p.theme.fontSizes.body};
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 8px;
`;

const Image = styled.img`
  margin-top: 8px;
  margin-bottom: 8px;
  width: 100%;
  padding: 0 20%;
`;

export default IrrigationRecommendationRelease;
