import * as React from 'react';
import { Link, RouteComponentProps } from '@reach/router';

const Code404: React.SFC<RouteComponentProps<{}>> = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: '20%',
        textAlign: 'center',
      }}
    >
      <h1>Page not found!</h1>
      <h3>Sorry, but the page you were looking for could not be found.</h3>
      <Link to="/" style={{ color: 'inherit' }}>
        <h4>Click here to return to the home page.</h4>
      </Link>
    </div>
  );
};

export default Code404;
