import React, { Suspense, lazy } from 'react';
import Loading from 'components/Loading/Loading';

const EditField = lazy(() =>
  import(/* webpackChunkName: "EditField" */ './EditField')
);

const LoadableEditField: React.FC = props => (
  <Suspense fallback={<Loading />}>
    <EditField {...props} />
  </Suspense>
);

export default LoadableEditField;
