import { useLayoutEffect } from 'react';
import { useIsMobile } from 'providers/Responsive';
import './chat.css';

interface Zoho {
  salesiq: {
    floatbutton?: {
      visible: (visibility: 'show' | 'hide') => void;
    };
  };
}

function showFloatButton() {
  const zoho = getZoho();
  if (zoho && zoho.salesiq.floatbutton) {
    zoho.salesiq.floatbutton.visible('show');
  }
}

function hideFloatButton() {
  const zoho = getZoho();
  if (zoho && zoho.salesiq.floatbutton) {
    zoho.salesiq.floatbutton.visible('hide');
  }
}

function showTextBubble() {
  // If you take this out after several refreshes it doesn’t always reload
  const element = document.getElementById('titlediv');
  if (element) element.style.display = 'block';
}

function hideTextBubble() {
  const element = document.getElementById('titlediv');
  if (element) element.classList.add('zoho-chat-hidden');
}

/**
 * Returned typed version of zoho
 */
const getZoho = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (window as any).$zoho as (Zoho | undefined);
};

export const useZohoChat = (drawerOpen: boolean) => {
  const isMobile = useIsMobile();
  let show = true;
  if (!drawerOpen) show = false;
  if (!isMobile) show = true;
  useLayoutEffect(() => {
    if (show) {
      showFloatButton();
      showTextBubble();
      setTimeout(() => hideTextBubble(), 15000);
    } else {
      hideFloatButton();
    }
  }, [show]);
};

export const useHideZoho = () => {
  useLayoutEffect(() => {
    hideFloatButton();
    return showFloatButton;
  }, []);
};
