import * as React from 'react';
import { ApolloProvider } from '@apollo/react-common';
import * as Sentry from '@sentry/browser';
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import {
  InMemoryCache,
  NormalizedCacheObject,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import { createApolloFetch } from 'apollo-fetch';
import { User } from '@firebase/auth-types';

import {
  isProduction,
  isDevelopment,
  isAquilaStorybook,
  isAquilaDevelopment,
} from 'services/env';
import { firebaseAuth } from 'services/auth';
import { AuthProvider } from 'providers/Auth';
import { ApolloLink } from 'apollo-link';

export let uri = '';
let validIframeOrigins: string[] = [];
if (isDevelopment) {
  // Local dev
  console.log('using local DB');
  uri = `http://localhost:4000/graphql/external`;
  validIframeOrigins = ['http://localhost:3100'];
} else if (isAquilaDevelopment || isAquilaStorybook) {
  // Online test
  console.log('using dev DB');
  uri = 'https://aquila-test.mytrellis.com/graphql/external/';
  validIframeOrigins = ['https://distributorportaldev-21cdd.firebaseapp.com'];
} else if (isProduction) {
  // Online production
  console.log('using production DB');
  uri = 'https://aquila.mytrellis.com/graphql/external/';
  validIframeOrigins = [
    'https://distributor.mytrellis.com',
    'https://distributor-portal-staging.firebaseapp.com',
  ];
}

const httpLink = createHttpLink({ uri });
const cache = new InMemoryCache({
  fragmentMatcher: new IntrospectionFragmentMatcher({
    introspectionQueryResultData: require('../../fragmentTypes.json'),
  }),
});

const omitTypenameLink = new ApolloLink((operation, forward) => {
  // Automatically strips __typename from input types
  if (operation.variables) {
    operation.variables = JSON.parse(
      JSON.stringify(operation.variables),
      omitTypename
    );
  }

  if (forward) return forward(operation);
  else return null;
});

function omitTypename(key: string, value: unknown) {
  return key === '__typename' ? undefined : value;
}

function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

const withApollo = (WrappedComponent: typeof React.Component) => {
  interface ApolloHOCState {
    token: string | null;
    loading: boolean;
    peekUid: string | null;
  }

  class ApolloHOC extends React.Component<{}, ApolloHOCState> {
    lastRefresh = 0;
    user: User | null = null;
    client: ApolloClient<NormalizedCacheObject>;
    fbUnsub: (() => void) | null = null;

    state: ApolloHOCState = {
      token: null,
      loading: true,
      peekUid: null,
    };

    inFlightTokenFromParent: Promise<string> | null = null;
    getTokenFromParent = () => {
      if (this.inFlightTokenFromParent) return this.inFlightTokenFromParent;
      const p = new Promise<string>((resolve, reject) => {
        const watchdog = setTimeout(() => {
          reject(new Error('Did not get token from parent in time'));
        }, 20000);
        const handler = (e: MessageEvent) => {
          const { data } = e;
          if (typeof data !== 'string' || !data.startsWith('TOKEN:')) return;
          const token = data.split(':')[1];
          window.removeEventListener('message', handler);
          clearTimeout(watchdog);
          this.inFlightTokenFromParent = null;
          resolve(token);
        };
        window.addEventListener('message', handler);
        window.parent.postMessage('REQUEST_TOKEN', '*');
      });
      this.inFlightTokenFromParent = p;
      return p;
    };

    getTokenFromFirebase = () => {
      if (!this.user) return null;
      return this.user.getIdToken(true);
    };

    refreshToken = async () => {
      const token = inIframe()
        ? await this.getTokenFromParent()
        : await this.getTokenFromFirebase();

      // Only actually setState the first time (loading = true)
      // or if it's a new token
      if (this.state.loading || token !== this.state.token) {
        this.setState({ token, loading: false });
        if (token) this.lastRefresh = Date.now();
      }

      return token;
    };

    constructor(props: {}) {
      super(props);
      const THIRTY_MINUTES = 1000 * 60 * 30;
      const authLink = setContext(async (_, { headers }) => {
        let { token, peekUid } = this.state;
        if (token) {
          if (Date.now() > this.lastRefresh + THIRTY_MINUTES) {
            token = await this.refreshToken();
          }
          const firebasetoken = peekUid ? `peek-${token}::${peekUid}` : token;
          return {
            headers: {
              ...headers,
              firebasetoken,
            },
          };
        } else {
          return {
            headers: {
              ...headers,
            },
          };
        }
      });

      const errorLink = onError(
        ({ graphQLErrors, networkError, operation }) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          Sentry.withScope((scope) => {
            scope.setExtra('operationName', operation.operationName);
            scope.setExtra('operationVariables', operation.variables);
            if (graphQLErrors) {
              scope.setExtra(
                'graphQLErrors',
                graphQLErrors.map(({ message }) => message)
              );
            }
            if (networkError) {
              console.error(networkError);
              scope.setExtra('networkError', networkError.message);
              scope.setExtra('networkErrorKeys', Object.keys(networkError));
              scope.setExtra('networkErrorStack', networkError.stack);
              scope.setExtra('networkErrorName', networkError.name);
            }
            scope.setExtra('firebasetoken', this.state.token);
            const prefix = networkError ? 'Network Error' : 'GraphQL Error';
            Sentry.captureMessage(`${prefix} - ${operation.operationName}`);
          });
        }
      );

      const link = errorLink.concat(
        omitTypenameLink.concat(authLink.concat(httpLink))
      );

      this.client = new ApolloClient({
        link,
        cache,
        defaultOptions: {
          watchQuery: {
            errorPolicy: 'all',
            fetchPolicy: 'cache-first',
          },
        },
      });
    }

    // Peek from distributor portal
    onPeek = (event: MessageEvent) => {
      if (validIframeOrigins.every((o) => o !== event.origin)) return;
      try {
        // Tell it that we got the message
        const { data } = event;
        const {
          token,
          targetUid,
        }: { token: string; targetUid: string } = JSON.parse(data);
        if (!token || !targetUid) return;
        window.parent.postMessage('GOT_PEEK_MSG', '*');
        Sentry.configureScope((scope) => scope.setUser({ username: 'Peeker' }));
        this.setState({ loading: true, peekUid: targetUid }, async () => {
          await this.client.resetStore();
          this.setState({
            token,
            loading: false,
          });
        });
      } catch (err) {
        // It wasn't a peek message
      }
    };

    componentDidMount() {
      if (!inIframe()) {
        // If we are in an iframe, we are peeking
        // If we are peeking, creds will come from parent, so we don't need
        // to use firebase
        this.fbUnsub = firebaseAuth().onAuthStateChanged((user) => {
          const { token } = this.state;
          if (token && token.startsWith('peek-')) return;
          this.user = user;

          // Reset graphql cache
          cache.reset();

          this.refreshToken();

          if (user !== null && !this.state.peekUid) {
            const query = `
            mutation LoginTracking($input: UpdateLoginInput!) {
              updateLoginTracking(input: $input) {
                id
              }
            }
          `;
            const variables = {
              input: { id: user.uid, timestamp: Date.now() },
            };

            const apolloFetch = createApolloFetch({ uri });

            apolloFetch({ query, variables }).catch(console.error);
          }
        });
      } else {
        // If we are peeking, creds will come from parent, so we don't need
        // to use firebase
        // Refreshing the token will request it from the parent
        this.refreshToken();
      }

      window.addEventListener('message', this.onPeek);
    }

    render() {
      if (this.state.loading) {
        // Wait on firebase auth to resolve before showing anything
        return null;
      }

      return (
        <ApolloProvider client={this.client}>
          <AuthProvider
            value={{
              authed: !!this.state.token,
              peeking: inIframe(),
            }}
          >
            <WrappedComponent {...this.props} />
          </AuthProvider>
        </ApolloProvider>
      );
    }

    componentWillUnmount() {
      if (this.fbUnsub) this.fbUnsub();
      window.removeEventListener('message', this.onPeek);
    }
  }

  return ApolloHOC;
};

export default withApollo;
