/*
Top most error handler.  If a component uses componentDidCatch above
 here, it will not be caught.  This is basically the equivilant of
 returning a 404 page.
*/

import * as React from 'react';
import * as Sentry from '@sentry/browser';

import GenericError from 'components/Errors/GenericError';

interface ErrorHandlerProps {}
interface ErrorHandlerState {
  hasError: boolean;
}

const withErrorHandler = (WrappedComponent: React.ComponentType) => {
  class ErrorHandler extends React.Component<
    ErrorHandlerProps,
    ErrorHandlerState
  > {
    constructor(props: ErrorHandlerProps) {
      super(props);
      this.state = { hasError: false };
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    componentDidCatch(error: Error | null, errorInfo: any) {
      Sentry.withScope(scope => {
        Object.keys(errorInfo).forEach(key => {
          scope.setExtra(key, errorInfo[key]);
        });
        Sentry.captureException(error);
      });
      this.setState({ hasError: true });
    }

    render() {
      if (this.state.hasError) {
        return <GenericError />;
      }
      return <WrappedComponent {...this.props} />;
    }
  }

  return ErrorHandler;
};

export default withErrorHandler;
