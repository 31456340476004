import React from 'react';
import { Link } from '@reach/router';
import gql from 'graphql-tag';

import Drawer from '@material-ui/core/Drawer';
import ButtonBase from '@material-ui/core/ButtonBase';

import { colors } from 'services/theme';
import { DesktopOnly } from 'providers/Responsive';

import SidebarButton from './components/SidebarButton';
import LinkedSidebarButton from './components/LinkedSidebarButton';
import { SidebarUserFragment } from 'types/graphql';

const FieldsIcon = require('../assets/fields.png');
const HardwareIcon = require('../assets/hardware.png');
const ArchiveIcon = require('../assets/archive.png');
const WeatherStationIcon = require('../assets/weather_station.png');
const IrrigationSystemIcon = require('../assets/irrigation_system.png');
const HelpIcon = require('../assets/help.png');

const TrellisDots = require('../assets/trellis_logo_white_dots_only.svg');

gql`
  fragment SidebarUser on User {
    id
    weatherStations {
      id
    }
    pressureSensors {
      id
    }
  }
`;

interface SidebarProps {
  isOpen: boolean;
  toggleDrawer: () => void;
  drawerType: 'permanent' | 'persistent' | 'temporary' | undefined;
  sideBarWidth: number;
  // returnPartialData is true
  user: Partial<SidebarUserFragment> | undefined;
}

const Sidebar: React.FC<SidebarProps> = (props) => {
  const { isOpen, toggleDrawer, drawerType, sideBarWidth, user } = props;

  const hasWeatherStation =
    !!user && !!user.weatherStations && user.weatherStations.length > 0;

  const hasPressureSensor =
    !!user && !!user.pressureSensors && user.pressureSensors.length > 0;

  return (
    <Drawer
      variant={drawerType}
      open={isOpen}
      onClose={toggleDrawer}
      style={{ zIndex: 1400 }}
    >
      <div
        style={{
          background: colors.primary,
          height: '100%',
          width: sideBarWidth,
        }}
      >
        <div style={{ height: 24 }} />

        {/* Trellis logo */}
        <DesktopOnly>
          <Link to="/">
            <ButtonBase style={{ padding: 0, width: '100%' }} disableRipple>
              <img src={TrellisDots} alt="Logo" style={{ width: '65%' }} />
            </ButtonBase>
          </Link>
          <div style={{ height: 24 }} />
        </DesktopOnly>

        {/* Navigation buttons */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            background: colors.primary,
          }}
        >
          <SidebarButton path="/fields" icon={FieldsIcon} text="Fields" />
          {hasWeatherStation && (
            <SidebarButton
              path="/weatherstations"
              icon={WeatherStationIcon}
              text="Weather"
            />
          )}
          {hasPressureSensor && (
            <SidebarButton
              path="/irrigationsystems"
              icon={IrrigationSystemIcon}
              text="Irrigation"
            />
          )}
          <SidebarButton path="/hardware" icon={HardwareIcon} text="Hardware" />
          <SidebarButton path="/archive" icon={ArchiveIcon} text="Archive" />
          <LinkedSidebarButton
            linkTo="https://support.mytrellis.com"
            icon={HelpIcon}
            text="Help"
          />
          {/* Padding for zoho button */}
          <div style={{ height: 96 }} />
        </div>
      </div>
    </Drawer>
  );
};

export default Sidebar;
