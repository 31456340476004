import React, { Suspense, lazy } from 'react';
import Loading from 'components/Loading/Loading';

const Notifications = lazy(() =>
  import(/* webpackChunkName: "Notifications" */ './Notifications')
);

const LoadableNotifications: React.FC = props => (
  <Suspense fallback={<Loading />}>
    <Notifications {...props} />
  </Suspense>
);

export default LoadableNotifications;
