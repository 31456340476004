import { useState, useEffect } from 'react';
import { globalHistory } from '@reach/router';

const useLocation = () => {
  const [loc, setLoc] = useState(globalHistory.location);

  useEffect(() => {
    const removeListener = globalHistory.listen(params => {
      setLoc(params.location);
    });
    return () => {
      removeListener();
    };
  }, []);

  return loc;
};

export default useLocation;
