import firebase from '@firebase/app';
import '@firebase/auth';
import { FirebaseAuth } from '@firebase/auth-types';

const prodConfig = {
  apiKey: 'AIzaSyCCXMsUxtTPnFGvbt-5z6b9UVCIbiD8cII',
  authDomain: 'trellis2.firebaseapp.com',
  databaseURL: 'https://trellis2.firebaseio.com',
  projectId: 'project-2290036988929696476',
  storageBucket: 'project-2290036988929696476.appspot.com',
  messagingSenderId: '252615291324',
};
const devConfig = {
  apiKey: 'AIzaSyBnHhFmE39eyyXEN4l8FUZ5d55hvAmEMJ8',
  authDomain: 'trellis-test.firebaseapp.com',
  databaseURL: 'https://trellis-test.firebaseio.com',
  projectId: 'trellis-test',
  storageBucket: 'trellis-test.appspot.com',
  messagingSenderId: '500800841131',
};
let config = {};
if (process.env.NODE_ENV === 'development') {
  // Local
  console.log('Using development auth');
  config = devConfig;
} else if (
  process.env.REACT_APP_AQUILA === 'development' ||
  process.env.STORYBOOK_AQUILA === 'development'
) {
  // Online test
  console.log('Using development auth');
  config = devConfig;
} else {
  // Online production
  console.log('Using production auth');
  config = prodConfig;
}

firebase.initializeApp(config);
export const firebaseAuth = firebase.auth as () => FirebaseAuth;

export function logout() {
  return firebaseAuth().signOut();
}

export function login(email: string, pw: string) {
  return firebaseAuth().signInWithEmailAndPassword(email, pw);
}

export function resetPassword(email: string) {
  return firebaseAuth().sendPasswordResetEmail(email);
}
