import React from 'react';
import { logout } from 'services/auth';
import { RouteComponentProps } from '@reach/router';

class ForceLogout extends React.Component<RouteComponentProps> {
  componentDidMount() {
    logout();
  }

  render() {
    return null;
  }
}

export default ForceLogout;
