import React from 'react';
import { Link, Location } from '@reach/router';
import styled from 'styled-components';

import Button from '@material-ui/core/ButtonBase';

import { colors } from 'services/theme';

type SidebarButtonProps = {
  path: string;
  icon: string;
  text: string;
};

const SidebarButton: React.FC<SidebarButtonProps> = ({ path, icon, text }) => {
  return (
    <Location>
      {({ location }) => {
        const active = `/${location.pathname.split('/')[1]}` === path;
        return (
          <Link to={path} style={{ textDecoration: 'none' }}>
            <Button
              color="primary"
              style={{
                width: '100%',
                minWidth: '100%',
                height: '100px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 0,
                background: active ? colors.primaryDark : colors.primary,
                paddingLeft: 0,
              }}
            >
              <SidebarImg src={icon} />
              <SidebarSpan className="sideButton">{text}</SidebarSpan>
            </Button>
          </Link>
        );
      }}
    </Location>
  );
};

const SidebarImg = styled.img`
  height: 46px;
  width: 46px;
  min-width: 0px;
`;

const SidebarSpan = styled.span`
  color: white;
  text-transform: none;
  margin-left: 0px;
  margin-top: 4px;
  margin-bottom: 2px;
  font-size: ${(p) => p.theme.fontSizes.body};
`;

export default SidebarButton;
