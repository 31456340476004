const { NODE_ENV, STORYBOOK_AQUILA, REACT_APP_AQUILA } = process.env;
const { version: pjsonVersion } = require('../../package.json');

export const isProduction = NODE_ENV === 'production';
export const isDevelopment = !isProduction;

export const isStorybook = STORYBOOK_AQUILA === 'development';
export const isJest = NODE_ENV === 'test';

export const isAquilaStorybook = STORYBOOK_AQUILA === 'development';
export const isAquilaDevelopment = REACT_APP_AQUILA === 'development';

export const isTesting = isAquilaStorybook || isJest;
export const version = pjsonVersion;
