import React, { useState } from 'react';
import gql from 'graphql-tag';

import Appbar from './components/Appbar/Appbar';
import Sidebar from './components/Sidebar/Sidebar';
import { useZohoChat } from 'services/chat';

import { useIsMobile } from 'providers/Responsive';

import ErrorBoundary from 'components/Errors/ErrorBoundary';
import { NOTIFICATION_POPUP_NOTIFICATION } from 'components/NotificationPopup';
import useLocation from 'hooks/location';
import { useOverlayQuery, SidebarUserFragmentDoc } from 'types/graphql';

gql`
  query Overlay(
    $sensorId: String!
    $fieldId: String!
    $archivedSensorId: String!
    $fetchSensor: Boolean!
    $fetchArchivedSensor: Boolean!
    $fetchField: Boolean!
  ) {
    me {
      id
      name {
        first
        last
      }
      hasAccountIssues
      notifications {
        id
        read
        ...NotificationPopupNotification
      }
      ...SidebarUser
    }
    sensor(id: $sensorId) @include(if: $fetchSensor) {
      id
      name
    }
    archivedSensor(id: $archivedSensorId) @include(if: $fetchArchivedSensor) {
      id
      name
    }
    field(id: $fieldId) @include(if: $fetchField) {
      id
      name
    }
  }
  ${NOTIFICATION_POPUP_NOTIFICATION}
  ${SidebarUserFragmentDoc}
`;

const SIDE_BAR_WIDTH = 94;
const APPBAR_HEIGHT = 64;

const Overlay: React.FC = (props) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const toggleDrawer = () => setDrawerOpen(!drawerOpen);
  const isMobile = useIsMobile();
  const drawerType = isMobile ? 'temporary' : 'permanent';
  useZohoChat(drawerOpen);

  const location = useLocation();

  let sensorId = '';
  let archivedSensorId = '';
  let fieldId = '';
  const sensorIdMatch = /sensor\/(.*?)(\/|$)/.exec(location.pathname);
  const fieldIdMatch = /field\/(.*?)(\/|$)/.exec(location.pathname);
  const isArchived = location.pathname.startsWith('/archive');
  if (sensorIdMatch) {
    if (isArchived) {
      archivedSensorId = sensorIdMatch[1];
    } else {
      sensorId = sensorIdMatch[1];
    }
  }
  if (fieldIdMatch) fieldId = fieldIdMatch[1];

  const { data, error, networkStatus } = useOverlayQuery({
    variables: {
      sensorId,
      archivedSensorId,
      fieldId,
      fetchSensor: !!sensorId,
      fetchArchivedSensor: !!archivedSensorId,
      fetchField: !!fieldId,
    },
    returnPartialData: false,
  });

  // This is the initial query, we never want to show until the intial query has finished
  // When the page changes, the status will be 2 (refetching)
  const loading = networkStatus === 1;

  if (!loading && !data) throw Error('No data');

  if (error) throw error;

  return (
    <>
      {/* 
        We want to begin loading the content even if the overlay hasn't 
        finished loading, so we render the content with "hidden" until
        the overlay is loaded
      */}
      {!loading && data && (
        <>
          <Appbar
            isMobile={isMobile}
            toggleDrawer={toggleDrawer}
            appBarHeight={APPBAR_HEIGHT}
            sideBarWidth={isMobile ? 0 : SIDE_BAR_WIDTH}
            data={data}
          />
          <Sidebar
            isOpen={drawerOpen}
            toggleDrawer={toggleDrawer}
            drawerType={drawerType}
            sideBarWidth={SIDE_BAR_WIDTH}
            user={data.me}
          />
        </>
      )}
      <div
        id="content"
        style={{
          paddingLeft: isMobile ? 0 : SIDE_BAR_WIDTH,
          paddingTop: APPBAR_HEIGHT,
          visibility: !loading ? 'visible' : 'hidden',
        }}
      >
        <ErrorBoundary>{props.children}</ErrorBoundary>
      </div>
    </>
  );
};

export default Overlay;
