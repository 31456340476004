import React from 'react';

import BasicModal from 'components/BasicModal';
import PopupContent from './PopupContent';
import { PopupNotification } from './types';

interface NotificationPopupModalProps {
  notification: PopupNotification;
  open: boolean;
  onClose: () => void;
}

const NotificationPopupModal: React.FC<NotificationPopupModalProps> = props => {
  return (
    <BasicModal
      open={props.open}
      onClose={props.onClose}
      testid="notification-popup-modal"
      maxWidth={600}
      noPadding
    >
      <PopupContent notification={props.notification} onClose={props.onClose} />
    </BasicModal>
  );
};

export default NotificationPopupModal;
