import * as React from 'react';
import { ApolloConsumer } from '@apollo/react-common';
import ApolloClient from 'apollo-client';
import * as Sentry from '@sentry/browser';
import gql from 'graphql-tag';

import { AnalyticsInfoQuery } from 'types/graphql';
import analytics from 'services/analytics';
import { getStoreCopy } from 'hooks/localstorage';
import { version } from 'services/env';

const GET_USER_ANALYTICS_INFO = gql`
  query AnalyticsInfo {
    me {
      id
      accountEmail
      name {
        first
        last
      }
    }
  }
`;

class AnalyticsContextSetter extends React.Component<{
  client: ApolloClient<object>;
}> {
  componentDidMount() {
    // Should only run when the user has just logged in
    // or has a cold start
    this.props.client
      .query<AnalyticsInfoQuery>({
        query: GET_USER_ANALYTICS_INFO,
      })
      .then(res => {
        const { id, accountEmail, name } = res.data.me;
        const email = accountEmail ? accountEmail : undefined;
        let username: string | undefined;
        if (name) {
          const { first, last } = name;
          if (first && last) username = `${first} ${last}`;
          else if (first) username = first;
          else if (last) username = last;
        }

        // Set Sentry user context
        Sentry.configureScope(scope => {
          scope.setUser({
            id,
            username,
            email,
          });
        });

        const store = getStoreCopy();

        // Update analytics
        analytics.set({
          username,
          email,
          version,
          fieldsPageSortBy: store.fieldsPageSort,
          fieldCardsCompact: store.fieldCardsCompact,
        });
      });
  }

  componentWillUnmount() {
    // Should only run when the user has just logged out
    // Unset Sentry user context
    Sentry.configureScope(scope => scope.setUser({}));
  }

  render() {
    return null;
  }
}

class AnalyticsContext extends React.Component {
  render() {
    return (
      <ApolloConsumer>
        {client => <AnalyticsContextSetter client={client} />}
      </ApolloConsumer>
    );
  }
}

export default AnalyticsContext;
