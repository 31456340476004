import React, { Suspense, lazy } from 'react';
import Loading from 'components/Loading/Loading';

const Login = lazy(() => import(/* webpackChunkName: "Login" */ './Login'));

const LoadableLogin: React.FC = props => (
  <Suspense fallback={<Loading />}>
    <Login {...props} />
  </Suspense>
);

export default LoadableLogin;
