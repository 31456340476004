import React from 'react';
import { Location } from '@reach/router';
import styled from 'styled-components';

import Button from '@material-ui/core/ButtonBase';

import { colors } from 'services/theme';

type LinkedSidebarButtonProps = {
  linkTo: string;
  icon: string;
  text: string;
};

const LinkedSidebarButton: React.FC<LinkedSidebarButtonProps> = ({
  linkTo,
  icon,
  text,
}) => {
  return (
    <Location>
      {({}) => {
        return (
          <a href={linkTo} style={{ textDecoration: 'none' }}>
            <Button
              color="primary"
              style={{
                width: '100%',
                minWidth: '100%',
                height: '100px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 0,
                background: colors.primary,
                paddingLeft: 0,
              }}
            >
              <LinkedSidebarImg src={icon} />
              <LinkedSidebarSpan className="sideButton">
                {text}
              </LinkedSidebarSpan>
            </Button>
          </a>
        );
      }}
    </Location>
  );
};

const LinkedSidebarImg = styled.img`
  height: 46px;
  width: 46px;
  min-width: 0px;
`;

const LinkedSidebarSpan = styled.span`
  color: white;
  text-transform: none;
  margin-left: 0px;
  margin-top: 4px;
  margin-bottom: 2px;
  font-size: ${(p) => p.theme.fontSizes.body};
`;

export default LinkedSidebarButton;
