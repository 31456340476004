import React, { Suspense, lazy } from 'react';
import Loading from 'components/Loading/Loading';

const ArchivedSensor = lazy(() =>
  import(/* webpackChunkName: "ArchivedSensor" */ './ArchivedSensor')
);

const LoadableArchivedSensor: React.FC = props => (
  <Suspense fallback={<Loading />}>
    <ArchivedSensor {...props} />
  </Suspense>
);
export default LoadableArchivedSensor;
