import React from 'react';
import { Link } from '@reach/router';
import { colors } from 'services/theme';
import history from 'services/history';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ButtonBase from '@material-ui/core/ButtonBase';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Popover from '@material-ui/core/Popover';

import MenuIcon from '@material-ui/icons/Menu';
import RightArrowIcon from '@material-ui/icons/KeyboardArrowRight';
import DropdownIcon from '@material-ui/icons/ArrowDropDown';

import NotificationsButton from './components/NotificationsButton';
import NotificationPopup from 'components/NotificationPopup';

const emptyMan = require('../assets/profile.png');

import { logout } from 'services/auth';
import { FontClasses } from 'services/theme';

import { OverlayQuery } from 'types/graphql';
import { getValidNotifications } from 'services/notification';

const menuButtonId = 'appBarMenuButton';

type AppbarProps = {
  isMobile: boolean;
  toggleDrawer: () => void;
  appBarHeight: number;
  sideBarWidth: number;
  data: Partial<OverlayQuery>;
};

interface AppbarState {
  menuOpen: boolean;
}

class Appbar extends React.PureComponent<AppbarProps, AppbarState> {
  state: AppbarState = {
    menuOpen: false,
  };

  expanderPressed = () => {
    this.props.toggleDrawer();
  };

  menuButton: HTMLElement | null = null;

  openMenu = () => {
    this.setState({
      menuOpen: true,
    });
  };

  componentDidMount() {
    this.menuButton = document.getElementById(menuButtonId) as HTMLElement;
  }

  render() {
    const { data, appBarHeight, sideBarWidth, isMobile } = this.props;

    const { field, sensor: liveSensor, archivedSensor, me } = data;
    const sensor = liveSensor || archivedSensor;

    const { pathname } = window.location;
    const parts = pathname.split('/');
    const rtSettings = pathname.slice(0, 9) === '/settings';
    const rtProfile = rtSettings && parts.indexOf('profile') !== -1;
    const rtSubscriptions = rtSettings && parts.indexOf('subscriptions') !== -1;
    const rtInvoices = rtSettings && parts.indexOf('invoices') !== -1;
    const rtManageUsers = rtSettings && parts.indexOf('users') !== -1;
    const rtNotifications = rtSettings && parts.indexOf('notifications') !== -1;

    const onNotificationPage = location.pathname === '/notifications';
    const onIssuePage = location.pathname === '/issues';

    let notificationCount = 0;
    if (me && me.notifications) {
      notificationCount = getValidNotifications(
        me.notifications.filter((n) => !n.read)
      ).length;
    }
    if (onNotificationPage || onIssuePage) notificationCount = 0;

    const menuItemStyle = { minWidth: 96 };
    return (
      <AppBar
        style={{
          zIndex: 1200,
          height: appBarHeight,
          paddingLeft: sideBarWidth,
          background: colors.primaryExtraLight,
          left: 0,
          right: 'auto',
          overflow: 'hidden',
        }}
        elevation={1}
        color="secondary"
      >
        <Toolbar
          id="AppBarToolbar"
          style={{
            paddingLeft: 0,
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingRight: 0,
          }}
        >
          {/* Minimize/maximize sidebar button */}
          {isMobile && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                margin: '0 10px',
              }}
            >
              <IconButton onClick={this.expanderPressed}>
                <MenuIcon style={{ fontSize: 36 }} />
              </IconButton>
            </div>
          )}

          {/* Current route info (fields) */}
          {!isMobile && (field || sensor) && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: 48,
              }}
            >
              {field && (
                <ButtonBase
                  disableRipple
                  onClick={() => history.push(`/field/${field.id}`)}
                >
                  <span className={FontClasses.title}>{field.name}</span>
                </ButtonBase>
              )}
              {field && sensor && <RightArrowIcon />}
              {sensor && (
                <ButtonBase disableRipple>
                  <span className={FontClasses.title}>{sensor.name}</span>
                </ButtonBase>
              )}
            </div>
          )}

          {/* Current route info (settings) */}
          {!isMobile && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: 48,
              }}
            >
              {rtSettings && (
                <ButtonBase
                  disableRipple
                  onClick={() => history.push(`/settings`)}
                >
                  <span className={FontClasses.title}>Settings</span>
                </ButtonBase>
              )}
              {rtProfile && <RightArrowIcon />}
              {rtProfile && (
                <ButtonBase
                  disableRipple
                  onClick={() => history.push(`/settings/profile`)}
                >
                  <span className={FontClasses.title}>Profile</span>
                </ButtonBase>
              )}
              {rtSubscriptions && <RightArrowIcon />}
              {rtSubscriptions && (
                <ButtonBase
                  disableRipple
                  onClick={() => history.push(`/settings/subscriptions`)}
                >
                  <span className={FontClasses.title}>Subscriptions</span>
                </ButtonBase>
              )}
              {rtInvoices && <RightArrowIcon />}
              {rtInvoices && (
                <ButtonBase
                  disableRipple
                  onClick={() => history.push(`/settings/profile/invoices`)}
                >
                  <span className={FontClasses.title}>View Invoices</span>
                </ButtonBase>
              )}
              {rtManageUsers && <RightArrowIcon />}
              {rtManageUsers && (
                <ButtonBase
                  disableRipple
                  onClick={() => history.push(`/settings/users`)}
                >
                  <span className={FontClasses.title}>Manage Shared Users</span>
                </ButtonBase>
              )}
              {rtNotifications && <RightArrowIcon />}
              {rtNotifications && (
                <ButtonBase
                  disableRipple
                  onClick={() => history.push(`/settings/notifications`)}
                >
                  <span className={FontClasses.title}>Notifications</span>
                </ButtonBase>
              )}
            </div>
          )}

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <NotificationsButton
              active={onNotificationPage}
              unread={notificationCount}
            />
            <NotificationPopup
              notifications={
                data && data.me && data.me.notifications
                  ? data.me.notifications
                  : []
              }
            />
            <div style={{ width: 5 }} />
            <ButtonBase
              onClick={this.openMenu}
              className={FontClasses.title}
              style={{
                height: appBarHeight,
                color: colors.text,
                padding: 5,
              }}
              id={menuButtonId}
            >
              <img
                src={emptyMan}
                style={{
                  height: 36,
                  width: 36,
                  borderRadius: '100%',
                  borderColor: 'white',
                  borderStyle: 'solid',
                  background: colors.grey,
                }}
              />
              <div style={{ width: 8 }} />
              {!isMobile && `${me?.name.first} ${me?.name.last}`}
              <DropdownIcon />
            </ButtonBase>
            <Popover
              open={this.state.menuOpen}
              onClose={() => this.setState({ menuOpen: false })}
              anchorEl={this.menuButton}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              marginThreshold={0}
              id="appBarMenu"
            >
              <MenuList>
                <Link to="/settings" style={{ textDecoration: 'none' }}>
                  <MenuItem
                    onClick={() => {}}
                    style={menuItemStyle}
                    id="settingsButton"
                  >
                    <span className={FontClasses.subtitle}>Settings</span>
                  </MenuItem>
                </Link>
                <a
                  href="https://support.mytrellis.com"
                  style={{ textDecoration: 'none' }}
                >
                  <MenuItem
                    onClick={() => {}}
                    style={menuItemStyle}
                    id="helpButton"
                  >
                    <span className={FontClasses.subtitle}>Help</span>
                  </MenuItem>
                </a>
                <MenuItem
                  onClick={logout}
                  style={menuItemStyle}
                  id="logoutButton"
                >
                  <span className={FontClasses.subtitle}>Logout</span>
                </MenuItem>
              </MenuList>
            </Popover>
          </div>
        </Toolbar>
      </AppBar>
    );
  }
}

export default Appbar;
