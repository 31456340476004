import {
  NotificationPopupNotificationFragment as Notification,
  PopupId,
} from 'types/graphql';

export type PopupNotification = Extract<
  Notification,
  | { __typename: 'NotificationAnnouncement' }
  | { __typename: 'NotificationRelease' }
>;

export function isPopupNotification(n: Notification): n is PopupNotification {
  return (
    n.__typename === 'NotificationAnnouncement' ||
    n.__typename === 'NotificationRelease'
  );
}

const implementedPopupIds = [
  PopupId.SensorManagementPlanRelease,
  PopupId.IrrigationRecommendationRelease,
];

export function isImplementedPopupId(id: PopupId | null) {
  if (!id) return false;
  return !!implementedPopupIds.find(e => e === id);
}

export type ShowableNotification = PopupNotification & { read: false };

export function isShowableNotification(
  n: PopupNotification
): n is ShowableNotification {
  if (n.read) return false;
  if (n.__typename === 'NotificationRelease') return true;
  return isImplementedPopupId(n.popupToShow);
}
