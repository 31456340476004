import React, { Suspense, lazy } from 'react';
import Loading from 'components/Loading/Loading';

const AddField = lazy(() =>
  import(/* webpackChunkName: "AddField" */ './AddField')
);

const LoadableAddField: React.FC = props => (
  <Suspense fallback={<Loading />}>
    <AddField {...props} />
  </Suspense>
);

export default LoadableAddField;
