// Polyfills
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import { isProduction, version } from 'services/env';
import { disableFragmentWarnings } from 'graphql-tag';
disableFragmentWarnings();

if (isProduction) {
  Sentry.init({
    dsn: 'https://1e41c9325e1441f0a133d889826bec25@sentry.io/1323086',
    release: version,
  });
}

import Wrapped from './wrapper';

ReactDOM.render(<Wrapped />, document.getElementById('root'));
