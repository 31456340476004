import React, { Suspense, lazy } from 'react';
import Loading from 'components/Loading/Loading';

const Activate = lazy(() =>
  import(/* webpackChunkName: "Activate" */ './Activate')
);

const LoadableActivate: React.FC = props => (
  <Suspense fallback={<Loading />}>
    <Activate {...props} />
  </Suspense>
);

export default LoadableActivate;
