import React, { Suspense, lazy } from 'react';
import Loading from 'components/Loading/Loading';

const WeatherStationList = lazy(() =>
  import(/* webpackChunkName: "WeatherStationList" */ './WeatherStationList')
);

const LoadableWeatherStationList: React.FC = props => (
  <Suspense fallback={<Loading />}>
    <WeatherStationList {...props} />
  </Suspense>
);

export default LoadableWeatherStationList;
