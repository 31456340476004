import React, { Suspense, lazy } from 'react';
import Loading from 'components/Loading/Loading';

const SensorEdit = lazy(() =>
  import(/* webpackChunkName: "SensorEdit" */ './SensorEdit')
);

const LoadableSensorEdit: React.FC = props => (
  <Suspense fallback={<Loading />}>
    <SensorEdit {...props} />
  </Suspense>
);

export default LoadableSensorEdit;
