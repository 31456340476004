import moment from 'moment';
import { NotificationListNotificationFragment as Notification } from 'types/graphql';
import { NotificationProps } from 'pages/Notifications/components/Notification';
import { isImplementedPopupId } from 'components/NotificationPopup/types';

function nonNull<T>(value: T | null): value is T {
  return value !== null;
}

export const parseNotifications = (
  notifications: Notification[] | null
): NotificationProps[] => {
  if (!notifications) return [];
  return notifications
    .map(n => {
      switch (n.__typename) {
        case 'NotificationDrySensor':
          let secondary = 'Click to view graph';
          if (n.timestamp) {
            const occured = moment(n.timestamp);
            const now = moment();
            secondary = moment.duration(occured.diff(now)).humanize(true);
          }
          return {
            read: n.read,
            primary: `Sensor ${n.sensorId} is looking dry!`,
            secondary,
            type: 'alert' as const,
            href: `/sensor/${n.sensorId}`,
            id: n.id,
            popup: null,
          };
        case 'NotificationAnnouncement':
          if (n.popupToShow && !isImplementedPopupId(n.popupToShow))
            return null;
          return {
            read: n.read,
            primary: n.primary,
            secondary: n.secondary,
            type: 'message' as const,
            href: n.href,
            id: n.id,
            popup: n,
          };
        case 'NotificationSoilTypePopulate':
          return {
            read: n.read,
            primary: `Based on it's location, we selected ${n.soilType} as the soil texture for ${n.sensorName}`,
            secondary:
              "If you would like to change it, you can do so on the sensor's page",
            type: 'message' as const,
            href: `/sensor/${n.sensorId}`,
            id: n.id,
            popup: null,
          };
        case 'NotificationRelease':
          return {
            read: n.read,
            primary: n.primary,
            secondary: n.secondary,
            id: n.id,
            popup: n,
            type: 'message' as const,
          };
        default:
          return null;
      }
    })
    .filter(nonNull);
};

const VALID_NOTIFICATION_TYPES = [
  'NotificationDrySensor',
  'NotificationAnnouncement',
  'NotificationSoilTypePopulate',
  'NotificationRelease',
];

export const getValidNotifications = (
  notifications: { __typename: string }[]
) => {
  return notifications.filter(n =>
    VALID_NOTIFICATION_TYPES.includes(n.__typename)
  );
};
