import React, { Suspense, lazy } from 'react';
import Loading from 'components/Loading/Loading';

const PressureSensor = lazy(() =>
  import(/* webpackChunkName: "PressureSensor" */ './PressureSensor')
);

const LoadablePressureSensor: React.FC = props => (
  <Suspense fallback={<Loading />}>
    <PressureSensor {...props} />
  </Suspense>
);

export default LoadablePressureSensor;
