import React, { Suspense, lazy } from 'react';
import Loading from '../../components/Loading/Loading';

const Hardware = lazy(() =>
  import(/* webpackChunkName: "Hardware" */ './Hardware')
);

const LoadableHardware: React.FC = props => (
  <Suspense fallback={<Loading />}>
    <Hardware {...props} />
  </Suspense>
);

export default LoadableHardware;
