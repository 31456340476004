import * as React from 'react';
import { Router, Redirect, RouteComponentProps } from '@reach/router';

import Code404 from 'components/Errors/Code404';
import AnalyticsContext from 'components/AnalyticsContext';
import ForceLogout from 'components/ForceLogout';

import { withAuth, InjectedAuthProps } from 'providers/Auth';

import Login from 'pages/Entry/Login/LoadableLogin';
import Activate from 'pages/Entry/Activate/LoadableActivate';

import Overlay from 'pages/Overlay/Overlay';

import Fields from 'pages/Fields/LoadableFields';
import Field from 'pages/Field/LoadableField';
import AddField from 'pages/AddField/LoadableAddField';
import EditField from 'pages/EditField/LoadableEditField';
import Hardware from 'pages/Hardware/LoadableHardware';
import Sensor from 'pages/Sensor/LoadableSensor';
import Settings from 'pages/Settings/LoadableSettings';
import MissingInfo from 'pages/MissingInfo/LoadableMissingInfo';
import UserNotifications from 'pages/Notifications/LoadableNotifications';
import Archive from 'pages/Archive/LoadableArchive';
import ArchivedSensor from 'pages/ArchivedSensor/LoadableArchivedSensor';
import ArchivedField from 'pages/ArchivedField/LoadableArchivedField';
import Gateway from 'pages/Gateway/LoadableGateway';
import SensorEdit from 'pages/SensorEdit/LoadableSensorEdit';
import WeatherStation from 'pages/WeatherStation/LoadableWeatherStation';
import WeatherStationEdit from 'pages/WeatherStationEdit/LoadableWeatherStationEdit';
import WeatherStationList from 'pages/WeatherStationList/LoadableWeatherStationList';
import IrrigationSystemList from 'pages/IrrigationSystemList/LoadableIrrigationSystemList';
import IrrigationSystem from 'pages/IrrigationSystem/LoadableIrrigationSystem';
import PressureSensor from 'pages/PressureSensor/LoadablePressureSensor';
import PressureSensorEdit from 'pages/PressureSensorEdit/LoadablePressureSensorEdit';
import IrrigationSystemForm from 'pages/IrrigationSystemForm/LoadableIrrigationSystemForm';
import ArchivedIrrigationSystem from 'pages/ArchivedIrrigationSystem/LoadableArchivedIrrigationSystem';
import ArchivedPressureSensor from 'pages/ArchivedPressureSensor/LoadableArchivedPressureSensor';
import LoadableRegister from 'pages/Entry/Register/LoadableRegister';

interface RouteProps {
  path: string;
  component: React.ComponentType<RouteComponentProps<{}>>;
  forceLogout?: boolean;
}

type AppProps = InjectedAuthProps;

class App extends React.Component<AppProps> {
  render() {
    const { authed } = this.props;

    // These routes can also be accessed via /fields/etc
    // We maybe can remove these after a while, idk if it provides any value
    // It would break old links though
    const fieldableRoutes: RouteProps[] = [
      {
        path: 'sensor/:sensorId',
        component: Sensor,
      },
      {
        path: 'sensor/:sensorId/edit',
        component: SensorEdit,
      },
      {
        path: 'ws/:wsId',
        component: WeatherStation,
      },
      {
        path: 'ws/:wsId/edit',
        component: WeatherStationEdit,
      },
    ];

    const loggedInRoutes: RouteProps[] = [
      {
        path: '/',
        component: () => <Redirect from="/" to="fields" noThrow />,
      },
      {
        path: 'fields',
        component: Fields,
      },
      {
        path: 'fields/add',
        component: AddField,
      },
      {
        path: 'field/:fieldId',
        component: Field,
      },
      {
        path: 'field/:fieldId/edit',
        component: EditField,
      },
      ...fieldableRoutes,
      ...fieldableRoutes.map(({ path, component }) => ({
        path: `field/:fieldId/${path}`,
        component,
      })),
      {
        path: 'irrigationsystems',
        component: IrrigationSystemList,
      },
      {
        path: 'irrigationsystem/:irrigationSystemId',
        component: IrrigationSystem,
      },
      {
        path: 'irrigationsystem/:irrigationSystemId/edit',
        component: IrrigationSystemForm,
      },
      {
        path: 'irrigationsystem/add',
        component: IrrigationSystemForm,
      },
      {
        path: 'pressuresensor/:pressureSensorId',
        component: PressureSensor,
      },
      {
        path: 'pressuresensor/:pressureSensorId/edit',
        component: PressureSensorEdit,
      },
      {
        path: 'base/:gatewayId',
        component: Gateway,
      },
      {
        path: 'hardware',
        component: Hardware,
      },
      {
        path: 'archive',
        component: Archive,
      },
      {
        path: 'archive/sensor/:sensorId',
        component: ArchivedSensor,
      },
      {
        path: 'archive/field/:fieldId',
        component: ArchivedField,
      },
      {
        path: 'archive/irrigationsystem/:irrigationSystemId',
        component: ArchivedIrrigationSystem,
      },
      {
        path: 'archive/pressuresensor/:pressureSensorId',
        component: ArchivedPressureSensor,
      },
      {
        path: 'settings/*',
        component: Settings,
      },
      {
        path: 'missing',
        component: MissingInfo,
      },
      {
        path: 'notifications',
        component: UserNotifications,
      },
      {
        path: 'weatherstations',
        component: WeatherStationList,
      },
    ];

    const loggedOutRoutes: RouteProps[] = [
      {
        path: 'login',
        component: Login,
      },
      {
        path: 'activate',
        component: Activate,
        forceLogout: true,
      },
      {
        path: 'register',
        component: LoadableRegister,
        forceLogout: true,
      },
    ];

    if (!authed) {
      return (
        <Router>
          {/* Public */}
          {loggedOutRoutes.map(({ component: Component, path }) => {
            return <Component path={path} key={path} />;
          })}
          <Redirect from="/" to="login" default noThrow />
        </Router>
      );
    } else {
      return (
        <Overlay>
          <AnalyticsContext />

          <Router>
            {/* Private */}
            {loggedInRoutes.map(({ component: Component, path }) => (
              <Component path={path} key={path} />
            ))}

            {/* If someone goes to activate while logged in, log them out */}
            {loggedOutRoutes.map(({ path, forceLogout }) =>
              forceLogout ? (
                <ForceLogout path={path} key={path} />
              ) : (
                <RedirectComponent path={path} to="/" key={path} />
              )
            )}

            {/* 404 */}
            <Code404 default />
          </Router>
        </Overlay>
      );
    }
  }
}

const RedirectComponent: React.FC<RouteComponentProps<{
  to: string;
}>> = (props) => <Redirect to={props.to} noThrow />;

export default withAuth(App);
