import React, { Suspense, lazy } from 'react';
import Loading from 'components/Loading/Loading';

const Register = lazy(() =>
  import(/* webpackChunkName: "Activate" */ './Register')
);

const LoadableRegister: React.FC = (props) => (
  <Suspense fallback={<Loading />}>
    <Register {...props} />
  </Suspense>
);

export default LoadableRegister;
