import React, { Suspense, lazy } from 'react';
import Loading from 'components/Loading/Loading';

const ArchivedIrrigationSystem = lazy(() =>
  import(
    /* webpackChunkName: "ArchivedIrrigationSystem" */ './ArchivedIrrigationSystem'
  )
);

const LoadableArchivedIrrigationSystem: React.FC = props => (
  <Suspense fallback={<Loading />}>
    <ArchivedIrrigationSystem {...props} />
  </Suspense>
);

export default LoadableArchivedIrrigationSystem;
