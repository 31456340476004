import React, { useContext } from 'react';

const authCtx = React.createContext({
  authed: false,
  peeking: false,
});

export const { Provider: AuthProvider, Consumer: AuthConsumer } = authCtx;

export type InjectedAuthProps = {
  authed: boolean;
  peeking: boolean;
};

export const withAuth = <T extends InjectedAuthProps>(
  Component: React.ComponentType<T>
) =>
  class WithAuth extends React.Component<T & InjectedAuthProps> {
    render() {
      return (
        <AuthConsumer>
          {auth => <Component {...this.props} {...auth} />}
        </AuthConsumer>
      );
    }
  };

export function useAuth() {
  return useContext(authCtx);
}
