import * as React from 'react';
import * as Sentry from '@sentry/browser';
import { isRedirect } from '@reach/router';

import GenericError from './GenericError';

interface ErrorBoundaryProps {
  header?: string;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error?: Error;
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  componentDidCatch(error: Error, errorInfo: any) {
    // Propogate reach router redirect
    if (isRedirect(error)) throw error;

    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
    this.setState({ hasError: true, error });
  }

  render() {
    const { header } = this.props;
    if (this.state.hasError) {
      return (
        <GenericError header={header} message="" error={this.state.error} />
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
