import * as React from 'react';
import { FontClasses } from 'services/theme';

interface GenericErrorProps {
  error?: Error;
  message?: string;
  header?: string;
}

const GenericError: React.SFC<GenericErrorProps> = props => {
  let debug = false;
  console.error('GenericError: ', props.error);
  const { header = 'Sorry, something went wrong!' } = props;
  if (
    process.env.REACT_APP_AQUILA === 'development' ||
    process.env.STORYBOOK_AQUILA === 'development'
  ) {
    debug = true;
  }
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: '20%',
        textAlign: 'center',
      }}
    >
      <h1>{header}</h1>
      {props.message !== undefined ? (
        <h4>{props.message}</h4>
      ) : (
        <a href="/" style={{ color: 'inherit' }}>
          <h4>Click here to return to the home page.</h4>
        </a>
      )}
      {debug && (
        <pre className={FontClasses.caption}>
          {props.error && props.error.message}
        </pre>
      )}
    </div>
  );
};

export default GenericError;
