import React, { Suspense, lazy } from 'react';
import Loading from 'components/Loading/Loading';

const WeatherStationEdit = lazy(() =>
  import(/* webpackChunkName: "WeatherStationEdit" */ './WeatherStationEdit')
);

const LoadableWeatherStationEdit: React.FC = props => (
  <Suspense fallback={<Loading />}>
    <WeatherStationEdit {...props} />
  </Suspense>
);

export default LoadableWeatherStationEdit;
