import React, { Suspense, lazy } from 'react';
import Loading from 'components/Loading/Loading';

const Fields = lazy(() => import(/* webpackChunkName: "Fields" */ './Fields'));

const LoadableFields: React.FC = props => (
  <Suspense fallback={<Loading />}>
    <Fields {...props} />
  </Suspense>
);

export default LoadableFields;
