import React, { Suspense, lazy } from 'react';
import Loading from 'components/Loading/Loading';

const ArchivedField = lazy(() =>
  import(/* webpackChunkName: "ArchivedField" */ './ArchivedField')
);

const LoadableArchivedField: React.FC<
  import('./ArchivedField').ArchivedFieldProps
> = props => (
  <Suspense fallback={<Loading />}>
    <ArchivedField {...props} />
  </Suspense>
);

export default LoadableArchivedField;
