import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import first from 'lodash/first';

import { NotificationPopupNotificationFragment as Notification } from 'types/graphql';

import NotificationPopupModal from './NotificationPopupModal';
import {
  isPopupNotification,
  isShowableNotification,
  ShowableNotification,
} from './types';
import { useMarkNotifictionAsRead } from 'hooks/markAsRead';

export const NOTIFICATION_POPUP_NOTIFICATION = gql`
  fragment NotificationPopupNotification on Notification {
    __typename
    id
    read
    ... on NotificationAnnouncement {
      popupToShow
    }
    ... on NotificationRelease {
      primary
      secondary
      blogPostURL: href
      header
      body
      img
    }
  }
`;

interface NotificationPopupProps {
  notifications: Notification[];
}

const NotificationPopup: React.FC<NotificationPopupProps> = props => {
  const [open, setOpen] = useState(true);
  const [
    notificationToShow,
    setNotificationToShow,
  ] = useState<ShowableNotification | null>(null);
  const markAsRead = useMarkNotifictionAsRead();

  // If we already have a notification to show, we pretend there
  // are no notifications to show, so it won't change the displayed
  // notification
  const notification = notificationToShow
    ? undefined
    : first(
        props.notifications
          .filter(isPopupNotification)
          .filter(isShowableNotification)
      );

  // If we have a notification, store it in the state
  // It will be marked as read when it displays, so once
  // we store it we don't care about the list of notifications
  const notificationId = notification ? notification.id : '';
  useEffect(() => {
    if (!notification) return;
    markAsRead([notificationId]);
    setNotificationToShow(notification);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationId]);

  // We only display if we stored a notification in the state
  if (!notificationToShow) return null;

  return (
    <NotificationPopupModal
      notification={notificationToShow}
      open={open}
      onClose={() => setOpen(false)}
    />
  );
};

export default NotificationPopup;
