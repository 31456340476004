import React, { Suspense, lazy } from 'react';
import Loading from '../../components/Loading/Loading';

const Settings = lazy(() =>
  import(/* webpackChunkName: "Settings" */ './Settings')
);

const LoadableSettings: React.FC = props => (
  <Suspense fallback={<Loading />}>
    <Settings {...props} />
  </Suspense>
);

export default LoadableSettings;
