import React from 'react';
import { PopupId } from 'types/graphql';

import { PopupNotification } from '../types';

import SensorManagementPlanRelease from './SensorManagementPlanRelease';
import IrrigationRecommendationRelease from './IrrigationRecommendationRelease';
import GenericRelease from './GenericRelease';

interface PopupContentProps {
  notification: PopupNotification;
  onClose: () => void;
}

const PopupContent: React.FC<PopupContentProps> = ({
  notification,
  onClose,
}) => {
  if (notification.__typename === 'NotificationRelease')
    return <GenericRelease notification={notification} onClose={onClose} />;
  switch (notification.popupToShow) {
    case PopupId.SensorManagementPlanRelease:
      return <SensorManagementPlanRelease onClose={onClose} />;
    case PopupId.IrrigationRecommendationRelease:
      return <IrrigationRecommendationRelease onClose={onClose} />;
    default:
      return null;
  }
};

export default PopupContent;
