import { navigate } from '@reach/router';

export const goUpOne = () => {
  const currentPath = window.location.pathname;
  const upOne = currentPath.slice(0, currentPath.lastIndexOf('/'));
  navigate(upOne);
};

// Redirects to archive directory page once archive complete
export const goBackToArchiveDirectory = () => {
  const currentPath = window.location.pathname.split('/');
  // get root url
  const backToArchives = currentPath[0] + '/archive';
  navigate(backToArchives);
};

// This could probably be removed eventually, it's just for react-router compat
const history = {
  push: (path: string) => navigate(path),
  location: window.location,
};

export default history;
