import { useMarkAsReadMutation } from 'types/graphql';
import { useAuth } from 'providers/Auth';

export function useMarkNotifictionAsRead() {
  const { peeking } = useAuth();
  const [markAsRead] = useMarkAsReadMutation();
  return (notificationIds: string[]) => {
    if (peeking) return Promise.resolve();
    return markAsRead({ variables: { input: { notificationIds } } });
  };
}
