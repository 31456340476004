import React, { Suspense, lazy } from 'react';
import Loading from 'components/Loading/Loading';

const Sensor = lazy(() => import(/* webpackChunkName: "Sensor" */ './Sensor'));

const LoadableSensor: React.FC = props => (
  <Suspense fallback={<Loading />}>
    <Sensor {...props} />
  </Suspense>
);

export default LoadableSensor;
