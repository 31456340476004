import { useState } from 'react';
import analytics from 'services/analytics';

export type FieldsSortMode = 'alphabetical' | 'hoursToDry';

// Default values
const store = {
  fieldsPageSort: 'alphabetical' as FieldsSortMode,
  fieldCardsCompact: false,
  weatherCardsCompact: false,
  irrigationSystemCardsCompact: false,
};
type Store = typeof store;
type StoreKey = keyof Store;

export const getStoreCopy = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const copy: any = {};
  Object.keys(store).forEach(k => {
    copy[k] = getItem(k as StoreKey);
  });
  return copy;
};

function getItem(key: StoreKey) {
  const res = localStorage.getItem(key);
  if (res !== null) return JSON.parse(res);
  else return store[key];
}

function setItem<K extends StoreKey>(key: K, val: Store[K]) {
  const serialized = JSON.stringify(val);
  localStorage.setItem(key, serialized);
}

export function useLocalStorage<K extends StoreKey>(key: K) {
  type Val = Store[K];
  const [value, setStateValue] = useState<Val>(getItem(key));

  const setValue = (newValue: Val) => {
    // Update state
    setStateValue(newValue);
    // Update localstorage
    setItem(key, newValue);
    // Notify analytics
    analytics.event('Localstorage', key, String(newValue));
  };

  return [value, setValue] as const;
}
